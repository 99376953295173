import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { history } from 'Store/Reducers/RootReducer';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.ADD_COMPENDIUM_INIT,
});

const success = () => ({
  type: BHP.ADD_COMPENDIUM_SUCCESS,
});

const failure = () => ({
  type: BHP.ADD_COMPENDIUM_FAILURE,
});

const addCompendium = (data, { route, path }) => async (dispatch) => {
  dispatch(init());
  try {
    const { legal_basis_source, executive_act_source } = data;
    dispatch(Actions.showLoader());
    delete data.legal_basis_source;
    delete data.executive_act_source;
    const {
      data: { uid },
    } = await Connector.addCompendium(data);
    dispatch(success());
    if (legal_basis_source) {
      const pdfForm = new FormData();
      pdfForm.append('file', legal_basis_source);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'legal_basis', file_name: legal_basis_source.name, file: pdfForm });
      // await Connector.addPdfToCompendium(pdfForm);
    }
    if (executive_act_source) {
      const pdfForm = new FormData();
      pdfForm.append('file', executive_act_source);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'executive_act', file_name: executive_act_source.name, file: pdfForm });
    }

    history.push({ pathname: path, state: { activeTab: route } });
    toast.success('Pomyślnie dodano wpis');
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    console.log(err);

    toast.error('Nie udało się dodać wpisu');
    return err;
  }
};

export default addCompendium;
