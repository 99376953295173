import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';
import { ROLES, USER_LEVELS } from 'Shared/enums';

const getAuthStore = (state: RootState) => state.authStore;
const authorizedUser = createSelector(getAuthStore, (state) => state.authorizedUser);
const token = createSelector(getAuthStore, (state) => state.token);
const getUserRoles = createSelector(authorizedUser, (user) => user.roles);
const getUserLevel = createSelector(authorizedUser, ({ level }) => level);
const getDevicesError = createSelector(getAuthStore, ({ devicesError }) => devicesError);
const getIsCustomerAreaManager = createSelector(
  authorizedUser,
  (user) =>
    user.roles.some(({ role }) => role === ROLES.CUSTOMER) &&
    (user.level === USER_LEVELS.MANAGING || user.level === USER_LEVELS.DETAILED || user.level === USER_LEVELS.DEMO) &&
    user.companies?.has_add_permission,
);

export { authorizedUser, token, getUserRoles, getUserLevel, getDevicesError, getIsCustomerAreaManager };
