// import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';

const success = () => ({
    type: BHP.RESET_COLUMN_FILTER_TERMS,
});

const resetColumnFilterTerms = () => async (dispatch) => {
    dispatch(success());
};

export default resetColumnFilterTerms;
