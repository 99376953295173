import * as React from 'react';
import { Icons } from 'Shared/components/forms/iconsField/iconsField';

const LearnMore = ({ newTab }: P) => {
  return (
    <div className="learnMore">
      <div className="learnMore__icon learnMore__icon--addition">
        <Icons type={newTab?.icon} />
      </div>
      <div className="learnMore__title">{newTab?.name}</div>
      <div className="learnMore__badge">Brak dostępu</div>
      <div className="learnMore__heading">Twój abonament obecnie nie obejmuje dostępu do tego obszaru. </div>
      <div className="learnMore__description">
        Znajdziesz w nim informacje potrzebne do skutecznej identyfikacji i oceny zgodności z wymaganiami prawnymi dotyczącymi tego obszaru.
      </div>
      <div className="learnMore__ctaDescription">Skontaktuj się z nami w celu uzyskania dostępu do obszaru {newTab?.name}.</div>
      <div className="learnMore__badges">
        <div className=" learnMore__badge" style={{ backgroundColor: newTab?.color }}>
          <div>+ 512 193 743</div>
          <div>singula@wiso.pl</div>
        </div>
      </div>
    </div>
  );
};
type P = {
  onCtaClick: () => void;
  newTab?: any;
};

export default LearnMore;
