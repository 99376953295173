import * as React from 'react';
import { ReactTable, Badge, TableSelect, Modal, SVG } from 'Shared/components';
import { AssociateUserRow } from './components';
import moment from 'moment';
import { SVG_TYPE, MODAL_TYPE, REQUEST_STATUS } from 'Shared/enums';
import UserForm from '../userForm';

const options = [
  {
    value: 'demo',
    label: 'Demo',
  },
  {
    value: 'basic',
    label: 'Podstawowy',
  },
  {
    value: 'detailed',
    label: 'Rozszerzony',
  },
  {
    value: 'managing',
    label: 'Nadzorujący',
  },
];

const PermissionCell = ({
  original: { area_bhp, area_environment, area_energy, area_additional, level, uuid },
  setOpened,
  setActiveUser,
  editUser,
  options,
  original,
  setType,
}: any) => {
  return (
    <div className="permissionsCell">
      <Badge title="BHP" color="blue" value={area_bhp} onClick={() => editUser({ area_bhp: !area_bhp }, uuid)} />
      <Badge title="OŚ" color="green" value={area_environment} onClick={() => editUser({ area_environment: !area_environment }, uuid)} />
      <Badge title="EN" color="orange" value={area_energy} onClick={() => editUser({ area_energy: !area_energy }, uuid)} />
      <Badge title="DOD" color="red" value={area_additional} onClick={() => editUser({ area_additional: !area_additional }, uuid)} />
      <TableSelect
        options={options}
        onClick={(val) => {
          editUser({ level: val }, uuid);
        }}
        value={level}
      />
      <div
        className="permissionsCell permissionsCell__edit"
        onClick={() => {
          setOpened(true);
          setType('EDIT');
          setActiveUser(original);
        }}>
        <SVG type={SVG_TYPE.EDIT} />
      </div>
      <div
        className="permissionsCell permissionsCell__delete"
        onClick={() => {
          setOpened(true);
          setType('DELETE');
          setActiveUser(original);
        }}>
        <SVG type={SVG_TYPE.DELETE} />
      </div>
    </div>
  );
};
const getColumns = (editUser, setOpened, setActiveUser, setType) => [
  {
    expander: true,
    width: 0,
    style: { display: 'none' },
  },
  {
    id: 'email',
    accessor: 'email',
    Header: 'Email',
    // style: { fontWeight: '600', minWidth: '250px' },
    style: { fontWeight: '600' },
    width: 250,
  },
  {
    id: 'firstLastName',
    accessor: ({ first_name, last_name }) => `${first_name} ${last_name}`,
    Header: 'Imię i nazwisko',
    // style: { fontWeight: '600', minWidth: '200px' },
    style: { fontWeight: '600' },
    width: 200,
  },
  {
    id: 'created_at',
    accessor: 'created_at',
    Header: 'Data dodania',
    style: { fontWeight: '600' },
    width: 200,
    /* eslint-disable */
    Cell: ({ value }) => (value ? moment(value).format('DD.MM.YYYY') : ''),
    /* eslint-enable */
  },
  {
    id: 'permissions',
    Header: 'Uprawnienia',
    // style: { fontWeight: '600', minWidth: '500' },
    style: { fontWeight: '600' },
    // minWidth: 200,
    width: 350,
    /* eslint-disable */
    Cell: ({ original }) => (
      <PermissionCell
        original={original}
        editUser={editUser}
        options={options}
        setOpened={setOpened}
        setActiveUser={setActiveUser}
        setType={setType}
      />
    ), // eslint-disable-line
    /* eslint-enable */
  },
];

const AssociatedUsersTable = ({ users, addUser, editUser, deleteUser, disabled, editingUser }: P) => {
  const [isOpened, setOpened] = React.useState(false);
  const [activeUser, setActiveUser] = React.useState({
    uuid: '',
    email: '',
    first_name: '',
    last_name: '',
    created_at: '',
    area_bhp: false,
    area_energy: false,
    area_environment: false,
  });

  React.useEffect(() => {
    editingUser.status === REQUEST_STATUS.SUCCESS && setOpened(false);
  }, [editingUser]);
  const [type, setType] = React.useState('');
  const onEditUser = (data) => {
    editUser(data, activeUser.uuid);
  };
  const onSubmit = () => {
    deleteUser(activeUser.uuid);
    setOpened(false);
  };
  const onClose = () => {
    setOpened(false);
  };
  const isEditLoading = () => editingUser.status === REQUEST_STATUS.INIT;
  return (
    <div className="associatedUsersTable">
      <AssociateUserRow addUser={addUser} options={options} disabled={disabled} />
      <ReactTable columns={getColumns(editUser, setOpened, setActiveUser, setType)} data={users} expanded={{ 0: true }} />
      <Modal opened={isOpened} onSubmit={onSubmit} onClose={onClose} type={type === 'EDIT' ? MODAL_TYPE.FORM : MODAL_TYPE.DELETE}>
        {type === 'EDIT' ? (
          <UserForm
            isLoading={isEditLoading()}
            onClose={onClose}
            initialValues={{ first_name: activeUser.first_name, last_name: activeUser.last_name }}
            onSubmit={onEditUser}
          />
        ) : (
          <>
            <div className="modal__title">Usuń podpiętego użytkownika</div>
            <div className="modal__info">
              <ul>
                <li>
                  Email: <span className="info-value">{activeUser.email}</span>
                </li>
                <li>
                  Imię i Nazwisko:
                  <span className="info-value">
                    {activeUser.first_name} {activeUser.last_name}
                  </span>
                </li>
                <li>
                  Data dodania <span className="info-value">{moment(activeUser.created_at).format('YYYY-MM-DD HH:mm')}</span>
                </li>
                <li>
                  Dostępy
                  <span className="info-areas">
                    <Badge title="BHP" color="blue" value={activeUser.area_bhp} disabled />
                    <Badge title="OŚ" color="green" value={activeUser.area_environment} disabled />
                    <Badge title="EN" color="orange" value={activeUser.area_energy} disabled />
                  </span>
                </li>
              </ul>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
type P = {
  users: Array<any>;
  addUser: (userData: any) => void;
  editUser: (userData: any, userUuid: any) => void;
  deleteUser: (userUuid: any) => void;
  disabled: boolean;
  editingUser: any;
};
export default AssociatedUsersTable;
