import { Connector } from 'Modules/NewTabModule';
import { NewTab } from '../EnumTypes/ActionEnumTypes';


const success = (data) => ({
  type: NewTab.CREATE_NEW_TAB_SUCCESS,
  payload: {
    data
  },
});

const failure = () => ({
  type: NewTab.CREATE_NEW_TAB_FAILURE,
});

const createNewTab = (form) => {
  return async (dispatch) => {
    try {
      const { data } = await Connector.createNewTab(form);
      dispatch(success(data));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default createNewTab;
