import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = () => ({
  type: BHP.EDIT_INSTITUTION_INIT,
});

const success = (institution) => ({
  type: BHP.EDIT_INSTITUTION_SUCCESS,
  payload: {
    institution,
  },
});

const failure = () => ({
  type: BHP.EDIT_INSTITUTION_FAILURE,
});

const editInstitution = (institution: any) => async (dispatch) => {
  dispatch(init());
  try {
    const { id } = institution;
    await Connector.editInstitution({ ...institution }, id);
    dispatch(success(institution));
    toast.success('Pomyślnie edytowano instytucję');
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się zapisać zmian');

    return err;
  }
};

export default editInstitution;
