export enum SUBMENU {
  DICT = 'DICT',
  INSTITUTIONS = 'INSTITUTIONS',
  COMPENDIUMS = 'COMPENDIUMS',
  NORMS = 'NORMS',
  DANGEROUS_WORKS = 'DANGEROUS_WORKS',
}
export const SUBMENU_ARR = [SUBMENU.DICT, SUBMENU.INSTITUTIONS, SUBMENU.COMPENDIUMS, SUBMENU.NORMS];

export const SUBMENU_MAP = new Map<SUBMENU, string>([
  [SUBMENU.DICT, 'Słownik'],
  // [SUBMENU.INSTITUTIONS, 'Instytucje kontrolne'],
  [SUBMENU.INSTITUTIONS, 'Strony zainteresowane'],
  [SUBMENU.COMPENDIUMS, 'Kompendium wiedzy'],
  [SUBMENU.NORMS, 'Normy'],
  [SUBMENU.DANGEROUS_WORKS, 'Prace niebezpieczne'],
]);
