import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getUserLevel } from 'Store/Selectors/AuthSelector';
import { getCompendiumsActiveCompany } from 'Store/Selectors/BhpSelector';
import { toast } from 'react-toastify';

const init = () => ({
  type: BHP.GET_SINGLE_COMPENDIUM_INIT,
});

const success = (compendium: any) => ({
  type: BHP.GET_SINGLE_COMPENDIUM_SUCCESS,
  payload: {
    compendium,
  },
});

const failure = () => ({
  type: BHP.GET_SINGLE_COMPENDIUM_FAILURE,
});

const getCompanies = (id) => async (dispatch, getState) => {
  dispatch(init());
  try {
    const level = getUserLevel(getState());
    const activeCompany = getCompendiumsActiveCompany(getState());
    const { data } = await Connector.getCompendium(id, level, activeCompany);
    dispatch(success(data));
    return(data);
  } catch (err) {
    toast.error('Nie udało się pobrać danych');
    dispatch(failure());
  }
};

export default getCompanies;
