import * as React from 'react';
import { ReactTable, Cells, NewRow, HighlightText } from 'Shared/components';
import moment from 'moment';
import Select from 'react-select';
import { getArea, getFiltersInLocal, saveFiltersInLocal } from 'Shared/utils';
let letter = '';

const prevFilter = {};

const filterMethod = (filter, row, setColumnFilterTerms) => {
  if (prevFilter[filter.id] !== filter.value) {
    prevFilter[filter.id] = filter.value;
    setColumnFilterTerms({ [filter.id]: filter.value.toLowerCase() });
  }
  return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
};
const getColumns = (
  onEditClick,
  isCustomerLogged,
  manageRow,
  areHiddenRowsShown,
  onDeleteRowClick,
  isEditorOrSuperAdmin,
  letterOptions,
  filters,
  isCustomerAreaManager,
  userLevel,
  setColumnFilterTerms,
  area,
) => [
  {
    id: 'edit',
    accessor: 'edit',
    width: 30,
    headerClassName: 'sticky  sticky--hidden',
    className: 'sticky cell--noPadding cell--white',
    show: isEditorOrSuperAdmin || isCustomerAreaManager,
    filterable: false,
    /* eslint-disable */
    Cell: ({ value, original }) =>
      isEditorOrSuperAdmin || (isCustomerAreaManager && original.company_id) ? (
        <Cells.Edit value={value} original={original} onClick={onEditClick} onDeleteClick={onDeleteRowClick} />
      ) : null,
    /* eslint-enable */
  },
  {
    id: 'letter',
    accessor: ({ term }) => term && term.charAt(0),
    Header: 'Lit.',
    width: 70,
    className: 'uppercase',
    /* eslint-disable */
    filterMethod: ({ value, id }, row) => {
      filters[id] = value;
      saveFiltersInLocal({ ...filters, area });
      if (value && value.length) {
        return value.some((item) => row[id].toLowerCase().indexOf(item.value.toLowerCase()) > -1);
      } else {
        return true;
      }
    },
    Filter: ({ filter, onChange, column: { id } }) => {
      !filter && onChange(filters.hasOwnProperty(id) ? filters[id] : null);
      return (
        <Select
          value={filter ? filter.value : ''}
          style={{ width: '100%' }}
          placeholder="A-Z"
          isMulti
          onChange={(value) => onChange(value)}
          options={letterOptions}
          classNamePrefix="react-select"
        />
      );
    },
    Cell: ({ value }) => {
      if (letter.toLowerCase() !== value.toLowerCase()) {
        letter = value;
        return <div className="cell--centered">{value}</div>;
      }
      return '';
    },
    /* eslint-enable */
    style: { fontWeight: '700' },
  },
  {
    id: 'term',
    accessor: 'term',
    Header: 'Pojęcie',
    width: 240,
    style: { fontWeight: '700' },
    /* eslint-disable */
    Cell: ({ value, original, column }) => (
      <>
        <NewRow created_at={original.created_at} /> <HighlightText text={value} columnId={column.id} />
      </>
    ),
    // Filter: ({ filter, onChange }) => {
    //   return <Cells.InputFilter value={filter ? filter.value : 'mati'} onChange={onChange} />;
    // },
    /* eslint-enable */
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
  },
  {
    id: 'content',
    accessor: 'content',
    Header: 'Treść',
    style: { whiteSpace: 'unset', fontWeight: '600' },
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    /* eslint-disable */
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
  },
  {
    id: 'normative_act',
    accessor: 'normative_act',
    Header: 'Akt normatywny',
    style: { whiteSpace: 'unset' },
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    /* eslint-disable */
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    /* eslint-enable */
    maxWidth: 500,
  },
  {
    id: 'updated_at',
    accessor: 'updated_at',
    Header: 'Data aktualizacji',
    filterable: false,
    Cell: ({ original }) => moment(original.updated_at).format('YYYY-MM-DD HH:mm'),
    width: 180,
  },
  {
    id: 'visibility',
    accessor: 'visibility',
    Header: 'WIDOCZNOŚĆ',
    width: 150,
    show: isCustomerLogged,
    filterable: false,
    /* eslint-disable */
    Cell: ({ original: { id } }) => <Cells.VisibilityCell areHiddenRowsShown={areHiddenRowsShown} onClick={() => manageRow('dictionaries', id)} />,
    /* eslint-enable */
  },
];

const DictionaryDesktop = ({
  dictionaries,
  onEditRowClick,
  isCustomerLogged,
  manageRow,
  areHiddenRowsShown,
  onDeleteRowClick,
  isEditorOrSuperAdmin,
  // filterByLetter,
  showExtendedText,
  hideExtendedText,
  isExtendedTextVisible,
  isCustomerAreaManager,
  userLevel,
  setColumnFilterTerms,
}: P) => {
  let filterByLetter = React.useMemo(
    () =>
      dictionaries
        .map(({ term }) => term)
        .map((val) => {
          const value = dictionaries.find(({ term }) => term.charAt(0).toLowerCase() === val.charAt(0).toLowerCase());
          return value.term.charAt(0).toUpperCase();
        }),
    [dictionaries],
  );

  filterByLetter = React.useMemo(() => Array.from(new Set(filterByLetter)), [filterByLetter]);

  const letterOptions = React.useMemo(
    () =>
      filterByLetter.map((letter) => {
        return {
          value: letter,
          label: letter,
        };
      }),
    [filterByLetter],
  );
  const area = getArea();
  const tempFilters = getFiltersInLocal();
  const filters = tempFilters.area === area ? tempFilters : {};

  const columns = getColumns(
    onEditRowClick,
    isCustomerLogged,
    manageRow,
    areHiddenRowsShown,
    onDeleteRowClick,
    isEditorOrSuperAdmin,
    letterOptions,
    filters,
    isCustomerAreaManager,
    userLevel,
    setColumnFilterTerms,
    area,
  );
  return (
    <div className="dictionaryTable">
      {dictionaries.length ? (
        <ReactTable
          filterable
          areRowsColored
          columns={columns}
          data={dictionaries}
          className="bhpTable bhpTable--dictionaries"
          showExtendedText={showExtendedText}
          hideExtendedText={hideExtendedText}
          showExtendTextManage={false}
          isExtendedTextVisible={isExtendedTextVisible}
        />
      ) : null}
    </div>
  );
};
type P = {
  dictionaries: Array<any>;
  onEditRowClick: (dictionaryData) => void;
  onDeleteRowClick: (dictionaryData) => void;
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  manageRow: (type, id) => void;
  areHiddenRowsShown: boolean;
  // filterByLetter: any;
  showExtendedText: () => void;
  hideExtendedText: () => void;
  isExtendedTextVisible: boolean;
  isCustomerAreaManager: boolean;
  userLevel: string;
  setColumnFilterTerms: (data: any) => void;
};
export default React.memo(DictionaryDesktop);
// export default React.memo(DictionaryDesktop);
