import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import Changelog from './Main';
import { Actions, Selectors } from 'Modules/UserModule';

type ConnectedP = {
  changelogs: Array<any>;
};

type DispatchedP = {
  actions: {
    getChangelogs: () => void;
    findChangelogs: (start_date, end_date) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getChangelogs: Actions.getChangelog,
      findChangelogs: Actions.findChangelogs,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  changelogs: Selectors.getChangelogs(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Changelog);
