import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.GET_COMPANIES_OPTIONS_INIT,
});

const success = (companies: Array<any>) => ({
  type: User.GET_COMPANIES_OPTIONS_SUCCESS,
  payload: {
    companies,
  },
});

const failure = () => ({
  type: User.GET_COMPANIES_OPTIONS_FAILURE,
});

const getCompaniesOptions = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getCompaniesOptions();
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getCompaniesOptions;
