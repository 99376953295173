import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import { Selectors } from 'Modules/UserModule';
import { Actions } from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.EDIT_USER_INIT,
});

const success = (userData, uuid) => ({
  type: User.EDIT_USER_SUCCESS,
  payload: {
    userData,
    uuid,
  },
});

const failure = () => ({
  type: User.EDIT_USER_FAILURE,
});

const editUser = (userData: any, userUuid) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const state = getState();
    const { roles } = state.userStore.company.data.users.find((user) => user.uuid === userUuid);
    const role = [];
    roles.map((singleRole) => {
      return role.push(singleRole.role);
    });
    await Connector.editUser({ ...userData, role }, userUuid);
    dispatch(success(userData, userUuid));
    toast.success('Pomyślnie edytowano użytkownika');
    dispatch(Actions.hideLoader());
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default editUser;
