import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_NORMS_INIT,
});

const success = (norms: Array<any>) => ({
  type: BHP.GET_NORMS_SUCCESS,
  payload: {
    norms,
  },
});

const failure = () => ({
  type: BHP.GET_NORMS_FAILURE,
});

const getNorms = (isMyCompanyEntries = false, areNewlyAdded = false) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getNorms(getArea(), isMyCompanyEntries, areNewlyAdded);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getNorms;
