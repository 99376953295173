import { Connector, Actions as KnowledgeActions } from 'Modules/KnowledgeModule';
import { Knowledge } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: Knowledge.ADD_TOPICALITY_INIT,
});

const success = () => ({
  type: Knowledge.ADD_TOPICALITY_SUCCESS,
});

const failure = () => ({
  type: Knowledge.ADD_TOPICALITY_FAILURE,
});

const addTopicality = (topicalityData: any) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    await Connector.addTopicality(topicalityData);
    dispatch(success());
    toast.success('Dodano nową aktualność');
    dispatch(Actions.hideLoader());
    await dispatch(KnowledgeActions.getTopicalities());
  } catch (err) {
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default addTopicality;
