import API from './Config';
import { AxiosPromise } from 'axios';
import { USER_LEVELS } from 'Shared/enums';

export function getInstitutions(area, isMyCompanyEntries, areNewlyAdded): AxiosPromise<any> {
  return API.get(`institutions?area=${area}${isMyCompanyEntries ? '&my_company_entries=true' : ''}${areNewlyAdded ? '&newly_added=true' : ''}`);
}

export function getDictionaries(area, isMyCompanyEntries, areNewlyAdded): AxiosPromise<any> {
  return API.get(`dictionaries?area=${area}${isMyCompanyEntries ? '&my_company_entries=true' : ''}${areNewlyAdded ? '&newly_added=true' : ''}`);
}

export function getCompendiums(
  area: string,
  getDetailed: boolean,
  company_uuid: string,
  isMyRequirements: boolean,
  isMyCompanyEntries: boolean,
  areNewlyAdded: boolean,
): AxiosPromise<any> {
  return API.get(
    `compendiums?area=${area}&view_level=${getDetailed ? 'detailed' : 'basic'}${company_uuid && `&company_uuid=${company_uuid}`}${
      isMyRequirements ? `&my_requirements=true` : ''
    }${isMyCompanyEntries ? `&my_company_entries=true` : ''}${areNewlyAdded ? '&newly_added=true' : ''}`,
  );
}
export function getCompendiumRatingsCounter(
  area: string,
  getDetailed: boolean,
  company_uuid: string,
  isMyRequirements: boolean,
  isMyCompanyEntries: boolean,
  areNewlyAdded: boolean,
): AxiosPromise<any> {
  return API.get(
    `compendiums/rating-status?area=${area}&view_level=${getDetailed ? 'detailed' : 'basic'}${company_uuid && `&company_uuid=${company_uuid}`}${
      isMyRequirements ? `&my_requirements=true` : ''
    }${isMyCompanyEntries ? `&my_company_entries=true` : ''}${areNewlyAdded ? '&newly_added=true' : ''}`,
  );
}

export function getCompendium(id: number, level: string, company_uuid): AxiosPromise<any> {
  return API.get(`compendiums/${id}/${level === USER_LEVELS.BASIC ? 'basic' : 'detailed'}${company_uuid ? `?company_uuid=${company_uuid}` : ''}`);
}
export function editCompendium(compendium: any, id: number): AxiosPromise<any> {
  return API.put(`compendiums/${id}`, { ...compendium });
}
export function editDictionary(dictionary: any, id: number): AxiosPromise<any> {
  return API.put(`dictionaries/${id}`, { ...dictionary });
}
export function editInstitution(institution: any, id: number): AxiosPromise<any> {
  return API.put(`institutions/${id}`, { ...institution });
}
export function editNorm(norm: any, id: number): AxiosPromise<any> {
  return API.put(`norms/${id}`, { ...norm });
}
export function getNorms(area, isMyCompanyEntries, areNewlyAdded): AxiosPromise<any> {
  return API.get(`norms?area=${area}${isMyCompanyEntries ? '&my_company_entries=true' : ''}${areNewlyAdded ? '&newly_added=true' : ''}`);
}
export function hideRow(table_type, row_id): AxiosPromise<any> {
  return API.post('hidden-rows', {
    table_type,
    row_id,
  });
}

export function destroyHiddenRow(type: 'compendiums' | 'dictionaries' | 'institutions' | 'norms', id: number): AxiosPromise<any> {
  return API.delete(`hidden-rows/${id}/${type}`);
}

export function getHiddenRows(
  screen: 'bhp' | 'energy' | 'environment' | 'additional',
  type: 'compendiums' | 'dictionaries' | 'institutions' | 'norms',
): AxiosPromise<any> {
  return API.get(`hidden-rows/${screen}/${type}`);
}

export function addRatingCompatibility(data): AxiosPromise<any> {
  return API.post('rating-compatibilities', data);
}

export function deleteRatingCompatibilities(uuid): AxiosPromise<any> {
  return API.delete(`rating-compatibilities/${uuid}`, );
}

export function addCompendium(compendiumData: any): AxiosPromise<any> {
  return API.post(`compendiums`, compendiumData);
}

export function addPdfToCompendium(data: any): AxiosPromise<any> {
  return API.post(`pdfs/?parent_uid=${data.compendium_uid}&type=${data.type}&parent_type=compendium&file_name=${data.file_name}`, data.file);
}

export function addCompendiumEvidence(data: any, file: any): AxiosPromise<any> {
  return API.post(`pdfs/?parent_type=rating_compatibility&parent_uid=${data.parent_uid}&type=${data.type}&file_name=${data.file_name}`, file);
}

export function addDictionary(dictionaryData: any): AxiosPromise<any> {
  return API.post(`dictionaries`, dictionaryData);
}

export function addInstitution(institutionData: any): AxiosPromise<any> {
  return API.post(`institutions`, institutionData);
}

export function addNorm(normData: any): AxiosPromise<any> {
  return API.post(`norms`, normData);
}

export function deleteDictionary(id: any): AxiosPromise<any> {
  return API.delete(`dictionaries/${id}`);
}

export function deleteInstitution(id: any): AxiosPromise<any> {
  return API.delete(`institutions/${id}`);
}

export function deleteCompendium(id: any): AxiosPromise<any> {
  return API.delete(`compendiums/${id}`);
}

export function copyCompendium(uid: any): AxiosPromise<any> {
  return API.put(`compendiums/copy/${uid}`);
}

export function deleteNorm(id: any): AxiosPromise<any> {
  return API.delete(`norms/${id}`);
}

export function removeFiles(uuid: any): AxiosPromise<any> {
  return API.delete(`pdfs/${uuid}`);
}

export function searchCompendiums(area, searchData, activeCompany): AxiosPromise<any> {
  return API.get(`compendiums/search/?search=${searchData}&area=${area}${activeCompany ? `&company_uuid=${activeCompany.value}` : ''}`);
}

export function downloadFile(uuid): AxiosPromise<any> {
  return API.get(`pdfs/${uuid}`, { responseType: 'blob' });
}

export function addTask(data: any): AxiosPromise<any> {
  return API.post(`tasks`, data);
}

export function editTask(data: any, uuid: number): AxiosPromise<any> {
  return API.put(`tasks/${uuid}`, data);
}

export function addEvidence(data: any, uuid: number): AxiosPromise<any> {
  return API.put(`tasks/${uuid}/evidence`, data);
}

export function removeTask(uuid: any): AxiosPromise<any> {
  return API.delete(`tasks/${uuid}`);
}

export function addEvidenceFile(data: any, file): AxiosPromise<any> {
  return API.post(`pdfs/?parent_uid=${data.uuid}&parent_type=tasks&file_name=${data.name}`, file);
}
