// import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';

const success = (filters) => ({
  type: BHP.SET_COLUMN_FILTER_TERMS,
  payload: {
    filters,
  },
});

const setColumnFilterTerms = (data) => async (dispatch) => {
  dispatch(success(data));
};

export default setColumnFilterTerms;
