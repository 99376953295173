import * as React from 'react';
// import { Formik, Field } from 'formik';
// import { RegularField } from 'Shared/components/forms';
import { CompendiumCounters } from 'Shared/components';

let timer;
const onChange = (value, onSearch) => {
  clearTimeout(timer);
  timer = setTimeout(() => onSearch(value), 700);
};

const SearchForm = ({ onSearch, searchedTerm = '', compendiumCounterRatings }: P) => {
  const [value, setValue] = React.useState(searchedTerm);
  // React.useEffect(() => {
  //   setValue(searchedTerm);
  // }, [searchedTerm]);
  return (
    // <Formik initialValues={{ term: '' }} onSubmit={({ term }) => onSearch(term)}>
    //   {({ handleSubmit, errors }) => (
    //     <form onSubmit={handleSubmit} className="search__form">
    //       <Field wrapperClassName={'gray'} name="term" type="term" placeholder="Wyszukaj" component={RegularField} errors={errors} />
    //     </form>
    //   )}
    // </Formik>
    <div className="search__form">
      <div className="form__field form__field--term form__field--gray">
        <input
          type="text"
          placeholder="Wyszukaj"
          value={value}
          onChange={(e) => {
            onChange(e.currentTarget.value, onSearch);
            setValue(e.currentTarget.value);
          }}
        />
      </div>
      <CompendiumCounters compendiumCounterRatings={compendiumCounterRatings} />
    </div>
  );
};

type P = {
  onSearch: (term: string) => void;
  searchedTerm: string;
  compendiumCounterRatings: any;
};
export default SearchForm;
