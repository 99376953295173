import React from 'react';
import ReactTooltip from 'react-tooltip';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  value: any;
  original: any;
  onClick?: (rowData: any) => void;
  onCopyClick?: (rowData: any) => void;
  onDeleteClick?: (rowData: any) => void;
};

const Edit = ({ value, onClick, onDeleteClick, original, onCopyClick }: P) => (
  <>
    {onClick && (
      <div className="edit--cell" onClick={() => onClick(original)} data-tip="Edytuj" data-for="managementTooltip">
        <SVG type={SVG_TYPE.EDIT} />
      </div>
    )}
    {onDeleteClick && (
      <div className="edit--cell" onClick={() => onDeleteClick(original)} data-tip="Usuń" data-for="managementTooltip">
        <SVG type={SVG_TYPE.ROW_DELETE} />
      </div>
    )}
    {onCopyClick && (
      <div className="edit--cell" onClick={() => onCopyClick(original)} data-tip="Duplikuj" data-for="managementTooltip">
        <SVG type={SVG_TYPE.COPY} />
      </div>
    )}
    <ReactTooltip id="managementTooltip" place="right" effect="solid" className={`tooltip`} delayShow={200} />
  </>
);

export default Edit;
