import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import DashboardUsers from './Main';
import { Actions, Types, Selectors as UserSelectors } from 'Modules/UserModule';
import { Selectors } from 'Modules/AuthModule';
import { REQUEST_STATUS } from 'Shared/enums';

type ConnectedP = {
  usersList: Array<Types.SingleUserInList>;
  companies: Array<any>;
  authorizedUser: any;
  history?: any;
  editingEmployee: {
    status: REQUEST_STATUS;
  };
};

type DispatchedP = {
  actions: {
    getUsers: () => void;
    getCompanies: () => void;
    addUser: (userData: any, company_uuid: any) => void;
    editCompany: (companyData: any, uuid: any) => void;
    editEmployee: (userData: any, userUuid: any) => void;
    deleteUser: (uuid: string, isEmployee: boolean) => void;
    deleteCompany: (uuid: string) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getUsers: Actions.getUsers,
      getCompanies: Actions.getCompanies,
      addUser: Actions.addUser,
      editEmployee: Actions.editEmployee,
      deleteUser: Actions.deleteUser,
      editCompany: Actions.editCompany,
      deleteCompany: Actions.deleteCompany,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  usersList: state.userStore.usersList,
  companies: state.userStore.companies.data,
  authorizedUser: Selectors.authorizedUser(state),
  editingEmployee: UserSelectors.getEditingEmployeeStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardUsers);
