import * as React from 'react';
import image from 'Assets/Img/knowledge_post_1.png';
import moment from 'moment';

/* eslint-disable */
const KnowledgePosts = ({ knowledgePosts, deviceType }: P) => {
  if (knowledgePosts.length > 0) {
    // const latestPost = knowledgePosts[0];
    // const rightColumnPosts = [knowledgePosts[1], knowledgePosts[2]];
    // const posts = deviceType === 'SMARTPHONE' ? knowledgePosts : knowledgePosts.filter((_, idx) => idx > 2);

    return (
      <div className="knowledgePosts">
        {/* {deviceType !== 'SMARTPHONE' && (
          <div className="knowledgePosts__top">
            <div className="knowledgePosts__latest">
              <Post post={latestPost} />
            </div>
            <div className="knowledgePosts__column">
              {rightColumnPosts.map((post) => (
                <Post key={post.id} post={post} />
              ))}
            </div>
          </div>
        )} */}
        <div className="knowledgePosts__inner">
          {knowledgePosts.map((post) => (
            <Post key={post.id} post={post} />
          ))}
        </div>
      </div>
    );
  }
  return null;
};
/* eslint-enable */
type P = {
  knowledgePosts: Array<any>;
  deviceType: string;
};

export default KnowledgePosts;

const Post = ({ post }: any) => {
  return (
    <div className="knowledgePost">
      <a href={post.link} target="_blank" rel="noopener noreferrer">
        <div className="knowledgePost__image">
          <img src={post.better_featured_image?.source_url || image} alt={post.title} />
        </div>
        {/* <div className="knowledgePost__title">{post.title}</div> */}
        <div className="knowledgePost__title">{post.title.length > 75 ? `${post.title.substring(0, 75)}...` : post.title}</div>
        {/* <div className="knowledgePost__date">Dodano {post.createDate}</div> */}
        <div className="knowledgePost__date">Dodano {moment(post.createDate).format('YYYY-MM-DD')}</div>
      </a>
    </div>
  );
};
