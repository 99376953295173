import * as React from 'react';
import { MainType } from './Container';
import { browserName, deviceType, browserVersion, osName, osVersion } from 'react-device-detect';
import Logo from 'Assets/Img/singula-logo.svg';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import moment from 'moment';

type P = {
  history?: any;
  location?: any;
};
type S = {
  newDeviceName: string;
};

class DeviceManagement extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      newDeviceName: '',
    };
  }

  addDevice = (currentDeviceType) => {
    const newDevice = {
      device_type: currentDeviceType,
      device_name: this.state.newDeviceName,
      browser_name: browserName,
      browser_version: browserVersion,
      os_name: osName,
      os_version: osVersion,
    };
    this.props.addNewDevice(newDevice);
  };

  render() {
    const {
      devicesError: { connectedDevices, isMaxDevices },
      logout,
    } = this.props;
    const { newDeviceName } = this.state;
    const currentDeviceType = deviceType === 'browser' ? 'desktop' : 'mobile';

    const dateMonthAgo = moment().subtract(1, 'months').utc().valueOf();
    const isActivationLimitUsed = connectedDevices.find(
      ({ device_change_date }) => device_change_date !== null && moment(device_change_date).utc().valueOf() >= dateMonthAgo,
    );
    // const isDeviceToChange = connectedDevices.some(({ device_type }) => device_type === currentDeviceType);
    const isDeviceToChange = connectedDevices.length;
    return (
      <div className="device-management">
        <div className="device-management__container">
          <div className="device-management__header">
            <div className="device-management__logo-entire">
              <img src={Logo} alt="" />
              {isMaxDevices ? (
                <>
                  <h1>
                    Limit ilości aktywnych urządzeń <br /> został przekroczony
                  </h1>
                  {isActivationLimitUsed ? (
                    <p>
                      Zaloguj się na Twoje aktywne urządzenia by <br /> zalogować się do systemu
                    </p>
                  ) : (
                    <p>
                      Nazwij swoje urządzenie. Twoje aktualne <br /> urządzenie zostanie usuniete.
                    </p>
                  )}
                </>
              ) : isDeviceToChange ? (
                <>
                  <h1>Zastąp swoje aktualne urządzenie</h1>
                  <p>
                    Aby korzystać z systemu należy zastąpić <br /> aktualne urządzenie i przeglądarkę <br /> internetową tego konta
                  </p>
                </>
              ) : (
                <>
                  <h1>Nazwij swoje urządzenie</h1>
                  <p>
                    Aby korzystać z systemu należy przypisać <br /> urządzenie i przeglądarkę <br /> internetową do konta
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="device-management__cards-container">
            {connectedDevices.map(({ device_type, device_name, browser_name, os_name }, idx: number) => (
              <div
                className={`device-management__card ${
                  isDeviceToChange && !isActivationLimitUsed ? 'device-management__card--selected' : ''
                  // currentDeviceType === device_type && !isActivationLimitUsed ? 'device-management__card--selected' : ''
                }`}
                key={idx}>
                <SVG type={device_type === 'mobile' ? SVG_TYPE.MOBILE : SVG_TYPE.DESKTOP} />
                <p className="device-management__card--os-name">{os_name}</p>
                <p className="device-management__card--device-name">{device_name}</p>
                <p className="device-management__card--browser-name">{browser_name}</p>
                {isDeviceToChange && !isActivationLimitUsed && (
                  <p className="device-management__card--delete-info">To urządzenie zostanie usunięte</p>
                )}
                {/* {currentDeviceType === device_type && !isActivationLimitUsed && (
                  <p className="device-management__card--delete-info">To urządzenie zostanie usunięte</p>
                )} */}
              </div>
            ))}
            <div
              className={`device-management__card device-management__card--add ${
                isActivationLimitUsed && isDeviceToChange ? 'device-management__card--disabled' : ''
              }`}>
              <SVG type={currentDeviceType === 'mobile' ? SVG_TYPE.MOBILE : SVG_TYPE.DESKTOP} />
              <p className="device-management__card--os-name">{osName}</p>
              <p className="device-management__card--device-name">{newDeviceName ? newDeviceName : 'Nowe urządzenie'}</p>
              <p className="device-management__card--browser-name">{browserName}</p>
              {isActivationLimitUsed && isDeviceToChange ? (
                <p className="device-management__card--limit-info">Brak dostępnych aktywacji</p>
              ) : (
                <>
                  <div className="device-management__card--input">
                    <input
                      type="text"
                      placeholder="Wpisz nazwę urządzenia (dowolna nazwa)"
                      value={newDeviceName}
                      onChange={(e) => this.setState({ newDeviceName: e.target.value })}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="device-management__buttons">
            <p className="device-management__activations-limit">
              limit urządzeń: {connectedDevices.length ? <span className="device-management__activations-limit--disabled">0</span> : '1'}
            </p>
            {/* <p className="device-management__activations-limit">
              limit urządzeń desktopowych:{' '}
              {connectedDevices.some(({ device_type }) => device_type === 'desktop') ? (
                <span className="device-management__activations-limit--disabled">0</span>
              ) : (
                '1'
              )}
            </p>
            <p className="device-management__activations-limit">
              limit urządzeń mobilnych:{' '}
              {connectedDevices.some(({ device_type }) => device_type === 'mobile') ? (
                <span className="device-management__activations-limit--disabled">0</span>
              ) : (
                '1'
              )}
            </p> */}
            {/* {(connectedDevices.some(({ device_type }) => device_type === currentDeviceType) || isMaxDevices) && ( */}
            {(isDeviceToChange || isMaxDevices) && (
              <p className="device-management__activations-limit">
                limit możliwych zmian: {isActivationLimitUsed ? <span className="device-management__activations-limit--disabled">0</span> : '1'}
              </p>
            )}
            <p className="device-management__activations-info">
              {isActivationLimitUsed && isDeviceToChange && (
                <>
                  Nowa możliwa zmiana będzie dostepna <br /> po 1 miesiącu od ostatniej zmiany <br /> (
                  {moment(isActivationLimitUsed.device_change_date).add(1, 'months').format('YYYY-MM-DD')}){/* (change_device_date + 1msc) */}
                </>
              )}
            </p>
            <p className="device-management__activations-problems">
              W razie problemów skontaktuj się <br /> z serwisem pod numerem: <br /> <span> 32 320 74 83</span>
            </p>
            <button
              className={`btn btn--default btn--login btn--center ${newDeviceName ? '' : 'btn--disabled'}`}
              onClick={() => newDeviceName && this.addDevice(currentDeviceType)}>
              Zaloguj się
            </button>
            <button className="btn btn--transparent btn--cancel" onClick={logout}>
              Anuluj
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DeviceManagement;
