import * as React from 'react';
import { SUBMENU } from './../../consts';
import { getDeviceType } from 'Shared/utils';
import { MobileSubmenu, DesktopSubmenu } from './components';

type P = {
  active: SUBMENU;
  onActiveChange: (submenuItem: SUBMENU) => void;
  onSearch: (term: string) => void;
  onAddClick: () => void;
};

const SubmenuHeader = ({ active, onActiveChange, onSearch, onAddClick }: P) => {
  const deviceType = getDeviceType();
  console.log(active);
  return (
    <div className="dashboardUsers__header">
      <div className="dashboardUsers__submenu">{deviceType !== 'SMARTPHONE' ? <DesktopSubmenu active={active} onActiveChange={onActiveChange} /> : <MobileSubmenu active={active} onActiveChange={onActiveChange} />}</div>
      {/* <div className="dashboardUsers__addClient">
      </div> */}
      <div className="dashboardUsers__searchBox">
        {active === SUBMENU.CLIENTS && (
          <button className="dashboardUsers__addClient" onClick={onAddClick}>
            Dodaj klienta +
          </button>
        )}
        {/* <SearchForm onSearch={onSearch} /> */}
      </div>
    </div>
  );
};

export default SubmenuHeader;
