import { Connector } from 'Modules/NotificationModule';
import { Notification } from '../EnumTypes/ActionEnumTypes';


const success = (data) => ({
  type: Notification.GET_NOTIFICATIONS_NOT_READ_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Notification.GET_NOTIFICATIONS_NOT_READ_FAILURE,
});

const getNotificationsNotRead = () => {
  return async (dispatch) => {
    try {
      const { data } = await Connector.getNotificationsNotRead();
      dispatch(success(data.is_not_read_notification));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default getNotificationsNotRead;
