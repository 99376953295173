import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_COMPANY_INIT,
});

const success = (company: any) => ({
  type: User.GET_COMPANY_SUCCESS,
  payload: {
    company,
  },
});

const failure = () => ({
  type: User.GET_COMPANY_FAILURE,
});

const getCompanies = (guid) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getCompany(guid);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log(err);
  }
};

export default getCompanies;
