import { Connector } from 'Modules/KnowledgeModule';
import { Knowledge } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Knowledge.GET_KNOWLEDGE_POSTS_INIT,
});

const success = (knowledgePosts: any) => ({
  type: Knowledge.GET_KNOWLEDGE_POSTS_SUCCESS,
  payload: {
    knowledgePosts,
  },
});

const failure = () => ({
  type: Knowledge.GET_KNOWLEDGE_POSTS_FAILURE,
});

const getKnowledgePosts = () => {
  return async (dispatch) => {
    dispatch(init());
    try {
      const { data: knowledgePosts } = await Connector.getKnowledgePosts();
      dispatch(success(knowledgePosts));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default getKnowledgePosts;
