import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getBhpStore = (state: RootState) => state.bhp;
const getInstitutions = createSelector(getBhpStore, ({ institutions: { data = [] } }) => data);
const getInstitutionsStatus = createSelector(getBhpStore, ({ institutions: { status } }) => status);
const getDictionaries = createSelector(getBhpStore, ({ dictionaries: { data = [] } }) => data);
const getDictionariesStatus = createSelector(getBhpStore, ({ dictionaries: { status } }) => status);
const getCompendium = createSelector(getBhpStore, ({ compendium: { data } }) => data);
const getCompendiumStatus = createSelector(getBhpStore, ({ compendium: { status } }) => status);
const getCompendiums = createSelector(getBhpStore, ({ compendiums: { data = [] } }) => data);
const getCompendiumsStatus = createSelector(getBhpStore, ({ compendiums: { status } }) => status);
const getCompendiumTermLetters = createSelector(getCompendiums, (compendiums = []) =>
  Array.from(new Set(compendiums.map(({ term }) => term.charAt(0).toUpperCase()))),
);
const getNorms = createSelector(getBhpStore, ({ norms: { data = [] } }) => data);
const getNormsStatus = createSelector(getBhpStore, ({ norms: { status } }) => status);
const getEditingCompendiumStatus = createSelector(getBhpStore, ({ editingCompendium }) => editingCompendium);
const getEditingDictionaryStatus = createSelector(getBhpStore, ({ editingDictionary }) => editingDictionary);
const getEditingInstitutionStatus = createSelector(getBhpStore, ({ editingInstitution }) => editingInstitution);
const getEditingNormStatus = createSelector(getBhpStore, ({ editingNorm }) => editingNorm);
const getCompendiumsActiveCompany = createSelector(getBhpStore, ({ compendiums: { activeCompany } }) => activeCompany);
const getSearchedTerm = createSelector(getBhpStore, ({ compendiums: { searchData } }) => searchData);
const getSearchedTermPathname = createSelector(getBhpStore, ({ compendiums: { pathname } }) => pathname);
const getColumnFilterTerms = createSelector(getBhpStore, ({ columnFiltersTerm }) => columnFiltersTerm);
const getCompendiumActiveRating = createSelector(getBhpStore, ({ compendiumActiveRating: { data = {} } }) => data);
const getCompendiumRatingsCounter = createSelector(getBhpStore, ({ compendiumCounterRatings: { data = {} } }) => data);

export {
  getEditingInstitutionStatus,
  getEditingCompendiumStatus,
  getEditingDictionaryStatus,
  getEditingNormStatus,
  getInstitutions,
  getInstitutionsStatus,
  getDictionaries,
  getDictionariesStatus,
  getCompendiums,
  getCompendiumsStatus,
  getCompendiumTermLetters,
  getCompendium,
  getCompendiumStatus,
  getNorms,
  getNormsStatus,
  getCompendiumsActiveCompany,
  getSearchedTerm,
  getCompendiumActiveRating,
  getColumnFilterTerms,
  getSearchedTermPathname,
  getCompendiumRatingsCounter
};
