import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.EDIT_SETTINGS_INIT,
});

const success = (settingsData: Array<any>) => ({
  type: User.EDIT_SETTINGS_SUCCESS,
  payload: {
    settingsData,
  },
});

const failure = () => ({
  type: User.EDIT_SETTINGS_FAILURE,
});

const editSettings = (settingsData) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await UserModule.Connector.editSettings(settingsData);
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
    toast.success('Pomyślnie zapisano zmiany');
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log(err);
  }
};

export default editSettings;
