import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
// import { history } from 'Store/Reducers/RootReducer';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.ADD_EVIDENCE_INIT,
});

const success = (data) => ({
  type: BHP.ADD_EVIDENCE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: BHP.ADD_EVIDENCE_FAILURE,
});

const addEvidence = (data, uuid) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const response = await Connector.addEvidence(data, uuid);
    dispatch(success(response.data));
    toast.success('Pomyślnie dodano dowód do zadania');
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    toast.error('Nie udało się dodać dowodu do zadania');
    return err;
  }
};

export default addEvidence;
