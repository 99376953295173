import { Connector } from 'Modules/KnowledgeModule';
import { Knowledge } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = (uuid) => ({
  type: Knowledge.DELETE_TOPICALITY_INIT,
  payload: {
    uuid,
  },
});

const success = (uuid) => ({
  type: Knowledge.DELETE_TOPICALITY_SUCCESS,
  payload: {
    uuid,
  },
});

const failure = () => ({
  type: Knowledge.DELETE_TOPICALITY_FAILURE,
});

const deleteUser = (topicalityUuid) => async (dispatch) => {
  dispatch(init(topicalityUuid));
  try {
    dispatch(Actions.showLoader());
    await Connector.deleteTopicality(topicalityUuid);
    dispatch(success(topicalityUuid));
    toast.success('Pomyślnie usunięto aktualność');
    dispatch(Actions.hideLoader());
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default deleteUser;
