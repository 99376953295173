import { Field, Formik } from 'formik';
import * as React from 'react';
import { Button } from 'Shared/components';
import { RegularField, ColorPicker } from 'Shared/components/forms';
import LabelForm from '../labelForm';
import * as Yup from 'yup';

type P = {
  onSubmit: (formValues: any) => any;
  isCustomerAreaManager: boolean;
};

const initialValues = {
  normative_act: '',
  requirement: '',
  extra_activities: '',
};

const getNormSchema = () =>
  Yup.object().shape({
    normative_act: Yup.string().required('Pole wymagane'),
    requirement: Yup.string().required('Pole wymagane'),
    extra_activities: Yup.string().required('Pole wymagane'),
  });

const AddNormForm = ({ onSubmit, isCustomerAreaManager }: P) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getNormSchema()}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="areaManagement__form">
          <LabelForm value="Norma" />
          <Field placeholder="dodaj tekst..." type="textarea" name="normative_act" component={RegularField} errors={errors} />
          <LabelForm value="Tytuł" />
          <Field placeholder="dodaj tekst..." type="textarea" name="requirement" component={RegularField} errors={errors} />
          <LabelForm value="Komentarz" />
          <Field placeholder="dodaj tekst..." type="textarea" name="extra_activities" component={RegularField} errors={errors} />
          {isCustomerAreaManager ? (
            <>
              <LabelForm value="Kolor wpisu" />
              <Field name="color" type="text" component={ColorPicker} errors={errors} />
            </>
          ) : null}
          <div className="areaManagement__form--save">
            <Button label="Dodaj wpis +" classNames="btn btn--navy-blue" type="submit" />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddNormForm;
