import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Selectors } from 'Modules/UserModule';
import { Actions } from 'Modules/ViewManagementModule';
import { Actions as UserActions } from 'Modules/UserModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.DELETE_USER_INIT,
});

const success = () => ({
  type: User.DELETE_USER_SUCCESS,
});

const failure = () => ({
  type: User.DELETE_USER_FAILURE,
});

const deleteUser = (userUuid, isEmployee = false) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const uuid = Selectors.getUuid(getState());
    await Connector.deleteUser(userUuid);
    dispatch(success());
    isEmployee ? await dispatch(UserActions.getUsers()) : await dispatch(UserActions.getCompany(uuid));
    toast.success('Pomyślnie usunięto użytkownika');
    dispatch(Actions.hideLoader());
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default deleteUser;
