import * as React from 'react';
import { InfoBox, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  activeCompendium: any;
  goBack: () => void;
  onClick: (id: number) => void;
};

const CompendiumPreview = ({ activeCompendium: { term, department, legal_basis, executive_act, legal_requirements, released_from_responsibility, organization_type }, goBack, onClick }: P) => (
  <div className="compendiumPreview">
    <PreviewHeading term={term} department={department} goBack={goBack} onClick={onClick} />
    <div className="compendiumPreview__details">
      <InfoBox mobile title={'Ocena zgodności'} content={''} rating contentBolded />
      <InfoBox mobile title={'GŁÓWNA PODSTAWA PRAWNA'} content={legal_basis} contentBolded />
      <InfoBox mobile title={'AKT WYKONAWCZY'} content={executive_act} contentBolded />
      <InfoBox mobile title={'WYMAGANIE PRAWNE'} content={legal_requirements} contentBolded />
      <InfoBox mobile title={'TYP ORGANIZACJI'} content={organization_type} contentBolded />
      <InfoBox mobile title={'ZWOLNIENI Z OBOWIĄZKU'} content={released_from_responsibility} contentBolded />
    </div>
  </div>
);

export default CompendiumPreview;

const PreviewHeading = ({ term, department, goBack, onClick }: any) => (
  <div className="compendiumPreview__heading">
    <div className="compendiumPreview__goBack">
      <span onClick={goBack}> &lt; Wróc</span>
    </div>
    <div className="compendiumPreview__heading__inner">
      <div className="compendiumPreview__term">{term}</div>
      <div className="compendiumPreview__department">{department}</div>
      <div className="compendiumPreview__options">
        <button className="btn btn--blue compendiumPreview__extendedView" onClick={onClick}>
          <span className="star">
            <SVG type={SVG_TYPE.STAR} />
          </span>
          Widok rozszerzony <span className="arrow">&gt;</span>
        </button>
      </div>
    </div>
  </div>
);
