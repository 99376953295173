import { store } from 'Store/Store';
import { getRouterPathname } from 'Store/Selectors/RouterSelector';
import { AREA_TYPE } from 'Shared/enums';
const getArea = () => {
  const location = getRouterPathname(store.getState());
  if (location.includes('energia') || location.includes('energy')) {
    return AREA_TYPE.ENERGY;
  }
  if (location.includes('bhp')) {
    return AREA_TYPE.BHP;
  }
  if (location.includes('ochrona-srodowiska') || location.includes('environment')) {
    return AREA_TYPE.ENVIRONMENT;
  }
  if (location.includes('dodatkowa')|| location.includes('additional')) {
    return AREA_TYPE.ADDITIONAL;
  }
};
export default getArea;
