import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField, Badge } from 'Shared/components/forms';
import { Button } from 'Shared/components';
import * as Yup from 'yup';

type P = {
  isLoading: boolean;
  onClose: () => void;
  initialValues: any;
  onSubmit: (formValues: any) => any;
  type: string;
};

const getTopicalitySchema = () =>
  Yup.object().shape({
    content: Yup.string().required('Pole wymagane'),
    link: Yup.string().required('Pole wymagane'),
  });

const TopicalityForm = ({ onClose, initialValues, onSubmit, isLoading, type }: P) => (
  <div className="editModal">
    <h1 className="font-lg bold-700"> {type === 'ADD' ? 'Dodaj nową aktualność' : 'Edytuj aktualność'}</h1>
    <Formik
      initialValues={initialValues}
      validationSchema={getTopicalitySchema()}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="editModal--form">
          <Field type="textarea" name="content" label="Treść aktualności" component={RegularField} errors={errors} />
          <Field type="text" name="link" label="Link" component={RegularField} errors={errors} />
          <label>Kategorie</label>
          <div className="editModal__topicality-categories">
            <Field label="BHP" name="bhp" component={Badge} topicalityBadge errors={errors} color="blue" />
            <Field label="OŚ" name="environment" component={Badge} topicalityBadge errors={errors} color="green" />
            <Field label="EN" name="energy" component={Badge} topicalityBadge errors={errors} color="orange" />
            <Field label="INNE" name="others" component={Badge} topicalityBadge errors={errors} color="gray" />
          </div>
          <div className="editModal__actions">
            <Button label="Anuluj" classNames="btn btn--transparent btn--cancel" onClick={onClose} />
            <Button label={type === 'ADD' ? 'Dodaj' : 'Zapisz zmiany'} classNames="btn btn--navy-blue" type="submit" isLoading={isLoading} />
          </div>
        </form>
      )}
    </Formik>
  </div>
);

export default TopicalityForm;
