import * as React from 'react';
import { SVG_TYPE } from 'Shared/enums';
import editIcon from 'Assets/Img/Icons/edit-icon.svg';

type P = {
  type: SVG_TYPE;
};
const SVG = ({ type }: P) => {
  switch (type) {
    case SVG_TYPE.FILE: {
      return (
        <svg className="svg svg--file" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.4 16.5">
          <g transform="translate(0.5 0.5)">
            <path
              className="a"
              d="M13.75,3H7.55A1.55,1.55,0,0,0,6,4.55v12.4A1.55,1.55,0,0,0,7.55,18.5h9.3a1.55,1.55,0,0,0,1.55-1.55V7.65Z"
              transform="translate(-6 -3)"
            />
            <path className="a" d="M21,3V7.65h4.65" transform="translate(-13.25 -3)" />
            <path className="a" d="M18.2,19.5H12" transform="translate(-8.9 -10.975)" />
            <path className="a" d="M18.2,25.5H12" transform="translate(-8.9 -13.875)" />
            <path className="a" d="M13.55,13.5H12" transform="translate(-8.9 -8.075)" />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.FILE_ADD: {
      return (
        <svg className="svg svg--file-add" width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.8531 3.50938L7.49063 0.146875C7.39688 0.053125 7.27031 0 7.1375 0H0.5C0.223437 0 0 0.223437 0 0.5V13.5C0 13.7766 0.223437 14 0.5 14H10.5C10.7766 14 11 13.7766 11 13.5V3.86406C11 3.73125 10.9469 3.60313 10.8531 3.50938ZM9.84688 4.09375H6.90625V1.15313L9.84688 4.09375ZM9.875 12.875H1.125V1.125H5.84375V4.5C5.84375 4.67405 5.91289 4.84097 6.03596 4.96404C6.15903 5.08711 6.32595 5.15625 6.5 5.15625H9.875V12.875ZM6 6.375C6 6.30625 5.94375 6.25 5.875 6.25H5.125C5.05625 6.25 5 6.30625 5 6.375V8.0625H3.3125C3.24375 8.0625 3.1875 8.11875 3.1875 8.1875V8.9375C3.1875 9.00625 3.24375 9.0625 3.3125 9.0625H5V10.75C5 10.8188 5.05625 10.875 5.125 10.875H5.875C5.94375 10.875 6 10.8188 6 10.75V9.0625H7.6875C7.75625 9.0625 7.8125 9.00625 7.8125 8.9375V8.1875C7.8125 8.11875 7.75625 8.0625 7.6875 8.0625H6V6.375Z"
            fill="black"
          />
        </svg>
      );
    }
    case SVG_TYPE.NOTIFICATION: {
      return (
        <svg className="svg svg--notification" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_93_149)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7924 28.8853C12.0674 29.7871 12.6248 30.5766 13.3824 31.1375C14.14 31.6986 15.0577 32.0014 16.0005 32.0014C16.9432 32.0014 17.8609 31.6986 18.6185 31.1375C19.3761 30.5766 19.9335 29.7871 20.2085 28.8853H11.7924ZM17.6543 3.36613V1.65405C17.6543 1.06301 17.339 0.516855 16.8271 0.221335C16.3152 -0.0741852 15.6846 -0.0741852 15.1727 0.221335C14.6609 0.516855 14.3455 1.06299 14.3455 1.65405V3.36613C15.4451 3.24499 16.5547 3.24499 17.6543 3.36613H17.6543ZM27.3695 26.1077C27.2732 26.0601 27.1845 25.9983 27.1065 25.9244C23.7374 23.1457 23.9705 18.07 24.1654 13.8777C24.2045 13.03 24.2419 12.223 24.2419 11.4051C24.226 10.4717 24.0073 9.55272 23.6008 8.71232C23.1944 7.87179 22.6099 7.12971 21.888 6.53763C20.2367 5.16243 18.1488 4.42179 16 4.44899C13.8513 4.42191 11.7632 5.16253 10.112 6.53763C9.39016 7.12971 8.80579 7.87179 8.39931 8.71232C7.99285 9.55286 7.774 10.4717 7.75816 11.4051C7.75816 12.223 7.79524 13.0301 7.83462 13.8777C8.02858 18.0697 8.26243 23.1457 4.89382 25.9244C4.63299 26.1545 4.47776 26.1267 4.30339 26.406V26.4061C4.2062 26.5676 4.16933 26.7585 4.19943 26.9446C4.22954 27.1308 4.32464 27.3002 4.46797 27.423C4.6112 27.5456 4.79317 27.6136 4.98183 27.6147H27.0184C27.207 27.6136 27.389 27.5456 27.5322 27.423C27.6756 27.3003 27.7707 27.1308 27.8008 26.9446C27.8309 26.7585 27.794 26.5676 27.6968 26.4061C27.6174 26.2784 27.5042 26.1751 27.3697 26.1077L27.3695 26.1077Z"
              fill="#252D53"
            />
          </g>
          <defs>
            <clipPath id="clip0_93_149">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case SVG_TYPE.NOTIFICATION_DOT: {
      return (
        <svg className="svg svg--notification-dot" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_122_52)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7924 28.8853C12.0674 29.7871 12.6248 30.5766 13.3824 31.1375C14.14 31.6986 15.0577 32.0014 16.0005 32.0014C16.9432 32.0014 17.8609 31.6986 18.6185 31.1375C19.3761 30.5766 19.9335 29.7871 20.2085 28.8853H11.7924ZM17.6543 3.36613V1.65405C17.6543 1.06301 17.339 0.516855 16.8271 0.221335C16.3152 -0.0741852 15.6846 -0.0741852 15.1727 0.221335C14.6609 0.516855 14.3455 1.06299 14.3455 1.65405V3.36613C15.4451 3.24499 16.5547 3.24499 17.6543 3.36613H17.6543ZM27.3695 26.1077C27.2732 26.0601 27.1845 25.9983 27.1065 25.9244C23.7374 23.1457 23.9705 18.07 24.1654 13.8777C24.2045 13.03 24.2419 12.223 24.2419 11.4051C24.226 10.4717 24.0073 9.55272 23.6008 8.71232C23.1944 7.87179 22.6099 7.12971 21.888 6.53763C20.2367 5.16243 18.1488 4.42179 16 4.44899C13.8513 4.42191 11.7632 5.16253 10.112 6.53763C9.39016 7.12971 8.80579 7.87179 8.39931 8.71232C7.99285 9.55286 7.774 10.4717 7.75816 11.4051C7.75816 12.223 7.79524 13.0301 7.83462 13.8777C8.02858 18.0697 8.26243 23.1457 4.89382 25.9244C4.63299 26.1545 4.47776 26.1267 4.30339 26.406V26.4061C4.2062 26.5676 4.16933 26.7585 4.19943 26.9446C4.22954 27.1308 4.32464 27.3002 4.46797 27.423C4.6112 27.5456 4.79317 27.6136 4.98183 27.6147H27.0184C27.207 27.6136 27.389 27.5456 27.5322 27.423C27.6756 27.3003 27.7707 27.1308 27.8008 26.9446C27.8309 26.7585 27.794 26.5676 27.6968 26.4061C27.6174 26.2784 27.5042 26.1751 27.3697 26.1077L27.3695 26.1077Z"
              fill="#252D53"
            />
            <circle cx="23" cy="6" r="5.5" fill="#0080FF" stroke="#F6F6F6" />
          </g>
          <defs>
            <clipPath id="clip0_122_52">
              <rect width="32" height="32" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case SVG_TYPE.NOTIFICATION_OPEN: {
      return (
        <svg className="svg svg--open" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22.2222 22.2222H2.77778V2.77778H12.5V0H0V25H25V12.5H22.2222V22.2222Z" fill="#707070" />
          <path d="M22.2222 0H15.2777V2.77778H20.2638L13.7916 9.25L15.75 11.2083L22.2222 4.73611V9.72222H25V0H22.2222Z" fill="#707070" />
        </svg>
      );
    }
    case SVG_TYPE.GREEN_FACE: {
      return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_87_1015)">
            <path
              d="M15.856 8.428C15.856 9.89712 15.4204 11.3332 14.6042 12.5548C13.788 13.7763 12.6279 14.7284 11.2706 15.2906C9.91329 15.8528 8.41976 15.9999 6.97887 15.7133C5.53798 15.4267 4.21444 14.7192 3.17561 13.6804C2.13679 12.6416 1.42934 11.318 1.14273 9.87713C0.856119 8.43624 1.00322 6.94272 1.56543 5.58543C2.12763 4.22814 3.0797 3.06804 4.30123 2.25184C5.52276 1.43564 6.95888 1 8.428 1C9.40346 1 10.3694 1.19213 11.2706 1.56542C12.1718 1.93871 12.9906 2.48586 13.6804 3.17561C14.3701 3.86536 14.9173 4.68422 15.2906 5.58543C15.6639 6.48663 15.856 7.45254 15.856 8.428Z"
              stroke="#70AD47"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.45703 9.91406C5.81737 10.3596 6.26898 10.7228 6.78146 10.9791C7.29395 11.2354 7.85541 11.379 8.42803 11.4001C9.00083 11.3791 9.56248 11.2356 10.0751 10.9793C10.5878 10.723 11.0396 10.3597 11.4 9.91406"
              stroke="#70AD47"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path d="M6.2002 6.19995H6.2072" stroke="#70AD47" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.6572 6.19995H10.6642" stroke="#70AD47" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_87_1015">
              <rect width="16.856" height="16.856" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case SVG_TYPE.CHAT: {
      return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.875 9.625H6.88417M11 9.625H11.0092M15.125 9.625H15.1342M6.41667 16.5V18.6409C6.41667 19.1293 6.41667 19.3735 6.51679 19.499C6.60387 19.6081 6.73591 19.6715 6.87549 19.6714C7.03599 19.6712 7.2267 19.5186 7.60811 19.2135L9.79478 17.4642C10.2415 17.1068 10.4648 16.9281 10.7135 16.8011C10.9342 16.6884 11.1691 16.606 11.4118 16.5561C11.6854 16.5 11.9714 16.5 12.5434 16.5H14.85C16.3901 16.5 17.1602 16.5 17.7485 16.2003C18.2659 15.9366 18.6866 15.5159 18.9503 14.9985C19.25 14.4102 19.25 13.6401 19.25 12.1V7.15C19.25 5.60986 19.25 4.83978 18.9503 4.25153C18.6866 3.73408 18.2659 3.31338 17.7485 3.04973C17.1602 2.75 16.3901 2.75 14.85 2.75H7.15C5.60986 2.75 4.83978 2.75 4.25153 3.04973C3.73408 3.31338 3.31338 3.73408 3.04973 4.25153C2.75 4.83978 2.75 5.60986 2.75 7.15V12.8333C2.75 13.6858 2.75 14.112 2.8437 14.4618C3.09799 15.4108 3.83924 16.152 4.78825 16.4063C5.13796 16.5 5.56419 16.5 6.41667 16.5ZM7.33333 9.625C7.33333 9.87813 7.12813 10.0833 6.875 10.0833C6.62187 10.0833 6.41667 9.87813 6.41667 9.625C6.41667 9.37187 6.62187 9.16667 6.875 9.16667C7.12813 9.16667 7.33333 9.37187 7.33333 9.625ZM11.4583 9.625C11.4583 9.87813 11.2531 10.0833 11 10.0833C10.7469 10.0833 10.5417 9.87813 10.5417 9.625C10.5417 9.37187 10.7469 9.16667 11 9.16667C11.2531 9.16667 11.4583 9.37187 11.4583 9.625ZM15.5833 9.625C15.5833 9.87813 15.3781 10.0833 15.125 10.0833C14.8719 10.0833 14.6667 9.87813 14.6667 9.625C14.6667 9.37187 14.8719 9.16667 15.125 9.16667C15.3781 9.16667 15.5833 9.37187 15.5833 9.625Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case SVG_TYPE.FILE_ACTIVE: {
      return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8.4865 1.20436L2.4973 2.81016C2.10019 2.91663 1.76164 3.17649 1.55613 3.53258C1.35062 3.88866 1.29498 4.3118 1.40145 4.70891L4.61305 16.6873C4.71952 17.0844 4.97938 17.423 5.33547 17.6285C5.69155 17.834 6.11469 17.8896 6.5118 17.7832L15.4956 15.3745C15.8927 15.268 16.2313 15.0081 16.4368 14.652C16.6423 14.296 16.6979 13.8728 16.5914 13.4757L14.1827 4.49191L8.4865 1.20436Z"
            stroke="#0080FF"
            strokeWidth="2.00024"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.48633 1.20435L9.69054 5.69571L14.1819 4.49151"
            stroke="#0080FF"
            strokeWidth="2.00024"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M12.1906 9.03825L6.20215 10.6439" stroke="#0080FF" strokeWidth="2.00024" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M12.9944 12.0329L7.00586 13.6385" stroke="#0080FF" strokeWidth="2.00024" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6.89654 7.24783L5.39941 7.64923" stroke="#0080FF" strokeWidth="2.00024" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      );
    }
    case SVG_TYPE.USER: {
      return (
        <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            id="Icon awesome-user-tie"
            d="M8.231 9.4C9.16057 9.4 10.0693 9.12435 10.8422 8.60791C11.6151 8.09147 12.2175 7.35743 12.5732 6.49861C12.929 5.6398 13.022 4.69479 12.8407 3.78308C12.6593 2.87137 12.2117 2.03391 11.5544 1.3766C10.8971 0.719293 10.0596 0.271662 9.14792 0.0903113C8.23621 -0.0910392 7.2912 0.00203645 6.43239 0.357768C5.57358 0.7135 4.83954 1.31591 4.32309 2.08882C3.80665 2.86173 3.531 3.77043 3.531 4.7C3.531 5.94652 4.02618 7.14198 4.9076 8.0234C5.78902 8.90483 6.98448 9.4 8.231 9.4ZM11.751 10.6L10 17.632L8.824 12.632L10 10.576H6.468L7.644 12.634L6.468 17.634L4.711 10.598C3.44405 10.6543 2.24755 11.1967 1.37018 12.1125C0.492821 13.0282 0.00209232 14.2468 0 15.515L0 17.044C0 17.5118 0.185849 17.9605 0.516664 18.2913C0.847478 18.6222 1.29616 18.808 1.764 18.808H14.7C15.1678 18.808 15.6165 18.6222 15.9473 18.2913C16.2782 17.9605 16.464 17.5118 16.464 17.044V15.515C16.4619 14.2468 15.9712 13.0282 15.0938 12.1125C14.2165 11.1967 13.0199 10.6543 11.753 10.598L11.751 10.6Z"
            fill="black"
          />
        </svg>
      );
    }
    case SVG_TYPE.COPY: {
      return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_424_480)">
            <path
              d="M12 6V3.9C12 3.05992 12 2.63988 11.8365 2.31901C11.6927 2.03677 11.4632 1.8073 11.181 1.66349C10.8601 1.5 10.4401 1.5 9.6 1.5H3.9C3.05992 1.5 2.63988 1.5 2.31901 1.66349C2.03677 1.8073 1.8073 2.03677 1.66349 2.31901C1.5 2.63988 1.5 3.05992 1.5 3.9V9.6C1.5 10.4401 1.5 10.8601 1.66349 11.181C1.8073 11.4632 2.03677 11.6927 2.31901 11.8365C2.63988 12 3.05992 12 3.9 12H6M8.4 16.5H14.1C14.9401 16.5 15.3601 16.5 15.681 16.3365C15.9632 16.1927 16.1927 15.9632 16.3365 15.681C16.5 15.3601 16.5 14.9401 16.5 14.1V8.4C16.5 7.55992 16.5 7.13988 16.3365 6.81901C16.1927 6.53677 15.9632 6.3073 15.681 6.16349C15.3601 6 14.9401 6 14.1 6H8.4C7.55992 6 7.13988 6 6.81901 6.16349C6.53677 6.3073 6.3073 6.53677 6.16349 6.81901C6 7.13988 6 7.55992 6 8.4V14.1C6 14.9401 6 15.3601 6.16349 15.681C6.3073 15.9632 6.53677 16.1927 6.81901 16.3365C7.13988 16.5 7.55992 16.5 8.4 16.5Z"
              stroke="#2B2B2B"
              strokeWidth="1.8"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_424_480">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case SVG_TYPE.GREY_FACE: {
      return (
        <svg className="grey-face" xmlns="http://www.w3.org/2000/svg" width="16.856" height="16.856" viewBox="0 0 16.856 16.856">
          <g transform="translate(1 1)">
            <path className="a" d="M17.856,10.428A7.428,7.428,0,1,1,10.428,3,7.428,7.428,0,0,1,17.856,10.428Z" transform="translate(-3 -3)" />
            <path className="a" d="M12,21.145A25.5,25.5,0,0,1,14.971,21a25.5,25.5,0,0,1,2.971.145" transform="translate(-7.543 -11.506)" />
            <path className="a" d="M13.5,13.5h.007" transform="translate(-8.3 -8.3)" />
            <path className="a" d="M22.5,13.5h.007" transform="translate(-12.843 -8.3)" />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.FILE_REMOVE: {
      return (
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.4933 4.22012L6.86667 0.22012C6.80434 0.151058 6.72824 0.0958063 6.64328 0.0579169C6.55832 0.0200276 6.46636 0.00033987 6.37333 0.000119984H1.70667C1.48517 -0.00252208 1.26531 0.0384933 1.05967 0.120824C0.854019 0.203155 0.666603 0.325189 0.508121 0.479956C0.349639 0.634723 0.223196 0.819193 0.136012 1.02283C0.0488281 1.22647 0.00261092 1.44529 0 1.66679V11.6668C0.00261092 11.8883 0.0488281 12.1071 0.136012 12.3107C0.223196 12.5144 0.349639 12.6989 0.508121 12.8536C0.666603 13.0084 0.854019 13.1304 1.05967 13.2127C1.26531 13.2951 1.48517 13.3361 1.70667 13.3335H8.96C9.1815 13.3361 9.40135 13.2951 9.607 13.2127C9.81265 13.1304 10.0001 13.0084 10.1585 12.8536C10.317 12.6989 10.4435 12.5144 10.5307 12.3107C10.6178 12.1071 10.6641 11.8883 10.6667 11.6668V4.66679C10.6662 4.50156 10.6045 4.34239 10.4933 4.22012V4.22012ZM6.66667 2.00012L8.49333 4.00012H7.16C7.09019 3.99591 7.02191 3.97783 6.95916 3.94694C6.89641 3.91606 6.84044 3.87298 6.79451 3.82023C6.74859 3.76748 6.71363 3.70611 6.69168 3.6397C6.66973 3.5733 6.66123 3.50318 6.66667 3.43345V2.00012ZM8.96 12.0001H1.70667C1.66025 12.0028 1.61376 11.9963 1.56985 11.981C1.52594 11.9657 1.48549 11.9419 1.45081 11.9109C1.41613 11.88 1.3879 11.8425 1.36774 11.8006C1.34758 11.7587 1.33589 11.7132 1.33333 11.6668V1.66679C1.33589 1.62036 1.34758 1.5749 1.36774 1.533C1.3879 1.4911 1.41613 1.45359 1.45081 1.42263C1.48549 1.39166 1.52594 1.36784 1.56985 1.35254C1.61376 1.33724 1.66025 1.33075 1.70667 1.33345H5.33333V3.43345C5.32257 3.92454 5.5065 4.39994 5.84498 4.7559C6.18346 5.11186 6.649 5.31949 7.14 5.33345H9.33333V11.6668C9.33078 11.7132 9.31909 11.7587 9.29893 11.8006C9.27877 11.8425 9.25054 11.88 9.21586 11.9109C9.18118 11.9419 9.14072 11.9657 9.09682 11.981C9.05291 11.9963 9.00642 12.0028 8.96 12.0001Z"
            fill="black"
          />
          <path
            d="M6.66666 7.33344H3.99999C3.82318 7.33344 3.65361 7.40367 3.52859 7.5287C3.40357 7.65372 3.33333 7.82329 3.33333 8.0001C3.33333 8.17691 3.40357 8.34648 3.52859 8.47151C3.65361 8.59653 3.82318 8.66677 3.99999 8.66677H6.66666C6.84347 8.66677 7.01304 8.59653 7.13807 8.47151C7.26309 8.34648 7.33333 8.17691 7.33333 8.0001C7.33333 7.82329 7.26309 7.65372 7.13807 7.5287C7.01304 7.40367 6.84347 7.33344 6.66666 7.33344Z"
            fill="black"
          />
        </svg>
      );
    }
    case SVG_TYPE.FILE_DOWNLOAD: {
      return (
        <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.39067 0C6.7037 7.64874e-05 7.00673 0.110289 7.24667 0.311333L7.33333 0.390667L10.276 3.33333C10.4973 3.55468 10.6338 3.84685 10.6613 4.15867L10.6667 4.276V12C10.6668 12.3364 10.5397 12.6604 10.311 12.907C10.0823 13.1537 9.76877 13.3048 9.43333 13.33L9.33333 13.3333H1.33333C0.996949 13.3334 0.672956 13.2064 0.426301 12.9777C0.179647 12.7489 0.0285623 12.4354 0.00333348 12.1L6.68453e-08 12V1.33333C-0.000106386 0.996949 0.126938 0.672956 0.355665 0.426301C0.584392 0.179647 0.897897 0.0285622 1.23333 0.00333341L1.33333 0H6.39067ZM5.33333 1.33333H1.33333V12H9.33333V5.33333H6.33333C6.06812 5.33333 5.81376 5.22798 5.62623 5.04044C5.43869 4.8529 5.33333 4.59855 5.33333 4.33333V1.33333ZM5.33333 6C5.51014 6 5.67971 6.07024 5.80474 6.19526C5.92976 6.32029 6 6.48986 6 6.66667V8.472L6.276 8.196C6.40173 8.07456 6.57014 8.00737 6.74493 8.00888C6.91973 8.0104 7.08694 8.08052 7.21055 8.20412C7.33415 8.32773 7.40426 8.49493 7.40578 8.66973C7.4073 8.84453 7.34011 9.01293 7.21867 9.13867L5.80533 10.5533C5.74342 10.6153 5.66989 10.6645 5.58896 10.698C5.50803 10.7316 5.42128 10.7489 5.33367 10.7489C5.24606 10.7489 5.15931 10.7316 5.07837 10.698C4.99744 10.6645 4.92392 10.6153 4.862 10.5533L3.448 9.13867C3.38433 9.07717 3.33354 9.00361 3.2986 8.92227C3.26366 8.84093 3.24527 8.75345 3.2445 8.66493C3.24373 8.57641 3.2606 8.48863 3.29412 8.4067C3.32764 8.32477 3.37714 8.25033 3.43974 8.18774C3.50233 8.12514 3.57677 8.07564 3.6587 8.04212C3.74063 8.0086 3.82841 7.99173 3.91693 7.9925C4.00545 7.99327 4.09293 8.01166 4.17427 8.0466C4.25561 8.08154 4.32917 8.13233 4.39067 8.196L4.66667 8.472V6.66667C4.66667 6.48986 4.7369 6.32029 4.86193 6.19526C4.98695 6.07024 5.15652 6 5.33333 6V6ZM6.66667 1.60933V4H9.05733L6.66667 1.60933Z"
            fill="black"
          />
        </svg>
      );
    }
    case SVG_TYPE.PARAGRAPH: {
      return (
        <svg className="svg svg--paragraph" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 20">
          <defs></defs>
          <text className="a" transform="translate(0 16)">
            <tspan x="0" y="0">
              §
            </tspan>
          </text>
        </svg>
      );
    }
    case SVG_TYPE.CLOSE: {
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_302_2)">
            <path
              d="M13.6533 2.34133C12.9111 1.59749 12.0292 1.00761 11.0584 0.605584C10.0875 0.203557 9.0468 -0.00269902 7.996 -0.00133606C3.57733 -0.00133606 -0.00466919 3.58066 -0.00466919 7.99933C-0.00466919 10.2087 0.891331 12.2093 2.33933 13.6573C3.08155 14.4012 3.96342 14.9911 4.93428 15.3931C5.90514 15.7951 6.94586 16.0014 7.99666 16C12.4153 16 15.9973 12.418 15.9973 7.99933C15.9973 5.79 15.1013 3.78933 13.6533 2.34133V2.34133ZM12.6253 12.626C12.0181 13.2347 11.2967 13.7175 10.5023 14.0465C9.70798 14.3756 8.85645 14.5444 7.99666 14.5433C4.38133 14.5433 1.45066 11.6127 1.45066 7.99733C1.44959 7.13754 1.61842 6.28601 1.94746 5.49168C2.2765 4.69734 2.75926 3.97585 3.368 3.36866C3.97504 2.75991 4.69641 2.27714 5.49064 1.9481C6.28487 1.61906 7.1363 1.45023 7.996 1.45133C11.6107 1.45133 14.5413 4.382 14.5413 7.99666C14.5425 8.85636 14.3737 9.70781 14.0446 10.502C13.7156 11.2963 13.2328 12.0176 12.624 12.6247L12.6253 12.626Z"
              fill="black"
            />
            <path
              d="M9.02466 8.00001L11.5947 5.43002C11.7214 5.29183 11.7899 5.11003 11.7859 4.92255C11.7818 4.73507 11.7055 4.5564 11.5728 4.42384C11.4402 4.29129 11.2615 4.21508 11.074 4.21114C10.8865 4.2072 10.7048 4.27582 10.5667 4.40268L10.5673 4.40201L7.99733 6.97201L5.42733 4.40201C5.28915 4.27524 5.10734 4.20674 4.91986 4.2108C4.73238 4.21487 4.55371 4.29119 4.42116 4.42383C4.2886 4.55647 4.2124 4.73519 4.20845 4.92267C4.20451 5.11015 4.27313 5.29192 4.4 5.43002L4.39933 5.42935L6.96933 7.99935L4.39933 10.5693C4.32702 10.6357 4.26888 10.716 4.22842 10.8054C4.18795 10.8948 4.16599 10.9914 4.16387 11.0895C4.16174 11.1876 4.17949 11.2852 4.21604 11.3762C4.25259 11.4673 4.3072 11.55 4.37656 11.6194C4.44593 11.6888 4.52862 11.7435 4.61966 11.7801C4.71071 11.8167 4.80822 11.8345 4.90632 11.8325C5.00443 11.8304 5.10111 11.8085 5.19053 11.7681C5.27995 11.7277 5.36028 11.6696 5.42666 11.5973L5.42733 11.5967L7.99733 9.02668L10.5673 11.5967C10.6337 11.669 10.714 11.7271 10.8033 11.7676C10.8927 11.8081 10.9894 11.83 11.0875 11.8321C11.1856 11.8343 11.2831 11.8165 11.3742 11.78C11.4653 11.7434 11.548 11.6888 11.6174 11.6194C11.6868 11.5501 11.7415 11.4674 11.7781 11.3763C11.8147 11.2853 11.8325 11.1878 11.8305 11.0897C11.8284 10.9916 11.8065 10.8949 11.7661 10.8055C11.7257 10.7161 11.6676 10.6357 11.5953 10.5693L11.5947 10.5687L9.02466 8.00001Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_302_2">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    }
    case SVG_TYPE.DELETE: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.672" height="10.672" viewBox="0 0 10.672 10.672">
          <path
            className="a"
            d="M7.359,10.961l3.034-3.034a.954.954,0,0,0,0-1.349L9.718,5.9a.954.954,0,0,0-1.349,0L5.336,8.938,2.3,5.9a.954.954,0,0,0-1.349,0l-.674.674a.954.954,0,0,0,0,1.349l3.034,3.034L.279,13.995a.954.954,0,0,0,0,1.349l.674.674a.954.954,0,0,0,1.349,0l3.034-3.034L8.37,16.018a.954.954,0,0,0,1.349,0l.674-.674a.954.954,0,0,0,0-1.349Z"
            transform="translate(0 -5.625)"
          />
        </svg>
      );
    }
    case SVG_TYPE.CALENDAR: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.813" height="13.5" viewBox="0 0 11.813 13.5">
          <path
            className="a"
            d="M11.5,4.219H.316A.317.317,0,0,1,0,3.9V2.953A1.266,1.266,0,0,1,1.266,1.688H2.531V.316A.317.317,0,0,1,2.848,0H3.9a.317.317,0,0,1,.316.316V1.688H7.594V.316A.317.317,0,0,1,7.91,0H8.965a.317.317,0,0,1,.316.316V1.688h1.266a1.266,1.266,0,0,1,1.266,1.266V3.9A.317.317,0,0,1,11.5,4.219ZM.316,5.063H11.5a.317.317,0,0,1,.316.316v6.855A1.266,1.266,0,0,1,10.547,13.5H1.266A1.266,1.266,0,0,1,0,12.234V5.379A.317.317,0,0,1,.316,5.063ZM8.648,8.754a.317.317,0,0,0-.316-.316H6.75V6.855a.317.317,0,0,0-.316-.316H5.379a.317.317,0,0,0-.316.316V8.438H3.48a.317.317,0,0,0-.316.316V9.809a.317.317,0,0,0,.316.316H5.063v1.582a.317.317,0,0,0,.316.316H6.434a.317.317,0,0,0,.316-.316V10.125H8.332a.317.317,0,0,0,.316-.316Z"
          />
        </svg>
      );
    }
    case SVG_TYPE.USERS: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.889" height="7" viewBox="0 0 10.889 7">
          <path
            d="M9.674,10.792A1.459,1.459,0,1,0,8.189,9.333,1.476,1.476,0,0,0,9.674,10.792Zm-3.96,0A1.459,1.459,0,1,0,4.23,9.333,1.476,1.476,0,0,0,5.715,10.792Zm0,1.069c-1.163,0-3.465.559-3.465,1.7v1.313h7V13.563C9.25,12.42,6.878,11.861,5.715,11.861Zm3.96.267a2.641,2.641,0,0,0-.424.024,1.449,1.449,0,0,1,.778,1.41v1.313h3.111V13.563C13.139,12.42,10.837,12.129,9.674,12.129Z"
            transform="translate(-2.25 -7.875)"
          />
        </svg>
      );
    }
    case SVG_TYPE.ALERT: {
      return (
        <svg className="svg svg--alert-x" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.672 10.672">
          <path
            className="a"
            d="M7.359,10.961l3.034-3.034a.954.954,0,0,0,0-1.349L9.718,5.9a.954.954,0,0,0-1.349,0L5.336,8.938,2.3,5.9a.954.954,0,0,0-1.349,0l-.674.674a.954.954,0,0,0,0,1.349l3.034,3.034L.279,13.995a.954.954,0,0,0,0,1.349l.674.674a.954.954,0,0,0,1.349,0l3.034-3.034L8.37,16.018a.954.954,0,0,0,1.349,0l.674-.674a.954.954,0,0,0,0-1.349Z"
            transform="translate(0 -5.625)"
          />
        </svg>
      );
    }
    // TODO: ZMIANA ASSETU GDY ZOSTANIE DOSTARCZONY
    case SVG_TYPE.STAR: {
      return <>☆</>;
    }
    case SVG_TYPE.BHP: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40.149" height="52.708" viewBox="0 0 40.149 52.708">
          <g transform="translate(-19.687 -24.813)">
            <path
              d="M196.381,140.853c.016-.242.061-.474.061-.721v-6.177H194.9a4.631,4.631,0,0,1-3.7-1.841l-1.389,1.389a1.543,1.543,0,0,1-1.092.452H181v6.177c0,.247.045.479.061.721,2.228-2.126,4.692-3.81,7.66-3.81S194.153,138.728,196.381,140.853Z"
              transform="translate(-148.959 -93.701)"
            />
            <path
              d="M189.8,214.262c1.291,2.958,3.845,5,6.815,5s5.524-2.045,6.815-5c-2.516-2.644-4.593-4.262-6.815-4.262S192.318,211.618,189.8,214.262Z"
              transform="translate(-156.855 -163.569)"
            />
            <path
              d="M81.074,0A16.986,16.986,0,0,0,64.088,16.986v8a4.621,4.621,0,0,0,1.544,8.981H81.074c-6.158,0-10.809-5.831-10.809-12.352V13.9a1.543,1.543,0,0,1,1.544-1.544h8.626l2.636-2.636a1.544,1.544,0,0,1,2.636,1.092,1.545,1.545,0,0,0,1.544,1.544h3.088A1.543,1.543,0,0,1,91.884,13.9v7.721c0,7.65-5.632,12.352-10.809,12.352H96.516A4.621,4.621,0,0,0,98.06,24.99v-8A16.986,16.986,0,0,0,81.074,0Z"
              transform="translate(-41.313 24.813)"
            />
            <path d="M222.459,360h-8.7a4.6,4.6,0,0,0,8.7,0Z" transform="translate(-178.35 -298.127)" />
            <path
              d="M100.421,360h-1.7A7.73,7.73,0,0,0,91,367.721V374.1a1.543,1.543,0,0,0,1.544,1.544h30.884a1.543,1.543,0,0,0,1.544-1.544v-6.383A7.73,7.73,0,0,0,117.251,360h-1.7a7.721,7.721,0,0,1-15.129,0Z"
              transform="translate(-68.225 -298.127)"
            />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.ENERGY: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="36.111" height="50.75" viewBox="0 0 36.111 50.75">
          <g transform="translate(-520.996 -44.982)">
            <path
              d="M865.219,1705.732h-4.391a1.708,1.708,0,0,1,0-3.417h4.391a1.708,1.708,0,0,1,0,3.417Zm2.44-5.368h-9.271a1.708,1.708,0,1,1,0-3.416h9.271a1.708,1.708,0,1,1,0,3.416Zm1.708-5.49h-1.219a.489.489,0,0,1-.489-.488V1682.87a4.006,4.006,0,0,1,.415-1.745l2.6-5.2a1.227,1.227,0,0,0-1.1-1.781,1.238,1.238,0,0,0-1.1.683l-3.1,6.295a3.808,3.808,0,0,0-.4,1.721v11.541a.488.488,0,0,1-.488.488h-2.928a.488.488,0,0,1-.488-.488v-11.541a3.808,3.808,0,0,0-.4-1.721l-3.086-6.27a1.279,1.279,0,1,0-2.293,1.135l2.561,5.124a3.949,3.949,0,0,1,.415,1.744v11.529a.488.488,0,0,1-.488.488h-1.086a1.952,1.952,0,0,1-1.951-1.867,13.621,13.621,0,0,0-2.281-6.8,9.153,9.153,0,0,0-2.1-2.432,10.933,10.933,0,0,1-1.743-1.838h.037c-1.823-2.573-3.685-5.747-3.685-9.772a15.846,15.846,0,0,1,1.485-6.69,17.546,17.546,0,0,1,3.979-5.464,18.741,18.741,0,0,1,5.761-3.684,17.952,17.952,0,0,1,13.661,0,18.73,18.73,0,0,1,5.761,3.682,16.585,16.585,0,0,1,5.464,12.158c0,4.027-1.863,7.2-3.685,9.772h.013a10.957,10.957,0,0,1-1.742,1.837,9.158,9.158,0,0,0-2.1,2.433,14.493,14.493,0,0,0-2.245,6.832A1.953,1.953,0,0,1,869.367,1694.875Zm-6.222-36.608a1.225,1.225,0,0,0-.686.21c-.223.149-5.467,3.713-5.467,8.4a5.406,5.406,0,0,0,1.67,3.978,8.338,8.338,0,0,0,3.252,1.895v3.97a1.23,1.23,0,1,0,2.461,0v-3.97a8.335,8.335,0,0,0,3.251-1.895,5.406,5.406,0,0,0,1.671-3.978c0-4.691-5.243-8.255-5.467-8.4A1.225,1.225,0,0,0,863.145,1658.267Zm1.23,11.855v-3.241a1.23,1.23,0,1,0-2.461,0v3.235a3.618,3.618,0,0,1-2.461-3.235c0-2.577,2.582-4.943,3.692-5.841,1.111.9,3.691,3.268,3.691,5.841a3.615,3.615,0,0,1-2.459,3.24Z"
              transform="translate(-323.971 -1610)"
            />
          </g>
        </svg>
      );
    }
    // TODO: ZMIANA ASSETU GDY ZOSTANIE DOSTARCZONY
    case SVG_TYPE.EDIT: {
      return (
        // <svg xmlns="http://www.w3.org/2000/svg" width="16.653" height="16.5" viewBox="0 0 16.653 16.5"><defs><style>.a{opacity:0.7;}.b{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g class="a" transform="translate(-1591.13 -200.682)"><g transform="translate(1589.13 198.864)"><path class="b" d="M9.486,6H4.441A1.441,1.441,0,0,0,3,7.441V17.53a1.441,1.441,0,0,0,1.441,1.441H14.53a1.441,1.441,0,0,0,1.441-1.441V12.486" transform="translate(0 -1.653)"/><path class="b" d="M19.567,3.266a1.529,1.529,0,0,1,2.162,2.162l-6.846,6.846L12,12.994l.721-2.883Z" transform="translate(-4.676 0)"/></g></g></svg>
        <img className="edit-icon" src={editIcon} alt="Edytuj" />
      );
    }
    case SVG_TYPE.SOURCE: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.75" height="14" viewBox="0 0 15.75 14">
          <path
            className="a"
            d="M15.75.656v3.5a.657.657,0,0,1-1.12.464l-.976-.976L6.994,10.3a.656.656,0,0,1-.928,0l-.619-.619a.656.656,0,0,1,0-.928L12.107,2.1,11.13,1.12A.657.657,0,0,1,11.594,0h3.5A.656.656,0,0,1,15.75.656ZM11.13,7.4l-.437.437a.656.656,0,0,0-.192.464V12.25H1.75V3.5H8.969a.656.656,0,0,0,.464-.192L9.87,2.87a.656.656,0,0,0-.464-1.12H1.312A1.312,1.312,0,0,0,0,3.062v9.625A1.312,1.312,0,0,0,1.312,14h9.625a1.312,1.312,0,0,0,1.312-1.312V7.868A.656.656,0,0,0,11.13,7.4Z"
          />
        </svg>
      );
    }
    case SVG_TYPE.PDF: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.64" height="14" viewBox="0 0 10.64 14">
          <path
            className="a"
            d="M21.267,7.406h3.185a.173.173,0,0,0,.175-.175h0a1.036,1.036,0,0,0-.375-.8L21.582,4.2a1.124,1.124,0,0,0-.721-.259h0A.258.258,0,0,0,20.6,4.2V6.741A.665.665,0,0,0,21.267,7.406Z"
            transform="translate(-13.987 -3.941)"
          />
          <path
            className="a"
            d="M13.017,6.738v-2.8H8.432a1.123,1.123,0,0,0-1.12,1.12v11.76a1.123,1.123,0,0,0,1.12,1.12h8.4a1.123,1.123,0,0,0,1.12-1.12v-8.5h-3.36A1.577,1.577,0,0,1,13.017,6.738Z"
            transform="translate(-7.312 -3.937)"
          />
        </svg>
      );
    }
    case SVG_TYPE.ROW_DELETE: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.424" height="15.424" viewBox="0 0 15.424 15.424">
          <path
            className="a"
            d="M10.283,1.928A7.712,7.712,0,1,0,17.995,9.64a7.712,7.712,0,0,0-7.712-7.712Zm0,13.978A6.266,6.266,0,1,1,16.549,9.64a6.266,6.266,0,0,1-6.266,6.266Zm2.41-10.122-2.41,2.41-2.41-2.41L6.427,7.23l2.41,2.41-2.41,2.41L7.873,13.5l2.41-2.41,2.41,2.41,1.446-1.446-2.41-2.41,2.41-2.41Z"
            transform="translate(-2.571 -1.928)"
          />
        </svg>
      );
    }
    case SVG_TYPE.MOBILE: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.878" height="39.095" viewBox="0 0 26.878 39.095">
          <path
            d="M30.861,1.928H8.87A2.451,2.451,0,0,0,6.427,4.371V38.58A2.451,2.451,0,0,0,8.87,41.023H30.861A2.451,2.451,0,0,0,33.3,38.58V4.371A2.451,2.451,0,0,0,30.861,1.928Zm-11,37.33a1.9,1.9,0,1,1,1.9-1.9A1.9,1.9,0,0,1,19.866,39.258Zm11-5.566H8.87V6.815H30.861Z"
            transform="translate(-6.427 -1.928)"
          />
        </svg>
      );
    }
    case SVG_TYPE.DESKTOP: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.171" height="39.246" viewBox="0 0 43.171 39.246">
          <path
            d="M40.746,3H5.425A3.936,3.936,0,0,0,1.5,6.925V30.472A3.936,3.936,0,0,0,5.425,34.4H19.161v3.925H15.236v3.925h15.7V38.321H27.01V34.4H40.746a3.936,3.936,0,0,0,3.925-3.925V6.925A3.936,3.936,0,0,0,40.746,3Zm0,27.472H5.425V6.925H40.746Z"
            transform="translate(-1.5 -3)"
          />
        </svg>
      );
    }
    case SVG_TYPE.USER_TIE: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.463" height="18.815" viewBox="0 0 16.463 18.815">
          <path d="M8.231,9.407a4.7,4.7,0,1,0-4.7-4.7A4.7,4.7,0,0,0,8.231,9.407Zm3.52,1.2L10,17.639l-1.176-5L10,10.583H6.468l1.176,2.058-1.176,5L4.711,10.605A4.93,4.93,0,0,0,0,15.522v1.529a1.764,1.764,0,0,0,1.764,1.764H14.7a1.764,1.764,0,0,0,1.764-1.764V15.522a4.93,4.93,0,0,0-4.711-4.917Z" />
        </svg>
      );
    }
    case SVG_TYPE.RATING_CALENDAR: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.95" height="15.5" viewBox="0 0 13.95 15.5">
          <path
            d="M9.15,9.975H7.6v1.55H9.15Zm3.1,0H10.7v1.55h1.55Zm3.1,0H13.8v1.55h1.55ZM16.9,4.55h-.775V3h-1.55V4.55h-6.2V3H6.825V4.55H6.05A1.543,1.543,0,0,0,4.508,6.1L4.5,16.95A1.55,1.55,0,0,0,6.05,18.5H16.9a1.555,1.555,0,0,0,1.55-1.55V6.1A1.555,1.555,0,0,0,16.9,4.55Zm0,12.4H6.05V8.425H16.9Z"
            transform="translate(-4.5 -3)"
          />
        </svg>
      );
    }
    case SVG_TYPE.STAR_FILLED: {
      return (
        <svg className="svg svg--star" xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28">
          <defs>
            {/* <style>.a{fill:#0080ff;}.b{filter:url(#a);}</style> */}
            <filter id="a" x="0" y="0" width="29" height="28" filterUnits="userSpaceOnUse">
              {/* <feOffset input="SourceAlpha"/> */}
              <feGaussianBlur stdDeviation="2" result="b" />
              <feFlood floodColor="#fff" />
              <feComposite operator="in" in2="b" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g transform="translate(6 6)">
            <g className="b" transform="matrix(1, 0, 0, 1, -6, -6)">
              <path
                className="a"
                d="M7.55,2.894a1,1,0,0,1,1.9,0l.831,2.53a1,1,0,0,0,.95.688H13.9a1,1,0,0,1,.584,1.812L12.342,9.461a1,1,0,0,0-.366,1.124l.821,2.5a1,1,0,0,1-1.534,1.124L9.084,12.643a1,1,0,0,0-1.168,0L5.738,14.209A1,1,0,0,1,4.2,13.085l.821-2.5a1,1,0,0,0-.366-1.124L2.52,7.923A1,1,0,0,1,3.1,6.111H5.769a1,1,0,0,0,.95-.688Z"
                transform="translate(6 6)"
              />
            </g>
          </g>
        </svg>
      );
    }
    case SVG_TYPE.FACEBOOK: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="13.432" height="25.079" viewBox="0 0 13.432 25.079">
          <path d="M12.552 14.107l.7-4.539H8.891V6.623a2.269 2.269 0 012.559-2.452h1.98V.307A24.145 24.145 0 009.918 0C6.332 0 3.991 2.174 3.991 6.109v3.459H0v4.539h3.991v10.972h4.9V14.107z"></path>
        </svg>
      );
    }
    case SVG_TYPE.LINKEDIN: {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24.882" height="24.882" viewBox="0 0 24.882 24.882">
          <path d="M5.57 24.881H.411V8.269H5.57zM2.988 5.999a3 3 0 112.987-3.012 3.013 3.013 0 01-2.987 3.012zm21.889 18.882h-5.148v-8.082c0-1.927-.039-4.4-2.682-4.4-2.682 0-3.093 2.094-3.093 4.26v8.226H8.8V8.269h4.948v2.266h.072a5.421 5.421 0 014.88-2.682c5.221 0 6.181 3.438 6.181 7.9v9.125z"></path>
        </svg>
      );
    }
    case SVG_TYPE.CHEVRON: {
      return (
        <svg className="svg svg--chevron" xmlns="http://www.w3.org/2000/svg" width="6.714" height="10.6" viewBox="0 0 6.714 10.6">
          <path className="a" d="M131.766,211.5l-4.593,4.593,4.593,4.593" transform="translate(-125.759 -210.793)" />
        </svg>
      );
    }
    case SVG_TYPE.MAGNIFIER: {
      return (
        <svg className="svg svg--magnifier" xmlns="http://www.w3.org/2000/svg" width="19.137" height="19.137" viewBox="0 0 19.137 19.137">
          <g transform="translate(-3 -3)">
            <path className="a" d="M18.292,11.4a6.9,6.9,0,1,1-6.9-6.9,6.9,6.9,0,0,1,6.9,6.9Z" />
            <path className="a" d="M28.725,28.725l-3.75-3.75" transform="translate(-8.709 -8.709)" />
          </g>
        </svg>
      );
    }
  }
};

export default SVG;
