import { ViewManagement } from './EnumTypes/ActionEnumTypes';
import * as ViewManagementActionModel from './Models/ViewManagementActionModel';

function showLoader(): ViewManagementActionModel.ShowLoader {
  return {
    type: ViewManagement.SHOW_LOADER,
  };
}

function hideLoader(): ViewManagementActionModel.HideLoader {
  return {
    type: ViewManagement.HIDE_LOADER,
  };
}

function setPrevPath(prevPath: string): ViewManagementActionModel.SetPrevPath {
  return {
    type: ViewManagement.SET_PREV_PATH,
    payload: {
      prevPath,
    },
  };
}
function expandMenu(): ViewManagementActionModel.ExpandMenu {
  return {
    type: ViewManagement.EXPAND_MENU,
  };
}
function expandAddReminderView(): ViewManagementActionModel.ExpandAddReminderView {
  return {
    type: ViewManagement.EXPAND_ADD_REMINDER_VIEW,
  };
}
const openCompendiumPreview = () => ({
  type: ViewManagement.OPEN_COMPENDIUM_PREVIEW_MOBILE,
});
const closeCompendiumPreview = () => ({
  type: ViewManagement.CLOSE_COMPENDIUM_PREVIEW_MOBILE,
});
const showExtendedText = () => ({
  type: ViewManagement.SHOW_EXTENDED_TEXT,
});
const hideExtendedText = () => ({
  type: ViewManagement.HIDE_EXTENDED_TEXT,
});
export {
  showLoader,
  hideLoader,
  setPrevPath,
  expandMenu,
  expandAddReminderView,
  openCompendiumPreview,
  closeCompendiumPreview,
  showExtendedText,
  hideExtendedText,
};
