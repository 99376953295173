export enum Auth {
  LOG_IN_INIT = 'LOG_IN_INIT',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT_INIT = 'LOG_OUT_INIT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SEND_LINK_INIT = 'SEND_LINK_INIT',
  SEND_LINK_SUCCESS = 'SEND_LINK_SUCCESS',
  SEND_LINK_FAILURE = 'SEND_LINK_FAILURE',
  GET_REFRESH_TOKEN_INIT = 'GET_REFRESH_TOKEN_INIT',
  GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE',
  ADD_NEW_DEVICE_INIT = 'ADD_NEW_DEVICE_INIT',
  ADD_NEW_DEVICE_SUCCESS = 'ADD_NEW_DEVICE_SUCCESS',
  ADD_NEW_DEVICE_FAILURE = 'ADD_NEW_DEVICE_FAILURE',
}

export enum ViewManagement {
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  SET_PREV_PATH = 'SET_PREV_PATH',
  EXPAND_MENU = 'EXPAND_MENU',
  EXPAND_ADD_REMINDER_VIEW = 'EXPAND_ADD_REMINDER_VIEW',
  OPEN_COMPENDIUM_PREVIEW_MOBILE = 'OPEN_COMPENDIUM_PREVIEW_MOBILE',
  CLOSE_COMPENDIUM_PREVIEW_MOBILE = 'CLOSE_COMPENDIUM_PREVIEW_MOBILE',
  SHOW_EXTENDED_TEXT = 'SHOW_EXTENDED_TEXT',
  HIDE_EXTENDED_TEXT = 'HIDE_EXTENDED_TEXT',
}

export enum User {
  GET_USERS_INIT = 'GET_USERS_INIT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',
  GET_COMPANIES_INIT = 'GET_COMPANIES_INIT',
  GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAILURE = 'GET_COMPANIES_FAILURE',
  GET_COMPANY_INIT = 'GET_COMPANY_INIT',
  GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE = 'GET_COMPANY_FAILURE',
  ADD_COMPANY_INIT = 'ADD_COMPANY_INIT',
  ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS',
  ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE',
  EDIT_COMPANY_INIT = 'EDIT_COMPANY_INIT',
  EDIT_COMPANY_SUCCESS = 'EDIT_COMPANY_SUCCESS',
  EDIT_COMPANY_FAILURE = 'EDIT_COMPANY_FAILURE',
  CLEAR_ACTIVE_COMPANY = 'CLEAR_ACTIVE_COMPANY',
  ADD_USER_INIT = 'ADD_USER_INIT',
  ADD_USER_SUCCESS = 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE = 'ADD_USER_FAILURE',
  EDIT_USER_INIT = 'EDIT_USER_INIT',
  EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE = 'EDIT_USER_FAILURE',
  DELETE_USER_INIT = 'DELETE_USER_INIT',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE = 'DELETE_USER_FAILURE',
  EDIT_EMPLYEE_INIT = 'EDIT_EMPLYEE_INIT',
  EDIT_EMPLYEE_SUCCESS = 'EDIT_EMPLYEE_SUCCESS',
  EDIT_EMPLYEE_FAILURE = 'EDIT_EMPLYEE_FAILURE',
  GET_CHANGELOG_INIT = 'GET_CHANGELOG_INIT',
  GET_CHANGELOG_SUCCESS = 'GET_CHANGELOG_SUCCESS',
  GET_CHANGELOG_FAILURE = 'GET_CHANGELOG_FAILURE',
  DELETE_COMPANY_INIT = 'DELETE_COMPANY_INIT',
  DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILURE = 'DELETE_COMPANY_FAILURE',
  GET_COMPANIES_OPTIONS_INIT = 'GET_COMPANIES_OPTIONS_INIT',
  GET_COMPANIES_OPTIONS_SUCCESS = 'GET_COMPANIES_OPTIONS_SUCCESS',
  GET_COMPANIES_OPTIONS_FAILURE = 'GET_COMPANIES_OPTIONS_FAILURE',
  GET_USERS_IN_COMPANY_OPTIONS_INIT = 'GET_USERS_IN_COMPANY_OPTIONS_INIT',
  GET_USERS_IN_COMPANY_OPTIONS_SUCCESS = 'GET_USERS_IN_COMPANY_OPTIONS_SUCCESS',
  GET_USERS_IN_COMPANY_OPTIONS_FAILURE = 'GET_USERS_IN_COMPANY_OPTIONS_FAILURE',
  GET_SETTINGS_INIT = 'GET_SETTINGS_INIT',
  GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE',
  EDIT_SETTINGS_INIT = 'EDIT_SETTINGS_INIT',
  EDIT_SETTINGS_SUCCESS = 'EDIT_SETTINGS_SUCCESS',
  EDIT_SETTINGS_FAILURE = 'EDIT_SETTINGS_FAILURE',
  GET_COMPANY_EMPLOYEE_INIT = 'GET_COMPANY_EMPLOYEE_INIT',
  GET_COMPANY_EMPLOYEE_SUCCESS = 'GET_COMPANY_EMPLOYEE_SUCCESS',
  GET_COMPANY_EMPLOYEE_FAILURE = 'GET_COMPANY_EMPLOYEE_FAILURE',
}

export enum Knowledge {
  GET_KNOWLEDGE_POSTS_INIT = 'GET_KNOWLEDGE_POSTS_INIT',
  GET_KNOWLEDGE_POSTS_SUCCESS = 'GET_KNOWLEDGE_POSTS_SUCCESS',
  GET_KNOWLEDGE_POSTS_FAILURE = 'GET_KNOWLEDGE_POSTS_FAILURE',
  GET_TOPICALITIES_INIT = 'GET_TOPICALITIES_INIT',
  GET_TOPICALITIES_SUCCESS = 'GET_TOPICALITIES_SUCCESS',
  GET_TOPICALITIES_FAILURE = 'GET_TOPICALITIES_FAILURE',
  ADD_TOPICALITY_INIT = 'ADD_TOPICALITY_INIT',
  ADD_TOPICALITY_SUCCESS = 'ADD_TOPICALITY_SUCCESS',
  ADD_TOPICALITY_FAILURE = 'ADD_TOPICALITY_FAILURE',
  EDIT_TOPICALITY_INIT = 'EDIT_TOPICALITY_INIT',
  EDIT_TOPICALITY_SUCCESS = 'EDIT_TOPICALITY_SUCCESS',
  EDIT_TOPICALITY_FAILURE = 'EDIT_TOPICALITY_FAILURE',
  DELETE_TOPICALITY_INIT = 'DELETE_TOPICALITY_INIT',
  DELETE_TOPICALITY_SUCCESS = 'DELETE_TOPICALITY_SUCCESS',
  DELETE_TOPICALITY_FAILURE = 'DELETE_TOPICALITY_FAILURE',
}

export enum BHP {
  GET_INSTITUTIONS_INIT = 'GET_INSTITUTIONS_INIT',
  GET_INSTITUTIONS_SUCCESS = 'GET_INSTITUTIONS_SUCCESS',
  GET_INSTITUTIONS_FAILURE = 'GET_INSTITUTIONS_FAILURE',  
  DOWNLOAD_FILE_INIT = 'DOWNLOAD_FILE_INIT',
  DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS',
  DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE',
  GET_DICTIONARIES_INIT = 'GET_DICTIONARIES_INIT',
  GET_DICTIONARIES_SUCCESS = 'GET_DICTIONARIES_SUCCESS',
  GET_DICTIONARIES_FAILURE = 'GET_DICTIONARIES_FAILURE',
  GET_COMPENDIUMS_INIT = 'GET_COMPENDIUMS_INIT',
  GET_COMPENDIUMS_SUCCESS = 'GET_COMPENDIUMS_SUCCESS',
  GET_COMPENDIUMS_FAILURE = 'GET_COMPENDIUMS_FAILURE',
  GET_HIDDEN_COMPENDIUMS_INIT = 'GET_HIDDEN_COMPENDIUMS_INIT',
  GET_HIDDEN_COMPENDIUMS_SUCCESS = 'GET_HIDDEN_COMPENDIUMS_SUCCESS',
  GET_HIDDEN_COMPENDIUMS_FAILURE = 'GET_HIDDEN_COMPENDIUMS_FAILURE',
  GET_SINGLE_COMPENDIUM_INIT = 'GET_SINGLE_COMPENDIUM_INIT',
  GET_SINGLE_COMPENDIUM_SUCCESS = 'GET_SINGLE_COMPENDIUM_SUCCESS',
  GET_SINGLE_COMPENDIUM_FAILURE = 'GET_SINGLE_COMPENDIUM_FAILURE',
  GET_NORMS_INIT = 'GET_NORMS_INIT',
  GET_NORMS_SUCCESS = 'GET_NORMS_SUCCESS',
  GET_NORMS_FAILURE = 'GET_NORMS_FAILURE',
  EDIT_COMPENDIUM_INIT = 'EDIT_COMPENDIUM_INIT',
  EDIT_COMPENDIUM_SUCCESS = 'EDIT_COMPENDIUM_SUCCESS',
  EDIT_COMPENDIUM_FAILURE = 'EDIT_COMPENDIUM_FAILURE',
  EDIT_DICTIONARY_INIT = 'EDIT_DICTIONARY_INIT',
  EDIT_DICTIONARY_SUCCESS = 'EDIT_DICTIONARY_SUCCESS',
  EDIT_DICTIONARY_FAILURE = 'EDIT_DICTIONARY_FAILURE',
  EDIT_INSTITUTION_INIT = 'EDIT_INSTITUTION_INIT',
  EDIT_INSTITUTION_SUCCESS = 'EDIT_INSTITUTION_SUCCESS',
  EDIT_INSTITUTION_FAILURE = 'EDIT_INSTITUTION_FAILURE',
  HIDE_ROW_INIT = 'HIDE_ROW_INIT',
  HIDE_ROW_SUCCESS = 'HIDE_ROW_SUCCESS',
  HIDE_ROW_FAILURE = 'HIDE_ROW_FAILURE',
  DESTROY_HIDDEN_ROW_INIT = 'DESTROY_HIDDEN_ROW_INIT',
  DESTROY_HIDDEN_ROW_SUCCESS = 'DESTROY_HIDDEN_ROW_SUCCESS',
  DESTROY_HIDDEN_ROW_FAILURE = 'DESTROY_HIDDEN_ROW_FAILURE',
  GET_HIDDEN_DICTIONARIES_INIT = 'GET_HIDDEN_DICTIONARIES_INIT',
  GET_HIDDEN_DICTIONARIES_SUCCESS = 'GET_HIDDEN_DICTIONARIES_SUCCESS',
  GET_HIDDEN_DICTIONARIES_FAILURE = 'GET_HIDDEN_DICTIONARIES_FAILURE',
  GET_HIDDEN_INSTITUTIONS_INIT = 'GET_HIDDEN_INSTITUTIONS_INIT',
  GET_HIDDEN_INSTITUTIONS_SUCCESS = 'GET_HIDDEN_INSTITUTIONS_SUCCESS',
  GET_HIDDEN_INSTITUTIONS_FAILURE = 'GET_HIDDEN_INSTITUTIONS_FAILURE',
  GET_HIDDEN_NORMS_INIT = 'GET_HIDDEN_NORMS_INIT',
  GET_HIDDEN_NORMS_SUCCESS = 'GET_HIDDEN_NORMS_SUCCESS',
  GET_HIDDEN_NORMS_FAILURE = 'GET_HIDDEN_NORMS_FAILURE',
  ADD_RATING_COMPATIBILITY_INIT = 'ADD_RATING_COMPATIBILITY_INIT',
  ADD_RATING_COMPATIBILITY_SUCCESS = 'ADD_RATING_COMPATIBILITY_SUCCESS',
  ADD_RATING_COMPATIBILITY_FAILURE = 'ADD_RATING_COMPATIBILITY_FAILURE',
  ADD_COMPENDIUM_INIT = 'ADD_COMPENDIUM_INIT',
  ADD_COMPENDIUM_SUCCESS = 'ADD_COMPENDIUM_SUCCESS',
  ADD_COMPENDIUM_FAILURE = 'ADD_COMPENDIUM_FAILURE',
  ADD_COMPENDIUM_EVIDENCE_INIT = 'ADD_COMPENDIUM_EVIDENCE_INIT',
  ADD_COMPENDIUM_EVIDENCE_SUCCESS = 'ADD_COMPENDIUM_EVIDENCE_SUCCESS',
  ADD_COMPENDIUM_EVIDENCE_FAILURE = 'ADD_COMPENDIUM_EVIDENCE_FAILURE',
  REMOVE_FILES_INIT = 'REMOVE_FILES_INIT',
  REMOVE_FILES_SUCCESS = 'REMOVE_FILES_SUCCESS',
  REMOVE_FILES_FAILURE = 'REMOVE_FILES_FAILURE',
  ADD_DICTIONARY_INIT = 'ADD_DICTIONARY_INIT',
  ADD_DICTIONARY_SUCCESS = 'ADD_DICTIONARY_SUCCESS',
  ADD_DICTIONARY_FAILURE = 'ADD_DICTIONARY_FAILURE',
  ADD_INSTITUTION_INIT = 'ADD_INSTITUTION_INIT',
  ADD_INSTITUTION_SUCCESS = 'ADD_INSTITUTION_SUCCESS',
  ADD_INSTITUTION_FAILURE = 'ADD_INSTITUTION_FAILURE',
  ADD_NORM_INIT = 'ADD_NORM_INIT',
  ADD_NORM_SUCCESS = 'ADD_NORM_SUCCESS',
  ADD_NORM_FAILURE = 'ADD_NORM_FAILURE',
  EDIT_NORM_INIT = 'EDIT_NORM_INIT',
  EDIT_NORM_SUCCESS = 'EDIT_NORM_SUCCESS',
  EDIT_NORM_FAILURE = 'EDIT_NORM_FAILURE',
  DELETE_DICTIONARY_INIT = 'DELETE_DICTIONARY_INIT',
  DELETE_DICTIONARY_SUCCESS = 'DELETE_DICTIONARY_SUCCESS',
  DELETE_DICTIONARY_FAILURE = 'DELETE_DICTIONARY_FAILURE',
  DELETE_INSTITUTION_INIT = 'DELETE_INSTITUTION_INIT',
  DELETE_INSTITUTION_SUCCESS = 'DELETE_INSTITUTION_SUCCESS',
  DELETE_INSTITUTION_FAILURE = 'DELETE_INSTITUTION_FAILURE',
  DELETE_COMPENDIUM_INIT = 'DELETE_COMPENDIUM_INIT',
  DELETE_COMPENDIUM_SUCCESS = 'DELETE_COMPENDIUM_SUCCESS',
  DELETE_COMPENDIUM_FAILURE = 'DELETE_COMPENDIUM_FAILURE',
  COPY_COMPENDIUM_INIT = 'COPY_COMPENDIUM_INIT',
  COPY_COMPENDIUM_SUCCESS = 'COPY_COMPENDIUM_SUCCESS',
  COPY_COMPENDIUM_FAILURE = 'COPY_COMPENDIUM_FAILURE',
  DELETE_NORM_INIT = 'DELETE_NORM_INIT',
  DELETE_NORM_SUCCESS = 'DELETE_NORM_SUCCESS',
  DELETE_NORM_FAILURE = 'DELETE_NORM_FAILURE',
  SET_ACTIVE_RATING_INIT = 'SET_ACTIVE_RATING_INIT',
  SET_ACTIVE_RATING_SUCCESS = 'SET_ACTIVE_RATING_SUCCESS',
  SET_ACTIVE_RATING_FAILURE = 'SET_ACTIVE_RATING_FAILURE',
  SET_COLUMN_FILTER_TERMS = 'SET_COLUMN_FILTER_TERMS',
  RESET_COLUMN_FILTER_TERMS = 'RESET_COLUMN_FILTER_TERMS',
  DELETE_RATING_COMPATIBILITIES_INIT = 'DELETE_RATING_COMPATIBILITIES_INIT',
  DELETE_RATING_COMPATIBILITIES_SUCCESS = 'DELETE_RATING_COMPATIBILITIES_SUCCESS',
  DELETE_RATING_COMPATIBILITIES_FAILURE = 'DELETE_RATING_COMPATIBILITIES_FAILURE',
  ADD_TASK_INIT = 'ADD_TASK_INIT',
  ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS',
  ADD_TASK_FAILURE = 'ADD_TASK_FAILURE',
  EDIT_TASK_INIT = 'EDIT_TASK_INIT',
  EDIT_TASK_SUCCESS = 'EDIT_TASK_SUCCESS',
  EDIT_TASK_FAILURE = 'EDIT_TASK_FAILURE',
  ADD_EVIDENCE_INIT = 'ADD_EVIDENCE_INIT',
  ADD_EVIDENCE_SUCCESS = 'ADD_EVIDENCE_SUCCESS',
  ADD_EVIDENCE_FAILURE = 'ADD_EVIDENCE_FAILURE',
  REMOVE_TASK_INIT = 'REMOVE_TASK_INIT',
  REMOVE_TASK_SUCCESS = 'REMOVE_TASK_SUCCESS',
  REMOVE_TASK_FAILURE = 'REMOVE_TASK_FAILURE',
  ADD_EVIDENCE_FILE_INIT = 'ADD_EVIDENCE_FILE_INIT',
  ADD_EVIDENCE_FILE_SUCCESS = 'ADD_EVIDENCE_FILE_SUCCESS',
  ADD_EVIDENCE_FILE_FAILURE = 'ADD_EVIDENCE_FILE_FAILURE',
  GET_COMPENDIUM_RATINGS_COUNTER_INIT = 'GET_COMPENDIUM_RATINGS_COUNTER_INIT',
  GET_COMPENDIUM_RATINGS_COUNTER_FAILURE = 'GET_COMPENDIUM_RATINGS_COUNTER_FAILURE',
  GET_COMPENDIUM_RATINGS_COUNTER_SUCCESS = 'GET_COMPENDIUM_RATINGS_COUNTER_SUCCESS',
}



export enum Notification {
  GET_NOTIFICATIONS_INIT = 'GET_NOTIFICATIONS_INIT',
  GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE',
  GET_LAST_NOTIFICATIONS_INIT = 'GET_LAST_NOTIFICATIONS_INIT',
  GET_LAST_NOTIFICATIONS_SUCCESS = 'GET_LAST_NOTIFICATIONS_SUCCESS',
  GET_LAST_NOTIFICATIONS_FAILURE = 'GET_LAST_NOTIFICATIONS_FAILURE',
  READ_NOTIFICATION_INIT = 'READ_NOTIFICATION_INIT',
  READ_NOTIFICATION_SUCCESS = 'READ_NOTIFICATION_SUCCESS',
  READ_NOTIFICATION_FAILURE = 'READ_NOTIFICATION_FAILURE',
  GET_NOTIFICATIONS_NOT_READ_INIT = 'GET_NOTIFICATIONS_NOT_READ_INIT',
  GET_NOTIFICATIONS_NOT_READ_SUCCESS = 'GET_NOTIFICATIONS_NOT_READ_SUCCESS',
  GET_NOTIFICATIONS_NOT_READ_FAILURE = 'GET_NOTIFICATIONS_NOT_READ_FAILURE',
}




export enum NewTab {
  CREATE_NEW_TAB_INIT = 'CREATE_NEW_TAB_INIT',
  CREATE_NEW_TAB_SUCCESS = 'CREATE_NEW_TAB_SUCCESS',
  CREATE_NEW_TAB_FAILURE = 'CREATE_NEW_TAB_FAILURE',
  EDIT_NEW_TAB_INIT = 'EDIT_NEW_TAB_INIT',
  EDIT_NEW_TAB_SUCCESS = 'EDIT_NEW_TAB_SUCCESS',
  EDIT_NEW_TAB_FAILURE = 'EDIT_NEW_TAB_FAILURE',
  GET_NEW_TAB_INIT = 'GET_NEW_TAB_INIT',
  GET_NEW_TAB_SUCCESS = 'GET_NEW_TAB_SUCCESS',
  GET_NEW_TAB_FAILURE = 'GET_NEW_TAB_FAILURE',
}


