import { connect } from 'react-redux';
import { Selectors, Actions } from 'Modules/AuthModule';
import { RootState } from 'Store/Reducers/RootReducer';
import DeviceManagement from './Main';

type ConnectedP = {
  devicesError: any;
};

type DispatchedP = {
  //   loginUser: (email: string, password: string) => void;
  logout: () => void;
  addNewDevice: (deviceData: any) => void;
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps: DispatchedP = {
  //   loginUser: (email: string, password: string) => AuthModule.Actions.login(email, password),
  logout: () => Actions.logout(),
  addNewDevice: (deviceData: any) => Actions.addNewDevice(deviceData),
};

const mapStateToProps = (state: RootState): ConnectedP => ({
  devicesError: Selectors.getDevicesError(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManagement);
