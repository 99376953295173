import * as React from 'react';
import { ROUTES } from 'Shared/enums';
import { Letters, CompendiumElement, CompendiumPreview } from './components';

type P = {
  compendiums: Array<any>;
  history: any;
  compendiumsTermLetters: Array<string>;
  openCompendiumPreview: () => void;
  closeCompendiumPreview: () => void;
  isCompendiumPreview: boolean;
};
const CompendiumsMobile = ({
  compendiums,
  history,
  compendiumsTermLetters,
  openCompendiumPreview,
  closeCompendiumPreview,
  isCompendiumPreview,
}: P) => {
  const [activeLetter, setActiveLetter] = React.useState(compendiumsTermLetters ? compendiumsTermLetters[0] : undefined);
  const [activeCompendiumPreview, setActiveCompendiumPreview] = React.useState(undefined);

  const redirectToExtended = (id) => {
    history.push(`${ROUTES.COMPENDIUM_DETAILS}-${id}`);
    closeCompendiumPreview();
  };

  const openPreview = (compendium) => {
    setActiveCompendiumPreview(compendium);
    openCompendiumPreview();
  };

  const clearActiveCompendium = () => {
    closeCompendiumPreview();
    setActiveCompendiumPreview(undefined);
  };
  const scrollToLetter = (letter) => {
    setActiveLetter(letter);
    const elementToScroll = document.querySelector(`.compendiumElement--term[data-letter=${letter}]`);
    elementToScroll.scrollIntoView();
  };
  return (
    <div className={`compendiumsMobile ${isCompendiumPreview ? 'compendiumsMobile--preview' : ''}`}>
      {isCompendiumPreview ? (
        <CompendiumPreview activeCompendium={activeCompendiumPreview} goBack={clearActiveCompendium} onClick={redirectToExtended} />
      ) : (
        <>
          {compendiums.map((compendium) => (
            <CompendiumElement
              key={compendium.uid}
              term={compendium.term}
              department={compendium.department}
              onClick={() => openPreview(compendium)}
            />
          ))}
          <Letters letters={compendiumsTermLetters} onClick={scrollToLetter} activeLetter={activeLetter} />
        </>
      )}
    </div>
  );
};

export default CompendiumsMobile;
