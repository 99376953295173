import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Route, Switch } from 'react-router-dom';
import { Selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { ToastContainer } from 'react-toastify';
import * as AuthModule from './Modules/AuthModule';
import * as ViewManagementModule from './Modules/ViewManagementModule';
import * as H from 'history';
import Preloader from 'Shared/components/preloader';
import { AuthRoute } from 'Shared/components';
import Dashboard from './Dashboard';
import CustomerLogin from 'Screens/customerLogin/Container';
import Login from 'Screens/login/Container';
import DeviceManagement from 'Screens/deviceManagement/Container';
import { success as loginSuccess } from 'Store/Actions/Auth/login';
import { ROUTES } from 'Shared/enums';
import { Selectors as AuthSelectors, Actions as AuthActions } from 'Modules/AuthModule';
import Regulations from 'Screens/regulations/regulations';

class App extends React.Component<ConnectedP & DispatchedP, any> {

  public async componentDidMount() {
    const { pathname } = this.props.location;
    const isUserLogged = localStorage.getItem('isUserLogged');
    if (this.props.location.search.split('=')[0] === '?token') {
      await this.props.getRefreshToken(this.props.location.search.split('=')[1]);
      return await this.props.getAuthorizedUser();
    } else if ((pathname === ROUTES.CUSTOMER_LOGIN || pathname === ROUTES.LOGIN) && isUserLogged) {
      await this.props.getRefreshToken();
    }
    if (!this.props.authorizedUser && isUserLogged) {
      await this.props.getAuthorizedUser();
    }
  }

  shouldComponentUpdate({ location }) {
    const { pathname } = this.props.location;
    if (location.pathname !== pathname) {
      this.props.setPrevPath(pathname);
    }
    return true;
  }

  public render() {
    const {
      loaderVisible,
      devicesError: { error },
    } = this.props;
    return (
      <div className="main-wrapper">
        <ToastContainer autoClose={6000} />
        {loaderVisible && <Preloader />}
        {error ? (
          <DeviceManagement />
        ) : (
          <div className="page__inner">
            <Switch>
              <Route exact path={ROUTES.CUSTOMER_LOGIN} component={CustomerLogin} />
              <Route path={ROUTES.LOGIN} component={Login} />
              <AuthRoute path={ROUTES.DASHBOARD} component={Dashboard} />
              <Route exact path={ROUTES.REGULATIONS} component={Regulations} />
              <Route component={CustomerLogin} />
            </Switch>
          </div>
        )}
      </div>
    );
  }
}

type ConnectedP = {
  loaderVisible: boolean;
  history: any;
  location: H.Location;
  token: string;
  devicesError: any;
  authorizedUser: any;
};
type DispatchedP = {
  setPrevPath: (prevPath: string) => void;
  loginUserFromStorage: (token: string) => void;
  getRefreshToken: (refreshToken?: string) => void;
  getAuthorizedUser: () => void;
};

const mapDispatchToProps: DispatchedP = {
  setPrevPath: (prevPath: string) => ViewManagementModule.Actions.setPrevPath(prevPath),
  loginUserFromStorage: (token: string) => loginSuccess({ token }),
  getRefreshToken: (refreshToken: string) => AuthModule.Actions.getRefreshToken(refreshToken),
  getAuthorizedUser: () => AuthActions.getAuthorizedUser(),
};

function mapStateToProps(state: RootState) {
  return {
    loaderVisible: ViewManagementSelectors.loaderVisible(state),
    token: state.authStore.token,
    devicesError: AuthSelectors.getDevicesError(state),
    authorizedUser: AuthSelectors.authorizedUser(state),
  };
}

const Routes = connect(mapStateToProps, mapDispatchToProps)(App);

export default Routes;
