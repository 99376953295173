import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';

export function getNotifications(form?): AxiosPromise<any> {
  return API.get(`notifications${form.page ? '?page=' + form.page : ''}${form.isNotRead ? "&isNotRead=true" : ""}`);
}

export function getLastNotifications(): AxiosPromise<any> {
  return API.get(`notifications/last`);
}

export function readNotification(data): AxiosPromise<any> {
  return API.put(`notifications`, data);
}

export function getNotificationsNotRead(): AxiosPromise<any> {
  return API.get(`notifications/not-read`);
}
