import { Connector } from 'Modules/NotificationModule';
import { Notification } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Notification.GET_LAST_NOTIFICATIONS_INIT,
});

const success = (lastNotifications: any) => ({
  type: Notification.GET_LAST_NOTIFICATIONS_SUCCESS,
  payload: {
    lastNotifications,
  },
});

const failure = () => ({
  type: Notification.GET_LAST_NOTIFICATIONS_FAILURE,
});

const getLastNotifications = () => {
  return async (dispatch) => {
    dispatch(init());
    try {
      const { data } = await Connector.getLastNotifications();
      dispatch(success(data));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default getLastNotifications;
