import { Field, Formik } from 'formik';
import * as React from 'react';
import { Button } from 'Shared/components';
import { RegularField, ColorPicker } from 'Shared/components/forms';
import LabelForm from '../labelForm';
import * as Yup from 'yup';

type P = {
  onSubmit: (formValues: any) => any;
  isCustomerAreaManager: boolean;
};

const initialValues = {
  name: '',
  short: '',
  role: '',
  tasks: '',
};

const getInstitutionSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Pole wymagane'),
    short: Yup.string().required('Pole wymagane'),
    role: Yup.string().required('Pole wymagane'),
    tasks: Yup.string().required('Pole wymagane'),
  });

const AddInstitutionForm = ({ onSubmit, isCustomerAreaManager }: P) => {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getInstitutionSchema()}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="areaManagement__form">
          <LabelForm value="Interesariusz" />
          <Field placeholder="dodaj tekst..." type="textarea" name="name" component={RegularField} errors={errors} />
          <LabelForm value="Skrót" />
          <Field placeholder="dodaj tekst..." type="textarea" name="short" component={RegularField} errors={errors} />
          <LabelForm value="Rola" />
          <Field placeholder="dodaj tekst..." type="textarea" name="role" component={RegularField} errors={errors} />
          <LabelForm value="Zadania" />
          <Field placeholder="dodaj tekst..." type="textarea" name="tasks" component={RegularField} errors={errors} />
          {isCustomerAreaManager ? (
            <>
              <LabelForm value="Kolor wpisu" />
              <Field name="color" type="text" component={ColorPicker} errors={errors} />
            </>
          ) : null}
          <div className="areaManagement__form--save">
            <Button label="Dodaj wpis +" classNames="btn btn--navy-blue" type="submit" />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddInstitutionForm;
