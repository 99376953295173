export const SystemRolesArray = [
  {
    label: 'Super Admin',
    value: 'super_admin',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Redaktor',
    value: 'editor',
  },
  {
    label: 'Klient',
    value: 'customer',
  },
];
