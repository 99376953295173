import { connect } from 'react-redux';
import * as AuthModule from 'Modules/AuthModule';
import { RootState } from 'Store/Reducers/RootReducer';
import CustomerLogin from './Main';

type ConnectedP = {
  authorizedUser: AuthModule.Types.AuthorizedUserData;
};

type DispatchedP = {
  sendLink: (email: string) => void;
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps: DispatchedP = {
  sendLink: (email) => AuthModule.Actions.sendLink(email),
};

const mapStateToProps = (state: RootState): ConnectedP => ({
  authorizedUser: AuthModule.Selectors.authorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerLogin);
