import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_CHANGELOG_INIT,
});

const success = (changelogData: Array<any>) => ({
  type: User.GET_CHANGELOG_SUCCESS,
  payload: {
    changelogData,
  },
});

const failure = () => ({
  type: User.GET_CHANGELOG_FAILURE,
});

const getChangelog = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await UserModule.Connector.getChangelog();
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log(err);
  }
};

export default getChangelog;
