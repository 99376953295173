import * as React from 'react';
import { MainType } from './Container';
import { ReactTable } from 'Shared/components';
import moment from 'moment';
import { DatePicker } from 'Shared/components/forms';
import { Formik, Field } from 'formik';
import Select from 'react-select';

class Changelog extends React.Component<MainType, {}> {


  componentDidMount() {
    this.props.actions.getChangelogs();
  }

  getColumns = (userOptions, actionOptions) => [
    {
      id: 'date',
      accessor: 'created_at',
      filterable: false,
      Header: 'Data',
      Cell: ({ original }) => moment(original.created_at).format('YYYY-MM-DD HH:mm'),
    },
    {
      id: 'user',
      Header: 'Wykonujący',
      accessor: 'user',
      filterMethod: ({ value, id }, row) => {
        console.log(value, id);
        if (value && value.length) {
          return value.some((item) => row[id].toLowerCase().indexOf(item.value.toLowerCase()) > -1);
        } else {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <Select
          value={filter ? filter.value : ''}
          style={{ width: '100%' }}
          placeholder="Wybierz wykonującego"
          isMulti
          onChange={(value) => onChange(value)}
          options={userOptions}
          classNamePrefix="react-select"
        />
      ),
      style: { whiteSpace: 'unset', fontWeight: '600' },
    },
    {
      id: 'action',
      accessor: 'action',
      Header: 'Czynność',
      filterMethod: ({ value, id }, row) => {
        console.log(value, id);
        if (value && value.length) {
          return value.some((item) => row[id].toLowerCase().indexOf(item.value.toLowerCase()) > -1);
        } else {
          return true;
        }
      },
      Filter: ({ filter, onChange }) => (
        <Select
          value={filter ? filter.value : ''}
          style={{ width: '100%' }}
          placeholder="Wybierz czynność"
          isMulti
          onChange={(value) => onChange(value)}
          options={actionOptions}
          classNamePrefix="react-select"
        />
      ),
      style: { whiteSpace: 'unset' },
    },
  ];

  render() {
    const {
      changelogs,
      actions: { findChangelogs },
    } = this.props;

    const getUnique = (arr, comp) => {
      const unique = arr
        .map((e) => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => {
          return {
            label: arr[e][comp],
            value: arr[e][comp],
          };
        });
      return unique;
    };

    const filterByAction = getUnique(changelogs, 'action');
    const filterByUser = getUnique(changelogs, 'user');

    return (
      <div className="changelog">
        <div className="changelog__search">
          <Formik initialValues={{ start_date: '', end_date: '' }} onSubmit={() => console.log('')} enableReinitialize>
            {({ handleSubmit, errors, values: { end_date, start_date } }) => (
              <form onSubmit={handleSubmit} style={{ marginTop: '10px' }} className="changelog__form">
                <Field
                  name="start_date"
                  type="text"
                  onChange={(date) =>
                    findChangelogs(date ? moment(date).format('YYYY-MM-DD') : '', end_date ? moment(end_date).format('YYYY-MM-DD') : '')
                  }
                  component={DatePicker}
                  isRange={false}
                  errors={errors}
                  placeholder="Szukaj od"
                  showClearDate
                  wrapperClassName={'date-picker-blank'}
                />
                <Field
                  name="end_date"
                  type="text"
                  onChange={(date) =>
                    findChangelogs(start_date ? moment(start_date).format('YYYY-MM-DD') : '', date ? moment(date).format('YYYY-MM-DD') : '')
                  }
                  component={DatePicker}
                  isRange={false}
                  errors={errors}
                  placeholder="Szukaj do"
                  showClearDate
                  wrapperClassName={'date-picker-blank'}
                />
              </form>
            )}
          </Formik>
        </div>
        <div className="changelog__title">Changelog</div>
        <ReactTable filterable columns={this.getColumns(filterByUser, filterByAction)} data={changelogs} className="bhpTable" />
      </div>
    );
  }
}

export default Changelog;
