import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_USERS_INIT,
});

const success = (usersData: Array<UserModule.Types.SingleUserInList>) => ({
  type: User.GET_USERS_SUCCESS,
  payload: {
    usersData,
  },
});

const failure = () => ({
  type: User.GET_USERS_FAILURE,
});

const getUsers = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await UserModule.Connector.getUsers();
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log(err);
  }
};

export default getUsers;
