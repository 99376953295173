import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PROTECTED_ROUTES_MAP, ROUTES, USER_LEVELS } from 'Shared/enums';
import { matchUrlToRoute } from 'Shared/utils';

type RoleRouteProps = {
  component: any;
  path: string;
  exact: boolean;
  authorizedUser: any;
  location?: any;
};

const RoleRoute = (props: RoleRouteProps) => {
  const {
    component: Component,
    exact,
    authorizedUser,
    location: { pathname },
    ...rest
  } = props;
  let isAccessGranted =
    authorizedUser &&
    authorizedUser.roles.some(({ role }) => {
      const protectedRouteRoles = PROTECTED_ROUTES_MAP.get(pathname)
        ? PROTECTED_ROUTES_MAP.get(pathname)
        : PROTECTED_ROUTES_MAP.get(matchUrlToRoute(pathname));
      return protectedRouteRoles.includes(role);
    });

  if (pathname === ROUTES.CHANGELOG && authorizedUser.level !== USER_LEVELS.MANAGING) {
    isAccessGranted = false;
  }
  return (
    <Route
      {...rest}
      exact={exact}
      render={(routeProps) =>
        isAccessGranted ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard',
            }}
          />
        )
      }
    />
  );
};

export default RoleRoute;
