import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { USER_LEVELS } from 'Shared/enums';

const init = () => ({
  type: BHP.ADD_RATING_COMPATIBILITY_INIT,
});

const success = (data) => ({
  type: BHP.ADD_RATING_COMPATIBILITY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: BHP.ADD_RATING_COMPATIBILITY_FAILURE,
});

const addRatingCompatibility = (info, showAlert = false) => async (dispatch) => {
  dispatch(init());
  try {

    if(info.level === USER_LEVELS.MANAGING) {
      if(info.legal_requirement_proof) {
        const { data } = await Connector.addRatingCompatibility(info);
        dispatch(success(data));
        toast.success('Automatycznie zapisano');
        return data
      } else {
        toast.error('Uzupełnij odniesienie do dowodu(ów)');
      }
    } else if(info.level === USER_LEVELS.DETAILED) {
      if(info?.legal_requirement_owner?.uuid === info.authorizedUserId) {
        if(info.legal_requirement_proof) {
          const { data } = await Connector.addRatingCompatibility(info);
          dispatch(success(data));
          toast.success('Automatycznie zapisano');
          return data
        } else {
          toast.error('Uzupełnij odniesienie do dowodu(ów)');
        }
      } else {
        toast.error('Musisz być właścicielem wymagania prawnego')
      }
    } else {
      const { data, data: responseData } = await Connector.addRatingCompatibility(info);
      if (responseData.data?.legal_requirement_owner) {
        info.legal_requirement_owner = responseData.data.legal_requirement_owner;
      }
      dispatch(success(data));
      toast.success('Automatycznie zapisano');
      return data;
    }
  } catch (err) {
    dispatch(failure());
    console.log(err);
    // toast.error('Nie udało się zapisać zmian');
    return err;
  }
};

export default addRatingCompatibility;
