import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.GET_COMPANY_EMPLOYEE_INIT,
});

const success = (data) => ({
  type: User.GET_COMPANY_EMPLOYEE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.GET_COMPANY_EMPLOYEE_FAILURE,
});

const getCompanyEmployee = (id) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await UserModule.Connector.getCompanyEmployee(id);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getCompanyEmployee;
