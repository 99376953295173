import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

type AuthRouteProps = {
  component: any;
  path: string;
};

const AuthRoute = (props: AuthRouteProps) => {
  const { component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        localStorage.getItem('isUserLogged') ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
