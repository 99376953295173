import * as React from 'react';
import { FieldProps } from 'formik';
import { TableSelect as TableSelectComponent } from 'Shared/components';

type P = {
  options: Array<any>;
};

type S = {};

class TableSelect extends React.PureComponent<P & FieldProps, S> {
  render() {
    const {
      field: { value, name },
      form: { setFieldValue },
      options,
    } = this.props;
    return <TableSelectComponent value={value} onClick={(val) => setFieldValue(name, val)} options={options} />;
  }
}

export default TableSelect;
