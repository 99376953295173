import * as React from 'react';
import { FieldProps } from 'formik';
import { Badge as BadgeComponent } from 'Shared/components';

type P = {
  label?: string;
  color?: string;
  employeeBadge?: boolean;
  topicalityBadge?: boolean;
  disabled?: boolean;
};
type S = {};
class Badge extends React.PureComponent<P & FieldProps, S> {

  onClick = (employeeBadge, topicalityBadge, { name, value }, { setFieldValue, values }) => {
    const { rolesCounter, superadmin } = values;
    if (topicalityBadge) {
      setFieldValue('others', false);
      setFieldValue('bhp', false);
      setFieldValue('environment', false);
      setFieldValue('energy', false);
      setFieldValue(name, value ? value : !value);
      setFieldValue('category', name);
    } else if (!employeeBadge) {
      setFieldValue(name, !value);
    } else if (employeeBadge) {
      if (name === 'superadmin' && !value) {
        setFieldValue('editor', true);
        setFieldValue('admin', true);
        setFieldValue(name, !value);
        setFieldValue('rolesCounter', { ...rolesCounter, counter: 3, name });
      } else if (name === 'superadmin' && !value === false) {
        setFieldValue(name, !value);
        setFieldValue('rolesCounter', { ...rolesCounter, counter: 2, name });
      } else if ((name === 'editor' || name === 'admin') && superadmin) {
        setFieldValue('superadmin', false);
        setFieldValue(name, !value);
        const prevName = name === 'editor' ? 'admin' : 'editor';
        setFieldValue('rolesCounter', { ...rolesCounter, counter: 1, name: prevName });
      } else if (name === 'admin') {
        setFieldValue(name, !value);
        setFieldValue('rolesCounter', {
          ...rolesCounter,
          counter: !value ? rolesCounter.counter + 1 : rolesCounter.counter - 1,
          name: !value ? name : 'editor',
        });
      } else if (name === 'editor') {
        setFieldValue(name, !value);
        setFieldValue('rolesCounter', {
          ...rolesCounter,
          counter: !value ? rolesCounter.counter + 1 : rolesCounter.counter - 1,
          name: !value ? name : 'admin',
        });
      }
    }
  };

  render() {
    const { label, field, form, color, employeeBadge, disabled, topicalityBadge } = this.props;
    const {
      form: {
        values: { rolesCounter },
      },
    } = this.props;

    return (
      <BadgeComponent
        title={label}
        onClick={() => this.onClick(employeeBadge, topicalityBadge, field, form)}
        color={color || ''}
        value={field.value}
        disabled={disabled || (employeeBadge && rolesCounter.counter === 1 && rolesCounter.name === field.name)}
      />
    );
  }
}

export default Badge;
