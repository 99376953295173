import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { getSearchedTerm, getColumnFilterTerms } from 'Store/Selectors/BhpSelector';
import normalize from 'normalize-strings';

const HighlightText = ({ searchTerm = '', text, columnFilterTerm, columnId }: P) => {
  const filterTerm = columnFilterTerm?.[columnId];
  const isColumnFilterPresent = filterTerm?.length > 2;
  return (
    <span>
      <Highlighter
        highlightClassName="text-highlighted"
        searchWords={isColumnFilterPresent || searchTerm.length > 2 ? [filterTerm || searchTerm] : ['']}
        autoEscape={true}
        textToHighlight={text}
        // sanitize={(value) => (value ? value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : '')}
        sanitize={(value) => (value && value.length ? normalize(value) : '')}
      />
    </span>
  );
};

type P = {
  searchTerm?: string;
  text: string;
  columnFilterTerm?: any;
  columnId?: string;
};

const mapStateToProps = (state): any => ({
  searchTerm: getSearchedTerm(state),
  columnFilterTerm: getColumnFilterTerms(state),
});

export default connect(mapStateToProps, null)(HighlightText);
