export enum SUBMENU {
  CLIENTS = 'CLIENTS',
  WISO_EMPLOYEES = 'WISO_EMPLOYEES',
}
export const SUBMENU_ARR = [SUBMENU.CLIENTS, SUBMENU.WISO_EMPLOYEES];

export const SUBMENU_MAP = new Map<SUBMENU, string>([
  [SUBMENU.CLIENTS, 'Klienci'],
  [SUBMENU.WISO_EMPLOYEES, 'Pracownicy WISO'],
]);
