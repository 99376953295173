import * as Types from 'Shared/models/BhpModel';
import { BHP } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';
import { sortBy, orderBy } from 'lodash';

export type State = Types.BhpStore;

const activeCompany = JSON.parse(sessionStorage.getItem('compendiumsActiveCompany'));

export const initialState: State = {
  institutions: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  dictionaries: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  compendiums: {
    data: [],
    activeCompany: activeCompany ? activeCompany.value : '',
    status: REQUEST_STATUS.IDLE,
  },
  compendium: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  compendiumActiveRating: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  compendiumCounterRatings: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  norms: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  editingCompendium: {
    status: REQUEST_STATUS.IDLE,
    uid: undefined,
  },
  editingDictionary: {
    status: REQUEST_STATUS.IDLE,
  },
  editingInstitution: {
    status: REQUEST_STATUS.IDLE,
  },
  editingNorm: {
    status: REQUEST_STATUS.IDLE,
  },
  deletingDictionary: {
    status: REQUEST_STATUS.IDLE,
  },
  deletingInstitution: {
    status: REQUEST_STATUS.IDLE,
  },
  deletingCompendium: {
    status: REQUEST_STATUS.IDLE,
  },
  deletingNorm: {
    status: REQUEST_STATUS.IDLE,
  },
  columnFiltersTerm: {},
};

export function bhpReducer(state: State = initialState, action) {
  switch (action.type) {
    case BHP.GET_HIDDEN_INSTITUTIONS_SUCCESS:
    case BHP.GET_INSTITUTIONS_SUCCESS: {
      return {
        ...state,
        institutions: {
          data: action.payload.institutions,
          status: REQUEST_STATUS.SUCCESS,
        },
        compendiums: {
          ...state.compendiums,
          searchData: '',
        },
      };
    }
    case BHP.GET_HIDDEN_INSTITUTIONS_INIT:
    case BHP.GET_INSTITUTIONS_INIT: {
      return {
        ...state,
        institutions: {
          data: state.institutions.data,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.GET_HIDDEN_INSTITUTIONS_FAILURE:
    case BHP.GET_INSTITUTIONS_FAILURE: {
      return {
        ...state,
        institutions: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case BHP.GET_HIDDEN_DICTIONARIES_SUCCESS:
    case BHP.GET_DICTIONARIES_SUCCESS: {
      if (JSON.stringify(action.payload.dictionaries) === JSON.stringify(state.dictionaries.data)) {
        return {
          ...state,
          dictionaries: {
            data: state.dictionaries.data,
            status: REQUEST_STATUS.SUCCESS,
          },
          compendiums: {
            ...state.compendiums,
            searchData: '',
          },
        };
      } else {
        return {
          ...state,
          dictionaries: {
            data: action.payload.dictionaries,
            status: REQUEST_STATUS.SUCCESS,
          },
        };
      }
    }
    case BHP.GET_HIDDEN_DICTIONARIES_INIT:
    case BHP.GET_DICTIONARIES_INIT: {
      return {
        ...state,
        dictionaries: {
          data: state.dictionaries.data,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.GET_HIDDEN_DICTIONARIES_FAILURE:
    case BHP.GET_DICTIONARIES_FAILURE: {
      return {
        ...state,
        dictionaries: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }

    case BHP.GET_HIDDEN_COMPENDIUMS_SUCCESS:
    case BHP.GET_COMPENDIUMS_SUCCESS: {
      const activeCompany = JSON.parse(sessionStorage.getItem('compendiumsActiveCompany'));

      return {
        ...state,
        compendiums: {
          data: action.payload.compendiums,
          activeCompany: action.payload.company_uuid ? action.payload.company_uuid : activeCompany?.value,
          status: REQUEST_STATUS.SUCCESS,
          searchData: action.payload?.searchData,
          pathname: action.payload?.pathname,
        },
      };
    }
    case BHP.GET_HIDDEN_COMPENDIUMS_INIT:
    case BHP.GET_COMPENDIUMS_INIT: {
      return {
        ...state,
        compendiums: {
          ...state.compendiums,
          // data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.GET_HIDDEN_COMPENDIUMS_FAILURE:
    case BHP.GET_COMPENDIUMS_FAILURE: {
      return {
        ...state,
        compendiums: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.GET_SINGLE_COMPENDIUM_INIT: {
      return {
        ...state,
        compendium: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }    
    case BHP.ADD_COMPENDIUM_EVIDENCE_SUCCESS: {
      // console.log(action.payload.data.activeRating)
      // console.log(state.compendium.data.rating)

      const activeRating = state.compendium.data.rating.find((item)=> item.id === action.payload.data.activeRating.id);
      activeRating.rating_pdfs.push(action.payload.response.data)

      return {
        ...state,
        compendium: {
           ...state.compendium,
        },
      };
    }
    case BHP.REMOVE_FILES_SUCCESS: {

      const updatedCompendiumsData = (state.compendiums?.data || []).map((item) => {
        if (item.pdfs) {
          const updatedPdfs = (item.pdfs || []).filter(({ uuid }) => uuid !== action.payload.uuid);
          // console.log(updatedPdfs)
          return {
            ...item,
            pdfs: updatedPdfs,
          };
        }
        return item;
      });
    
      const updatedCompendiumData = (state.compendium?.data || []).map((item) => {
        const updatedRatingPDFs = (item.rating_pdfs || []).filter(({ uuid }) => uuid !== action.payload.uuid);
        return {
          ...item,
          rating_pdfs: updatedRatingPDFs,
        };
      });
    
      if (!state.compendiums || !state.compendium) {
        return state;
      }

      // console.log(updatedCompendiumsData)
    
      return {
        ...state,
        compendiums: {
          ...state.compendiums,
          data: updatedCompendiumsData,
        },
        compendium: {
          ...state.compendium,
          data: updatedCompendiumData,
        },
      };
    }
    
    case BHP.GET_SINGLE_COMPENDIUM_SUCCESS: {
      const ratingCopy = action.payload.compendium.rating ? orderBy(action.payload.compendium.rating, ['created_at'], ['desc']) : [];
      const activeRating =
        ratingCopy.length &&
        ratingCopy.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        });
      return {
        ...state,
        compendium: {
          data: action.payload.compendium,
          status: REQUEST_STATUS.SUCCESS,
        },
        compendiumActiveRating: {
          data: activeRating,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.GET_SINGLE_COMPENDIUM_FAILURE: {
      return {
        ...state,
        compendium: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.GET_HIDDEN_NORMS_SUCCESS:
    case BHP.GET_NORMS_SUCCESS: {
      return {
        ...state,
        norms: {
          data: action.payload.norms,
          status: REQUEST_STATUS.SUCCESS,
        },
        compendiums: {
          ...state.compendiums,
          searchData: '',
        },
      };
    }
    case BHP.GET_HIDDEN_NORMS_INIT:
    case BHP.GET_NORMS_INIT: {
      return {
        ...state,
        norms: {
          data: state.norms.data,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.GET_HIDDEN_NORMS_FAILURE:
    case BHP.GET_NORMS_FAILURE: {
      return {
        ...state,
        norms: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.EDIT_COMPENDIUM_INIT: {
      return {
        ...state,
        editingCompendium: {
          status: REQUEST_STATUS.INIT,
          uid: action.payload.uid,
        },
      };
    }
    case BHP.EDIT_COMPENDIUM_SUCCESS: {
      const { data } = state.compendiums;
      const idx = data.findIndex(({ uid }) => uid === action.payload.compendium.uid);
      data[idx] = action.payload.compendium;
      return {
        ...state,
        compendiums: {
          ...state.compendiums,
          data,
        },
        editingCompendium: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.EDIT_COMPENDIUM_FAILURE: {
      return {
        ...state,
        editingCompendium: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.EDIT_DICTIONARY_INIT: {
      return {
        ...state,
        editingDictionary: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.EDIT_DICTIONARY_SUCCESS: {
      const { data } = state.dictionaries;
      const idx = data.findIndex(({ id }) => id === action.payload.dictionary.id);
      data[idx] = action.payload.dictionary;
      const newData = data.map((el) => {
        el.termSort = el.term.toLowerCase();
        return el;
      });
      let sortedData = sortBy(newData, ['termSort']);
      sortedData = sortedData.map((el) => {
        delete el.termSort;
        return el;
      });
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: sortedData,
        },
        editingDictionary: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.EDIT_DICTIONARY_FAILURE: {
      return {
        ...state,
        editingDictionary: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.EDIT_INSTITUTION_INIT: {
      return {
        ...state,
        editingInstitution: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.EDIT_INSTITUTION_SUCCESS: {
      const { data } = state.institutions;
      const idx = data.findIndex(({ id }) => id === action.payload.institution.id);
      data[idx] = action.payload.institution;
      return {
        ...state,
        institutions: {
          ...state.institutions,
          data,
        },
        editingInstitution: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.EDIT_INSTITUTION_FAILURE: {
      return {
        ...state,
        editingInstitution: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.HIDE_ROW_SUCCESS: {
      const {
        payload: { type, id },
      } = action;
      if (type === 'compendiums') {
        const { data } = state.compendiums;
        const filtered = data.filter(({ id: compendiumId }) => compendiumId !== id);
        return {
          ...state,
          compendiums: {
            ...state.compendiums,
            data: filtered,
          },
        };
      }
      if (type === 'dictionaries') {
        const { data } = state.dictionaries;
        const filtered = data.filter(({ id: dictionaryId }) => dictionaryId !== id);
        return {
          ...state,
          dictionaries: {
            ...state.dictionaries,
            data: filtered,
          },
        };
      }
      if (type === 'institutions') {
        const { data } = state.institutions;
        const filtered = data.filter(({ id: institutionId }) => institutionId !== id);
        return {
          ...state,
          institutions: {
            ...state.institutions,
            data: filtered,
          },
        };
      }
      if (type === 'norms') {
        const { data } = state.norms;
        const filtered = data.filter(({ id: normId }) => normId !== id);
        return {
          ...state,
          norms: {
            ...state.norms,
            data: filtered,
          },
        };
      }
      return {
        ...state,
      };
    }
    case BHP.DESTROY_HIDDEN_ROW_SUCCESS: {
      const {
        payload: { type, id },
      } = action;
      if (type === 'compendiums') {
        const { data } = state.compendiums;
        const filtered = data.filter(({ id: compendiumId }) => compendiumId !== id);
        return {
          ...state,
          compendiums: {
            ...state.compendiums,
            data: filtered,
          },
        };
      }
      if (type === 'dictionaries') {
        const { data } = state.dictionaries;
        const filtered = data.filter(({ id: dictionaryId }) => dictionaryId !== id);
        return {
          ...state,
          dictionaries: {
            ...state.dictionaries,
            data: filtered,
          },
        };
      }
      if (type === 'institutions') {
        const { data } = state.institutions;
        const filtered = data.filter(({ id: institutionId }) => institutionId !== id);
        return {
          ...state,
          institutions: {
            ...state.institutions,
            data: filtered,
          },
        };
      }
      if (type === 'norms') {
        const { data } = state.norms;
        const filtered = data.filter(({ id: normId }) => normId !== id);
        return {
          ...state,
          norms: {
            ...state.norms,
            data: filtered,
          },
        };
      }
      return {
        ...state,
      };
    }
    case BHP.ADD_RATING_COMPATIBILITY_SUCCESS: {
      let { data } = state.compendium;
      const activeRating = action.payload.data.rating_compatibility;
      const activeRatingId = action.payload.data.rating_compatibility.id
      const isRatingExist = data.rating.find(({id}) => id === activeRatingId);
      
      if(!isRatingExist) {
        data = { ...data, rating: [...data.rating, action.payload.data.rating_compatibility] };
        data = { ...data };
      } else {
        const idx = data.rating.findIndex(({ id }) => id === action.payload.data.rating_compatibility.id);
        data.rating[idx] = action.payload.data.rating_compatibility;
        data = { ...data };
      }

      return {
        ...state,
        compendium: {
          // ...state.compendium,
          data,
        },
        compendiumActiveRating: {
          data: activeRating,
        },
      };
    }
    case BHP.EDIT_NORM_INIT: {
      return {
        ...state,
        editingNorm: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.EDIT_NORM_SUCCESS: {
      const { data } = state.norms;
      const idx = data.findIndex(({ id }) => id === action.payload.norm.id);
      data[idx] = action.payload.norm;
      return {
        ...state,
        norms: {
          ...state.norms,
          data: [...data],
        },
        editingNorm: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.EDIT_NORM_FAILURE: {
      return {
        ...state,
        editingNorm: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.DELETE_DICTIONARY_INIT: {
      return {
        ...state,
        deletingDictionary: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.DELETE_RATING_COMPATIBILITIES_SUCCESS: {
      const newRating = state.compendium.data.rating.filter(({uuid}) => uuid !== action.payload.uuid)
      state.compendium.data.rating = newRating
      
      return {
        ...state,
        compendium: {
          ...state.compendium,
        },
      };
    }
    case BHP.DELETE_DICTIONARY_SUCCESS: {
      const { data } = state.dictionaries;
      const idx = data.findIndex(({ id }) => id === action.payload.id);
      console.log(data[idx]);
      data.splice(idx, 1);
      console.log(idx);
      console.log(data[idx]);
      return {
        ...state,
        dictionaries: {
          ...state.dictionaries,
          data: [...data],
        },
        deletingDictionary: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.DELETE_DICTIONARY_FAILURE: {
      return {
        ...state,
        deletingDictionary: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.DELETE_INSTITUTION_INIT: {
      return {
        ...state,
        deletingInstitution: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.DELETE_INSTITUTION_SUCCESS: {
      const { data } = state.institutions;
      const idx = data.findIndex(({ id }) => id === action.payload.id);
      data.splice(idx, 1);
      return {
        ...state,
        institutions: {
          ...state.institutions,
          data,
        },
        deletingInstitution: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.DELETE_INSTITUTION_FAILURE: {
      return {
        ...state,
        deletingInstitution: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.DELETE_COMPENDIUM_INIT: {
      return {
        ...state,
        deletingCompendium: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.DELETE_COMPENDIUM_SUCCESS: {
      const { data } = state.compendiums;
      const idx = data.findIndex(({ uid }) => uid === action.payload.uid);
      data.splice(idx, 1);
      
      return {
        ...state,
        compendiums: {
          ...state.compendiums,
          data,
        },
        deletingCompendium: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    
    case BHP.DELETE_COMPENDIUM_FAILURE: {
      return {
        ...state,
        deletingCompendium: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.DELETE_NORM_INIT: {
      return {
        ...state,
        deletingNorm: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.DELETE_NORM_SUCCESS: {
      const { data } = state.norms;
      const idx = data.findIndex(({ id }) => id === action.payload.id);
      data.splice(idx, 1);
      return {
        ...state,
        norms: {
          ...state.norms,
          data: [...data],
        },
        deletingNorm: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.DELETE_NORM_FAILURE: {
      return {
        ...state,
        deletingNorm: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.SET_ACTIVE_RATING_INIT: {
      return {
        ...state,
        compendiumActiveRating: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.SET_ACTIVE_RATING_SUCCESS: {
      return {
        ...state,
        compendiumActiveRating: {
          data: action.payload.activeRating,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.SET_ACTIVE_RATING_FAILURE: {
      return {
        ...state,
        compendiumActiveRating: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.GET_COMPENDIUM_RATINGS_COUNTER_INIT: {
      return {
        ...state,
        compendiumCounterRatings: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case BHP.GET_COMPENDIUM_RATINGS_COUNTER_SUCCESS: {
      return {
        ...state,
        compendiumCounterRatings: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case BHP.GET_COMPENDIUM_RATINGS_COUNTER_FAILURE: {
      return {
        ...state,
        compendiumCounterRatings: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case BHP.SET_COLUMN_FILTER_TERMS: {
      return {
        ...state,
        columnFiltersTerm: {
          ...state.columnFiltersTerm,
          ...action.payload.filters,
        },
      };
    }
    case BHP.RESET_COLUMN_FILTER_TERMS: {
      return {
        ...state,
        columnFiltersTerm: {},
      };
    }


    case BHP.EDIT_TASK_SUCCESS: {
      const editedTask = action.payload.data.tasks;
      const updatedRating = state.compendium.data.rating.map(rating => {
        if (rating.id === editedTask.rating_compatibility_id) {
          rating.tasks = rating.tasks.map(task => {
            if (task.id === editedTask.id) {
              return editedTask;
            }
            return task;
          });
        }
        return rating;
      });
      return {
        ...state,
        compendium: {
          ...state.compendium,
          data: {
            ...state.compendium.data,
            rating: updatedRating,
          },
        },
      };
    }
    

    case BHP.ADD_TASK_SUCCESS: {
      const newTask = action.payload.data.tasks; 
      const updatedRating = state.compendium.data.rating.map(rating => {
        if (rating.id === newTask.rating_compatibility_id) {
          rating.tasks.push(newTask);
        }
        return rating;
      });
    
      return {
        ...state,
        compendium: {
          ...state.compendium,
          data: {
            ...state.compendium.data,
            rating: updatedRating,
          },
        },
      };
    }

    case BHP.REMOVE_TASK_SUCCESS: {
      const updatedRating = state.compendium.data.rating.map(rating => {
        if (rating.id === action.payload.data.tasks.rating_compatibility_id) {
          const taskIndexToRemove = rating.tasks.findIndex(task => task.uuid === action.payload.data.tasks.uuid);
          if (taskIndexToRemove !== -1) {
            rating.tasks.splice(taskIndexToRemove, 1);
          }
        }
        return rating;
      });
      return {
        ...state,
        compendium: {
          ...state.compendium,
          data: {
            ...state.compendium.data,
            rating: updatedRating,
          },
        },
      };
    }

    case BHP.ADD_EVIDENCE_SUCCESS: {
      const editedTask = action.payload.data.tasks;
      const updatedRating = state.compendium.data.rating.map(rating => {
        if (rating.id === editedTask.rating_compatibility_id) {
          rating.tasks = rating.tasks.map(task => {
            if (task.id === editedTask.id) {
              return editedTask;
            }
            return task;
          });
        }
        return rating;
      });
      return {
        ...state,
        compendium: {
          ...state.compendium,
          data: {
            ...state.compendium.data,
            rating: updatedRating,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}
