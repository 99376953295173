import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromUserReducer from './UserReducer';
import * as fromAuthReducer from './AuthReducer';
import * as fromViewManagementReducer from './ViewManagementReducer';
import * as fromKnowledgeReducer from './KnowledgeReducer';
import * as fromBhpReducer from './BhpReducer';
import * as fromNotificationReducer from './NotificationReducer';
import * as fromNewTabReducer from './NewTabReducer';
import { createBrowserHistory } from 'history';

export type RootState = {
  router: any;
  userStore: fromUserReducer.State;
  authStore: fromAuthReducer.State;
  viewManagementStore: fromViewManagementReducer.State;
  knowledge: fromKnowledgeReducer.State;
  notificationStore: fromNotificationReducer.State;
  newTabStore: fromNewTabReducer.State;
  bhp: fromBhpReducer.State;
};

export const history = createBrowserHistory();

const rootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  userStore: fromUserReducer.userReducer,
  authStore: fromAuthReducer.authReducer,
  viewManagementStore: fromViewManagementReducer.viewManagementReducer,
  notificationStore: fromNotificationReducer.notificationReducer,
  knowledge: fromKnowledgeReducer.knowledgeReducer,
  newTabStore: fromNewTabReducer.newTabReducer,
  bhp: fromBhpReducer.bhpReducer,
} as any);

const appReducer = (state: RootState, action) => {
  if (action.type === 'LOG_OUT_SUCCESS') {
    state.authStore = undefined;
    // state.viewManagementStore.isMenuExpanded = false;
    // state.router = {
    //   router: { ...state.router },
    // };
  }
  return rootReducer(state, action);
};

export default appReducer;
