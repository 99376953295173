import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { Actions } from 'Modules/ViewManagementModule';

const init = (uid) => ({
  type: BHP.DELETE_COMPENDIUM_INIT,
  payload: {
    uid,
  },
});

const success = (uid) => ({
  type: BHP.DELETE_COMPENDIUM_SUCCESS,
  payload: {
    uid,
  },
});

const failure = () => ({
  type: BHP.DELETE_COMPENDIUM_FAILURE,
});

const deleteCompendium = (compendiumUid) => async (dispatch) => {
  dispatch(init(compendiumUid));
  try {
    dispatch(Actions.showLoader());
    await Connector.deleteCompendium(compendiumUid);
    dispatch(success(compendiumUid));
    dispatch(Actions.hideLoader());
    toast.success('Pomyślnie usunięto wiersz');
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default deleteCompendium;
