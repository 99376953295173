import * as AuthModule from '../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';
import { history } from '../../../Store/Reducers/RootReducer';
import { ROUTES } from 'Shared/enums';

const init = () => ({
  type: Auth.GET_REFRESH_TOKEN_INIT,
});

const success = (token: string): AuthActionModel.GetRefreshToken => ({
  type: Auth.GET_REFRESH_TOKEN_SUCCESS,
  payload: {
    token,
  },
});

const failure = () => ({
  type: Auth.GET_REFRESH_TOKEN_FAILURE,
});

const getRefreshToken = (refreshToken: string = null) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const {
      data: { token },
    } = await AuthModule.Connector.getRefreshToken(refreshToken);
    dispatch(success(token));
    dispatch(ViewManagementModule.Actions.hideLoader());
    history.push(ROUTES.BAZA_WIEDZY);
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log('GET_REFRESH_TOKEN', err.response);
    if (err.response && err.response.status === 404) {
      localStorage.removeItem('isUserLogged');
    }
    history.push(ROUTES.CUSTOMER_LOGIN);
    // toast.error('Niepoprawne dane logowania');
  }
};

export default getRefreshToken;
