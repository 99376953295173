import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './Reducers/RootReducer';

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window['__REDUX_DEVTOOLS_EXTENSION__'] ? window['__REDUX_DEVTOOLS_EXTENSION__']({ trace: true, traceLimit: 25 }) : (f: Function) => f,
  ),
);
