import 'react-dates/initialize';
import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store } from './Store/Store';
import { Route } from 'react-router-dom';
import { history } from './Store/Reducers/RootReducer';
import Routes from './Routes';

const RootApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/" component={Routes} />
    </ConnectedRouter>
  </Provider>
);
export default RootApp;
