import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getKnowledgeStore = (state: RootState) => state.knowledge;
const getKnowledgePosts = createSelector(getKnowledgeStore, ({ knowledgePosts: { data = [] } }) =>
  data.map(({ id, better_featured_image, date: createDate, link, title: { rendered: title }, image }) => ({
    id,
    createDate,
    title,
    image,
    link,
    better_featured_image,
  })),
);
const getKnowledgePostsStatus = createSelector(getKnowledgeStore, ({ knowledgePosts: { status } }) => status);
const getTopicalities = createSelector(
  getKnowledgeStore,
  ({
    topicalities: {
      data: { data = [], lastPage, page },
    },
  }) => {
    return {
      data: data.map(({ uuid, category, content, link, created_at }) => ({ uuid, category, content, link, created_at })),
      lastPage: lastPage,
      page: page,
    };
  },
  // data.map(({ uuid, category, content, link, created_at }) => ({ uuid, category, content, link, created_at })),
);
const getEditingTopicalityStatus = createSelector(getKnowledgeStore, ({ editingTopicality }) => editingTopicality);

export { getKnowledgePosts, getTopicalities, getKnowledgePostsStatus, getEditingTopicalityStatus };
