import * as React from 'react';
import { MainType } from './Container';
import ManageUserForm from './components/ManageUserForm';
import AssociatedUsersHeader from './components/associatedUsersHeader';
import AssociatedUsersTable from './components/associatedUsersTable';
import { ROUTES } from 'Shared/enums';

type P = {
  match?: any;
  history?: any;
};

class UserManagement extends React.PureComponent<MainType & P, {}> {
  componentDidMount() {
    if (this.props.match.params.id !== 'dodaj') {
      this.props.actions.getCompany(this.props.match.params.id);
    }
  }
  componentWillUnmount() {
    if (this.props.match.params.id !== 'dodaj') {
      this.props.actions.clearActiveCompany();
    }
  }
  addUser = (userData: any) => this.props.actions.addUser(userData, this.props.match.params.id);
  editUser = (userData: any, userUuid) => this.props.actions.editUser(userData, userUuid);

  render() {
    const { actions, match, company } = this.props;
    const { users } = company;
    const type = match.params.id === 'dodaj' ? 'ADD' : 'EDIT';
    const currentLoginsAmount = users ? users.length : 0;
    const isLoginsAmountFull = users && users.length === company.logins_amount;
    const manageCompany = (companyData) => (type === 'ADD' ? actions.addCompany(companyData) : actions.editCompany(companyData));
    return (
      <div className="userManagement">
        <div className="userManagement--l">
          <ManageUserForm
            currentLoginsAmount={currentLoginsAmount}
            manageCompany={manageCompany}
            company={type === 'EDIT' ? company : {}}
            submitLabel={type === 'ADD' ? 'Dodaj Klienta' : 'Zapisz zmiany'}
            goBack={() => this.props.history.push(ROUTES.USERS)}
          />
        </div>
        <div className="userManagement--r">
          <AssociatedUsersHeader maxLoginsAmount={company.logins_amount} currentLoginsAmount={currentLoginsAmount} />
          <AssociatedUsersTable
            users={users}
            addUser={this.addUser}
            disabled={type === 'ADD' || isLoginsAmountFull}
            editUser={this.editUser}
            editingUser={this.props.editingUser}
            deleteUser={this.props.actions.deleteUser}
          />
        </div>
      </div>
    );
  }
}
export default UserManagement;
