import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import UserManagement from './Main';
import { Actions, Selectors } from 'Modules/UserModule';
import { bindActionCreators } from 'redux';
import { REQUEST_STATUS } from 'Shared/enums';

type ConnectedP = {
  company: any;
  editingUser: {
    status: REQUEST_STATUS;
  };
};

type DispatchedP = {
  actions: {
    addCompany: (companyData: any) => void;
    editCompany: (companyData: any) => void;
    getCompany: (uuid: string) => void;
    clearActiveCompany: () => void;
    addUser: (userData: any, uuid: string) => void;
    editUser: (userData: any, uuid: string) => void;
    deleteUser: (uuid: string) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addCompany: Actions.addCompany,
      editCompany: Actions.editCompany,
      getCompany: Actions.getCompany,
      clearActiveCompany: Actions.clearActiveCompany,
      addUser: Actions.addUser,
      editUser: Actions.editUser,
      deleteUser: Actions.deleteUser,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  company: Selectors.getActiveCompany(state),
  editingUser: Selectors.getEditingUserStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
