import * as React from 'react';

type P = {
  value: any;
  onChange: (data: any) => void;
};

const InputFilter = ({ value = '', onChange }: P) => {
  return <input type="text" value={value} onChange={(event) => onChange(event.target.value)} />;
};

export default InputFilter;
