import React, { Component } from 'react';
import { Field, Formik } from 'formik';
import Select from 'react-select';
import { Button, Modal, SVG } from 'Shared/components';
import { MODAL_TYPE, USER_LEVELS, SVG_TYPE } from 'Shared/enums';
import { RegularField, DatePicker } from 'Shared/components/forms';
import moment from 'moment';
import * as Yup from 'yup';
import ReactTooltip from 'react-tooltip';

const MODAL_MANAGE = {
  ADD: 'add',
  EVIDENCE: 'evidence',
  EDIT: 'edit',
  DELETE: 'delete',
  EMPTY: '',
};

type TaskBoxState = {
  modalManage: string;
  taskUuid: string;
  task: any;
  taskFiles: any;
  userSelected: any;
};
type p = {
  isActiveDayRatingIsToday: boolean;
  isCustomerLogged: boolean;
  customerLevel: string;
  usersInCompanyOptions: any;
  addTask?: (data: any) => void;
  removeTask?: (uuid: string) => void;
  editTask?: (data: any, uuid: any) => void;
  addEvidence?: (data: any, uuid: any) => void;
  addEvidenceFile?: (data: any, file: any) => void;
  activeRating: any;
  loggedUserUuid: string;
  authorizedUser: any;
  companyEmployee: any;
};

class TaskBox extends Component<p, TaskBoxState> {
  constructor(props) {
    super(props);
    this.state = {
      modalManage: MODAL_MANAGE.EMPTY,
      task: {},
      taskUuid: '',
      taskFiles: [],
      userSelected: null,
    };
  }

  render() {
    const {
      customerLevel,
      loggedUserUuid,
      isCustomerLogged,
      usersInCompanyOptions,
      activeRating,
      addTask,
      removeTask,
      editTask,
      isActiveDayRatingIsToday,
      addEvidence,
      addEvidenceFile,
      authorizedUser,
      companyEmployee,
    } = this.props;
    const { modalManage, task, taskFiles, userSelected } = this.state;
    const isModalOpened = MODAL_MANAGE.EMPTY !== modalManage;
    const isManaging =
      (customerLevel === USER_LEVELS.DEMO || customerLevel === USER_LEVELS.MANAGING || customerLevel === USER_LEVELS.DETAILED) && isCustomerLogged;
    // const isModalForm = modalManage === MODAL_MANAGE.ADD;
    const isModalEdit = modalManage === MODAL_MANAGE.EDIT;
    const isModalEvidence = modalManage === MODAL_MANAGE.EVIDENCE;
    const isModalDelete = modalManage === MODAL_MANAGE.DELETE;
    const today = moment();
    const closeModal = () => this.setState({ modalManage: MODAL_MANAGE.EMPTY });

    const searchUserInCompanyOptions = (item) => {
      if (item) {
        const user = usersInCompanyOptions.find(({ value }) => value === item.value);
        this.setState({ userSelected: user });
      } else {
        const user = task && task.assigned_user && usersInCompanyOptions.find(({ value }) => value === task.assigned_user.uuid);
        if (user) {
          this.setState({ userSelected: user });
        } else {
          this.setState({ userSelected: usersInCompanyOptions[0] });
        }
      }
    };

    let initialValues = {};
    if (isModalEvidence) {
      initialValues = {
        evidence: '',
        work_plan_date: '',
        periodicity: '',
      };
    } else {
      initialValues = {
        deadline: isModalEdit ? moment(task.deadline).format('YYYYMMDD') : today,
        comment: isModalEdit ? task.comment : '',
        rating_compatibility_uuid: activeRating.uuid,
        assigned_user_uuid: isModalEdit ? task.assigned_user.uuid : '',
      };
    }

    const onSubmit = async (formValues: any) => {
      if (isModalEvidence) {
        const evidencePromises = taskFiles.map(async (item) => {
          await addEvidenceFile({ ...formValues, name: item.name, uuid: task.uuid }, item);
        });
        await Promise.all(evidencePromises);
        await addEvidence(formValues, task.uuid);
      } else {
        isModalEdit ? await editTask(formValues, task.uuid) : await addTask(formValues);
      }
      this.setState({ modalManage: MODAL_MANAGE.EMPTY, task: {}, taskUuid: '' });
    };

    const handleExpandText = (itemId) => {
      const element = document.querySelector(`.taskBox__task-item--plan[data-id="${itemId}"]`);
      if (element) {
        element.classList.toggle('taskBox__task-item--plan-collapse');
      }
    };

    const handleRemoveTask = async (uuid) => {
      await removeTask(uuid);
      this.setState({ modalManage: MODAL_MANAGE.EMPTY });
    };

    const handleChangeStatus = async ({ value }, item) => {
      if (value !== 'done') {
        await editTask({ status: value }, item.uuid);
      } else {
        this.setState({ modalManage: MODAL_MANAGE.EVIDENCE, task: item });
      }
    };

    const handleChangeTaskRealization = async (date, uuid) => {
      await editTask({ deadline: date }, uuid);
    };

    const statusOptions = [
      { value: 'to_do', label: 'Do zrobienia' },
      { value: 'in_progress', label: 'W trakcie' },
      { value: 'done', label: 'Zrobione' },
    ];

    const findStatusOption = (status) => statusOptions.find((option) => option.value === status);

    const getEvidenceSchema = () =>
      Yup.object().shape({
        evidence: Yup.string().required('Pole wymagane'),
      });

    const getAddNewTaskSchema = () =>
      Yup.object().shape({
        comment: Yup.string().required('Pole wymagane'),
        assigned_user_uuid: Yup.string().required('Pole wymagane'),
      });

    const fileInputSection = (taskFiles) => {
      const renderFileInputs = () => {
        let inputCount;

        if (taskFiles.length === 0) {
          inputCount = 3;
        } else if (taskFiles.length === 1) {
          inputCount = 2;
        } else if (taskFiles.length === 2) {
          inputCount = 1;
        } else {
          inputCount = 0;
        }

        const fileInputs = [];

        const handleSetFile = (e) => {
          const newFile = e.target.files[0];
          this.setState({ taskFiles: [...this.state.taskFiles, newFile] });
        };

        for (let i = 0; i < inputCount; i++) {
          fileInputs.push(
            <div
              key={i}
              className={`fulfillment-proof--icon-file `}
              data-tip="maksymalny rozmiar pliku wynosi 10MB, formaty obsługiwanych plików: JPG, JPEG, PDF, PNG">
              <SVG type={SVG_TYPE.FILE_ADD} />
              <input className="fulfillment-proof--input" type="file" accept=".jpg, .jpeg, .png, .pdf" onChange={handleSetFile} />
            </div>,
          );
        }

        return fileInputs;
      };

      const handleRemoveFile = (index) => {
        const updatedFiles = [...taskFiles];
        updatedFiles.splice(index, 1);
        this.setState({ taskFiles: updatedFiles });
      };

      return (
        <>
          {renderFileInputs()}
          {taskFiles.map((file, index) => (
            <div className="taskBox__addTask-form-field--files-item" key={index}>
              {`${file.name}`}
              <div onClick={() => handleRemoveFile(index)}>
                <SVG type={SVG_TYPE.DELETE} />
              </div>
            </div>
          ))}
        </>
      );
    };

    const handleDownloadTaskItem = (pdf) => {
      window.open(pdf.src, '_blank');
    };

    const isAllTasksDoneExceptOne = () => {
      const doneTasks = activeRating.tasks.filter((task) => task.status === 'done');
      return doneTasks.length === activeRating.tasks.length - 1;
    };

    const periodicityOptions = [
      {
        value: null,
        label: 'wyłączona cykliczność',
      },
      {
        value: 'one_month',
        label: 'co miesiąc',
      },
      {
        value: 'three_months',
        label: 'co 3 miesiące',
      },
      {
        value: 'six_months',
        label: 'co 6 miesięcy',
      },
      {
        value: 'twelve_months',
        label: 'co 12 miesięcy',
      },
      {
        value: 'custom_date',
        label: 'wybierz datę',
      },
    ];

    const defaultOptions = () => {
      const option = periodicityOptions.find(({ value }) => value === activeRating.periodicity);
      return option;
    };

    const isManagingAccountAndTodayAndNotcompatibility = isActiveDayRatingIsToday && authorizedUser.level === 'managing' && !activeRating.rating_3;
    const isMyRating = loggedUserUuid === activeRating.rating_author.uuid || authorizedUser.level === 'managing';
    const showAddButton = (isMyRating && isActiveDayRatingIsToday && !activeRating.rating_3) || isManagingAccountAndTodayAndNotcompatibility;

    const tooltipActiveUser = (isOnline) => {
      if (isOnline) {
        return (
          <div className="tooltipLastActivity">
            <span className="tooltipLastActivity__icon" />
            <span className="tooltipLastActivity__light">Online</span>
          </div>
        );
      }
    };
    const tooltipLastActivity = (lastActivity) => {
      if (lastActivity) {
        return (
          <div className="tooltipLastActivity">
            <span className="tooltipLastActivity__light">ostatnio online:</span>
            <span className="tooltipLastActivity__bold">{lastActivity}</span>
          </div>
        );
      }
    };

    const isUserTaskExists =
      activeRating.tasks && activeRating.tasks.length > 0 && activeRating.tasks.some((item) => loggedUserUuid === item.assigned_user.uuid);

    return (
      <>
        <Modal
          onClose={closeModal}
          className={`${isModalDelete ? '' : 'taskBox'} ${isModalEvidence ? 'evidence-modal' : ''}`}
          type={isModalDelete ? MODAL_TYPE.DELETE : MODAL_TYPE.FORM}
          onSubmit={() => handleRemoveTask(this.state.taskUuid)}
          opened={isModalOpened}>
          <>
            {isModalDelete ? (
              <>
                <div className="taskBox__modal-heading">Usuwanie</div>
                <div className="taskBox__modal-description"> Czy na pewno chcesz usunąć ten plan działań doskonalących? </div>
              </>
            ) : (
              <>
                <div className="taskBox__modal-heading">
                  {isModalEvidence ? 'Dowód' : <>{isModalEdit ? 'Edycja planu działań doskonalących' : 'Dodawanie planu działań doskonalących'}</>}
                </div>
                <Formik
                  enableReinitialize
                  validationSchema={isModalEvidence ? getEvidenceSchema() : getAddNewTaskSchema()}
                  initialValues={initialValues}
                  onSubmit={onSubmit}>
                  {({ handleSubmit, errors, setFieldValue, values }) => {
                    return (
                      <form onSubmit={handleSubmit} className="taskBox__addTask-form">
                        {!isModalEvidence && (
                          <>
                            <div className="taskBox__addTask-form--top">
                              <div className="taskBox__addTask-form-field taskBox__addTask-form-field--top">
                                <div className="taskBox__modal-label">Odpowiedzialny</div>
                                {isManaging && (
                                  <>
                                    {isModalEdit ? (
                                      <Select
                                        name="assigned_user_uuid"
                                        value={userSelected ? userSelected : searchUserInCompanyOptions(null)}
                                        onChange={(e) => {
                                          searchUserInCompanyOptions(e);
                                          setFieldValue('assigned_user_uuid', e.value);
                                        }}
                                        style={{ width: '100%' }}
                                        placeholder="Wybierz pracownika"
                                        options={companyEmployee?.data?.userLabel}
                                        className={`rating-select ${errors['assigned_user_uuid'] ? 'select-error' : ''}`}
                                        classNamePrefix={`rating-select`}
                                      />
                                    ) : (
                                      <Select
                                        name="assigned_user_uuid"
                                        onChange={({ value }) => setFieldValue('assigned_user_uuid', value)}
                                        style={{ width: '100%' }}
                                        placeholder="Wybierz pracownika"
                                        options={companyEmployee?.data?.userLabel}
                                        className={`rating-select ${errors['assigned_user_uuid'] ? 'select-error' : 'albo-nie'}`}
                                        classNamePrefix="rating-select"
                                        errors={errors}
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="taskBox__addTask-form-field taskBox__addTask-form-field--top">
                                <div className="taskBox__modal-label">Planowany termin</div>
                                <Field
                                  name="deadline"
                                  type="text"
                                  onChange={(date) => console.log(date)}
                                  // onChange={(date) =>
                                  //   findChangelogs(date ? moment(date).format('YYYY-MM-DD') : '', end_date ? moment(end_date).format('YYYY-MM-DD') : '')
                                  // }
                                  value={today}
                                  component={DatePicker}
                                  isRange={false}
                                  errors={errors}
                                  showClearDate
                                  wrapperClassName={'date-picker-blank'}
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {isModalEvidence && (
                          <>
                            {isAllTasksDoneExceptOne() && (
                              <>
                                <div className="taskBox__add-evidence-container">
                                  <div className="taskBox__modal-label">Termin następnej oceny</div>
                                  <div className="taskBox__add-evidence">
                                    <Select
                                      defaultValue={defaultOptions()}
                                      style={{ width: '100%', margin: '0 0 10px 0' }}
                                      placeholder="Cykliczność"
                                      onChange={({ value }) => {
                                        let howLong;
                                        if (value === 'one_month') {
                                          howLong = 1;
                                        } else if (value === 'three_months') {
                                          howLong = 3;
                                        } else if (value === 'six_months') {
                                          howLong = 6;
                                        } else if (value === 'twelve_months') {
                                          howLong = 12;
                                        }
                                        const endDay = moment().add(howLong, 'month');
                                        const endDayFormat = endDay.format('YYYYMMDD');
                                        setFieldValue('work_plan_date', endDayFormat);

                                        value === null ? setFieldValue('periodicity', null) : setFieldValue('periodicity', value);
                                      }}
                                      options={periodicityOptions}
                                      className={'rating-select '}
                                      wrapperClassName="ratingBox__face-label ratingBox__face-label--active"
                                      classNamePrefix="rating-select"
                                    />
                                    <Field
                                      name="work_plan_date"
                                      type="text"
                                      // onChange={(date) => data(date)}
                                      component={DatePicker}
                                      errors={errors}
                                      isRange={false}
                                    />
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                        <div className="taskBox__addTask-form-field">
                          <div className="taskBox__modal-label">{isModalEvidence ? 'Komentarz' : 'Plan działań doskonalących'}</div>
                          <Field
                            className="taskBox__addTask-form-textarea"
                            placeholder="Miejsce na opis"
                            type="textarea"
                            name={isModalEvidence ? 'evidence' : 'comment'}
                            component={RegularField}
                            errors={errors}
                          />
                        </div>
                        {isModalEvidence && (
                          <>
                            <div className="taskBox__addTask-form-field taskBox__addTask-form-field--add-files">
                              <div className="taskBox__modal-label">Załącz plik(opcjonalnie)</div>
                              <div className="taskBox__addTask-form-field--files">{fileInputSection(this.state.taskFiles)}</div>
                            </div>
                          </>
                        )}
                        <div className="taskBox__addTask-form-buttons">
                          <Button label="Anuluj" classNames="btn btn--transparent" onClick={() => closeModal()} />
                          <Button label="Zapisz" classNames="btn btn--navy-blue" type="submit" />
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              </>
            )}
          </>
        </Modal>
        {showAddButton && activeRating.tasks && activeRating.tasks.length === 0 && (
          <>
            <div className="taskBox__heading">
              <div className="taskBox__heading-item taskBox__heading-item--lp">L.p.</div>
              <div className="taskBox__heading-item taskBox__heading-item--plan">Plan działań doskonalących</div>
              <div className="taskBox__heading-item taskBox__heading-item--responsibility">Odpowiedzialny</div>
              <div className="taskBox__heading-item taskBox__heading-item--realization">Termin realizacji</div>
              <div className="taskBox__heading-item taskBox__heading-item--action">Akcje</div>
            </div>
          </>
        )}

        <div className="taskBox">
          {activeRating.tasks &&
            activeRating.tasks.length > 0 &&
            activeRating.tasks.map((item, idx) => {
              const lp = idx + 1;
              const selectedOption = findStatusOption(item.status);
              const isUserTask = loggedUserUuid === item.assigned_user.uuid;
              const showActionsHeading = isActiveDayRatingIsToday && !isUserTaskExists;
              return (
                <>
                  {idx === 0 && (
                    <div className="taskBox__heading">
                      <div className="taskBox__heading-item taskBox__heading-item--lp">L.p.</div>
                      <div className="taskBox__heading-item taskBox__heading-item--plan">Plan działań doskonalących</div>
                      <div className="taskBox__heading-item taskBox__heading-item--responsibility">Odpowiedzialny</div>
                      <div className="taskBox__heading-item taskBox__heading-item--realization">Termin realizacji</div>
                      {/* Te elementy poniżej będą wyświetlane w zależności od tego, czy jest to dzień dodania lub inny */}
                      {showActionsHeading ? (
                        <div className="taskBox__heading-item taskBox__heading-item--action">Akcje</div>
                      ) : (
                        <>
                          <div className="taskBox__heading-item taskBox__heading-item--evidence">Dowód</div>
                          <div className="taskBox__heading-item taskBox__heading-item--status">Status / Akcje</div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="taskBox__task">
                    <div className="taskBox__task-item taskBox__task-item--lp">
                      {lp}.
                      <SVG type={SVG_TYPE.FILE} />
                    </div>
                    <div data-id={item.id} className="taskBox__task-item taskBox__task-item--plan taskBox__task-item--plan-collapse">
                      <div className="taskBox__task-item taskBox__task-item--plan-item" onClick={() => handleExpandText(item.id)}>
                        {item.comment}
                      </div>
                      {item.evidence && (
                        <>
                          <div className="taskBox__task-item--evidence-comment">
                            <SVG type={SVG_TYPE.CHAT} />
                            {item.evidence}
                          </div>
                          <div className="taskBox__task-item--evidence-pdfs">
                            {item.tasks_pdfs &&
                              item.tasks_pdfs.map((pdf) => {
                                return (
                                  <div key={pdf.uid} className="taskBox__task-item--evidence-pdf" onClick={() => handleDownloadTaskItem(pdf)}>
                                    <SVG type={SVG_TYPE.FILE} />
                                    {pdf.file_name}
                                  </div>
                                );
                              })}
                          </div>
                        </>
                      )}
                    </div>
                    {!item.assigned_user.is_online && (
                      <>
                        <ReactTooltip place="bottom" effect="solid" className="tooltip" delayShow={200} html={true} />
                        <div
                          data-tip={tooltipLastActivity(item.assigned_user.last_activity)}
                          className="taskBox__task-item taskBox__task-item--responsibility"
                          data-for={`tooltipLastActivity--${idx}`}>
                          <SVG type={SVG_TYPE.USER} />
                          {item.assigned_user ? item.assigned_user.first_name : 'Użytkownik'}
                          {item.assigned_user ? item.assigned_user.last_name : 'usunięty'}
                        </div>
                        <ReactTooltip id={`tooltipLastActivity--${idx}`} getContent={() => tooltipLastActivity(item.assigned_user.last_activity)} />
                      </>
                    )}
                    {item.assigned_user.is_online && (
                      <>
                        <ReactTooltip place="bottom" effect="solid" className="tooltip" delayShow={200} html={true} />
                        <div
                          data-tip={tooltipActiveUser(item.assigned_user.last_activity)}
                          className="taskBox__task-item taskBox__task-item--responsibility"
                          data-for={`tooltipActive--${idx}`}>
                          <SVG type={SVG_TYPE.USER} />
                          {item.assigned_user ? item.assigned_user.first_name : 'Użytkownik'}{' '}
                          {item.assigned_user ? item.assigned_user.last_name : 'usunięty'}
                        </div>
                        <ReactTooltip id={`tooltipActive--${idx}`} getContent={() => tooltipActiveUser(item.assigned_user.last_activity)} />
                      </>
                    )}
                    <div className="taskBox__task-item taskBox__task-item--realization">
                      <SVG type={SVG_TYPE.RATING_CALENDAR} />
                      {isUserTask && item.status !== 'done' ? (
                        <>
                          <Formik initialValues={{ deadline: item.deadline }} onSubmit={onSubmit}>
                            {({ handleSubmit, errors, setFieldValue }) => (
                              <Field
                                name="deadline"
                                type="text"
                                onChange={(date) => handleChangeTaskRealization(date, item.uuid)}
                                value={item.deadline}
                                component={DatePicker}
                                isRange={false}
                                errors={errors}
                                showClearDate
                                wrapperClassName={'date-picker-blank'}
                              />
                            )}
                          </Formik>
                        </>
                      ) : (
                        <>{moment(item.deadline).format('DD/MM/YYYY')}</>
                      )}
                    </div>
                    {isActiveDayRatingIsToday && !isUserTask ? (
                      <>
                        {isMyRating ? (
                          <>
                            {isUserTaskExists && <div className="taskBox__task-item taskBox__task-item--evidence"></div>}
                            <div className="taskBox__task-item taskBox__task-item--action">
                              <Button
                                label="Edytuj"
                                classNames="btn btn--transparent"
                                onClick={() => this.setState({ modalManage: MODAL_MANAGE.EDIT, taskUuid: item.uuid, task: item })}
                              />
                              <Button
                                label="Usuń"
                                classNames="btn btn--transparent"
                                onClick={() => this.setState({ modalManage: MODAL_MANAGE.DELETE, taskUuid: item.uuid })}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="taskBox__task-item taskBox__task-item--evidence"></div>x
                            <div className="taskBox__task-item taskBox__task-item--status"></div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="taskBox__task-item taskBox__task-item--evidence">
                          {item.tasks_pdfs && item.tasks_pdfs.length > 0 && <SVG type={SVG_TYPE.FILE_ACTIVE} />}
                          {item.evidence && <SVG type={SVG_TYPE.CHAT} />}
                        </div>
                        <div className="taskBox__task-item taskBox__task-item--status">
                          {item.status === 'done' ? (
                            <div className="taskBox__task--done">
                              zrobione
                              <SVG type={SVG_TYPE.GREEN_FACE} />
                            </div>
                          ) : (
                            <>
                              {item.assigned_user && loggedUserUuid === item.assigned_user.uuid && (
                                <Select
                                  value={selectedOption}
                                  options={statusOptions}
                                  classNamePrefix="react-select"
                                  onChange={(value) => handleChangeStatus(value, item)}
                                />
                              )}
                              {isUserTaskExists && (
                                <div className="taskBox__task-item taskBox__task-item--action">
                                  <Button
                                    label="Edytuj"
                                    classNames="btn btn--transparent"
                                    onClick={() => this.setState({ modalManage: MODAL_MANAGE.EDIT, taskUuid: item.uuid, task: item })}
                                  />
                                  <Button
                                    label="Usuń"
                                    classNames="btn btn--transparent"
                                    onClick={() => this.setState({ modalManage: MODAL_MANAGE.DELETE, taskUuid: item.uuid })}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </>
              );
            })}
          {showAddButton && (
            <div className="taskBox__addTask-container">
              <Button
                onClick={() => this.setState({ modalManage: MODAL_MANAGE.ADD })}
                label="Dodaj +"
                classNames="btn btn--navy-blue"
                type="submit"
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default TaskBox;
