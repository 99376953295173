import * as React from 'react';
import { ReactTable, Badge, Modal, SVG } from 'Shared/components';
import { AssociateEmployeeRow } from '../associatedEmployeesTable/components';
import moment from 'moment';
import { SVG_TYPE, MODAL_TYPE, ROLES, REQUEST_STATUS } from 'Shared/enums';
import EmployeeFrom from '../employeeForm';

const PermissionCell = ({
  original: { roles, uuid, area_bhp, area_environment, area_energy },
  editEmployee,
  modifiedRoles,
  onEditRowClick,
  openModal,
  original,
  disabled = false,
}: // editUser,
any) => {
  const { editor, admin, super_admin } = modifiedRoles;
  roles.forEach(({ role }) => {
    if (role === modifiedRoles[role].name) {
      if (role === ROLES.SUPER_ADMIN) {
        modifiedRoles.editor.value = true;
        modifiedRoles.admin.value = true;
        return (modifiedRoles[role].value = true);
      } else {
        return (modifiedRoles[role].value = true);
      }
    }
  });
  const onClick = () => {
    openModal(original);
  };
  const onEdit = () => {
    onEditRowClick(original);
  };
  const disabledEditor = roles.length === 1 && roles[0].role === 'editor';
  const disabledAdmin = roles.length === 1 && roles[0].role === 'admin';
  const editorWithAdmin = { name: ['editor', 'admin'] };
  return (
    <>
      <div className="permissionsCell">
        <Badge title="BHP" color="blue" value={area_bhp} onClick={() => editEmployee({ area_bhp: !area_bhp }, uuid)} />
        <Badge title="OŚ" color="green" value={area_environment} onClick={() => editEmployee({ area_environment: !area_environment }, uuid)} />
        <Badge title="EN" color="orange" value={area_energy} onClick={() => editEmployee({ area_energy: !area_energy }, uuid)} />
        <Badge
          title="Redaktor"
          color="editor"
          value={modifiedRoles.editor.value}
          onClick={() => !disabledEditor && editEmployee(editor.value ? admin : editor, uuid)}
          disabled={disabled || disabledEditor}
        />
        <Badge
          title="Admin"
          color="admin"
          value={modifiedRoles.admin.value}
          onClick={() => !disabledAdmin && editEmployee(admin.value ? editor : admin, uuid)}
          disabled={disabled || disabledAdmin || modifiedRoles.admin.disabled}
        />
        <Badge
          title="Superadmin"
          color="superadmin"
          disabled={disabled || modifiedRoles.super_admin.disabled}
          value={modifiedRoles.super_admin.value}
          onClick={() => editEmployee(super_admin.value ? { ...editorWithAdmin } : super_admin, uuid)}
        />
        <div className="permissionsCell permissionsCell__edit" onClick={onEdit}>
          <SVG type={SVG_TYPE.EDIT} />
        </div>
        <div className="permissionsCell permissionsCell__delete" onClick={onClick}>
          <SVG type={SVG_TYPE.DELETE} />
        </div>
      </div>
    </>
  );
};

const getColumns = (editEmployee, openModal, authorizedUser, isAdmin, onEditRowClick) => [
  {
    id: 'email',
    accessor: 'email',
    Header: 'Email',
    style: { whiteSpace: 'unset', fontWeight: '600' },
  },
  {
    id: 'imie',
    accessor: 'first_name',
    Header: 'Imię',
    style: { whiteSpace: 'unset', fontWeight: '600' },
  },
  {
    id: 'nazwisko',
    accessor: 'last_name',
    Header: 'Nazwisko',
    style: { whiteSpace: 'unset', fontWeight: '600' },
  },
  {
    id: 'date',
    accessor: 'created_at',
    Header: 'Data dodania',
    style: { whiteSpace: 'unset', fontWeight: '600' },
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Uprawnienia',
    style: { whiteSpace: 'unset', fontWeight: '600' },
    width: 460,
    /* eslint-disable */
    Cell: ({ original }: any) => {
      const isSuperAdminGranted = original.roles.map(({ role }) => role).includes(ROLES.SUPER_ADMIN);
      const isDisabledCell = original.uuid === authorizedUser.uuid || (isAdmin && isSuperAdminGranted);
      return (
        <PermissionCell
          original={original}
          editEmployee={editEmployee}
          openModal={openModal}
          disabled={isDisabledCell}
          onEditRowClick={onEditRowClick}
          modifiedRoles={{
            editor: { name: 'editor', value: false },
            admin: { name: 'admin', value: false, disabled: isAdmin },
            super_admin: { name: 'super_admin', value: false, disabled: isAdmin },
          }}
        />
      );
    },
    /* eslint-enable */
  },
];

type S = {
  isOpened: boolean;
  activeUser: any;
  type: string;
};

class DashboardClientsDesktop extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isOpened: false,
      activeUser: {
        uuid: '',
        email: '',
        first_name: '',
        last_name: '',
        created_at: '',
        area_bhp: false,
        area_energy: false,
        area_environment: false,
      },
      type: '',
    };
  }
  componentDidMount() {
    this.props.getUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editingEmployee.status === REQUEST_STATUS.INIT && this.props.editingEmployee.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isOpened: false });
    }
  }

  addUser = (userData) => {
    const { superadmin, admin, editor } = userData;
    userData.role = [];
    if (superadmin) {
      userData.role.push('super_admin');
    } else if (admin && editor) {
      userData.role.push('editor', 'admin');
    } else if (admin) {
      userData.role.push('admin');
    } else if (editor) {
      userData.role.push('editor');
    }
    this.props.addUser(userData, null);
  };
  openModal = (activeUser) => {
    this.setState({ isOpened: true, type: 'DELETE', activeUser: { ...activeUser } });
  };
  onEditRowClick = (activeUser) => {
    console.log(activeUser);
    this.setState({ isOpened: true, type: 'EDIT', activeUser: { ...activeUser } });
  };
  onSubmit = () => {
    this.props.deleteUser(this.state.activeUser.uuid, true);
    this.setState({ isOpened: false });
  };
  onClose = () => {
    this.setState({ isOpened: false });
  };
  editUser = (data) => {
    this.props.editEmployee(data, this.state.activeUser.uuid);
    // this.setState({ isOpened: false });
  };
  isEditLoading = () => this.props.editingEmployee.status === REQUEST_STATUS.INIT;
  render() {
    const { usersList, authorizedUser } = this.props;
    const isAdmin = authorizedUser.roles.map(({ role }) => role).includes('admin');
    const columns = getColumns(this.props.editEmployee, this.openModal, authorizedUser, isAdmin, this.onEditRowClick);
    const { isOpened, activeUser, type } = this.state;
    const wisoEmployess = [];
    usersList.map((user: any) => {
      return user.roles.some((role) => role.role !== 'customer') && wisoEmployess.push(user);
    });

    return (
      <div className="dictionaryTable">
        <AssociateEmployeeRow addUser={this.addUser} isAdmin={isAdmin} />
        <ReactTable columns={columns} data={wisoEmployess} />
        <Modal opened={isOpened} onSubmit={this.onSubmit} onClose={this.onClose} type={type === 'EDIT' ? MODAL_TYPE.FORM : MODAL_TYPE.DELETE}>
          {type === 'EDIT' ? (
            <EmployeeFrom
              isLoading={this.isEditLoading()}
              onClose={this.onClose}
              initialValues={{ first_name: activeUser.first_name, last_name: activeUser.last_name }}
              onSubmit={this.editUser}
            />
          ) : (
            <>
              <div className="modal__title">Usuń pracownika WISO</div>
              <div className="modal__info">
                <ul>
                  <li>
                    Email: <span className="info-value">{activeUser.email}</span>
                  </li>
                  <li>
                    Imię i Nazwisko:
                    <span className="info-value">
                      {activeUser.first_name} {activeUser.last_name}
                    </span>
                  </li>
                  <li>
                    Data dodania <span className="info-value">{moment(activeUser.created_at).format('YYYY-MM-DD HH:mm')}</span>
                  </li>
                </ul>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}
type P = {
  usersList: Array<any>;
  getUsers: () => void;
  addUser: (userData: any, company_uuid) => void;
  editEmployee: (userData: any, userUuid: any) => void;
  // editUser: (userData: any, userUuid: any) => void;
  deleteUser: (userUuid: any, isEmployee: boolean) => void;
  authorizedUser: any;
  editingEmployee: any;
};
export default DashboardClientsDesktop;
