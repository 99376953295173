import * as React from 'react';
import { SUBMENU } from './../../consts';
import { getDeviceType } from 'Shared/utils';
import { ROUTES, AREA_TYPE, TABLE_TYPES } from 'Shared/enums';
import { MobileSubmenu, DesktopSubmenu } from './components';
import { history } from 'Store/Reducers/RootReducer';
import SearchForm from 'Shared/components/searchForm';

type P = {
  active: SUBMENU;
  onActiveChange: (submenuItem: SUBMENU) => void;
  onSearch: (term: string, pathname: string) => void;
  isEditorOrSuperAdmin: boolean;
  compendiumCounterRatings: any;
  isCustomerAreaManager: boolean;
  compendiumsActiveCompany: string;
  area: string;
  prevPath: string;
  searchedTerm: string;
  location?: any;
  newTab: any;
};

const setRoute = (active) => {
  switch (active) {
    case SUBMENU.DICT: {
      return TABLE_TYPES.SLOWNIK;
    }
    case SUBMENU.INSTITUTIONS: {
      return TABLE_TYPES.INSTYTUCJE;
    }
    case SUBMENU.COMPENDIUMS: {
      return TABLE_TYPES.KOMPENDIUM;
    }
    case SUBMENU.NORMS: {
      return TABLE_TYPES.NORMY;
    }
  }
};

const SubmenuHeader = ({
  active,
  onActiveChange,
  isEditorOrSuperAdmin,
  isCustomerAreaManager,
  onSearch,
  compendiumsActiveCompany,
  area,
  searchedTerm,
  location,
  compendiumCounterRatings,
  newTab,
}: P) => {
  const deviceType = getDeviceType();
  const { BHP, ENVIRONMENT, ADDITIONAL } = AREA_TYPE;
  const isAddClientHidden = active === SUBMENU.COMPENDIUMS && compendiumsActiveCompany;
  return (
    <div className={`dashboardDictionary__header dashboardDictionary__header--${area}`}>
      <div className="dashboardDictionary__submenu">
        {deviceType !== 'SMARTPHONE' ? (
          <DesktopSubmenu active={active} onActiveChange={onActiveChange} />
        ) : (
          <MobileSubmenu active={active} onActiveChange={onActiveChange} />
        )}
      </div>
      <div className="dashboardDictionary__searchBox">
        {(isEditorOrSuperAdmin && !isAddClientHidden) || isCustomerAreaManager ? (
          <button
            style={{ backgroundColor: area === ADDITIONAL ? newTab?.color : '' }}
            className={`dashboardUsers__addClient ${area === ADDITIONAL ? '' : `dashboardUsers__addClient--${area}`}`}
            onClick={() =>
              history.push(
                `${
                  area === BHP
                    ? ROUTES.BHP
                    : area === ENVIRONMENT
                    ? ROUTES.ENVIRONMENT_PROTECTION
                    : area === ADDITIONAL
                    ? ROUTES.ADDITIONAL
                    : ROUTES.ENERGIA
                }/dodaj/${setRoute(active)}`,
              )
            }>
            Dodaj wpis +
          </button>
        ) : null}
        {active === SUBMENU.COMPENDIUMS && (
          <SearchForm
            compendiumCounterRatings={compendiumCounterRatings}
            searchedTerm={searchedTerm}
            onSearch={(val) => onSearch(val, location.pathname)}
          />
        )}
      </div>
    </div>
  );
};

export default SubmenuHeader;
