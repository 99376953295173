import * as React from 'react';
import editClient from 'Assets/Img/Icons/ButtonIcons/users-edit.svg';
import activeStatus from 'Assets/Img/Icons/status-active.svg';
import expiredStatus from 'Assets/Img/Icons/status-expired.svg';
import calendar from 'Assets/Img/Icons/ButtonIcons/calendar.svg';
import expiresMonth from 'Assets/Img/Icons/orange-expires-in.svg';
import expiresDay from 'Assets/Img/Icons/red-expires-in.svg';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { SVG } from 'Shared/components';
import moment from 'moment';

export const ActionsCell = ({ history, cellData, openModal }: any) => (
  <div className="table--actions">
    <div className="btn btn--edit-client" onClick={() => history.push(`${ROUTES.USERS}/${cellData.uuid}`)}>
      <img src={editClient} alt="" />
      Edytuj
    </div>
    <div className="permissionsCell permissionsCell__delete" onClick={() => openModal(cellData, 'DELETE')}>
      <SVG type={SVG_TYPE.DELETE} />
    </div>
  </div>
);
export const checkIfExpired = (date) => {
  const curr_date = new Date();
  if (moment(curr_date).isAfter(date)) {
    return <span className="expired-date">{moment(date).format('YYYY-MM-DD HH:mm')}</span>;
  } else {
    return moment(date).format('YYYY-MM-DD HH:mm');
  }
};
export const checkClientStatus = (original, openModal) => {
  const curr_date = moment(new Date());
  const date_to_check = moment(original.expiration_date);
  const isExpired = moment(curr_date).isAfter(original.expiration_date);

  const remained_months = date_to_check.diff(curr_date, 'months');
  const remained_days = date_to_check.diff(curr_date, 'days');
  const remained_hours = date_to_check.diff(curr_date, 'hours');

  const onClick = () => {
    openModal(original, 'EXTEND_DATE');
  };
  if (!isExpired) {
    if (remained_months > 0 && remained_months <= 3) {
      return (
        <div className="btn--expires-month">
          <img src={expiresMonth} alt="" />
          {remained_months} mc
        </div>
      );
    } else if (remained_days >= 0 && remained_days < 31 && remained_months === 0) {
      return (
        <div className="btn--expires-month">
          <img src={expiresDay} alt="" />
          {remained_days === 0 ? `${remained_hours} h` : `${remained_days} ${remained_days === 1 ? 'dzień' : 'dni'}`}
        </div>
      );
    } else {
      return (
        <div className="btn--status-active">
          <img src={activeStatus} alt="" />
        </div>
      );
    }
  } else if (isExpired) {
    return (
      <div className="status-container">
        <div className="btn--status-expired">
          <img src={expiredStatus} alt="" />
        </div>
        <div className="btn btn--status-extend" onClick={onClick}>
          <img src={calendar} alt="" />
          Przedłuż
        </div>
      </div>
    );
  }
};
