import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_DICTIONARIES_INIT,
});

const success = (dictionaries: Array<any>) => ({
  type: BHP.GET_DICTIONARIES_SUCCESS,
  payload: {
    dictionaries,
  },
});

const failure = () => ({
  type: BHP.GET_DICTIONARIES_FAILURE,
});

const getDictionaries = (isMyCompanyEntries = false, areNewlyAdded = false) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getDictionaries(getArea(), isMyCompanyEntries, areNewlyAdded);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getDictionaries;
