import * as React from 'react';

type P = {
  classNames: string;
  label: string;
  isLoading?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
  disabled?: boolean;
};

const Button = ({ classNames, label, isLoading, onClick, type = 'button', disabled }: P) => (
  <button className={`btn ${classNames} ${isLoading ? 'is-loading' : ''}`} type={type} onClick={onClick && onClick} disabled={isLoading || disabled}>
    {isLoading && (
      <div className="btn--loader">
        <div className="btn--loader__inner" />
      </div>
    )}
    {label}
  </button>
);

export default Button;
