import * as React from 'react';
import SubmenuHeader from './components/submenuHeader';
import Dictionary from './components/dictionary';
import Institutions from './components/institutions';
import Compendiums from './components/compendiums';
import Norms from './components/norms';
import { SUBMENU } from './consts';
import { getDeviceType, getArea, checkAreaAccess } from 'Shared/utils';
import { MainType } from './Container';
import ImageWrapper from './components/imageWrapper';
import LearnMoreBHP from './components/learnMore';
import LearnMoreENVIRONMENT from 'Screens/environmentProtection/components/learnMore';
import LearnMoreENERGY from 'Screens/energia/components/learnMore';
import LearnMoreAddition from 'Screens/addition/components/learnMore';
import { ROLES, AREA_TYPE } from 'Shared/enums';

type P = {
  history?: any;
  location: any;
};
type S = {
  activeMenu: SUBMENU;
};

class DashboardDictionary extends React.Component<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: sessionStorage.getItem('activeMenu') ? sessionStorage.getItem('activeMenu') : this.props.location?.state?.activeTab ?? SUBMENU.DICT,
    };
  }

  async componentDidMount() {
    const {
      searchedTerm,
      searchedTermPathname,
      actions: { searchCompendiums, resetColumnFilterTerms },
      location,
      prevPath,
    } = this.props;

    if (prevPath !== location.pathname) {
      resetColumnFilterTerms();
    }

    if (searchedTerm && this.state.activeMenu === SUBMENU.COMPENDIUMS) {
      await searchCompendiums(searchedTermPathname === location.pathname ? searchedTerm : '', location.pathname);
      // await searchCompendiums(searchedTerm, location.pathname);
    }
  }

  setActiveMenu = (activeMenu) => {
    sessionStorage.setItem('activeMenu', activeMenu);
    this.setState({ activeMenu });
  };

  shouldComponentUpdate({ location }, { activeMenu }) {
    const { pathname } = this.props.location;
    if (activeMenu !== this.state.activeMenu) {
      this.props.actions.resetColumnFilterTerms();
    }

    if (location.pathname !== pathname) {
      this.props.actions.searchCompendiums('', pathname);
    }
    return true;
  }

  render() {
    const {
      authorizedUser: { roles, level },
      authorizedUser,
      editingCompendium,
      editingDictionary,
      editingInstitution,
      editingNorm,
      actions: {
        hideRow,
        editInstitution,
        deleteInstitution,
        editNorm,
        deleteNorm,
        editDictionary,
        deleteDictionary,
        editCompendium,
        deleteCompendium,
        copyCompendium,
        getInstitutions,
        getDictionaries,
        getCompendiums,
        searchCompendiums,
        getNorms,
        openCompendiumPreview,
        closeCompendiumPreview,
        getHiddenCompendiums,
        getHiddenDictionaries,
        destroyHiddenRow,
        getHiddenInstitutions,
        getHiddenNorms,
        getCompaniesOptions,
        getUsersInCompanyOptions,
        showExtendedText,
        hideExtendedText,
        setColumnFilterTerms,
        removeFiles,
        getCompendiumRatingsCounter,
      },
      isCompendiumPreview,
      normsStatus,
      norms,
      institutions,
      institutionsStatus,
      dictionaries,
      dictionariesStatus,
      compendiums,
      compendiumsStatus,
      history,
      compendiumsTermLetters,
      companiesOptions,
      compendiumsActiveCompany,
      location,
      usersInCompanyOptions,
      isExtendedTextVisible,
      isCustomerAreaManager,
      prevPath,
      searchedTerm,
      compendiumCounterRatings,
      newTab,
    } = this.props;

    const { activeMenu } = this.state;
    const { CUSTOMER, SUPER_ADMIN, EDITOR } = ROLES;
    const deviceType = getDeviceType();
    const area = getArea();
    const isCustomerLogged = roles.some(({ role }) => role === CUSTOMER);
    const isEditorOrSuperAdmin = roles.some(({ role }) => role === SUPER_ADMIN || role === EDITOR);
    // const isCustomerAreaManager = roles.some(({ role }) => role === CUSTOMER) && level === USER_LEVELS.MANAGING && companies?.has_add_permission;

    const isAreaAccess = checkAreaAccess(authorizedUser);

    if (isAreaAccess) {
      return (
        <div className={`dashboardDictionary dashboardDictionary--${area}`}>
          {!isCompendiumPreview && (
            <SubmenuHeader
              newTab={newTab}
              active={activeMenu}
              compendiumsActiveCompany={compendiumsActiveCompany}
              isEditorOrSuperAdmin={isEditorOrSuperAdmin}
              isCustomerAreaManager={isCustomerAreaManager}
              onActiveChange={this.setActiveMenu}
              onSearch={searchCompendiums}
              area={area}
              prevPath={prevPath}
              searchedTerm={searchedTerm}
              location={location}
              compendiumCounterRatings={compendiumCounterRatings}
              // getCompendiumRatingsCounter={getCompendiumRatingsCounter}
            />
          )}
          {activeMenu === SUBMENU.DICT && (
            <Dictionary
              getHiddenDictionaries={getHiddenDictionaries}
              isCustomerLogged={isCustomerLogged}
              isEditorOrSuperAdmin={isEditorOrSuperAdmin}
              editingDictionary={editingDictionary}
              editDictionary={editDictionary}
              deleteDictionary={deleteDictionary}
              dictionariesStatus={dictionariesStatus}
              getDictionaries={getDictionaries}
              dictionaries={dictionaries}
              deviceType={deviceType}
              hideRow={hideRow}
              destroyHiddenRow={destroyHiddenRow}
              location={location}
              showExtendedText={showExtendedText}
              hideExtendedText={hideExtendedText}
              isExtendedTextVisible={isExtendedTextVisible}
              isCustomerAreaManager={isCustomerAreaManager}
              userLevel={level}
              setColumnFilterTerms={setColumnFilterTerms}
            />
          )}
          {activeMenu === SUBMENU.INSTITUTIONS && (
            <Institutions
              deviceType={deviceType}
              getHiddenInstitutions={getHiddenInstitutions}
              isCustomerLogged={isCustomerLogged}
              isEditorOrSuperAdmin={isEditorOrSuperAdmin}
              editInstitution={editInstitution}
              deleteInstitution={deleteInstitution}
              editingInstitution={editingInstitution}
              institutionsStatus={institutionsStatus}
              getInstitutions={getInstitutions}
              institutions={institutions}
              hideRow={hideRow}
              destroyHiddenRow={destroyHiddenRow}
              location={location}
              showExtendedText={showExtendedText}
              hideExtendedText={hideExtendedText}
              isExtendedTextVisible={isExtendedTextVisible}
              isCustomerAreaManager={isCustomerAreaManager}
              userLevel={level}
              setColumnFilterTerms={setColumnFilterTerms}
            />
          )}
          {activeMenu === SUBMENU.COMPENDIUMS && (
            <Compendiums
              removeFiles={removeFiles}
              compendiumsActiveCompany={compendiumsActiveCompany}
              getCompaniesOptions={getCompaniesOptions}
              roles={roles}
              companiesOptions={companiesOptions}
              hideRow={hideRow}
              userLevel={level}
              isCustomerLogged={isCustomerLogged}
              isEditorOrSuperAdmin={isEditorOrSuperAdmin}
              editingCompendium={editingCompendium}
              editCompendium={editCompendium}
              isCompendiumPreview={isCompendiumPreview}
              openCompendiumPreview={openCompendiumPreview}
              closeCompendiumPreview={closeCompendiumPreview}
              getCompendiums={getCompendiums}
              getCompendiumRatingsCounter={getCompendiumRatingsCounter}
              compendiums={compendiums}
              compendiumsStatus={compendiumsStatus}
              history={history}
              deviceType={deviceType}
              compendiumsTermLetters={compendiumsTermLetters}
              getHiddenCompendiums={getHiddenCompendiums}
              destroyHiddenRow={destroyHiddenRow}
              deleteCompendium={deleteCompendium}
              copyCompendium={copyCompendium}
              location={location}
              getUsersInCompanyOptions={getUsersInCompanyOptions}
              usersInCompanyOptions={usersInCompanyOptions}
              showExtendedText={showExtendedText}
              hideExtendedText={hideExtendedText}
              isExtendedTextVisible={isExtendedTextVisible}
              isCustomerAreaManager={isCustomerAreaManager}
              searchedTerm={searchedTerm}
              setColumnFilterTerms={setColumnFilterTerms}
            />
          )}
          {activeMenu === SUBMENU.NORMS && (
            <Norms
              deviceType={deviceType}
              getHiddenNorms={getHiddenNorms}
              isCustomerLogged={isCustomerLogged}
              isEditorOrSuperAdmin={isEditorOrSuperAdmin}
              hideRow={hideRow}
              destroyHiddenRow={destroyHiddenRow}
              normsStatus={normsStatus}
              getNorms={getNorms}
              norms={norms}
              editingNorm={editingNorm}
              editNorm={editNorm}
              deleteNorm={deleteNorm}
              location={location}
              showExtendedText={showExtendedText}
              hideExtendedText={hideExtendedText}
              isExtendedTextVisible={isExtendedTextVisible}
              isCustomerAreaManager={isCustomerAreaManager}
              userLevel={level}
              setColumnFilterTerms={setColumnFilterTerms}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className={`area ${!isAreaAccess ? 'area--forbidden' : ''}`}>
          <>
            <div className="area__content">
              {area === AREA_TYPE.BHP ? (
                <LearnMoreBHP onCtaClick={() => console.log('click')} />
              ) : area === AREA_TYPE.ENVIRONMENT ? (
                <LearnMoreENVIRONMENT onCtaClick={() => console.log('click')} />
              ) : area === AREA_TYPE.ADDITIONAL ? (
                <LearnMoreAddition newTab={newTab} onCtaClick={() => console.log('click')} />
              ) : (
                <LearnMoreENERGY onCtaClick={() => console.log('click')} />
              )}
            </div>
            <div className="area__image">
              <ImageWrapper />
            </div>
          </>
        </div>
      );
    }
  }
}

export default DashboardDictionary;
