import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_COMPENDIUMS_INIT,
});

const success = (compendiums: Array<any>, searchData, pathname) => ({
  type: BHP.GET_COMPENDIUMS_SUCCESS,
  payload: {
    compendiums,
    searchData,
    pathname
  },
});

const failure = () => ({
  type: BHP.GET_COMPENDIUMS_FAILURE,
});

const searchCompendiums = (searchData: string, pathname) => async (dispatch, getState) => {
  dispatch(init());
  try {
    const activeCompany = JSON.parse(sessionStorage.getItem('compendiumsActiveCompany'));
    const { data } = await Connector.searchCompendiums(getArea(), searchData, activeCompany);
    dispatch(success(data, searchData, pathname));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default searchCompendiums;
