import React from 'react';
import greenFace from 'Assets/Img/Icons/green-face-icon.svg';
import orangeFace from 'Assets/Img/Icons/orange-face-icon.svg';
import greyFace from 'Assets/Img/Icons/grey-face-icon.svg';
import redFace from 'Assets/Img/Icons/red-face-icon.svg';

type P = {
  compendiumCounterRatings: any;
};

const CompendiumCounters = ({ compendiumCounterRatings }: P) => {
  return (
    <div className="compendiumCounters__wrapper">
      <div className="compendiumCounters">
        <div className="compendiumCounters__item">
          <div className="compendiumCounters__icon">
            <img src={greenFace} alt="" />
          </div>
          <div className="compendiumCounters__number">{compendiumCounterRatings.compatibility}</div>
        </div>
        <div className="compendiumCounters__item">
          <div className="compendiumCounters__icon">
            <img src={orangeFace} alt="" />
          </div>
          <div className="compendiumCounters__number">{compendiumCounterRatings.partial_compliance}</div>
        </div>
        <div className="compendiumCounters__item">
          <div className="compendiumCounters__icon">
            <img src={redFace} alt="" />
          </div>
          <div className="compendiumCounters__number">{compendiumCounterRatings.lack_of_compliance}</div>
        </div>
        <div className="compendiumCounters__item">
          <div className="compendiumCounters__icon">
            <img src={greyFace} alt="" />
          </div>
          <div className="compendiumCounters__number">{compendiumCounterRatings.no_compliance_status}</div>
        </div>
      </div>
    </div>
  );
};

export default CompendiumCounters;
