import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField, Badge } from 'Shared/components/forms';
import * as Yup from 'yup';

type P = {
  addUser: (userData: any) => void;
  disabled?: boolean;
  isAdmin: boolean;
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('Pole wymagane'),
  last_name: Yup.string().required('Pole wymagane'),
  email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
  password: Yup.string().required('Pole wymagane').min(6, 'Hasło musi zawierać minimum 6 znaków'),
  password_confirmation: Yup.string()
    .required('Pole wymagane')
    .when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password doesn't match"),
    }),
});

// const checkRoles = (values, setFieldValue) => {
//   console.log(values);
//   if (values.superadmin) {
//     setFieldValue('editor', true);
//     setFieldValue('admin', true);
//   }
// };

const AssociateEmployeeRow = ({ addUser, disabled, isAdmin }: P) => {
  return (
    <div className="associateUserRow">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          email: undefined,
          first_name: undefined,
          last_name: undefined,
          password: undefined,
          password_confirmation: undefined,
          editor: false,
          admin: false,
          superadmin: false,
          rolesCounter: { counter: 0, name: '' },
        }}
        onSubmit={addUser}
        enableReinitialize>
        {({ handleSubmit, errors }) => {
          return (
            <form onSubmit={handleSubmit} className="manageUser__form">
              <div className="associateUserRow__inner">
                <div className="associateUserRow--l">
                  <Field name="email" type="text" placeholder={'E-mail'} component={RegularField} errors={errors} wrapperClassName={'email'} />
                  <Field
                    name="first_name"
                    type="text"
                    placeholder={'Imię'}
                    component={RegularField}
                    errors={errors}
                    wrapperClassName={'first_name'}
                  />
                  <Field
                    name="last_name"
                    type="text"
                    placeholder={'Nazwisko'}
                    component={RegularField}
                    errors={errors}
                    wrapperClassName={'last_name'}
                  />
                  <Field
                    name="password"
                    type="password"
                    placeholder={'Hasło'}
                    component={RegularField}
                    errors={errors}
                    wrapperClassName={'password'}
                  />
                  <Field
                    name="password_confirmation"
                    type="password"
                    placeholder={'Powtórz hasło'}
                    component={RegularField}
                    errors={errors}
                    wrapperClassName={'password_confirmation'}
                  />
                </div>
                <div className="associateUserRow--r">
                  <div className="associateUserRow__permissions">
                    <Field label="BHP" name="area_bhp" component={Badge} errors={errors} color="blue" />
                    <Field label="OŚ" name="area_environment" component={Badge} errors={errors} color="green" />
                    <Field label="EN" name="area_energy" component={Badge} errors={errors} color="orange" />
                    <Field label="DOD" name="area_additional" component={Badge} errors={errors} color="red" />
                    {/* {checkRoles(values) &&} */}
                    <Field label="Redaktor" name="editor" component={Badge} employeeBadge={true} errors={errors} color="editor" />
                    <Field label="Admin" name="admin" component={Badge} employeeBadge={true} errors={errors} color="admin" disabled={isAdmin} />
                    <Field
                      label="Superadmin"
                      name="superadmin"
                      component={Badge}
                      employeeBadge={true}
                      errors={errors}
                      color="superadmin"
                      disabled={isAdmin}
                      onChange={() => console.log('MATIs')}
                    />
                    {/* <Field name="level" options={options} component={TableSelect} errors={errors} wrapperClassName={'level'} /> */}
                  </div>
                  <button type="submit" className={`btn btn--small btn--navy-blue ${disabled ? 'disabled' : ''}`} disabled={disabled}>
                    Dodaj
                  </button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AssociateEmployeeRow;
