import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { Actions } from 'Modules/ViewManagementModule';

const init = (id) => ({
  type: BHP.DELETE_NORM_INIT,
  payload: {
    id,
  },
});

const success = (id) => ({
  type: BHP.DELETE_NORM_SUCCESS,
  payload: {
    id,
  },
});

const failure = () => ({
  type: BHP.DELETE_NORM_FAILURE,
});

const deleteNorm = (normId) => async (dispatch) => {
  dispatch(init(normId));
  try {
    dispatch(Actions.showLoader());
    await Connector.deleteNorm(normId);
    dispatch(success(normId));
    dispatch(Actions.hideLoader());
    toast.success('Pomyślnie usunięto wiersz');
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default deleteNorm;
