import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: User.GET_COMPANIES_INIT,
});

const success = (companies: Array<any>) => ({
  type: User.GET_COMPANIES_SUCCESS,
  payload: {
    companies,
  },
});

const failure = () => ({
  type: User.GET_COMPANIES_FAILURE,
});

const getCompanies = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const { data } = await Connector.getCompanies();
    dispatch(success(data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    console.log(err);
  }
};

export default getCompanies;
