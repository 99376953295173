import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = () => ({
  type: BHP.EDIT_DICTIONARY_INIT,
});

const success = (dictionary) => ({
  type: BHP.EDIT_DICTIONARY_SUCCESS,
  payload: {
    dictionary,
  },
});

const failure = () => ({
  type: BHP.EDIT_DICTIONARY_FAILURE,
});

const editDictionary = (dictionary: any) => async (dispatch) => {
  dispatch(init());
  try {
    const { id } = dictionary;
    await Connector.editDictionary({ ...dictionary }, id);
    dispatch(success(dictionary));
    toast.success('Pomyślnie edytowano słownik');
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się zapisać zmian');

    return err;
  }
};

export default editDictionary;
