import * as React from 'react';
import CompendiumsDesktop from './components/compendiumsDesktop';
import CompendiumMobile from './components/compendiumMobile';
import CompendiumForm from './components/compendiumForm';
import { REQUEST_STATUS, MODAL_TYPE, USER_LEVELS } from 'Shared/enums';
import { Preloader, Modal, HiddenRowsToggle, ExtendTextToggle, Toggle, SVG } from 'Shared/components';
import Select from 'react-select';
import { ROLES } from 'Shared/enums';
import greenFace from 'Assets/Img/Icons/green-face-icon.svg';
import orangeFace from 'Assets/Img/Icons/orange-face-icon.svg';
import redFace from 'Assets/Img/Icons/red-face-icon.svg';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  getCompendiums: (
    getDetailed: boolean,
    company_uuid?: string,
    isMyRequirements?: boolean,
    isMyCompanyEntries?: boolean,
    areNewlyAdded?: boolean,
  ) => void;
  getCompendiumRatingsCounter: (
    getDetailed: boolean,
    company_uuid?: string,
    isMyRequirements?: boolean,
    isMyCompanyEntries?: boolean,
    areNewlyAdded?: boolean,
  ) => void;
  removeFiles?: (uuid: any) => void;
  compendiums: Array<any>;
  history: any;
  deviceType: string;
  compendiumsTermLetters: Array<string>;
  compendiumsStatus: REQUEST_STATUS;
  openCompendiumPreview: () => void;
  closeCompendiumPreview: () => void;
  getCompaniesOptions: () => void;
  getHiddenCompendiums: () => void;
  hideRow: (type, id) => void;
  isCompendiumPreview: boolean;
  editCompendium: (compendiumData: any) => any;
  deleteCompendium: (compendiumData: any) => any;
  copyCompendium: (uid: any) => any;
  destroyHiddenRow: (type: string, id: number) => void;
  editingCompendium: {
    status: REQUEST_STATUS;
    uid: number;
  };
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  userLevel: string;
  companiesOptions: Array<any>;
  roles: Array<any>;
  compendiumsActiveCompany: string;
  location: {
    pathname: string;
  };
  getUsersInCompanyOptions: (company_uuid) => void;
  usersInCompanyOptions: any;
  showExtendedText: () => void;
  hideExtendedText: () => void;
  isExtendedTextVisible: boolean;
  isCustomerAreaManager: boolean;
  searchedTerm: string;
  setColumnFilterTerms: (data: any) => void;
};
type S = {
  isEditModalOpened: boolean;
  compendiumData: any;
  areHiddenRowsShown: boolean;
  type: string;
  selectValue: any;
  isMyRequirements: boolean;
  isMyCompanyEntries: boolean;
  areNewlyAdded: boolean;
  isModalDeleteOpen: boolean;
};
class Compendiums extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpened: false,
      compendiumData: undefined,
      areHiddenRowsShown: false,
      type: '',
      selectValue: JSON.parse(sessionStorage.getItem('compendiumsActiveCompany')) || [],
      isMyRequirements: false,
      isMyCompanyEntries: false,
      areNewlyAdded: false,
      isModalDeleteOpen: false,
    };
  }

  componentDidMount() {
    const { getCompendiums, isCustomerLogged, getCompaniesOptions, roles, getCompendiumRatingsCounter } = this.props;
    // If user is not logged by customer, then get compendiums list with detailed view. Only ADMIN and SUPER_ADMIN see detailed columns in table
    if (!this.props.searchedTerm) {
      getCompendiums(!isCustomerLogged, this.state.selectValue ? this.state.selectValue.value : '');
      if (roles.some(({ role }) => role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN)) {
        getCompaniesOptions();
      }
      getCompendiumRatingsCounter(!isCustomerLogged, this.state.selectValue ? this.state.selectValue.value : '');
    }
    // this.props.getUsersInCompanyOptions(this.state.selectValue ? this.state.selectValue.value : '');
  }
  componentDidUpdate({ editingCompendium: { status }, location }) {
    if (this.props.location?.pathname !== location?.pathname) {
      this.props.getCompendiums(
        !this.props.isCustomerLogged,
        this.state.selectValue ? this.state.selectValue.value : '',
        this.state.isMyRequirements,
        this.state.isMyCompanyEntries,
        this.state.areNewlyAdded,
      );
    }
    if (status === REQUEST_STATUS.INIT && this.props.editingCompendium.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isEditModalOpened: false });
    }
  }
  onEditRowClick = (compendiumData) => {
    const { pdfs } = compendiumData;
    const legal_basis_pdf = pdfs && pdfs.find((item) => item.type === 'legal_basis');
    const executive_act_pdf = pdfs && pdfs.find((item) => item.type === 'executive_act');
    this.setState({
      isEditModalOpened: true,
      type: 'EDIT',
      compendiumData: {
        ...compendiumData,
        legal_basis_file_name: legal_basis_pdf ? legal_basis_pdf.file_name : '',
        executive_act_file_name: executive_act_pdf ? executive_act_pdf.file_name : '',
      },
    });
  };

  onDeleteRowClick = (compendiumData) => {
    this.setState({ isEditModalOpened: true, type: 'DELETE', compendiumData });
  };

  onCopyClick = async (compendiumData) => {
    const { getCompendiums, isCustomerLogged, getCompaniesOptions, roles, getCompendiumRatingsCounter } = this.props;
    await this.props.copyCompendium(compendiumData.uid);
    await getCompendiumRatingsCounter(!isCustomerLogged, this.state.selectValue ? this.state.selectValue.value : '');

    if (!this.props.searchedTerm) {
      getCompendiums(!isCustomerLogged, this.state.selectValue ? this.state.selectValue.value : '');
      if (roles.some(({ role }) => role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN)) {
        getCompaniesOptions();
      }
    }
  };

  onModalClose = () => this.setState({ isEditModalOpened: false, isModalDeleteOpen: false });
  isOpened = () => this.state.isEditModalOpened;
  isEditLoading = () => this.props.editingCompendium.status === REQUEST_STATUS.INIT;
  onHiddenRowsToggle = () => {
    this.setState(
      (prevState) => ({
        areHiddenRowsShown: !prevState.areHiddenRowsShown,
        isMyRequirements: false,
        isMyCompanyEntries: false,
        areNewlyAdded: false,
      }),
      () => {
        this.state.areHiddenRowsShown ? this.props.getHiddenCompendiums() : this.props.getCompendiums(!this.props.isCustomerLogged);
      },
    );
  };
  isLoading = () => this.props.compendiumsStatus === REQUEST_STATUS.INIT || this.props.compendiumsStatus === REQUEST_STATUS.IDLE;

  manageRow = (type, id) => (this.state.areHiddenRowsShown ? this.props.destroyHiddenRow(type, id) : this.props.hideRow(type, id));

  onDelete = async () => {
    const { isCustomerLogged, getCompendiumRatingsCounter } = this.props;
    const {
      compendiumData: { uid },
    } = this.state;
    await this.props.deleteCompendium(uid);
    await getCompendiumRatingsCounter(!isCustomerLogged, this.state.selectValue ? this.state.selectValue.value : '');

    this.setState({ isEditModalOpened: false, isModalDeleteOpen: false });
  };

  handleManageText = () => {
    const { showExtendedText, hideExtendedText, isExtendedTextVisible } = this.props;
    isExtendedTextVisible ? hideExtendedText() : showExtendedText();
  };

  handleMyRequirements = () => {
    this.props.getCompendiums(this.props.isCustomerLogged, '', !this.state.isMyRequirements, this.state.isMyCompanyEntries, this.state.areNewlyAdded);
    this.setState((prevState) => ({
      isMyRequirements: !prevState.isMyRequirements,
      areHiddenRowsShown: false,
      // areNewlyAdded: false,
    }));
  };

  handleMyCompanyEntries = () => {
    this.props.getCompendiums(this.props.isCustomerLogged, '', this.state.isMyRequirements, !this.state.isMyCompanyEntries);
    this.setState((prevState) => ({
      isMyCompanyEntries: !prevState.isMyCompanyEntries,
      areHiddenRowsShown: false,
      areNewlyAdded: false,
    }));
  };

  handleNewlyAdded = () => {
    this.props.getCompendiums(this.props.isCustomerLogged, '', this.state.isMyRequirements, false, !this.state.areNewlyAdded);
    this.setState((prevState) => ({
      areNewlyAdded: !prevState.areNewlyAdded,
      areHiddenRowsShown: false,
      isMyCompanyEntries: false,
      // isMyRequirements: false,
    }));
  };

  render() {
    const {
      compendiums,
      history,
      deviceType,
      compendiumsTermLetters,
      isCompendiumPreview,
      closeCompendiumPreview,
      openCompendiumPreview,
      editCompendium,
      isCustomerLogged,
      isEditorOrSuperAdmin,
      userLevel,
      companiesOptions,
      getCompendiums,
      roles,
      compendiumsActiveCompany,
      // usersInCompanyOptions,
      isExtendedTextVisible,
      isCustomerAreaManager,
      setColumnFilterTerms,
      removeFiles,
    } = this.props;

    const { compendiumData, areHiddenRowsShown, type, selectValue, isMyRequirements, isMyCompanyEntries, areNewlyAdded } = this.state;
    const isAdminOrSuperAdmin = roles.some(({ role }) => role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN);

    const getUnique = (arr, comp) => {
      const unique = arr
        .map((e) => e[comp]?.toLowerCase())
        // .map((e) => e[comp])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => {
          return {
            label: arr[e][comp],
            value: arr[e][comp],
          };
        });
      return unique;
    };
    // const filterByTerm = getUnique(compendiums, 'term');
    const filterByDepartment = getUnique(compendiums, 'department');
    // const filterByLegalBasis = getUnique(compendiums, 'legal_basis');
    // const filterByExecutiveAct = getUnique(compendiums, 'executive_act');
    // const filterByOrganizationType = getUnique(compendiums, 'organization_type');
    // const filterByReleasedFromResponsibility = getUnique(compendiums, 'released_from_responsibility');
    // const filterByRegulatoryOrgan = getUnique(compendiums, 'regulatory_organ');
    const filterByRating = [
      {
        label: (
          <div className="rating-option">
            <img src={greenFace} alt="Zgodność" />
            Zgodność
          </div>
        ),
        value: 'rating_3',
        searchableLabel: 'Zgodność',
      },
      {
        label: (
          <div className="rating-option">
            <img src={orangeFace} alt="Częsciowa zgodność" />
            Częściowa zgodność
          </div>
        ),
        value: 'rating_2',
        searchableLabel: 'Częsciowa zgodność',
      },
      {
        label: (
          <div className="rating-option">
            <img src={redFace} alt="Brak zgodności" />
            Brak zgodności
          </div>
        ),
        value: 'rating_1',
        searchableLabel: 'Brak zgodności',
      },
      {
        label: (
          <div className="rating-option">
            {/* <img src={orangeFace} alt="bez zgodności" /> */}
            <SVG type={SVG_TYPE.GREY_FACE} />
            brak statusu zgodności
          </div>
        ),
        value: 'brak',
        searchableLabel: 'brak statusu zgodności',
      },
    ];

    const getCounters = () => {
      this.props.getCompendiumRatingsCounter(!isCustomerLogged, this.state.selectValue ? this.state.selectValue.value : '');
    };
    return (
      <div className={`compendiums compendiums--${deviceType}`}>
        <Modal type={MODAL_TYPE.DELETE} opened={this.state.isModalDeleteOpen} onSubmit={this.onDelete} onClose={this.onModalClose} big>
          <>
            <div className="modal__title">Potwierdź usunięcie wpisu</div>
          </>
        </Modal>
        <Modal
          type={type === 'EDIT' ? MODAL_TYPE.FORM : MODAL_TYPE.DELETE}
          onSubmit={() => this.setState({ isModalDeleteOpen: true })}
          opened={this.isOpened()}
          onClose={this.onModalClose}
          className={type === 'EDIT' ? 'edit' : ''}
          big>
          {type === 'EDIT' ? (
            <CompendiumForm
              isLoading={this.isEditLoading()}
              onClose={this.onModalClose}
              initialValues={compendiumData}
              onSubmit={editCompendium}
              isCustomerAreaManager={isCustomerAreaManager}
              removeFiles={removeFiles}
              setInitialValues={(pdfs) =>
                this.setState({
                  compendiumData: {
                    ...compendiumData,
                    pdfs,
                  },
                })
              }
            />
          ) : (
            <>
              <div className="modal__title">Usuń wiersz</div>
              <div className="modal__info">
                <h2>Dział</h2>
                <p>{compendiumData?.department}</p>
                <h2>Pojęcie</h2>
                <p>{compendiumData?.term}</p>
                <h2>Główna podstawa prawna</h2>
                <p>{compendiumData?.legal_basis}</p>
                <h2>Akt wykonwaczy</h2>
                <p>{compendiumData?.executive_act}</p>
                <h2>Wymaganie prawne</h2>
                <p>{compendiumData?.legal_requirements}</p>
              </div>
            </>
          )}
        </Modal>
        <div className="dashboardDictionary__buttons">
          {isAdminOrSuperAdmin && (
            <Select
              value={selectValue ? selectValue : ''}
              style={{ width: '100%' }}
              placeholder="Wybierz klienta"
              isClearable
              onChange={(value) => {
                this.setState({ selectValue: value });
                sessionStorage.setItem('compendiumsActiveCompany', JSON.stringify(value));
                getCompendiums(!isCustomerLogged, value ? value.value : '');
              }}
              options={companiesOptions}
              className={'companies-select'}
              classNamePrefix="react-select"
            />
          )}
          {isCustomerLogged && (
            <HiddenRowsToggle onClick={this.onHiddenRowsToggle} areHiddenRowsShown={areHiddenRowsShown} disabled={this.isLoading()} />
          )}
          <ExtendTextToggle onClick={this.handleManageText} isExtendedTextVisible={isExtendedTextVisible} />
          {isCustomerLogged && (
            <>
              <Toggle label={'Nasze wpisy'} value={isMyCompanyEntries} onClick={this.handleMyCompanyEntries} />
              <Toggle label={'Nowości'} value={areNewlyAdded} onClick={this.handleNewlyAdded} />
            </>
          )}
          {isCustomerLogged && (userLevel === USER_LEVELS.DETAILED || userLevel === USER_LEVELS.MANAGING) && (
            <Toggle label={'Moje wymagania'} value={isMyRequirements} onClick={this.handleMyRequirements} />
          )}
        </div>
        {deviceType === 'DESKTOP' ? (
          <CompendiumsDesktop
            getCounters={getCounters}
            compendiumsActiveCompany={compendiumsActiveCompany}
            // filterByTerm={filterByTerm}
            filterByDepartment={filterByDepartment}
            // filterByExecutiveAct={filterByExecutiveAct}
            // filterByLegalBasis={filterByLegalBasis}
            // filterByReleasedFromResponsibility={filterByReleasedFromResponsibility}
            // filterByOrganizationType={filterByOrganizationType}
            // filterByRegulatoryOrgan={filterByRegulatoryOrgan}
            filterByRating={filterByRating}
            manageRow={this.manageRow}
            userLevel={userLevel}
            isCustomerLogged={isCustomerLogged}
            isEditorOrSuperAdmin={isEditorOrSuperAdmin}
            compendiums={compendiums}
            history={history}
            onEditRowClick={this.onEditRowClick}
            onDeleteRowClick={this.onDeleteRowClick}
            onCopyClick={this.onCopyClick}
            areHiddenRowsShown={areHiddenRowsShown}
            isCustomerAreaManager={isCustomerAreaManager}
            setColumnFilterTerms={setColumnFilterTerms}
            // usersInCompanyOptions={usersInCompanyOptions}
          />
        ) : (
          <CompendiumMobile
            isCompendiumPreview={isCompendiumPreview}
            closeCompendiumPreview={closeCompendiumPreview}
            openCompendiumPreview={openCompendiumPreview}
            compendiumsTermLetters={compendiumsTermLetters}
            compendiums={compendiums}
            history={history}
          />
        )}
        {this.isLoading() && <Preloader inner />}
      </div>
    );
  }
}

export default Compendiums;
