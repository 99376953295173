import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
// import { Actions } from 'Modules/ViewManagementModule';

const init = (id) => ({
  type: BHP.DELETE_RATING_COMPATIBILITIES_INIT,
  payload: {
    id,
  },
});

const success = (uuid) => ({
  type: BHP.DELETE_RATING_COMPATIBILITIES_SUCCESS,
  payload: {
    uuid,
  },
});

const failure = () => ({
  type: BHP.DELETE_RATING_COMPATIBILITIES_FAILURE,
});

const deleteRatingCompatibilities = (uuid) => async (dispatch) => {
  dispatch(init(uuid));
  try {
    // dispatch(Actions.showLoader());
    await Connector.deleteRatingCompatibilities(uuid);
    window.location.reload();
    // dispatch(Actions.hideLoader());
    dispatch(success(uuid));
    const elementToScroll = document.getElementById(`top`);
    elementToScroll.scrollIntoView();
    toast.success('Pomyślnie usunięto zgodność');
  } catch (err) {
    console.log(err);
    dispatch(failure());
    // dispatch(Actions.hideLoader());
  }
};

export default deleteRatingCompatibilities;
