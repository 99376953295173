import * as React from 'react';
import { SUBMENU_ARR, SUBMENU_MAP, SUBMENU } from './../../../consts';

const DesktopSubmenu = ({ active, onActiveChange }: P) => {
  return (
    <>
      {SUBMENU_ARR.map((submenuEl) => (
        <div
          className={`dashboardDictionary__submenu-item ${active === submenuEl ? 'active' : ''}`}
          key={submenuEl}
          onClick={() => onActiveChange(submenuEl)}>
          {SUBMENU_MAP.get(submenuEl)}
        </div>
      ))}
    </>
  );
};

type P = {
  active: SUBMENU;
  onActiveChange: (submenuItem: SUBMENU) => void;
};
export default DesktopSubmenu;
