import users from 'Assets/Img/Icons/high-five.svg';
import bhp from 'Assets/Img/Icons/bhp.svg';
import environment from 'Assets/Img/Icons/environment.svg';
import energy from 'Assets/Img/Icons/energy.svg';
import knowledge from 'Assets/Img/Icons/knowledge.svg';
import changelog from 'Assets/Img/Icons/changelog.svg';
import settings from 'Assets/Img/Icons/settings-icon.svg';

enum ROUTES {
  CUSTOMER_LOGIN = '/',
  LOGIN = '/login',
  DASHBOARD = '/dashboard',
  BHP = '/dashboard/bhp',
  ENVIRONMENT_PROTECTION = '/dashboard/ochrona-srodowiska',
  ENERGIA = '/dashboard/energia',
  BAZA_WIEDZY = '/dashboard/baza-wiedzy',
  USERS = '/dashboard/uzytkownicy',
  COMPENDIUM_DETAILS = '/dashboard/:area/kompendium-:id',
  COMPENDIUM_DETAILS_WITH_PARAM = '/dashboard/:area/kompendium',
  BHP_COMPENDIUM_DETAILS_WITH_PARAM = '/dashboard/bhp/kompendium',
  CHANGELOG = '/dashboard/changelog',
  SETTINGS = '/dashboard/settings',
  NOTIFICATIONS = '/dashboard/powiadomienia',
  REGULATIONS = '/regulamin',
  ADDITIONAL = '/dashboard/dodatkowa',
}

export enum TABLE_TYPES {
  SLOWNIK = 'slownik',
  INSTYTUCJE = 'instytucje',
  KOMPENDIUM = 'kompendium',
  NORMY = 'normy',
}

export enum ROUTES_LABELS {
  BHP = 'BHP',
  ENVIRONMENT = 'Ochrona środowiska',
  ENERGY = 'Energia',
  KNOWLEDGE = 'Baza wiedzy',
  USERS = 'Użytkownicy',
  CHANGELOG = 'Historia zmian',
  ADDITIONAL = 'Dodatkowa',
  SETTINGS = 'Ustawienia',
}

export enum ROLES {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  EDITOR = 'editor',
  CUSTOMER = 'customer',
}

export const ROUTES_MAP = new Map<ROUTES, { label: string; icon: string; className: string; roles: Array<any> }>([
  [ROUTES.BHP, { label: ROUTES_LABELS.BHP, icon: bhp, className: 'blue', roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER] }],
  [
    ROUTES.ENVIRONMENT_PROTECTION,
    {
      label: ROUTES_LABELS.ENVIRONMENT,
      icon: environment,
      className: 'green',
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER],
    },
  ],
  [
    ROUTES.ENERGIA,
    { label: ROUTES_LABELS.ENERGY, icon: energy, className: 'orange', roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER] },
  ],
  [
    ROUTES.BAZA_WIEDZY,
    { label: ROUTES_LABELS.KNOWLEDGE, icon: knowledge, className: 'gray', roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER] },
  ],
  [ROUTES.USERS, { label: ROUTES_LABELS.USERS, icon: users, className: 'yellow', roles: [ROLES.SUPER_ADMIN] }],
  [
    ROUTES.CHANGELOG,
    { label: ROUTES_LABELS.CHANGELOG, icon: changelog, className: 'gray', roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN] },
    // { label: ROUTES_LABELS.CHANGELOG, icon: changelog, className: 'gray', roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER] },
  ],
  [ROUTES.SETTINGS, { label: ROUTES_LABELS.SETTINGS, icon: settings, className: 'gray', roles: [ROLES.SUPER_ADMIN, ROLES.EDITOR] }],
]);
export const PROTECTED_ROUTES_MAP = new Map<string, Array<ROLES>>([
  [ROUTES.BHP, [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.ENVIRONMENT_PROTECTION, [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.ENERGIA, [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.ADDITIONAL, [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.BAZA_WIEDZY, [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.USERS, [ROLES.SUPER_ADMIN]],
  [ROUTES.COMPENDIUM_DETAILS, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.COMPENDIUM_DETAILS_WITH_PARAM, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.CHANGELOG, [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [ROUTES.SETTINGS, [ROLES.SUPER_ADMIN, ROLES.EDITOR]],
  [ROUTES.NOTIFICATIONS, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.BHP}/dodaj/${TABLE_TYPES.SLOWNIK}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.BHP}/dodaj/${TABLE_TYPES.INSTYTUCJE}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.BHP}/dodaj/${TABLE_TYPES.KOMPENDIUM}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.BHP}/dodaj/${TABLE_TYPES.NORMY}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ADDITIONAL}/dodaj/${TABLE_TYPES.SLOWNIK}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ADDITIONAL}/dodaj/${TABLE_TYPES.INSTYTUCJE}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ADDITIONAL}/dodaj/${TABLE_TYPES.KOMPENDIUM}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ADDITIONAL}/dodaj/${TABLE_TYPES.NORMY}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENERGIA}/dodaj/${TABLE_TYPES.SLOWNIK}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENERGIA}/dodaj/${TABLE_TYPES.INSTYTUCJE}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENERGIA}/dodaj/${TABLE_TYPES.KOMPENDIUM}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENERGIA}/dodaj/${TABLE_TYPES.NORMY}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENVIRONMENT_PROTECTION}/dodaj/${TABLE_TYPES.SLOWNIK}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENVIRONMENT_PROTECTION}/dodaj/${TABLE_TYPES.INSTYTUCJE}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENVIRONMENT_PROTECTION}/dodaj/${TABLE_TYPES.KOMPENDIUM}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
  [`${ROUTES.ENVIRONMENT_PROTECTION}/dodaj/${TABLE_TYPES.NORMY}`, [ROLES.SUPER_ADMIN, ROLES.EDITOR, ROLES.CUSTOMER]],
]);

export default ROUTES;
