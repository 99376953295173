import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import AreaManagement from './Main';
import { Actions } from 'Modules/BhpModule';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { bindActionCreators } from 'redux';

type ConnectedP = {
  authorizedUser: any;
};

type DispatchedP = {
  actions: {
    addCompendium: (compendiumData: any, currentRoute: any) => void;
    addDictionary: (dictionaryData: any, currentRoute: any) => void;
    addInstitution: (institutionData: any, currentRoute: any) => void;
    addNorm: (normData: any, currentRoute: any) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      addCompendium: Actions.addCompendium,
      addDictionary: Actions.addDictionary,
      addInstitution: Actions.addInstitution,
      addNorm: Actions.addNorm,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  authorizedUser: AuthSelectors.authorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(AreaManagement);
