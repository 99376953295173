import React from 'react';

export default function Toggle({ onClick, value, label }: any) {
  return (
    <div className="extendTextToggle">
      <span onClick={onClick}>{label}</span>
      <span onClick={onClick} className={`extendTextToggle__switch ${value ? 'extendTextToggle__switch--active' : ''}`}>
        <div className="extendTextToggle__switch_inner" />
      </span>
    </div>
  );
}
