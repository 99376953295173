/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import * as React from 'react';
import { ReactTable, Cells, NewRow, HighlightText } from 'Shared/components';
import moment from 'moment';
import Select from 'react-select';
import { getArea, getFiltersInLocal, saveFiltersInLocal } from 'Shared/utils';

const prevFilter = {};

const filterMethod = (filter, row, setColumnFilterTerms) => {
  if (prevFilter[filter.id] !== filter.value) {
    prevFilter[filter.id] = filter.value;
    setColumnFilterTerms({ [filter.id]: filter.value.toLowerCase() });
  }
  return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
};

const getColumns = (
  onEditRowClick,
  isCustomerLogged,
  manageRow,
  areHiddenRowsShown,
  onDeleteRowClick,
  isEditorOrSuperAdmin,
  filterByType,
  // filterByShort,
  letterOptions,
  filters,
  isCustomerAreaManager,
  userLevel,
  setColumnFilterTerms,
  area,
) => [
  {
    id: 'edit',
    accessor: 'edit',
    width: 30,
    headerClassName: 'sticky  sticky--hidden',
    className: 'sticky cell--noPadding cell--white',
    show: isEditorOrSuperAdmin || isCustomerAreaManager,
    filterable: false,
    Cell: ({ value, original }) =>
      isEditorOrSuperAdmin || (isCustomerAreaManager && original.company_id) ? (
        <Cells.Edit value={value} original={original} onClick={onEditRowClick} onDeleteClick={onDeleteRowClick} />
      ) : null,
  },

  {
    id: 'name',
    accessor: 'name',
    Header: 'Interesariusz',
    filterMethod: ({ value, id }, row) => {
      filters[id] = value;
      saveFiltersInLocal({ ...filters, area });
      if (value) {
        return row[id].toLowerCase().indexOf(value.value.toLowerCase()) > -1;
      } else {
        return true;
      }
    },
    Filter: ({ filter, onChange, column: { id } }) => {
      !filter && onChange(filters.hasOwnProperty(id) ? filters[id] : null);
      return (
        <Select
          value={filter && filter.value ? filter.value : ''}
          style={{ width: '100%' }}
          placeholder="Wszystkie"
          isClearable
          onChange={(value) => onChange(value)}
          options={filterByType}
          classNamePrefix="react-select"
        />
      );
    },
    Cell: ({ value, original, column }) => (
      <>
        <NewRow created_at={original.created_at} /> {value}
      </>
    ),
    style: { fontWeight: '700' },
    width: 200,
  },
  {
    id: 'short',
    accessor: 'short',
    Header: 'Skrót',
    style: { fontWeight: '700' },
    width: 105,
    Cell: ({ value, column }) => <HighlightText text={value} columnId={column.id} />,
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
  },
  {
    id: 'role',
    accessor: 'role',
    Header: 'Rola',
    style: { fontWeight: '700' },
    // Cell: Cells.ExtendText,
    // Cell: ({ value, column }) => <HighlightText text={value} columnId={column.id} />,
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
  },
  {
    id: 'tasks',
    accessor: 'tasks',
    Header: 'Zadania',
    // Cell: ({ value, column }) => <HighlightText text={value} columnId={column.id} />,
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
  },
  {
    id: 'updated_at',
    accessor: 'updated_at',
    Header: 'Data aktualizacji',
    filterable: false,
    Cell: ({ original }) => moment(original.updated_at).format('YYYY-MM-DD HH:mm'),
    width: 180,
  },
  {
    id: 'visibility',
    accessor: 'visibility',
    Header: 'WIDOCZNOŚĆ',
    filterable: false,
    width: 150,
    show: isCustomerLogged,
    Cell: ({ original: { id } }) => <Cells.VisibilityCell areHiddenRowsShown={areHiddenRowsShown} onClick={() => manageRow('institutions', id)} />,
  },
];
type P = {
  institutions: Array<any>;
  onEditRowClick: (institutions: any) => void;
  onDeleteRowClick: (institutions: any) => void;
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  manageRow: (type, id) => void;
  areHiddenRowsShown: boolean;
  filterByType: any;
  // filterByShort: any;
  filterByLetter: any;
  isCustomerAreaManager: boolean;
  userLevel: string;
  setColumnFilterTerms: (data: any) => void;
  isExtendedTextVisible: boolean;
};
const InstitutionsDesktop = ({
  filterByType,
  // filterByShort,
  institutions,
  onEditRowClick,
  isCustomerLogged,
  isEditorOrSuperAdmin,
  areHiddenRowsShown,
  manageRow,
  onDeleteRowClick,
  filterByLetter,
  isCustomerAreaManager,
  userLevel,
  setColumnFilterTerms,
  isExtendedTextVisible,
}: P) => {
  const letterOptions = React.useMemo(
    () =>
      filterByLetter.map((letter) => {
        return {
          value: letter,
          label: letter,
        };
      }),
    [filterByLetter],
  );
  const area = getArea();
  const tempFilters = getFiltersInLocal();
  const filters = tempFilters.area === area ? tempFilters : {};
  const columns = getColumns(
    onEditRowClick,
    isCustomerLogged,
    manageRow,
    areHiddenRowsShown,
    onDeleteRowClick,
    isEditorOrSuperAdmin,
    filterByType,
    // filterByShort,
    letterOptions,
    filters,
    isCustomerAreaManager,
    userLevel,
    setColumnFilterTerms,
    area,
  );
  return (
    <div className="institutionsDesktop">
      <ReactTable
        filterable
        areRowsColored
        data={institutions}
        columns={columns}
        className="bhpTable bhpTable--institutions"
        isExtendedTextVisible={isExtendedTextVisible}
      />
    </div>
  );
};

// export default React.memo(InstitutionsDesktop);
export default InstitutionsDesktop;
