import * as React from 'react';
import NormsDesktop from './components/normsDesktop';
import NormsMobile from './components/normsMobile';
import { REQUEST_STATUS, MODAL_TYPE } from 'Shared/enums';
import { Preloader, Modal, HiddenRowsToggle, ExtendTextToggle, Toggle } from 'Shared/components';
import NormsForm from './components/normsForm';

type P = {
  getNorms: (isMyCompanyEntries?: boolean, areNewlyAdded?: boolean) => void;
  norms: Array<any>;
  normsStatus: REQUEST_STATUS;
  getHiddenNorms: () => void;
  destroyHiddenRow: (type: string, id: number) => void;
  hideRow: (type, id) => void;
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  deviceType: string;
  editingNorm: any;
  editNorm: (normData: any) => void;
  deleteNorm: (normData: any) => void;
  location: {
    pathname: string;
  };
  showExtendedText: () => void;
  hideExtendedText: () => void;
  isExtendedTextVisible: boolean;
  isCustomerAreaManager: boolean;
  userLevel: string;
  setColumnFilterTerms: (data: any) => void;
};

type S = {
  areHiddenRowsShown: boolean;
  isEditModalOpened: boolean;
  normsData: undefined;
  type: string;
  isMyCompanyEntries: boolean;
  areNewlyAdded: boolean;
};

class Norms extends React.Component<P, any> {
  constructor(props) {
    super(props);
    this.state = {
      areHiddenRowsShown: false,
      normsData: undefined,
      isEditModalOpened: false,
      type: '',
      isMyCompanyEntries: false,
      areNewlyAdded: false,
    };
  }
  componentDidMount() {
    this.props.getNorms();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.props.getNorms(this.state.isMyCompanyEntries, this.state.areNewlyAdded);
    }
    if (prevProps.editingNorm.status === REQUEST_STATUS.INIT && this.props.editingNorm.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isEditModalOpened: false });
    }
  }

  isLoading = () => this.props.normsStatus === REQUEST_STATUS.INIT || this.props.normsStatus === REQUEST_STATUS.IDLE;

  onEditRowClick = (normsData) => {
    this.setState({ isEditModalOpened: true, type: 'EDIT', normsData });
  };

  onDeleteRowClick = (normsData) => {
    this.setState({ isEditModalOpened: true, type: 'DELETE', normsData });
  };

  onModalClose = () => this.setState({ isEditModalOpened: false });
  isOpened = () => this.state.isEditModalOpened;
  isEditLoading = () => this.props.editingNorm.status === REQUEST_STATUS.INIT;

  onHiddenRowsToggle = () => {
    this.setState(
      (prevState) => ({ areHiddenRowsShown: !prevState.areHiddenRowsShown, isMyCompanyEntries: false, areNewlyAdded: false }),
      () => {
        this.state.areHiddenRowsShown ? this.props.getHiddenNorms() : this.props.getNorms();
      },
    );
  };

  manageRow = (type, id) => (this.state.areHiddenRowsShown ? this.props.destroyHiddenRow(type, id) : this.props.hideRow(type, id));

  onDelete = () => {
    const {
      normsData: { id },
    } = this.state;
    this.props.deleteNorm(id);
    this.setState({ isEditModalOpened: false });
  };

  handleManageText = () => {
    this.props.isExtendedTextVisible ? this.props.hideExtendedText() : this.props.showExtendedText();
  };

  handleMyCompanyEntries = () => {
    this.props.getNorms(!this.state.isMyCompanyEntries);
    this.setState((prevState) => ({
      isMyCompanyEntries: !prevState.isMyCompanyEntries,
      areHiddenRowsShown: false,
      areNewlyAdded: false,
    }));
  };

  handleNewlyAdded = () => {
    this.props.getNorms(false, !this.state.areNewlyAdded);
    this.setState((prevState) => ({
      areNewlyAdded: !prevState.areNewlyAdded,
      areHiddenRowsShown: false,
      isMyCompanyEntries: false,
    }));
  };

  render() {
    const {
      norms,
      isCustomerLogged,
      isEditorOrSuperAdmin,
      deviceType,
      editNorm,
      isExtendedTextVisible,
      isCustomerAreaManager,
      userLevel,
      setColumnFilterTerms,
    } = this.props;
    const { areHiddenRowsShown, normsData, type, isMyCompanyEntries, areNewlyAdded } = this.state;
    return (
      <div className="norms">
        <Modal
          type={type === 'EDIT' ? MODAL_TYPE.FORM : MODAL_TYPE.DELETE}
          onSubmit={this.onDelete}
          opened={this.isOpened()}
          onClose={this.onModalClose}
          big>
          {type === 'EDIT' ? (
            <NormsForm
              isLoading={this.isEditLoading()}
              onClose={this.onModalClose}
              initialValues={normsData}
              onSubmit={editNorm}
              isCustomerAreaManager={isCustomerAreaManager}
            />
          ) : (
            <>
              <div className="modal__title">Usuń wiersz</div>
              <div className="modal__info">
                <h2>Akt normatywny</h2>
                <p>{normsData?.normative_act}</p>
                <h2>Akt normatywny II</h2>
                <p>{normsData?.requirement}</p>
                <h2>Wymagane dodatkowe działania</h2>
                <p>{normsData?.extra_activities}</p>
              </div>
            </>
          )}
        </Modal>
        <div className="dashboardDictionary__buttons">
          {isCustomerLogged && (
            <HiddenRowsToggle onClick={this.onHiddenRowsToggle} areHiddenRowsShown={areHiddenRowsShown} disabled={this.isLoading()} />
          )}
          <ExtendTextToggle onClick={this.handleManageText} isExtendedTextVisible={isExtendedTextVisible} />
          {isCustomerLogged && (
            <>
              <Toggle label={'Nasze wpisy'} value={isMyCompanyEntries} onClick={this.handleMyCompanyEntries} />
              <Toggle label={'Nowości'} value={areNewlyAdded} onClick={this.handleNewlyAdded} />
            </>
          )}
        </div>
        {deviceType === 'DESKTOP' ? (
          <NormsDesktop
            norms={norms}
            manageRow={this.manageRow}
            areHiddenRowsShown={areHiddenRowsShown}
            isCustomerLogged={isCustomerLogged}
            isEditorOrSuperAdmin={isEditorOrSuperAdmin}
            onEditRowClick={this.onEditRowClick}
            onDeleteRowClick={this.onDeleteRowClick}
            isCustomerAreaManager={isCustomerAreaManager}
            userLevel={userLevel}
            setColumnFilterTerms={setColumnFilterTerms}
          />
        ) : (
          <NormsMobile norms={norms} manageRow={this.manageRow} areHiddenRowsShown={areHiddenRowsShown} isCustomerLogged={isCustomerLogged} />
        )}
        {this.isLoading() && <Preloader inner />}
      </div>
    );
  }
}

export default Norms;
