import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions as UserActions } from 'Modules/UserModule';
import { Actions } from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.DELETE_COMPANY_INIT,
});

const success = () => ({
  type: User.DELETE_COMPANY_SUCCESS,
});

const failure = () => ({
  type: User.DELETE_COMPANY_FAILURE,
});

const deleteCompany = (company_uuid) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    await Connector.deleteCompany(company_uuid);
    dispatch(UserActions.getCompanies());
    toast.success('Pomyślnie usunięto klienta');
    dispatch(success());
    dispatch(Actions.hideLoader());
  } catch ({ response }) {
    if (response && response.status === 409) {
      toast.error('Nie można usunąć konta klienta gdy ma on pracowników');
    }
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default deleteCompany;
