// import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: BHP.SET_ACTIVE_RATING_INIT,
});

const success = (activeRating) => ({
  type: BHP.SET_ACTIVE_RATING_SUCCESS,
  payload: {
    activeRating,
  },
});

const failure = () => ({
  type: BHP.SET_ACTIVE_RATING_FAILURE,
});

const setActiveRating = (data) => async (dispatch) => {
  dispatch(init());
  try {
    // const { data } = await Connector.getHiddenRows('bhp', 'norms');
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default setActiveRating;
