/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { history } from 'Store/Reducers/RootReducer';

type P = {
  actions: {
    getNotifications: ({ page, isNotRead, init }) => void;
    readNotification: ({ uuid, isReadAll }) => void;
    getLastNotifications: () => void;
    getNotificationsNotRead: () => void;
  };
  notifications: any;
};

const Notifications = ({ actions: { getNotifications, readNotification, getLastNotifications, getNotificationsNotRead }, notifications }: P) => {
  const [isNotRead, setIsNotRead] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getNotifications({ page, isNotRead, init: true });
  }, []);

  const loadNextPage = useCallback(async () => {
    if (!loading) {
      setLoading(true);
      setTimeout(async () => {
        await getNotifications({ page: page + 1, isNotRead, init: false });
        setPage(page + 1);
        setLoading(false);
      }, 500);
    }
  }, [loading, getNotifications, isNotRead, page]);

  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('.dashboard__content.is-blurred');
      if (element) {
        const { scrollTop, clientHeight, scrollHeight } = element;
        if (scrollTop + clientHeight >= scrollHeight) {
          loadNextPage();
        }
      }
    };

    const element = document.querySelector('.dashboard__content.is-blurred');
    if (element) {
      element.addEventListener('scroll', handleScroll);
      if (page === 1) {
        const { clientHeight } = element;
        if (clientHeight >= 1000) {
          loadNextPage();
        }
      }
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadNextPage]);

  const getFirstOccurrences = (notifications: any) => {
    const seenDays = new Set<string>();
    const firstOccurrences = new Set<number>();
    notifications &&
      notifications.length > 0 &&
      notifications.forEach((notification, index) => {
        if (!seenDays.has(notification.creation_day)) {
          seenDays.add(notification.creation_day);
          firstOccurrences.add(index);
        }
      });
    return firstOccurrences;
  };

  const firstOccurrences = getFirstOccurrences(notifications.data);

  const getCreationDayLabel = (creationDay: 'today' | 'yesterday' | 'oldest'): string => {
    switch (creationDay) {
      case 'today':
        return 'DZISIAJ';
      case 'yesterday':
        return 'WCZORAJ';
      case 'oldest':
        return 'STARSZE';
      default:
        return '';
    }
  };

  const handleReadNotification = (uuid: string, isReading, area, compendiumId) => {
    !isReading && readNotification({ uuid, isReadAll: false });
    history.push(`/dashboard/${area}/kompendium-${compendiumId}`);
  };

  const handleSetAllReading = async () => {
    await readNotification({ uuid: false, isReadAll: true });
    await getLastNotifications();
    await getNotificationsNotRead();
  };
  const handleSetShowAllNotReading = () => {
    getNotifications({ page: 1, isNotRead: !isNotRead, init: true });
    setPage(1);
    setIsNotRead(!isNotRead);
  };

  return (
    <div className="notifications">
      <div className={`notification-popup--active`}>
        <div className="notification-popup__header">
          <span>Powiadomienia</span>
          <div className="notifications__settings">
            <div className="element" onClick={handleSetAllReading}>
              Oznacz wszystkie jako przeczytane
            </div>
            <div className="element" onClick={() => handleSetShowAllNotReading()}>
              Pokaż tylko nieprzeczytane
              <div className="extendTextToggle">
                <span className={`extendTextToggle__switch ${isNotRead ? 'extendTextToggle__switch--active' : ''}`}>
                  <div className="extendTextToggle__switch_inner" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="notification-popup__list" style={{ overflowY: 'auto' }}>
          {notifications &&
            notifications.data &&
            notifications.data.length > 0 &&
            notifications.data.map((notification, index) => {
              return (
                <React.Fragment key={notification.uuid}>
                  {firstOccurrences.has(index) && <div className="notification-popup__day">{getCreationDayLabel(notification.creation_day)}</div>}
                  <div
                    className="notification-popup__item"
                    onClick={() => handleReadNotification(notification.uuid, notification.is_read, notification.area, notification.compendium_id)}>
                    <div className="notification-popup__item-label notification-popup__item-label--ellipsis">
                      {notification.comment}
                      {!notification.is_read && <div className="notification-popup__item-noti" />}
                    </div>
                    <div className="notification-popup__item-time">{notification.time}</div>
                  </div>
                </React.Fragment>
              );
            })}
          {loading && (
            <>
              <div className="skeleton-container">
                <Skeleton height={30} style={{ marginBottom: '10px', width: '100%' }} />
                <Skeleton height={20} style={{ marginBottom: '10px', width: '30%' }} />
                <Skeleton height={20} style={{ marginBottom: '10px', width: '15%' }} />
              </div>
              <div className="skeleton-container">
                <Skeleton height={30} style={{ marginBottom: '10px', width: '100%' }} />
                <Skeleton height={20} style={{ marginBottom: '10px', width: '30%' }} />
                <Skeleton height={20} style={{ marginBottom: '10px', width: '15%' }} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
