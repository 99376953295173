import * as React from 'react';

type P = {
  pages: number;
  page: number;
  getFunction: (page?) => any;
};

const Pagination = (props: P) => {
  let currentPage: number = !props.page ? 1 : props.page > 0 && props.page <= props.pages ? Number(props.page) : props.page < 0 ? 1 : props.pages;
  const pageLinks = [];
  const nextPage = async (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= props.pages) {
      currentPage = pageNumber;
      await props.getFunction(pageNumber);
    }
  };

  if (props.pages > 6) {
    const showDots = currentPage >= 3 && currentPage < props.pages - 4;

    if (currentPage < 3) {
      for (let i = 1; i <= 3; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
      pageLinks.push('....');
      for (let i = props.pages - 2; i <= props.pages; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
    }
    if (showDots) {
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
      pageLinks.push('....');
      for (let i = props.pages - 2; i <= props.pages; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
    }
    if (currentPage >= props.pages - 4) {
      for (let i = currentPage - 2; i <= props.pages; i++) {
        const active = currentPage === i ? 'active' : '';
        pageLinks.push(
          <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
            {i}
          </li>,
        );
      }
    }
  } else {
    for (let i = 1; i <= props.pages; i++) {
      const active = currentPage === i ? 'active' : '';
      pageLinks.push(
        <li className={`pagination__list-element pagination__list-element--${active} `} key={i} onClick={() => nextPage(i)}>
          {i}
        </li>,
      );
    }
  }

  return (
    <div className="pagination">
      <ul className="pagination__list">
        <li
          className={`pagination__list-element ${currentPage === 1 && 'pagination__list-element--disabled'}`}
          onClick={() => nextPage(currentPage - 1)}>
          {'<'}
        </li>
        {pageLinks}
        <li
          className={`pagination__list-element ${currentPage === props.pages && 'pagination__list-element--disabled'}`}
          onClick={() => nextPage(currentPage + 1)}>
          {'>'}
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
