import * as React from 'react';
import { ReactTable, Modal, SVG } from 'Shared/components';
import moment from 'moment';
import { ActionsCell, checkIfExpired, checkClientStatus } from './components/cells';
import { SVG_TYPE, MODAL_TYPE } from 'Shared/enums';
import { Formik, Field } from 'formik';
import { DatePicker } from 'Shared/components/forms';

const LoginsCell = ({ original: { users, logins_amount, expiration_date } }: any) => {
  const curr_date = new Date();

  return (
    <div className={`logins-amount ${moment(curr_date).isAfter(expiration_date) ? 'logins-amount--expired-date' : ''}`}>
      <SVG type={SVG_TYPE.USERS} />
      {users.length} / {logins_amount}
    </div>
  );
};

const getColumns = (history, openModal) => [
  {
    id: 'company_name',
    accessor: 'company_name',
    Header: 'Firma',
    style: { whiteSpace: 'unset', fontWeight: '600' },
  },
  {
    id: 'created_at',
    accessor: 'created_at',
    Header: 'Data dodania',
    Cell: ({ original }) => moment(original.created_at).format('YYYY-MM-DD HH:mm'),
  },
  {
    id: 'expiration_date',
    accessor: 'expiration_date',
    Header: 'Data wygaśnięcia',
    Cell: ({ original }) => checkIfExpired(original.expiration_date),
  },
  {
    id: 'status',
    accessor: 'status',
    Header: 'Status',
    Cell: ({ original }) => checkClientStatus(original, openModal),
  },
  {
    id: 'logins_amount',
    accessor: 'logins_amount',
    Header: 'Ilość loginów',
    Cell: LoginsCell, // eslint-disable-line
  },
  {
    id: 'actions',
    accessor: 'actions',
    Header: 'Akcje',
    width: 150,
    Cell: ({ original }) => <ActionsCell cellData={original} history={history} openModal={openModal} />, // eslint-disable-line
  },
];

type S = {
  isOpened: boolean;
  activeUser: any;
  type: string;
};

class CompanyDesktop extends React.PureComponent<P, S> {
  constructor(props: P) {
    super(props);
    this.state = {
      isOpened: false,
      activeUser: {
        uuid: '',
        company_name: '',
        expiration_date: '',
        city: '',
        created_at: '',
        updated_at: '',
        country: '',
        flat_number: '',
        house_number: '',
        nip: '',
        street: '',
        zip_code: '',
        phone_number: '',
      },
      type: '',
    };
  }
  componentDidMount() {
    this.props.getCompanies();
  }
  openModal = (activeUser, type) => {
    this.setState({ isOpened: true, activeUser: { ...activeUser }, type });
  };
  onSubmit = () => {
    const { uuid } = this.state.activeUser;
    this.state.type === 'EXTEND_DATE' ? this.props.editCompany(this.state.activeUser, uuid) : this.props.deleteCompany(uuid);
    this.setState({ isOpened: false });
  };
  onClose = () => {
    this.setState({ isOpened: false });
  };
  onDateChange = (values) => {
    this.setState({ activeUser: { ...this.state.activeUser, expiration_date: values.expiration_date } });
  };

  render() {
    const { companies, history } = this.props;
    const { isOpened, activeUser, type } = this.state;
    const columns = getColumns(history, this.openModal);
    const { DELETE, CONFIRM } = MODAL_TYPE;
    return (
      <div className="dictionaryTable">
        <ReactTable columns={columns} data={companies} />
        <Modal
          opened={isOpened}
          onSubmit={this.onSubmit}
          onClose={this.onClose}
          type={type === 'DELETE' ? DELETE : CONFIRM}
          className={'overflow-unset'}>
          {type === 'DELETE' ? (
            <>
              <div className="modal__title">Usuń klienta</div>
              <div className="modal__info">
                <ul>
                  <li>
                    Firma: <span className="info-value">{activeUser.company_name}</span>
                  </li>
                  <li>
                    Data wygaśnięcia <span className="info-value">{checkIfExpired(activeUser.expiration_date)}</span>
                  </li>
                  <li>
                    Ilość loginów{' '}
                    <span className="info-value">
                      <LoginsCell original={activeUser} />
                    </span>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <>
              <div className="modal__title modal__title--with-icon">
                <SVG type={SVG_TYPE.CALENDAR} />
                Przedłuż ważność
              </div>
              <Formik
                initialValues={{ expiration_date: activeUser.expiration_date }}
                validate={this.onDateChange}
                onSubmit={this.onDateChange}
                enableReinitialize>
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit} className="modal__date-picker">
                    <Field name="expiration_date" type="text" component={DatePicker} errors={errors} wrapperClassName={'date-picker-blank'} />
                  </form>
                )}
              </Formik>
              <div className="modal__info">
                <ul>
                  <li>
                    Dla <span className="info-value">{activeUser.company_name}</span>
                  </li>
                  <li>
                    Dostęp do <span className="info-value">{moment(activeUser.expiration_date).format('YYYY-MM-DD HH:mm')}</span>
                  </li>
                </ul>
              </div>
            </>
          )}
        </Modal>
      </div>
    );
  }
}
type P = {
  companies: Array<any>;
  getCompanies: () => void;
  editCompany: (companyData: any, uuid: any) => void;
  deleteCompany: (uuid: any) => void;
  history: any;
};
export default CompanyDesktop;
