import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = () => ({
  type: BHP.HIDE_ROW_INIT,
});

const success = (type, id) => ({
  type: BHP.HIDE_ROW_SUCCESS,
  payload: {
    type,
    id,
  },
});

const failure = () => ({
  type: BHP.HIDE_ROW_FAILURE,
});

const hideRow = (type, id) => async (dispatch) => {
  dispatch(init());
  try {
    await Connector.hideRow(type, id);
    dispatch(success(type, id));
    toast.success('Pomyślnie ukryto wiersz');
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się zapisać zmian');
    return err;
  }
};

export default hideRow;
