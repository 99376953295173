import * as React from 'react';

type P = {
  options: Array<{ label: string; value: any }>;
  value?: any;
  onClick: (value) => void;
  disabled?: boolean;
};
const TableSelect = ({ options, value, onClick, disabled }: P) => {
  const current = value ? options.find((e) => e.value === value) : options[0];
  const [toggle, setToggle] = React.useState(false);
  const toggleOptions = () => !disabled && setToggle(!toggle);
  return (
    <div className={`tableSelect  ${toggle ? 'tableSelect--opened' : ''}`}>
      <div className={`tableSelect__value tableSelect__value--${current.value}`} onClick={() => toggleOptions()}>
        {current.label}
      </div>
      {toggle && (
        <div className="tableSelect__options">
          {options.map(({ label, value }) => (
            <div
              key={value}
              className="tableSelect__single"
              onClick={() => {
                toggleOptions();
                onClick(value);
              }}>
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TableSelect;
