import * as React from 'react';
import { MainType } from './Container';
import { Button } from 'Shared/components';
import { RegularField } from 'Shared/components/forms';
import { Formik, Field } from 'formik';
import LabelForm from 'Screens/areaManagement/components/labelForm';

class Settings extends React.Component<MainType, {}> {

  componentDidMount() {
    this.props.actions.getSettings();
  }

  render() {
    const {
      settings,
      actions: { editSettings },
    } = this.props;
    return (
      <div className="settings">
        <div className="settings__title">Ustawienia</div>
        <Formik
          // initialValues={{ email_title: '', email_content: '', email_link: '' }}
          initialValues={settings}
          onSubmit={(values) => editSettings(values)}
          enableReinitialize
          // validationSchema={}
        >
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="areaManagement__form">
              <LabelForm value="Tytuł wiadomości mailowej" />
              <Field placeholder="dodaj tytuł..." type="text" name="email_title" component={RegularField} errors={errors} />
              <LabelForm value="Treść wiadomości mailowej" />
              <Field placeholder="dodaj treść..." type="textarea" name="email_content" component={RegularField} errors={errors} />
              <LabelForm value="Link w wiadomości mailowej" />
              <Field placeholder="dodaj link..." type="text" name="email_link" component={RegularField} errors={errors} />
              <div className="areaManagement__form--save">
                <Button label="Zapisz zmiany" classNames="btn btn--navy-blue" type="submit" />
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

export default Settings;
