import * as React from 'react';
import { USER_LEVELS, SVG_TYPE } from 'Shared/enums';
import { HighlightText, SVG } from 'Shared/components';

type P = {
  value: any;
  history: any;
  original: any;
  userLevel: string;
  isCustomerLogged: boolean;
  highlight?: boolean;
  area?: string;
};

const ExtendedView = ({ value, isCustomerLogged, history, original: { id, uid }, userLevel, highlight, area }: P) => {
  return (
    <div className="extendedView">
      <div className="extendedView--title">{highlight ? <HighlightText text={value} /> : value}</div>
      {(userLevel === USER_LEVELS.DEMO || userLevel === USER_LEVELS.DETAILED || userLevel === USER_LEVELS.MANAGING) && isCustomerLogged && (
        <div className={`extendedView--btn extendedView--${area}`} onClick={() => history.push(`/dashboard/${area}/kompendium-${id}`)}>
          {/* ☆ Widok rozszerzony &gt; */}
          {/* <span> */}
          <SVG type={SVG_TYPE.MAGNIFIER} />
          <span>zobacz kartę</span>
          {/* </span> */}
          {/* Widok rozszerzony  */}
        </div>
      )}
      <span className="extendedView--identifier">{highlight ? <HighlightText text={uid} /> : uid}</span>
    </div>
  );
};

export default ExtendedView;
