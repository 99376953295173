import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import Notifications from './Main';
import { Selectors as NotificationSelectors, Actions as NotificationActions } from 'Modules/NotificationModule';

type ConnectedP = {
  notifications: any;
  notificationsNotRead: any;
};

type DispatchedP = {
  // actions: {
  //   getSettings: () => void;
  //   editSettings: (data) => void;
  // };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      readNotification: (uuid) => NotificationActions.readNotification(uuid),
      getNotifications: (page) => NotificationActions.getNotifications(page),
      getNotificationsNotRead: () => NotificationActions.getNotificationsNotRead(),
      getLastNotifications: () => NotificationActions.getLastNotifications(),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  notifications: NotificationSelectors.getNotifications(state),
  notificationsNotRead: NotificationSelectors.getNotificationsNotRead(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
