import { ROUTES } from 'Shared/enums';

const matchUrlToRoute = (url) => {
  if (url.includes('bhp')) {
    return ROUTES.BHP;
  }
  if (url.includes('ochrona-srodowiska')) {
    return ROUTES.ENVIRONMENT_PROTECTION;
  }
  if (url.includes('energia')) {
    return ROUTES.ENERGIA;
  }
  if (url.includes('kompendium')) {
    return ROUTES.COMPENDIUM_DETAILS;
  }
  if (url.includes('changelog')) {
    return ROUTES.CHANGELOG;
  }
  if (url.includes('uzytkownicy')) {
    return ROUTES.USERS;
  }
  if (url.includes('baza-wiedzy')) {
    return ROUTES.BAZA_WIEDZY;
  }
};

export default matchUrlToRoute;
