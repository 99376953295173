import * as React from 'react';
import moment from 'moment';
import { Cells } from 'Shared/components';

type P = {
  institutions: Array<any>;
  onEditRowClick: (institutions: any) => void;
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  manageRow: (type, id) => void;
  areHiddenRowsShown: boolean;
};
const InstitutionsMobile = ({ institutions, onEditRowClick, isCustomerLogged, isEditorOrSuperAdmin, areHiddenRowsShown, manageRow }: P) => {
  const [expandedRowId, setExpandedRowId] = React.useState(undefined);
  return (
    <div className="institutionsMobile">
      {institutions.map((institution, idx) => (
        <InstitutionElement
          key={institution.term}
          isCustomerLogged={isCustomerLogged}
          isEditorOrSuperAdmin={isEditorOrSuperAdmin}
          institution={institution}
          isExpanded={idx === expandedRowId}
          onClick={() => setExpandedRowId(idx !== expandedRowId ? idx : undefined)}
          onEdit={() => onEditRowClick(institution)}
          areHiddenRowsShown={areHiddenRowsShown}
          manageRow={manageRow}
        />
      ))}
    </div>
  );
};

export default InstitutionsMobile;

const InstitutionElement = ({
  institution: { name, id, short, role, tasks, created_at },
  isExpanded,
  onClick,
  onEdit,
  isCustomerLogged,
  isEditorOrSuperAdmin,
  manageRow,
  areHiddenRowsShown,
}: any) => (
  <div className="instutitionElement">
    <div className="instutitionElement__name" onClick={onClick}>
      <span>{id}</span> <span>{name}</span>
    </div>
    {isExpanded && (
      <div className="instutitionElement__content">
        <div className="instutitionElement__block">
          <span className="label">Skrót:</span>
          <span className="value">{short}</span>
        </div>
        <div className="instutitionElement__block">
          <span className="label">Rola:</span>
          <span className="value">
            <Cells.ExtendText value={role} />
          </span>
        </div>
        <div className="instutitionElement__block">
          <span className="label">Zadania:</span>
          <span className="value">
            <Cells.ExtendText value={tasks} />
          </span>
        </div>
        <div className="instutitionElement__block">
          <span className="label">Data aktualizacji:</span>
          <span className="value">{moment(created_at).format('YYYY-MM-DD HH:mm')}</span>
        </div>
        <div className="instutitionElement__block instutitionElement__block--edit">
          {isEditorOrSuperAdmin ? (
            <button className="btn btn--transparent btn--bordered btn--xs" onClick={onEdit}>
              Edytuj
            </button>
          ) : (
            isCustomerLogged && <Cells.VisibilityCell areHiddenRowsShown={areHiddenRowsShown} onClick={() => manageRow('institutions', id)} />
          )}
        </div>
      </div>
    )}
  </div>
);
