import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';
import * as AuthModule from 'Modules/AuthModule';

export function login(email: string, password: string): AxiosPromise<AuthModule.Types.LogInResponse> {
  return API.post('auth/login', { email, password });
}
export function logout(): AxiosPromise<any> {
  return API.put('auth/logout');
  // config(null, { Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}` })
}
// export function setRefreshToken(): AxiosPromise<any> {
//   return API.post('user/refresh');
// }
export function getAuthorizedUser(): AxiosPromise<AuthModule.Types.AuthorizedUserData> {
  return API.get('auth/me');
}

export function sendLink(email: string): AxiosPromise<any> {
  return API.post('auth/sendLink', { email });
}

export function getRefreshToken(refreshToken: string): AxiosPromise<any> {
  return API.post('auth/refresh', { refreshToken: refreshToken });
}

export function addNewDevice(deviceData: any): AxiosPromise<any> {
  return API.post('devices', deviceData);
}
