/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { SVG } from 'Shared/components';
import ReactTooltip from 'react-tooltip';
import { history } from 'Store/Reducers/RootReducer';

type P = {
  getLastNotifications: () => void;
  getNotificationsNotRead: () => void;
  readNotification: ({ uuid, isReadAll }) => void;
  lastNotifications: any;
  notificationsNotRead: boolean;
};

const NotificationHeader = ({ readNotification, getLastNotifications, getNotificationsNotRead, lastNotifications, notificationsNotRead }: P) => {
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    getLastNotifications();
  }, [getLastNotifications]);

  useEffect(() => {
    isActive && getLastNotifications();
  }, [isActive]);

  useEffect(() => {
    getNotificationsNotRead();
  }, [getNotificationsNotRead, window.location.pathname]);

  const [activeTooltip, setActiveTooltip] = useState(false);
  const popupRef = useRef(null);

  const handleClickNotification = () => {
    setIsActive(!isActive);
  };

  const handleRedirectToNotificationsPage = () => {
    window.open(ROUTES.NOTIFICATIONS, '_blank');
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isActive]);

  const getFirstOccurrences = (notifications: any) => {
    const seenDays = new Set<string>();
    const firstOccurrences = new Set<number>();

    notifications.forEach((notification, index) => {
      if (!seenDays.has(notification.creation_day)) {
        seenDays.add(notification.creation_day);
        firstOccurrences.add(index);
      }
    });

    return firstOccurrences;
  };

  const firstOccurrences = getFirstOccurrences(lastNotifications);

  const getCreationDayLabel = (creationDay: 'today' | 'yesterday' | 'oldest'): string => {
    switch (creationDay) {
      case 'today':
        return 'DZISIAJ';
      case 'yesterday':
        return 'WCZORAJ';
      case 'oldest':
        return 'STARSZE';
      default:
        return '';
    }
  };

  const handleReadNotification = (uuid: string, isReading, area, compendiumId) => {
    history.push(`/dashboard/${area}/kompendium-${compendiumId}`);
    setIsActive(false);
    !isReading && readNotification({ uuid, isReadAll: false });
  };

  return (
    <div className="notification-header">
      <div onClick={() => handleClickNotification()} className={`notification-header__icon ${isActive ? 'notification-header__icon--active' : ''}`}>
        {notificationsNotRead ? <SVG type={SVG_TYPE.NOTIFICATION_DOT} /> : <SVG type={SVG_TYPE.NOTIFICATION} />}
      </div>
      <div ref={popupRef} className={`notification-popup ${isActive ? 'notification-popup--active' : ''}`}>
        {activeTooltip ? (
          <>
            <ReactTooltip place="bottom" effect="solid" className={`tooltip`} delayShow={200} />
          </>
        ) : null}
        <div className="notification-popup__header">
          <span>Powiadomienia</span>
          <div
            onMouseOver={() => setActiveTooltip(true)}
            onMouseLeave={() => setActiveTooltip(true)}
            className="element"
            data-tip="Otwórz w nowej karcie"
            onClick={() => handleRedirectToNotificationsPage()}>
            <SVG type={SVG_TYPE.NOTIFICATION_OPEN} />
          </div>
        </div>
        {isActive && (
          <div className="notification-popup__list">
            {lastNotifications.map((notification, index) => (
              <React.Fragment key={notification.uuid}>
                {firstOccurrences.has(index) && <div className="notification-popup__day">{getCreationDayLabel(notification.creation_day)}</div>}
                <div
                  className="notification-popup__item"
                  onClick={() => handleReadNotification(notification.uuid, notification.is_read, notification.area, notification.compendium_id)}>
                  <div className="notification-popup__item-label notification-popup__item-label--ellipsis">
                    {notification.comment}
                    {!notification.is_read && <div className="notification-popup__item-noti" />}
                  </div>
                  <div className="notification-popup__item-time">{notification.time}</div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationHeader;
