import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_HIDDEN_INSTITUTIONS_INIT,
});

const success = (institutions: Array<any>) => ({
  type: BHP.GET_HIDDEN_INSTITUTIONS_SUCCESS,
  payload: {
    institutions,
  },
});

const failure = () => ({
  type: BHP.GET_HIDDEN_INSTITUTIONS_FAILURE,
});

const getHiddenInstitutions = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getHiddenRows(getArea(), 'institutions');
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getHiddenInstitutions;
