import * as Types from 'Shared/models/UserModel';
import { User } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.UserStore;

export const initialState: State = {
  token: null,
  user: null,
  usersList: [],
  companyEmployee: [],
  companies: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  companiesOptions: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  usersInCompanyOptions: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  company: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  changelogs: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  settings: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  editingEmployee: {
    status: REQUEST_STATUS.IDLE,
  },
  editingUser: {
    status: REQUEST_STATUS.IDLE,
  },
};

export function userReducer(state: any = initialState, action) {
  switch (action.type) {
    case User.GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.payload.usersData,
      };
    }
    case User.GET_COMPANIES_INIT: {
      return {
        ...state,
        companies: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companies: {
          data: action.payload.companies,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_COMPANIES_FAILURE: {
      return {
        ...state,
        companies: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_COMPANIES_OPTIONS_INIT: {
      return {
        ...state,
        companiesOptions: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_COMPANIES_OPTIONS_SUCCESS: {
      return {
        ...state,
        companiesOptions: {
          data: action.payload.companies,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_COMPANIES_OPTIONS_FAILURE: {
      return {
        ...state,
        companiesOptions: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_USERS_IN_COMPANY_OPTIONS_INIT: {
      return {
        ...state,
        usersInCompanyOptions: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_USERS_IN_COMPANY_OPTIONS_SUCCESS: {
      return {
        ...state,
        usersInCompanyOptions: {
          data: action.payload.companies,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_USERS_IN_COMPANY_OPTIONS_FAILURE: {
      return {
        ...state,
        usersInCompanyOptions: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_COMPANY_INIT: {
      return {
        ...state,
        company: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_COMPANY_SUCCESS: {
      return {
        ...state,
        company: {
          data: action.payload.company,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_COMPANY_FAILURE: {
      return {
        ...state,
        company: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_COMPANY_EMPLOYEE_INIT: {
      return {
        ...state,
        companyEmployee: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_COMPANY_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        companyEmployee: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_COMPANY_EMPLOYEE_FAILURE: {
      return {
        ...state,
        companyEmployee: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.CLEAR_ACTIVE_COMPANY: {
      return {
        ...state,
        company: {
          status: undefined,
          data: undefined,
        },
      };
    }
    case User.EDIT_EMPLYEE_INIT: {
      return {
        ...state,
        editingEmployee: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.EDIT_USER_INIT: {
      return {
        ...state,
        editingUser: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.EDIT_USER_SUCCESS: {
      const index = state.company.data.users.findIndex((user) => user.uuid === action.payload.uuid);
      const newUsersState = [...state.company.data.users];
      newUsersState[index] = { ...state.company.data.users[index], ...action.payload.userData };
      return {
        ...state,
        company: {
          data: {
            ...state.company.data,
            users: newUsersState,
          },
          status: REQUEST_STATUS.SUCCESS,
        },
        editingUser: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.EDIT_EMPLYEE_SUCCESS: {
      const index = state.usersList.findIndex((user) => user.uuid === action.payload.uuid);
      const newUsersState = [...state.usersList];
      newUsersState[index] = { ...state.usersList[index], ...action.payload.userData };
      return {
        ...state,
        usersList: newUsersState,
        editingEmployee: {
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_CHANGELOG_INIT: {
      return {
        ...state,
        changelogs: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_CHANGELOG_SUCCESS: {
      return {
        ...state,
        changelogs: {
          data: action.payload.changelogData,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_CHANGELOG_FAILURE: {
      return {
        ...state,
        changelogs: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.GET_SETTINGS_INIT: {
      return {
        ...state,
        settings: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: {
          data: action.payload.settingsData,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.GET_SETTINGS_FAILURE: {
      return {
        ...state,
        settings: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case User.EDIT_SETTINGS_INIT: {
      return {
        ...state,
        settings: {
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case User.EDIT_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: {
          data: action.payload.settingsData,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case User.EDIT_SETTINGS_FAILURE: {
      return {
        ...state,
        settings: {
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default: {
      return state;
    }
  }
}
