import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Selectors, Actions as UserActions } from 'Modules/UserModule';
import { Actions } from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.EDIT_COMPANY_INIT,
});

const success = () => ({
  type: User.EDIT_COMPANY_SUCCESS,
});

const failure = () => ({
  type: User.EDIT_COMPANY_FAILURE,
});
const placeholder = {
  house_number: '333',
  flat_number: '13',
};
const editCompany = (companyData: any, company_uuid = '') => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    if (!company_uuid) {
      const uuid = Selectors.getUuid(getState());
      await Connector.editCompany({ ...companyData, ...placeholder }, uuid);
      await dispatch(UserActions.getCompany(uuid));
      toast.success('Pomyślnie edytowano klienta');
    } else {
      await Connector.editCompany({ ...companyData }, company_uuid);
      dispatch(UserActions.getCompanies());
      toast.success('Pomyślnie przedłużono ważność');
    }
    dispatch(success());
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default editCompany;
