import * as React from 'react';
import DictionaryDesktop from './components/dictionaryDesktop';
import DictionaryTablet from './components/dictionaryTablet';
import DictionaryPhone from './components/dictionaryPhone';
import DictionaryForm from './components/dictionaryForm';
import { REQUEST_STATUS, MODAL_TYPE } from 'Shared/enums';
import { Preloader, Modal, HiddenRowsToggle, ExtendTextToggle, Toggle } from 'Shared/components';

type P = {
  deviceType: string;
  dictionaries: Array<any>;
  getDictionaries: (isMyCompanyEntries?: boolean, areNewlyAdded?: boolean) => void;
  getHiddenDictionaries: () => void;
  editDictionary: (dictionary: any) => void;
  deleteDictionary: (dictionary: any) => void;
  dictionariesStatus: REQUEST_STATUS;
  editingDictionary: {
    status: REQUEST_STATUS;
  };
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  hideRow: (type, id) => void;
  destroyHiddenRow: (type: string, id: number) => void;
  location: {
    pathname: string;
  };
  showExtendedText: () => void;
  hideExtendedText: () => void;
  setColumnFilterTerms: (data: any) => void;
  isExtendedTextVisible: boolean;
  isCustomerAreaManager: boolean;
  userLevel: string;
};
type S = {
  dictionaryData: any;
  isEditModalOpened: boolean;
  areHiddenRowsShown: boolean;
  type: string;
  isMyCompanyEntries: boolean;
  areNewlyAdded: boolean;
};
class Dictionary extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      dictionaryData: undefined,
      isEditModalOpened: false,
      areHiddenRowsShown: false,
      type: '',
      isMyCompanyEntries: false,
      areNewlyAdded: false,
    };
  }
  componentDidMount() {
    this.props.getDictionaries();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.props.getDictionaries(this.state.isMyCompanyEntries, this.state.areNewlyAdded);
    }
    if (prevProps.editingDictionary.status === REQUEST_STATUS.INIT && this.props.editingDictionary.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isEditModalOpened: false });
    }
  }

  onModalClose = () => this.setState({ isEditModalOpened: false });
  isOpened = () => this.state.isEditModalOpened;
  isEditLoading = () => this.props.editingDictionary.status === REQUEST_STATUS.INIT;
  onEditRowClick = (dictionaryData) => {
    this.setState({ isEditModalOpened: true, type: 'EDIT', dictionaryData });
  };
  onDeleteRowClick = (dictionaryData) => {
    this.setState({ isEditModalOpened: true, type: 'DELETE', dictionaryData });
  };

  onHiddenRowsToggle = () => {
    this.setState(
      (prevState) => ({ areHiddenRowsShown: !prevState.areHiddenRowsShown, isMyCompanyEntries: false, areNewlyAdded: false }),
      () => {
        this.state.areHiddenRowsShown ? this.props.getHiddenDictionaries() : this.props.getDictionaries();
      },
    );
  };
  manageRow = (type, id) => (this.state.areHiddenRowsShown ? this.props.destroyHiddenRow(type, id) : this.props.hideRow(type, id));

  isLoading = () => this.props.dictionariesStatus === REQUEST_STATUS.INIT || this.props.dictionariesStatus === REQUEST_STATUS.IDLE;

  onDelete = () => {
    const {
      dictionaryData: { id },
    } = this.state;
    this.props.deleteDictionary(id);
    this.setState({ isEditModalOpened: false });
  };

  handleManageText = () => {
    this.props.isExtendedTextVisible ? this.props.hideExtendedText() : this.props.showExtendedText();
  };

  handleMyCompanyEntries = () => {
    this.props.getDictionaries(!this.state.isMyCompanyEntries);
    this.setState((prevState) => ({
      isMyCompanyEntries: !prevState.isMyCompanyEntries,
      areHiddenRowsShown: false,
      areNewlyAdded: false,
    }));
  };

  handleNewlyAdded = () => {
    this.props.getDictionaries(false, !this.state.areNewlyAdded);
    this.setState((prevState) => ({
      areNewlyAdded: !prevState.areNewlyAdded,
      areHiddenRowsShown: false,
      isMyCompanyEntries: false,
    }));
  };

  render() {
    const {
      dictionaries,
      deviceType,
      editDictionary,
      isCustomerLogged,
      isEditorOrSuperAdmin,
      showExtendedText,
      hideExtendedText,
      isExtendedTextVisible,
      isCustomerAreaManager,
      userLevel,
      setColumnFilterTerms,
    } = this.props;
    const { dictionaryData, areHiddenRowsShown, type, isMyCompanyEntries, areNewlyAdded } = this.state;
    // let filterByLetter = dictionaries.length
    //   ? dictionaries
    //       .map(({ term }) => term)
    //       .map((val) => {
    //         const value = dictionaries.find(({ term }) => term.charAt(0).toLowerCase() === val.charAt(0).toLowerCase());
    //         return value.term.charAt(0).toUpperCase();
    //       })
    //   : [];
    // filterByLetter = Array.from(new Set(filterByLetter));
    return (
      <>
        <Modal
          type={type === 'EDIT' ? MODAL_TYPE.FORM : MODAL_TYPE.DELETE}
          opened={this.isOpened()}
          onClose={this.onModalClose}
          onSubmit={this.onDelete}
          big>
          {type === 'EDIT' ? (
            <DictionaryForm
              isLoading={this.isEditLoading()}
              onClose={this.onModalClose}
              initialValues={dictionaryData}
              onSubmit={editDictionary}
              isCustomerAreaManager={isCustomerAreaManager}
            />
          ) : (
            <>
              <div className="modal__title">Usuń wiersz</div>
              <div className="modal__info">
                <h2>Pojęcie</h2>
                <p>{dictionaryData?.term}</p>
                <h2>Treść</h2>
                <p>{dictionaryData?.content}</p>
                <h2>Akt normatywny</h2>
                <p>{dictionaryData?.normative_act}</p>
              </div>
            </>
          )}
        </Modal>
        <div className="dashboardDictionary__buttons">
          {isCustomerLogged && (
            <HiddenRowsToggle onClick={this.onHiddenRowsToggle} areHiddenRowsShown={areHiddenRowsShown} disabled={this.isLoading()} />
          )}
          {/* {showExtendTextManage ? ( */}
          <ExtendTextToggle onClick={this.handleManageText} isExtendedTextVisible={isExtendedTextVisible} />
          {isCustomerLogged && (
            <>
              <Toggle label={'Nasze wpisy'} value={isMyCompanyEntries} onClick={this.handleMyCompanyEntries} />
              <Toggle label={'Nowości'} value={areNewlyAdded} onClick={this.handleNewlyAdded} />
            </>
          )}
          {/* <button className={`btn btn--extendTextManage ${isExtendedTextVisible ? 'btn--extendActive' : ''}`} onClick={this.handleManageText}>
            {isExtendedTextVisible ? 'Zwiń' : 'Rozwiń'} wiersze
          </button> */}
        </div>
        {/* ) : null} */}
        {deviceType === 'DESKTOP' && (
          <DictionaryDesktop
            manageRow={this.manageRow}
            // filterByLetter={filterByLetter}
            areHiddenRowsShown={areHiddenRowsShown}
            isCustomerLogged={isCustomerLogged}
            isEditorOrSuperAdmin={isEditorOrSuperAdmin}
            dictionaries={dictionaries}
            onEditRowClick={this.onEditRowClick}
            onDeleteRowClick={this.onDeleteRowClick}
            showExtendedText={showExtendedText}
            hideExtendedText={hideExtendedText}
            isExtendedTextVisible={isExtendedTextVisible}
            isCustomerAreaManager={isCustomerAreaManager}
            userLevel={userLevel}
            setColumnFilterTerms={setColumnFilterTerms}
          />
        )}
        {deviceType === 'TABLET' && <DictionaryTablet dictionaries={dictionaries} />}
        {deviceType === 'SMARTPHONE' && <DictionaryPhone dictionaries={dictionaries} />}
        {this.isLoading() && <Preloader inner />}
      </>
    );
  }
}

export default Dictionary;
