import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.ADD_COMPENDIUM_EVIDENCE_INIT,
});

const success = (data,response) => ({
  type: BHP.ADD_COMPENDIUM_EVIDENCE_SUCCESS,
  payload: {
    data, response
  }
});

const failure = () => ({
  type: BHP.ADD_COMPENDIUM_EVIDENCE_FAILURE,
});

const addCompendiumEvidence = (data) => async (dispatch) => {
  dispatch(init());
  const file = new FormData();
  file.append('file', data.file);
  const response = await Connector.addCompendiumEvidence(data, file);
  try {
    // dispatch(Actions.showLoader());
    dispatch(success(data, response));
    toast.success('Pomyślnie dodano plik');
    // dispatch(Actions.hideLoader());
    return response.data;
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    console.log(err);
    toast.error('Nie udało się dodać pliku');
    return err;
  }
};

export default addCompendiumEvidence;
