import * as React from 'react';

const LearnMore = ({ onCtaClick }: P) => (
  <div className="learnMore">
    <div className="learnMore__icon">
      <SVG />
    </div>
    <div className="learnMore__title">OCHRONA ŚRODOWISKA</div>
    <div className="learnMore__badge">Brak dostępu</div>
    <div className="learnMore__heading">Twój abonament obecnie nie obejmuje dostępu do tego obszaru. </div>
    <div className="learnMore__description">
      Znajdziesz w nim informacje potrzebne do skutecznej identyfikacji i oceny zgodności z wymaganiami prawnymi oraz audytowania Systemu Zarządzania
      Środowiskowego według normy ISO 14001:2015.
    </div>
    <div className="learnMore__ctaDescription">Skontaktuj się z nami w celu uzyskania dostępu do obszaru OCHRONA ŚRODOWISKA.</div>
    <div className="learnMore__badges">
      <div className=" learnMore__badge learnMore__badge--green">
        <div>+ 512 193 743</div>
        <div>singula@wiso.pl</div>
      </div>
    </div>
  </div>
);
type P = {
  onCtaClick: () => void;
};

export default LearnMore;

const SVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.5 42.627">
    <g transform="translate(-322.176 -40.958)">
      <path
        d="M36.2,312.183a1.406,1.406,0,0,1-1.214-.026L29.6,309.464H27.092a1.42,1.42,0,0,1-1-.416l-1.837-1.837-1.421,1.421v2.253a1.42,1.42,0,0,1-.416,1l-2.842,2.842a1.42,1.42,0,0,1-2.009,0l-1.837-1.837-1.837,1.837a1.42,1.42,0,0,1-2.009,0l-2.425-2.426H2.263A24.046,24.046,0,0,0,0,322.253a1.42,1.42,0,0,0,1.421,1.421H22.829v-7.1a1.42,1.42,0,0,1,.416-1l2.842-2.842a1.419,1.419,0,0,1,1.64-.266l5.384,2.692h2.506a1.42,1.42,0,0,1,1,.416l2.842,2.842a1.419,1.419,0,0,1,.416,1v4.263h7.2a1.42,1.42,0,0,0,1.421-1.421,24.087,24.087,0,0,0-4.322-13.624Z"
        transform="translate(322.176 -240.088)"
      />
      <path
        d="M46.064,37.262c-3.5-2.184-9.279,1.441-11.3,4.669h0c-1.91,3.061-.136,4.882,2.566,6.621L35.8,51.045a23.853,23.853,0,0,0-10.127-2.916v-2.92c1.8,0,3.47-.105,4.594-1.22a4.114,4.114,0,0,0,1.089-3.043c0-3.415-2.767-9.946-7.1-9.946s-7.1,6.531-7.1,9.946a4.114,4.114,0,0,0,1.089,3.043c1.129,1.12,2.776,1.213,4.594,1.22v2.935a23.884,23.884,0,0,0-10.135,2.888l-1.526-2.478c2.642-1.7,4.479-3.554,2.566-6.621h0c-2.015-3.223-7.8-6.855-11.3-4.669-3.676,2.3-2.563,9.3-.753,12.2,1.932,3.09,4.379,2.239,7.077.6l1.534,2.491a24.408,24.408,0,0,0-6.5,6.866h6.246a1.42,1.42,0,0,1,1,.416l1.837,1.837,1.837-1.837a1.42,1.42,0,0,1,2.009,0l1.837,1.837,1.421-1.421V58a1.42,1.42,0,0,1,.416-1l2.842-2.842a1.42,1.42,0,0,1,2.009,0l2.425,2.425h2.253a1.422,1.422,0,0,1,.636.15l5.083,2.542,6.712-2.989a24.5,24.5,0,0,0-4.158-3.727l1.535-2.492c2.714,1.643,5.174,2.444,7.077-.6,1.808-2.9,2.921-9.9-.755-12.2Z"
        transform="translate(322.176 9.959)"
      />
      <path
        d="M282.367,398.795l-2.009-2.009H278.1a1.422,1.422,0,0,1-.635-.15l-4.768-2.384-1.7,1.7v6.516h11.367Z"
        transform="translate(76.847 -318.884)"
      />
    </g>
  </svg>
);
