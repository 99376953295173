import * as AuthModule from '../../../Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
// import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { history } from 'Store/Reducers/RootReducer';
import { ROUTES, ROLES } from 'Shared/enums';

const init = () => ({
  type: Auth.ADD_NEW_DEVICE_INIT,
});

export const success = () => ({
  type: Auth.ADD_NEW_DEVICE_SUCCESS,
});

const failure = () => ({
  type: Auth.ADD_NEW_DEVICE_FAILURE,
});

const addNewDevice = (deviceData: any) => {
  return async (dispatch, getState) => {
    dispatch(init());
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await AuthModule.Connector.addNewDevice(deviceData);
      await dispatch(AuthModule.Actions.getAuthorizedUser());
      const { roles } = AuthModule.Selectors.authorizedUser(getState());
      const isCustomer = roles.some(({ role }) => role === ROLES.CUSTOMER);
      history.push(isCustomer ? ROUTES.BAZA_WIEDZY : ROUTES.BHP);

      dispatch(success());
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(failure());
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
};

export default addNewDevice;
