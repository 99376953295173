import * as React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const DictionaryTablet = ({ dictionaries = [] }: P) => {
  let letter = '';
  return (
    <div className="dictionaryTable--tablet">
      {dictionaries.map((el) => {
        const firstLetter = el.term.charAt(0);
        if (letter === firstLetter) {
          return <DictionaryElement data={el} displayLetter={false} key={el.term} />;
        }
        letter = firstLetter;
        return <DictionaryElement data={el} displayLetter key={el.term} />;
      })}
    </div>
  );
};
type P = {
  dictionaries: Array<any>;
};
export default DictionaryTablet;

const DictionaryElement = ({ data, displayLetter }: any) => (
  <div className="dictionary__element">
    {displayLetter && <div className="dictionary__element--letter">{data.term.charAt(0)}</div>}
    <div className="dictionary__element--header">
      <div className="dictionary__element--header-l">
        <div className="dictionary__element--title">{data.term}</div>{' '}
        <div className="dictionary__element--icon">
          <SVG type={SVG_TYPE.FILE} />
        </div>
      </div>
      <div className="dictionary__element--header-r">
        <div className="dictionary__element--icon">
          <SVG type={SVG_TYPE.PARAGRAPH} />
        </div>
      </div>
    </div>
    <div className="dictionary__element--content">
      <div className="dictionary__element--content-l">{data.content}</div>
      <div className="dictionary__element--content-r">{data.normative_act}</div>
    </div>
  </div>
);
