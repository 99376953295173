import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_HIDDEN_COMPENDIUMS_INIT,
});

const success = (compendiums: Array<any>) => ({
  type: BHP.GET_HIDDEN_COMPENDIUMS_SUCCESS,
  payload: {
    compendiums,
  },
});

const failure = () => ({
  type: BHP.GET_HIDDEN_COMPENDIUMS_FAILURE,
});

const getHiddenCompendiums = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getHiddenRows(getArea(), 'compendiums');
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getHiddenCompendiums;
