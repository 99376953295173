import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField } from 'Shared/components/forms';
import { Button } from 'Shared/components';
import * as Yup from 'yup';

type P = {
  isLoading: boolean;
  onClose: () => void;
  initialValues: any;
  onSubmit: (formValues: any) => any;
};

const getEmployeeSchema = () =>
  Yup.object().shape({
    first_name: Yup.string().required('Pole wymagane'),
    last_name: Yup.string().required('Pole wymagane'),
    password: Yup.string().min(6, 'Hasło musi zawierać minimum 6 znaków'),
    password_confirmation: Yup.string().when('password', {
      is: (password) => (password && password.length > 0 ? true : false),
      then: Yup.string()
        .required('Pole wymagane')
        .oneOf([Yup.ref('password')], 'Hasła nie są zgodne'),
    }),
  });

const EmployeeForm = ({ onClose, initialValues, onSubmit, isLoading }: P) => (
  <div className="editModal editModal--institutions">
    <h1 className="font-lg bold-700">Edytuj pracownika WISO</h1>
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getEmployeeSchema()}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="editModal--form">
          <Field type="text" name="first_name" label="Imię" component={RegularField} errors={errors} />
          <Field type="text" name="last_name" label="Nazwisko" component={RegularField} errors={errors} />
          <Field type="password" name="password" label="Nowe hasło" component={RegularField} errors={errors} />
          <Field type="password" name="password_confirmation" label="Powtórz hasło" component={RegularField} errors={errors} />
          <div className="editModal__actions">
            <Button label="Anuluj" classNames="btn btn--transparent btn--cancel" onClick={onClose} />
            <Button label="Zapisz zmiany" classNames="btn btn--navy-blue" type="submit" isLoading={isLoading} />
          </div>
        </form>
      )}
    </Formik>
  </div>
);

export default EmployeeForm;
