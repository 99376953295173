import * as React from 'react';

type P = {
  onClick: () => void;
  isExtendedTextVisible: boolean;
  disabled?: boolean;
};

const ExtendTextToggle = ({ onClick, isExtendedTextVisible, disabled = false }: P) => (
  <div className="extendTextToggle">
    <span onClick={!disabled ? onClick : undefined}>{isExtendedTextVisible ? 'Zwiń wiersze' : 'Rozwiń wiersze'}</span>
    <span
      onClick={!disabled ? onClick : undefined}
      className={`extendTextToggle__switch ${isExtendedTextVisible ? 'extendTextToggle__switch--active' : ''}`}>
      <div className="extendTextToggle__switch_inner" />
    </span>
  </div>
);

export default ExtendTextToggle;
