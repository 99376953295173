import * as AuthModule from 'Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import * as AuthActionModel from '../Models/AuthActionModel';
import { Auth } from '../EnumTypes/ActionEnumTypes';
// import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { ROUTES, ROLES } from 'Shared/enums';
import { history } from '../../../Store/Reducers/RootReducer';

const init = () => ({
  type: Auth.LOG_IN_INIT,
});

export const success = (authData: AuthModule.Types.LogInResponse): AuthActionModel.LoginSuccess => ({
  type: Auth.LOG_IN_SUCCESS,
  payload: {
    authData,
  },
});

const failure = (): AuthActionModel.LoginFailure => ({
  type: Auth.LOG_IN_FAILURE,
});

const login = (email: string, password: string) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const response = await AuthModule.Connector.login(email, password);
    // Cookies.set('auth_token', response.data.token, { domain: process.env.REACT_APP_DOMAIN });
    dispatch(success(response.data));

    await dispatch(AuthModule.Actions.getAuthorizedUser());
    const { roles } = AuthModule.Selectors.authorizedUser(getState());
    console.log(roles);
    const isCustomer = roles.some(({ role }) => role === ROLES.CUSTOMER);

    console.log(isCustomer ? ROUTES.BAZA_WIEDZY : ROUTES.BHP);
    history.push(isCustomer ? ROUTES.BAZA_WIEDZY : ROUTES.BHP);
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    console.log('bład');
    dispatch(failure());
    if (err.response && err.response.status === 401) {
      toast.error('Niepoprawne dane logowania');
    } else if (err.response && err.response.status === 403) {
      toast.error('Dostępy Twojej firmy wygasły');
    } else {
      toast.error('Wystąpił błąd logowania');
    }
  }
};

export default login;
