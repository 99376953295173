import API from 'Shared/connectors/Config';
import { AxiosPromise } from 'axios';


export function createNewTab(data): AxiosPromise<any> {
  return API.post(`new-tab`, data);
}

export function editNewTab(data): AxiosPromise<any> {
  return API.put(`new-tab`, data);
}

export function getNewTab(): AxiosPromise<any> {
  return API.get(`new-tab`);
}
