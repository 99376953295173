import * as AuthModule from '../../../Modules/AuthModule';
import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = () => ({
  type: Auth.SEND_LINK_INIT,
});

const success = () => ({
  type: Auth.SEND_LINK_SUCCESS,
});

const failure = () => ({
  type: Auth.SEND_LINK_FAILURE,
});

const sendLink = (email: string) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    await AuthModule.Connector.sendLink(email);
    dispatch(success());
    toast.success('Pomyślnie wysłano link');
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    dispatch(failure());
    if (err.response && err.response.status === 403) {
      toast.error('Dostępy Twojej firmy wygasły');
    } else {
      toast.error('Wystąpił błąd logowania');
    }
  }
};

export default sendLink;
