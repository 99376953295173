import { Connector } from 'Modules/KnowledgeModule';
import { Knowledge } from '../EnumTypes/ActionEnumTypes';
// import { Actions } from 'Modules/ViewManagementModule';
import { toast } from 'react-toastify';

const init = (uuid: string) => ({
  type: Knowledge.EDIT_TOPICALITY_INIT,
  payload: {
    uuid,
  },
});

const success = (topicality) => ({
  type: Knowledge.EDIT_TOPICALITY_SUCCESS,
  payload: {
    topicality,
  },
});

const failure = () => ({
  type: Knowledge.EDIT_TOPICALITY_FAILURE,
});

const editTopicality = (topicality: any) => async (dispatch) => {
  dispatch(init(topicality.uuid));
  try {
    const { uuid } = topicality;
    await Connector.editTopicality({ ...topicality }, uuid);
    dispatch(success(topicality));
    toast.success('Pomyślnie edytowano aktualność');
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się zapisać zmian');

    return err;
  }
};

export default editTopicality;
