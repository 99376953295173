import * as React from 'react';
import InstitutionsDesktop from './components/institutionsDesktop';
import InstitutionsMobile from './components/institutionsMobile';
import InstitutionsForm from './components/institutionsForm';
import { REQUEST_STATUS, MODAL_TYPE } from 'Shared/enums';
import { Preloader, Modal, HiddenRowsToggle, ExtendTextToggle, Toggle } from 'Shared/components';

type P = {
  getInstitutions: (isMyCompanyEntries?: boolean, areNewlyAdded?: boolean) => void;
  institutions: Array<any>;
  institutionsStatus: REQUEST_STATUS;
  editInstitution: (instutition: any) => void;
  deleteInstitution: (instutition: any) => void;
  editingInstitution: {
    status: REQUEST_STATUS;
  };
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  getHiddenInstitutions: () => void;
  deviceType: string;
  hideRow: (type, id) => void;
  destroyHiddenRow: (type: string, id: number) => void;
  location: {
    pathname: string;
  };
  showExtendedText: () => void;
  hideExtendedText: () => void;
  isExtendedTextVisible: boolean;
  isCustomerAreaManager: boolean;
  userLevel: string;
  setColumnFilterTerms: (data: any) => void;
};
type S = {
  institutionData: any;
  isEditModalOpened: boolean;
  areHiddenRowsShown: boolean;
  type: string;
  isMyCompanyEntries: boolean;
  areNewlyAdded: boolean;
};
class Institutions extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      institutionData: undefined,
      isEditModalOpened: false,
      areHiddenRowsShown: false,
      type: '',
      isMyCompanyEntries: false,
      areNewlyAdded: false,
    };
  }
  componentDidMount() {
    this.props.getInstitutions();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location?.pathname !== prevProps.location?.pathname) {
      this.props.getInstitutions(this.state.isMyCompanyEntries, this.state.areNewlyAdded);
    }
    if (prevProps.editingInstitution.status === REQUEST_STATUS.INIT && this.props.editingInstitution.status === REQUEST_STATUS.SUCCESS) {
      this.setState({ isEditModalOpened: false });
    }
  }
  onEditRowClick = (institutionData) => {
    this.setState({ isEditModalOpened: true, type: 'EDIT', institutionData });
  };

  onDeleteRowClick = (institutionData) => {
    this.setState({ isEditModalOpened: true, type: 'DELETE', institutionData });
  };

  onModalClose = () => this.setState({ isEditModalOpened: false });
  isOpened = () => this.state.isEditModalOpened;
  isEditLoading = () => this.props.editingInstitution.status === REQUEST_STATUS.INIT;

  isLoading = () => this.props.institutionsStatus === REQUEST_STATUS.INIT || this.props.institutionsStatus === REQUEST_STATUS.IDLE;

  onHiddenRowsToggle = () => {
    this.setState(
      (prevState) => ({ areHiddenRowsShown: !prevState.areHiddenRowsShown, isMyCompanyEntries: false, areNewlyAdded: false }),
      () => {
        this.state.areHiddenRowsShown ? this.props.getHiddenInstitutions() : this.props.getInstitutions();
      },
    );
  };

  manageRow = (type, id) => (this.state.areHiddenRowsShown ? this.props.destroyHiddenRow(type, id) : this.props.hideRow(type, id));

  onDelete = () => {
    const {
      institutionData: { id },
    } = this.state;
    this.props.deleteInstitution(id);
    this.setState({ isEditModalOpened: false });
  };

  handleManageText = () => {
    this.props.isExtendedTextVisible ? this.props.hideExtendedText() : this.props.showExtendedText();
  };

  handleMyCompanyEntries = () => {
    this.props.getInstitutions(!this.state.isMyCompanyEntries);
    this.setState((prevState) => ({
      isMyCompanyEntries: !prevState.isMyCompanyEntries,
      areHiddenRowsShown: false,
      areNewlyAdded: false,
    }));
  };

  handleNewlyAdded = () => {
    this.props.getInstitutions(false, !this.state.areNewlyAdded);
    this.setState((prevState) => ({
      areNewlyAdded: !prevState.areNewlyAdded,
      areHiddenRowsShown: false,
      isMyCompanyEntries: false,
    }));
  };

  render() {
    const {
      institutions,
      editInstitution,
      isCustomerLogged,
      isEditorOrSuperAdmin,
      deviceType,
      isExtendedTextVisible,
      isCustomerAreaManager,
      userLevel,
      setColumnFilterTerms,
    } = this.props;
    const { institutionData, areHiddenRowsShown, type, isMyCompanyEntries, areNewlyAdded } = this.state;
    const getUnique = (arr, comp) => {
      const unique = arr
        // .map((e) => e[comp])
        .map((e) => e[comp].toLowerCase())
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((e) => arr[e])
        .map((e) => {
          return {
            label: arr[e][comp],
            value: arr[e][comp],
          };
        });
      return unique;
    };
    const filterByType = getUnique(institutions, 'name');
    // const filterByShort = getUnique(institutions, 'short');
    let filterByLetter = institutions
      .map(({ name }) => name)
      .map((val) => {
        const value = institutions.find(({ name }) => name.charAt(0).toLowerCase() === val.charAt(0).toLowerCase());
        return value.name.charAt(0).toUpperCase();
      });
    filterByLetter = Array.from(new Set(filterByLetter));
    return (
      <div className="institutions">
        <Modal
          type={type === 'EDIT' ? MODAL_TYPE.FORM : MODAL_TYPE.DELETE}
          onSubmit={this.onDelete}
          opened={this.isOpened()}
          onClose={this.onModalClose}
          big>
          {type === 'EDIT' ? (
            <InstitutionsForm
              isLoading={this.isEditLoading()}
              onClose={this.onModalClose}
              initialValues={institutionData}
              onSubmit={editInstitution}
              isCustomerAreaManager={isCustomerAreaManager}
            />
          ) : (
            <>
              <div className="modal__title">Usuń wiersz</div>
              <div className="modal__info">
                <h2>Interesariusz</h2>
                <p>{institutionData?.name}</p>
                <h2>Skrót</h2>
                <p>{institutionData?.short}</p>
                <h2>Rola</h2>
                <p>{institutionData?.role}</p>
                <h2>Zadania</h2>
                <p>{institutionData?.tasks}</p>
              </div>
            </>
          )}
        </Modal>
        <div className="dashboardDictionary__buttons">
          {isCustomerLogged && (
            <HiddenRowsToggle onClick={this.onHiddenRowsToggle} areHiddenRowsShown={areHiddenRowsShown} disabled={this.isLoading()} />
          )}
          <ExtendTextToggle onClick={this.handleManageText} isExtendedTextVisible={isExtendedTextVisible} />
          {/* {isCustomerLogged && <Toggle label={'Nasze wpisy'} value={isMyCompanyEntries} onClick={this.handleMyCompanyEntries} />} */}
          {isCustomerLogged && (
            <>
              <Toggle label={'Nasze wpisy'} value={isMyCompanyEntries} onClick={this.handleMyCompanyEntries} />
              <Toggle label={'Nowości'} value={areNewlyAdded} onClick={this.handleNewlyAdded} />
            </>
          )}
        </div>
        {deviceType === 'DESKTOP' ? (
          <InstitutionsDesktop
            filterByType={filterByType}
            filterByLetter={filterByLetter}
            // filterByShort={filterByShort}
            manageRow={this.manageRow}
            areHiddenRowsShown={areHiddenRowsShown}
            isCustomerLogged={isCustomerLogged}
            isEditorOrSuperAdmin={isEditorOrSuperAdmin}
            institutions={institutions}
            onEditRowClick={this.onEditRowClick}
            onDeleteRowClick={this.onDeleteRowClick}
            isCustomerAreaManager={isCustomerAreaManager}
            userLevel={userLevel}
            setColumnFilterTerms={setColumnFilterTerms}
            isExtendedTextVisible={isExtendedTextVisible}
          />
        ) : (
          <InstitutionsMobile
            manageRow={this.manageRow}
            areHiddenRowsShown={areHiddenRowsShown}
            isCustomerLogged={isCustomerLogged}
            isEditorOrSuperAdmin={isEditorOrSuperAdmin}
            institutions={institutions}
            onEditRowClick={this.onEditRowClick}
          />
        )}
        {this.isLoading() && <Preloader inner />}
      </div>
    );
  }
}

export default Institutions;
