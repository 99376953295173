import * as AuthModule from '../../../Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Auth.GET_AUTHORIZED_USER_INIT,
});

const success = (authorizedUserData: AuthModule.Types.AuthorizedUserData): AuthActionModel.GetAuthorizedUser => ({
  type: Auth.GET_AUTHORIZED_USER_SUCCESS,
  payload: {
    authorizedUserData,
  },
});

const failure = (devicesError) => ({
  type: Auth.GET_AUTHORIZED_USER_FAILURE,
  payload: {
    devicesError,
  },
});

const getAuthorizedUser = () => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(ViewManagementModule.Actions.showLoader());
    const response = await AuthModule.Connector.getAuthorizedUser();
    dispatch(success(response.data));
    dispatch(ViewManagementModule.Actions.hideLoader());
  } catch (err) {
    dispatch(ViewManagementModule.Actions.hideLoader());
    console.log(err.response);
    // console.log(response)
    if (err.response.status === 409) {
      dispatch(failure(err.response.data));
    }
  }
};

export default getAuthorizedUser;
