import * as React from 'react';
import { ROUTES, ROUTES_MAP, ROUTES_LABELS, USER_LEVELS } from 'Shared/enums';
import { NavLink } from 'react-router-dom';
import logo from 'Assets/Img/singula-logo.svg';
import { NotificationHeader, NewTabHeader } from 'Shared/components';

type P = {
  authorizedUser: any;
  getLastNotifications: () => void;
  getNotificationsNotRead: () => void;
  getNewTab: () => void;
  readNotification: (data) => void;
  createNewTab: (data) => void;
  editNewTab: (data) => void;
  lastNotifications: any;
  notificationsNotRead: any;
  newTab: any;
};

const DASHBOARD_HEADER_MENU_ARR = [
  ROUTES.USERS,
  ROUTES.BHP,
  ROUTES.ENVIRONMENT_PROTECTION,
  ROUTES.ENERGIA,
  ROUTES.BAZA_WIEDZY,
  ROUTES.CHANGELOG,
  ROUTES.SETTINGS,
];

const DashboardHeader = ({
  lastNotifications,
  getLastNotifications,
  readNotification,
  getNotificationsNotRead,
  notificationsNotRead,
  getNewTab,
  newTab,
  createNewTab,
  editNewTab,
  authorizedUser: { roles, area_bhp, area_environment, area_energy, level },
}: P) => {
  return (
    <div className="dashboard__header">
      <div className="dashboard__header-items">
        {DASHBOARD_HEADER_MENU_ARR.map((route) => {
          const { label, icon, className, roles: definedRoles } = ROUTES_MAP.get(route);
          let isAccessGranted = roles.some(({ role }) => definedRoles.includes(role));
          if (label === ROUTES_LABELS.CHANGELOG && level !== USER_LEVELS.MANAGING) {
            isAccessGranted = false;
          }
          const areaAccess = () => {
            switch (label) {
              case ROUTES_LABELS.BHP:
                return area_bhp;
              case ROUTES_LABELS.ENVIRONMENT:
                return area_environment;
              case ROUTES_LABELS.ENERGY:
                return area_energy;
              default:
                return true;
            }
          };
          if (isAccessGranted) {
            return (
              <NavLink
                to={route}
                className={`dashboard__header-item dashboard__header-item--${className} ${!areaAccess() ? 'dashboard__header-item--forbidden' : ''}`}
                activeClassName="dashboard__header-item--active"
                key={label}>
                {!areaAccess() && <span className="forbidden">Brak dostępu</span>}
                <img alt={label} src={icon} />
                <p>{label}</p>
              </NavLink>
            );
          } else {
            return null;
          }
        })}
        <NewTabHeader roles={roles} editNewTab={editNewTab} createNewTab={createNewTab} getNewTab={getNewTab} newTab={newTab} level={level} />
      </div>
      <NotificationHeader
        notificationsNotRead={notificationsNotRead}
        getNotificationsNotRead={getNotificationsNotRead}
        readNotification={readNotification}
        lastNotifications={lastNotifications}
        getLastNotifications={getLastNotifications}
      />
      <div className="dashboard__header-logo">
        <img src={logo} alt={'Wiso Group logo'} />
      </div>
    </div>
  );
};

export default DashboardHeader;
