import * as React from 'react';
import { MainType } from './Container';
import { InfoBox, Preloader } from 'Shared/components';
import { ROUTES, SUBMENU, REQUEST_STATUS, ROLES, SVG_TYPE, AREA_TYPE } from 'Shared/enums';
import { getArea, checkAreaAccess } from 'Shared/utils';
import { Redirect } from 'react-router-dom';

type P = {
  history?: any;
  match?: any;
};

class Main extends React.Component<MainType & P, any> {
  async componentDidMount() {
    await this.props.actions.getUsersInCompanyOptions();
    const { id } = this.props.match.params;
    const response = await this.props.actions.getCompendium(id);
    response.rating.length > 0 && (await this.props.actions.getCompanyEmployee(response?.rating[0]?.company_id));
  }

  componentDidUpdate(
    prevProps: Readonly<
      {
        compendium: any;
        compendiumStatus: REQUEST_STATUS;
        usersInCompanyOptions: any;
        authorizedUser: any;
        compendiumsActiveCompany: string;
        compendiumActiveRating: any;
        companyEmployee: any;
      } & {
        actions: {
          addCompendiumEvidence: (data: any) => void;
          removeFiles: (uuid: any) => void;
          deleteRatingCompatibilities: (uuid: any) => void;
          getCompendium: (id: number) => any;
          getUsersInCompanyOptions: () => void;
          addRatingCompatibility: (data: any, showAlert?: boolean) => void;
          setActiveRating: (data: any) => void;
          downloadFile: (uuid: string) => void;
          addEvidence: (data: any, uuid: any) => void;
          addTask: (data: any) => void;
          editTask: (data: any, uuid: any) => void;
          removeTask: (uuid: string) => void;
          addEvidenceFile: (data: any, file: any) => void;
          getCompanyEmployee: (id: any) => void;
        };
      } & P
    >,
    prevState: Readonly<any>,
    snapshot?: any,
  ): void {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.actions.getCompendium(this.props.match.params.id);
    }
  }

  render() {
    const {
      uid,
      term,
      regulatory_organ,
      department,
      executive_act,
      legal_basis,
      pdfs,
      legal_requirements,
      organization_responsibilities,
      deadline,
      organization_type,
      released_from_responsibility,
      potential_sanctions,
      norm_points,
      audit_questions,
      expected_proofs,
      change_description,
      // change_date,
      created_at,
    } = this.props.compendium || {};
    const {
      compendiumStatus,
      compendiumsActiveCompany,
      usersInCompanyOptions,
      compendiumActiveRating,
      authorizedUser: { roles, level, uuid },
      authorizedUser,
      companyEmployee,
      actions: {
        addRatingCompatibility,
        setActiveRating,
        addCompendiumEvidence,
        removeFiles,
        deleteRatingCompatibilities,
        downloadFile,
        addTask,
        editTask,
        addEvidence,
        removeTask,
        addEvidenceFile,
      },
    } = this.props;
    const area = getArea();
    const { getCompanyEmployee } = this.props.actions;

    const isAreaAccess = checkAreaAccess(authorizedUser);
    const isCustomerLogged = roles.some(({ role }) => role === ROLES.CUSTOMER);

    const backToPathname = area === AREA_TYPE.BHP ? ROUTES.BHP : area === AREA_TYPE.ENVIRONMENT ? ROUTES.ENVIRONMENT_PROTECTION : ROUTES.ENERGIA;

    let legal_basis_pdf;
    let executive_act_pdf;
    if (pdfs) {
      legal_basis_pdf = pdfs.find((item) => item.type === 'legal_basis');
      executive_act_pdf = pdfs.find((item) => item.type === 'executive_act');
    }

    if (compendiumStatus === REQUEST_STATUS.INIT || compendiumStatus === REQUEST_STATUS.IDLE) {
      return <Preloader inner knowledgeBase />;
    } else if (isAreaAccess) {
      return (
        <div id="top" className={`compendiumSingle compendiumSingle--${area}`}>
          <div className="compendiumSingle__header">
            <div className="compendiumSingle__header--backButton">
              <button
                className="btn btn--transparent"
                onClick={() => this.props.history.push({ pathname: backToPathname, state: { activeTab: SUBMENU.COMPENDIUMS } })}>
                {'<'} Wróć
              </button>
            </div>
            <div className="compendiumSingle__header--title">
              <h1>
                {term} - {uid}
              </h1>
              <h3>{department}</h3>
            </div>
          </div>
          <div className="compendiumSingle__inner">
            <div className="compendiumSingle__content">
              <InfoBox
                title={'GŁÓWNA PODSTAWA PRAWNA'}
                content={legal_basis}
                badgeText={legal_basis_pdf ? 'PDF' : ''}
                badgeRedirect={`${process.env.REACT_APP_API_URL}pdfs/${legal_basis_pdf?.uuid}`}
                svgType={SVG_TYPE.SOURCE}
                contentBolded
              />
              <InfoBox
                title={'AKT WYKONAWCZY'}
                content={executive_act}
                badgeText={executive_act_pdf ? 'PDF' : ''}
                badgeRedirect={`${process.env.REACT_APP_API_URL}pdfs/${executive_act_pdf?.uuid}`}
                svgType={SVG_TYPE.SOURCE}
              />
              <InfoBox title={'WYMAGANIE PRAWNE'} content={legal_requirements} />
              <InfoBox title={'OBOWIĄZEK ORGANIZACJI'} content={organization_responsibilities} starred />
              <InfoBox title={'ORGAN NADZORUJĄCY'} content={regulatory_organ} starred />
              <InfoBox title={'TERMIN GRANICZNY'} content={deadline} starred />
              <InfoBox title={'TYP ORGANIZACJI'} content={organization_type} />
              <InfoBox title={'ZWOLNIENI Z OBOWIĄZKU'} content={released_from_responsibility} />
              <InfoBox title={'POTENCJALNE SANKCJE'} content={potential_sanctions} starred />
              <InfoBox title={'PUNKT NORMY'} content={norm_points} starred />
              <InfoBox title={'PYTANIE AUDITORSKIE'} content={audit_questions} starred />
              <InfoBox title={'OCZEKIWANY DOWÓD'} content={expected_proofs} starred />
              <InfoBox title={'OPIS ZMIANY'} content={change_description} starred />
              {/* <InfoBox title={'DATA ZMIAN'} content={change_date} starred isDate /> */}
              <InfoBox title={'DATA DODANIA'} content={created_at} isDate />
              {/* <TaskBox usersInCompanyOptions={usersInCompanyOptions} isCustomerLogged={isCustomerLogged} customerLevel={level} /> */}
              {(compendiumsActiveCompany || isCustomerLogged) && (
                <InfoBox
                  getCompanyEmployee={getCompanyEmployee}
                  companyEmployee={companyEmployee}
                  compendium={this.props.compendium}
                  addRatingCompatibility={addRatingCompatibility}
                  compendiumsActiveCompany={compendiumsActiveCompany}
                  compendiumActiveRating={compendiumActiveRating}
                  setActiveRating={setActiveRating}
                  title={'OCENA ZGODNOŚCI'}
                  content={'ocena zgodnosci'}
                  customerLevel={level}
                  isCustomerLogged={isCustomerLogged}
                  rating
                  usersInCompanyOptions={usersInCompanyOptions}
                  loggedUserUuid={uuid}
                  pdfs={pdfs}
                  addCompendiumEvidence={addCompendiumEvidence}
                  removeFiles={removeFiles}
                  deleteRatingCompatibilities={deleteRatingCompatibilities}
                  downloadFile={downloadFile}
                  authorizedUser={authorizedUser}
                  addTask={addTask}
                  editTask={editTask}
                  addEvidence={addEvidence}
                  removeTask={removeTask}
                  addEvidenceFile={addEvidenceFile}
                />
              )}
            </div>
          </div>
          <div className="compendiumSingle__header">
            <div className="compendiumSingle__header--backButton">
              <button
                className="btn btn--transparent"
                onClick={() => this.props.history.push({ pathname: backToPathname, state: { activeTab: SUBMENU.COMPENDIUMS } })}>
                {'<'} Wróć
              </button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <Redirect
        to={{
          pathname: `/dashboard/${area}`,
        }}
      />
    );
    // else return null;
  }
}

export default Main;
