import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getNotificationStore = (state: RootState) => state.notificationStore;
const getNotifications = createSelector(getNotificationStore, (state) => state.notifications.data || []);
const getLastNotifications = createSelector(getNotificationStore, (state) => state.lastNotifications.data || []);
const getNotificationsNotRead = createSelector(getNotificationStore, (state) => state.notificationsNotRead.data || false);

export {
  getNotifications, getLastNotifications, getNotificationsNotRead
};
