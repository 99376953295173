import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_COMPENDIUM_RATINGS_COUNTER_INIT,
});

const success = (data: any) => ({
  type: BHP.GET_COMPENDIUM_RATINGS_COUNTER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: BHP.GET_COMPENDIUM_RATINGS_COUNTER_FAILURE,
});

const getCompendiumRatingsCounter = (
  getDetailed: boolean,
  company_uuid: string = '',
  isMyRequirements = false,
  isMyCompanyEntries = false,
  areNewlyAdded = false,
) => async (dispatch) => {

  dispatch(init());
  try {
    const { data } = await Connector.getCompendiumRatingsCounter(getArea(), getDetailed, company_uuid, isMyRequirements, isMyCompanyEntries, areNewlyAdded);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getCompendiumRatingsCounter;
