import * as React from 'react';
import { SUBMENU_ARR, SUBMENU_MAP, SUBMENU } from './../../../consts';

const MobileSubmenu = ({ active, onActiveChange }: P) => {
  const [isModalOpen, setModalVisibility] = React.useState(false);
  const onItemClick = (submenuEl) => {
    setModalVisibility(false);
    onActiveChange(submenuEl);
  };

  return (
    <div className="mobileSubmenu">
      <div className="mobileSubmenu__current" onClick={() => setModalVisibility(!isModalOpen)}>
        {SUBMENU_MAP.get(active)}
      </div>
      {isModalOpen && (
        <div className="mobileSubmenu__list">
          {SUBMENU_ARR.map((submenuEl) => (
            <div className={`mobileSubmenu__item ${active === submenuEl ? 'active' : ''}`} key={submenuEl} onClick={() => onItemClick(submenuEl)}>
              {SUBMENU_MAP.get(submenuEl)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

type P = {
  active: SUBMENU;
  onActiveChange: (submenuItem: SUBMENU) => void;
};
export default MobileSubmenu;
