import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
// import { history } from 'Store/Reducers/RootReducer';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.ADD_EVIDENCE_INIT,
});

const success = (data) => ({
  type: BHP.ADD_EVIDENCE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: BHP.ADD_EVIDENCE_FAILURE,
});

const addEvidenceFile = (data, taskFile) => async (dispatch) => {
  dispatch(init());
  const file = new FormData();
  file.append('file', taskFile);
  try {
    dispatch(Actions.showLoader());
    const response = await Connector.addEvidenceFile(data, file);
    dispatch(success(response.data));
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    return err;
  }
};

export default addEvidenceFile;
