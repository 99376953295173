import * as React from 'react';
import Logo from 'Assets/Img/singula-logo.svg';

type P = {
  inner?: boolean;
  knowledgeBase?: boolean;
};
/* eslint-disable */
const Preloader = ({ inner = false, knowledgeBase = false }) =>
  inner ? (
    <div className={`preloader__container preloader--inner ${knowledgeBase && 'preloader__container--knowledge'}`}>
      <div className="preloader__container-inner" />
      <div className="preloader__container-inner--lg" />
      <div className="preloader__container-inner--md" />
      <div className="preloader__container-inner--sm" />
    </div>
  ) : (
    <div className="preloader">
      <div className="preloader__logo">
        <img src={Logo} alt="" />
      </div>
      <div className="preloader__container">
        <div className="preloader__container-inner" />
        <div className="preloader__container-inner--lg" />
        <div className="preloader__container-inner--md" />
        <div className="preloader__container-inner--sm" />
      </div>
    </div>
  );
/* eslint-enable */
export default Preloader;
