import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from 'Store/Reducers/RootReducer';
import Settings from './Main';
import { Actions, Selectors } from 'Modules/UserModule';

type ConnectedP = {
  settings: any;
};

type DispatchedP = {
  actions: {
    getSettings: () => void;
    editSettings: (data) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getSettings: Actions.getSettings,
      editSettings: Actions.editSettings,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  settings: Selectors.getSettings(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
