import { connect } from 'react-redux';
import * as AuthModule from 'Modules/AuthModule';
import { RootState } from 'Store/Reducers/RootReducer';
import Login from './Main';

type ConnectedP = {
  authorizedUser: AuthModule.Types.AuthorizedUserData;
};

type DispatchedP = {
  loginUser: (email: string, password: string) => void;
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps: DispatchedP = {
  loginUser: (email: string, password: string) => AuthModule.Actions.login(email, password),
};

const mapStateToProps = (state: RootState): ConnectedP => ({
  authorizedUser: AuthModule.Selectors.authorizedUser(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
