import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField, ColorPicker } from 'Shared/components/forms';
import { Button } from 'Shared/components';

type P = {
  isLoading: boolean;
  onClose: () => void;
  initialValues: any;
  onSubmit: (formValues: any) => any;
  isCustomerAreaManager: boolean;
};

const InstitutionsForm = ({ onClose, initialValues, onSubmit, isLoading, isCustomerAreaManager }: P) => (
  <div className="editModal editModal--institutions">
    <h1 className="font-lg bold-700">Edytuj instytucje</h1>
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="editModal--form">
          <Field type="text" name="name" label="Interesariusz" component={RegularField} errors={errors} />
          <Field type="text" name="short" label="Skrót" component={RegularField} errors={errors} />
          <Field type="textarea" name="role" label="Rola" component={RegularField} errors={errors} />
          <Field type="textarea" name="tasks" label="Zadania" component={RegularField} errors={errors} />
          {isCustomerAreaManager ? (
            <>
              <Field name="color" type="text" label="Kolor wpisu" component={ColorPicker} errors={errors} />
            </>
          ) : null}
          <div className="editModal__actions">
            <Button label="Anuluj" classNames="btn btn--transparent btn--cancel" onClick={onClose} />
            <Button label="Zapisz zmiany" classNames="btn btn--navy-blue" type="submit" isLoading={isLoading} />
          </div>
        </form>
      )}
    </Formik>
  </div>
);

export default InstitutionsForm;
