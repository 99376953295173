import { Field, Formik } from 'formik';
import * as React from 'react';
import { Button } from 'Shared/components';
import { USER_LEVELS } from 'Shared/enums';
import { RegularField, ColorPicker } from 'Shared/components/forms';
import LabelForm from '../labelForm';
import * as Yup from 'yup';

type P = {
  onSubmit: (formValues: any) => any;
  isCustomerAreaManager: boolean;
  userLevel: string;
};

const getCompendiumSchema = (isBasisSource, isActSource) =>
  Yup.object().shape({
    department: Yup.string().required('Pole wymagane'),
    term: Yup.string().required('Pole wymagane'),
    legal_basis: Yup.string().required('Pole wymagane'),
    legal_basis_source: isBasisSource ? Yup.string().required('Pole wymagane') : null,
    executive_act: Yup.string().required('Pole wymagane'),
    executive_act_source: isActSource ? Yup.string().required('Pole wymagane') : null,
    legal_requirements: Yup.string().required('Pole wymagane'),
    organization_responsibilities: Yup.string().required('Pole wymagane'),
    regulatory_organ: Yup.string().required('Pole wymagane'),
    deadline: Yup.string().required('Pole wymagane'),
    organization_type: Yup.string().required('Pole wymagane'),
    released_from_responsibility: Yup.string().required('Pole wymagane'),
    potential_sanctions: Yup.string().required('Pole wymagane'),
    norm_points: Yup.string().required('Pole wymagane'),
    audit_questions: Yup.string().required('Pole wymagane'),
    expected_proofs: Yup.string().required('Pole wymagane'),
    change_description: Yup.string().required('Pole wymagane'),
    // change_date: Yup.date().required('Pole wymagane'),
    visible_for_demo: Yup.boolean().required('Pole wymagane'),
  });

const AddCompendiumForm = ({ onSubmit, isCustomerAreaManager, userLevel }: P) => {
  const initialValues = {
    department: '',
    term: '',
    legal_basis: '',
    legal_basis_source: '',
    executive_act: '',
    executive_act_source: '',
    legal_requirements: '',
    organization_responsibilities: '',
    regulatory_organ: '',
    deadline: '',
    organization_type: '',
    released_from_responsibility: '',
    potential_sanctions: '',
    norm_points: '',
    audit_questions: '',
    expected_proofs: '',
    change_description: '',
    // change_date: new Date(),
    visible_for_demo: userLevel === USER_LEVELS.DEMO ? true : false,
  };

  const [isBasisSource, setBasisSource] = React.useState(false);
  const [isActSource, setActSource] = React.useState(false);
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getCompendiumSchema(isBasisSource, isActSource)}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="areaManagement__form">
          <LabelForm value="Dział" />
          <Field placeholder="dodaj tekst..." type="textarea" name="department" component={RegularField} errors={errors} />
          <LabelForm value="Pojęcie" />
          <Field placeholder="dodaj tekst..." type="textarea" name="term" component={RegularField} errors={errors} />
          <LabelForm value="Główna podstawa prawna" source onSourceClick={() => setBasisSource(!isBasisSource)} />
          {isBasisSource && <Field type="file" name="legal_basis_source" placeholder="dodaj pdf..." component={RegularField} errors={errors} />}
          <Field placeholder="dodaj tekst..." type="textarea" name="legal_basis" component={RegularField} errors={errors} />
          <LabelForm value="Akt wykonawczy" source onSourceClick={() => setActSource(!isActSource)} />
          {isActSource && <Field type="file" name="executive_act_source" placeholder="dodaj pdf..." component={RegularField} errors={errors} />}
          <Field placeholder="dodaj tekst..." type="textarea" name="executive_act" component={RegularField} errors={errors} />
          <LabelForm value="Wymaganie prawne" />
          <Field placeholder="dodaj tekst..." type="textarea" name="legal_requirements" component={RegularField} errors={errors} />
          <LabelForm value="Obowiązek organizacji" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="organization_responsibilities" component={RegularField} errors={errors} />
          <LabelForm value="Organ nadzorujący" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="regulatory_organ" component={RegularField} errors={errors} />
          <LabelForm value="Termin graniczny" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="deadline" component={RegularField} errors={errors} />
          <LabelForm value="Typ organizacji" />
          <Field placeholder="dodaj tekst..." type="textarea" name="organization_type" component={RegularField} errors={errors} />
          <LabelForm value="Zwolnieni z obowiązku" />
          <Field placeholder="dodaj tekst..." type="textarea" name="released_from_responsibility" component={RegularField} errors={errors} />
          <LabelForm value="Potencjalne sankcje" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="potential_sanctions" component={RegularField} errors={errors} />
          <LabelForm value="Punkt normy" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="norm_points" component={RegularField} errors={errors} />
          <LabelForm value="Pytanie auditorskie" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="audit_questions" component={RegularField} errors={errors} />
          <LabelForm value="Oczekiwany dowód" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="expected_proofs" component={RegularField} errors={errors} />
          <LabelForm value="Opis zmiany" starred />
          <Field placeholder="dodaj tekst..." type="textarea" name="change_description" component={RegularField} errors={errors} />
          {/* <LabelForm value="Data zmian" starred />
          <Field name="change_date" type="text" component={DatePicker} errors={errors} /> */}
          {isCustomerAreaManager ? (
            <>
              <LabelForm value="Kolor wpisu" />
              <Field name="color" type="text" component={ColorPicker} errors={errors} />
            </>
          ) : (
            <>
              <LabelForm value="Widoczność" />
              <Field name="visible_for_demo" type="checkbox" label="Widoczny dla konta demo" component={RegularField} errors={errors} />
            </>
          )}
          <div className="areaManagement__form--save">
            <Button label="Dodaj wpis +" classNames="btn btn--navy-blue" type="submit" />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddCompendiumForm;
