import * as Types from 'Shared/models/NotificationModel';
import { Notification } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.NotificationStore;

export const initialState: State = {
  notifications: {
    data: {meta: {}, data: []},
    status: REQUEST_STATUS.IDLE,
  },  
  lastNotifications: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  notificationsNotRead: false,
};

export function notificationReducer(state: State = initialState, action) {
  switch (action.type) {
    case Notification.GET_NOTIFICATIONS_INIT: {
      return {
        ...state,
        notifications: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Notification.GET_NOTIFICATIONS_SUCCESS: {
      const init = action.payload.init
      if(init) {
        return {
          ...state,
          notifications: {
            data: {
              ...action.payload.notifications,
              data: [
                ...action.payload.notifications.data,
              ]
            },
            status: REQUEST_STATUS.SUCCESS,
          },
        };
      } else {
        return {
          ...state,
          notifications: {
            data: {
              ...action.payload.notifications,
              data: [
                ...state.notifications.data.data,  
                ...action.payload.notifications.data,
              ]
            },
            status: REQUEST_STATUS.SUCCESS,
          },
        };
      }
    }
    
    case Notification.GET_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        notifications: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Notification.GET_LAST_NOTIFICATIONS_INIT: {
      return {
        ...state,
        lastNotifications: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Notification.GET_LAST_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        lastNotifications: {
          data: action.payload.lastNotifications,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Notification.GET_LAST_NOTIFICATIONS_FAILURE: {
      return {
        ...state,
        lastNotifications: {
          data: [],
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case Notification.GET_NOTIFICATIONS_NOT_READ_INIT: {
      return {
        ...state,
        notificationsNotRead: {
          data: [],
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case Notification.GET_NOTIFICATIONS_NOT_READ_SUCCESS: {
      return {
        ...state,
        notificationsNotRead: {
          data: action.payload.data,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case Notification.READ_NOTIFICATION_SUCCESS: {
      const {data: {uuid, isReadAll} } = action.payload;
      if (isReadAll) {
        return {
          ...state,
          lastNotifications: {
            ...state.lastNotifications,
            data: state.lastNotifications.data.map(notification => ({
              ...notification,
              is_read: 'true'
            })),
          },
          notifications: {
            ...state.notifications,
            data: {
              ...state.notifications.data,
              data: state.notifications.data && state.notifications.data.data.map(notification => ({
                ...notification,
                is_read: 'true'
              })),
            },
          },
        };
      } else {
        return {
          ...state,
          lastNotifications: {
            ...state.lastNotifications,
            data: state.lastNotifications.data.map(notification =>
              notification.uuid === uuid ? { ...notification, is_read: 'true' } : notification
            ),
          },
          notifications: {
            ...state.notifications,
            data: {
              ...state.notifications.data,
              data: state.notifications.data && state.notifications.data.data.map(notification =>
                notification.uuid === uuid ? { ...notification, is_read: 'true' } : notification
              ),
            },
          },
        };
      }
    }
    default: {
      return state;
    }
  }
}
