import { Connector } from 'Modules/NewTabModule';
import { NewTab } from '../EnumTypes/ActionEnumTypes';


const success = (data) => ({
  type: NewTab.GET_NEW_TAB_SUCCESS,
  payload: {
    data
  },
});

const failure = () => ({
  type: NewTab.GET_NEW_TAB_FAILURE,
});

const getNewTab = () => {
  return async (dispatch) => {
    try {
      const { data } = await Connector.getNewTab();
      dispatch(success(data));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default getNewTab;
