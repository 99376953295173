import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = (uid: number) => ({
  type: BHP.EDIT_COMPENDIUM_INIT,
  payload: {
    uid,
  },
});

const success = (compendium) => ({
  type: BHP.EDIT_COMPENDIUM_SUCCESS,
  payload: {
    compendium,
  },
});

const failure = () => ({
  type: BHP.EDIT_COMPENDIUM_FAILURE,
});

const editCompendium = (compendium: any) => async (dispatch) => {
  dispatch(init(compendium.uid));
  try {
    const { legal_basis_source1,legal_basis_source2,legal_basis_source3, executive_act_source1, executive_act_source2, executive_act_source3 } = compendium;
    delete compendium.legal_basis_source1;
    delete compendium.legal_basis_source2;
    delete compendium.legal_basis_source3;
    delete compendium.legal_basis_file_name;
    delete compendium.executive_act_source1;
    delete compendium.executive_act_source2;
    delete compendium.executive_act_source3;
    delete compendium.executive_act_file_name;
    delete compendium.pdfs;

    const { uid } = compendium;

    if (legal_basis_source1) {
      const pdfForm = new FormData();
      pdfForm.append('file', legal_basis_source1);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'legal_basis', file_name: legal_basis_source1.name, file: pdfForm });
    }
    if (legal_basis_source2) {
      const pdfForm = new FormData();
      pdfForm.append('file', legal_basis_source2);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'legal_basis', file_name: legal_basis_source2.name, file: pdfForm });
    }
    if (legal_basis_source3) {
      const pdfForm = new FormData();
      pdfForm.append('file', legal_basis_source3);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'legal_basis', file_name: legal_basis_source3.name, file: pdfForm });
    }
    if (executive_act_source1) {
      const pdfForm = new FormData();
      pdfForm.append('file', executive_act_source1);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'executive_act', file_name: executive_act_source1.name, file: pdfForm });
    }
    if (executive_act_source2) {
      const pdfForm = new FormData();
      pdfForm.append('file', executive_act_source2);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'executive_act', file_name: executive_act_source2.name, file: pdfForm });
    }
    if (executive_act_source3) {
      const pdfForm = new FormData();
      pdfForm.append('file', executive_act_source3);
      await Connector.addPdfToCompendium({ compendium_uid: uid, type: 'executive_act', file_name: executive_act_source3.name, file: pdfForm });
    }
    const {data} = await Connector.editCompendium({ ...compendium, uid: undefined, rating: undefined }, uid);
    dispatch(success(data));
    toast.success('Pomyślnie edytowano kompendium');
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się zapisać zmian');

    return err;
  }
};

export default editCompendium;
