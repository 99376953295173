import * as React from 'react';
import { SVG, Pagination } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import moment from 'moment';

const PostTagMap = new Map<string, { name: string; color: string }>([
  ['energy', { name: 'EN', color: 'orange' }],
  ['environment', { name: 'OŚ', color: 'green' }],
  ['bhp', { name: 'BHP', color: 'blue' }],
  ['others', { name: 'INNE', color: 'gray' }],
]);

const CurrentNews = ({ topicalities, onEditRowClick, isTopicalitiesEditable, onDeleteRowClick, getTopicalities }: P) => (
  <div className="currentNews">
    {topicalities.data.map((post, idx) => (
      <Post
        post={post}
        key={idx}
        onEditRowClick={onEditRowClick}
        onDeleteRowClick={onDeleteRowClick}
        isTopicalitiesEditable={isTopicalitiesEditable}
      />
    ))}
    <Pagination pages={topicalities.lastPage} page={topicalities.page} getFunction={getTopicalities} />
  </div>
);

type P = {
  topicalities: {
    data: Array<any>;
    lastPage: number;
    page: number;
  };
  onEditRowClick: (topicalityData) => void;
  onDeleteRowClick: (topicalityData) => void;
  getTopicalities: (page?) => void;
  isTopicalitiesEditable: boolean;
};

const Post = ({ post, isTopicalitiesEditable, onEditRowClick, onDeleteRowClick }: any) => {
  const tag = PostTagMap.get(post.category);
  return (
    <div className="currentPost">
      <div className="currentPost__title">
        <a href={post.link} target="_blank" rel="noopener noreferrer">
          <div className="currentPost__tag_wrapper">
            <div className={`currentPost__tag currentPost__tag--${tag.color}`}>{tag.name}</div>
          </div>
          <div className="currentPost__title">{post.content}</div>
        </a>
        <p>{moment(post.created_at).format('YYYY-MM-DD')}</p>
      </div>
      {isTopicalitiesEditable && (
        <div className="currentPost__actions">
          <button className="currentPost__editPost" onClick={() => onEditRowClick(post)}>
            <SVG type={SVG_TYPE.EDIT} />
          </button>
          <div
            className="currentPost__deletePost"
            onClick={() => {
              onDeleteRowClick(post);
            }}>
            <SVG type={SVG_TYPE.DELETE} />
          </div>
        </div>
      )}
    </div>
  );
};
export default CurrentNews;
