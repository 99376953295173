import { ViewManagementStore } from 'Shared/models/ViewManagementModel';
import { ViewManagement } from '../Actions/EnumTypes/ActionEnumTypes';
// import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

export type State = ViewManagementStore;

export const initialState: State = {
  loaderVisible: false,
  prevPath: '',
  isMenuExpanded: window.innerWidth > 1200,
  isAddReminderView: false,
  isCompendiumPreview: false,
  isExtendedTextVisible: false,
};

export function viewManagementReducer(state: State = initialState, action) {
  switch (action.type) {
    case ViewManagement.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
      };
    }
    case ViewManagement.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
      };
    }
    case ViewManagement.SET_PREV_PATH: {
      return {
        ...state,
        prevPath: action.payload.prevPath,
      };
    }
    case ViewManagement.EXPAND_MENU: {
      return {
        ...state,
        isMenuExpanded: !state.isMenuExpanded,
      };
    }
    case ViewManagement.EXPAND_ADD_REMINDER_VIEW: {
      return {
        ...state,
        isAddReminderView: !state.isAddReminderView,
      };
    }
    case ViewManagement.OPEN_COMPENDIUM_PREVIEW_MOBILE: {
      return {
        ...state,
        isCompendiumPreview: true,
      };
    }
    case ViewManagement.CLOSE_COMPENDIUM_PREVIEW_MOBILE: {
      return {
        ...state,
        isCompendiumPreview: false,
      };
    }
    case ViewManagement.SHOW_EXTENDED_TEXT: {
      return {
        ...state,
        isExtendedTextVisible: true,
      };
    }
    case ViewManagement.HIDE_EXTENDED_TEXT: {
      return {
        ...state,
        isExtendedTextVisible: false,
      };
    }
    default: {
      return state;
    }
  }
}
