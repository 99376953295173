import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getUserStore = (state: RootState) => state.userStore;
const getActiveCompany = createSelector(getUserStore, (state) => state.company.data || {});
const getUuid = createSelector(getActiveCompany, ({ uuid }) => uuid);
const getInitialFormData = createSelector(getActiveCompany, ({ company_name, nip, street, zip_code }) => ({
  company_name,
  nip,
  street,
  zip_code,
}));
const getChangelogs = createSelector(getUserStore, (state) => state.changelogs.data || []);
const getEditingEmployeeStatus = createSelector(getUserStore, ({ editingEmployee }) => editingEmployee);
const getEditingUserStatus = createSelector(getUserStore, ({ editingUser }) => editingUser);
const getCompaniesOptions = createSelector(getUserStore, ({ companiesOptions: { data = [] } }) => data);
const getUsersInCompanyOptions = createSelector(getUserStore, ({ usersInCompanyOptions: { data = [] } }) => data);
const getSettings = createSelector(getUserStore, (state) => state.settings.data || {});
const getCompanyEmployee = createSelector(getUserStore, (state) => state.companyEmployee || {});

export {
  getUuid,
  getInitialFormData,
  getActiveCompany,
  getChangelogs,
  getEditingEmployeeStatus,
  getEditingUserStatus,
  getCompaniesOptions,
  getUsersInCompanyOptions,
  getSettings,
  getCompanyEmployee,
};
