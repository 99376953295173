import { Connector } from 'Modules/NotificationModule';
import { Notification } from '../EnumTypes/ActionEnumTypes';


const success = (notifications: any, isNotRead, init) => ({
  type: Notification.GET_NOTIFICATIONS_SUCCESS,
  payload: {
    notifications,
    isNotRead,
    init
  },
});

const failure = () => ({
  type: Notification.GET_NOTIFICATIONS_FAILURE,
});

const getNotifications = (form) => {
  return async (dispatch) => {
    try {
      const { data } = await Connector.getNotifications(form);
      dispatch(success(data, form.isNotRead, form.init));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default getNotifications;
