import * as React from 'react';

const Letters = ({ letters, onClick, activeLetter }: any) => (
  <div className="compendiumLetters">
    {letters.map((letter) => (
      <div key={letter} className={`compendiumLetters__single ${activeLetter === letter ? 'compendiumLetters__single--active' : ''}`} onClick={() => onClick(letter)}>
        {letter}
      </div>
    ))}
  </div>
);
export default Letters;
