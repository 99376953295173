import * as React from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'moment/locale/pl';
import 'react-dates/lib/css/_datepicker.css';

type Props = {
  label?: string;
  options?: any;
  type: string;
  icon?: string;
  disabled: boolean;
  placeholder?: string;
  wrapperClassName?: string;
  rows?: number;
  field: any;
  errors: any;
  form: any;
  isMulti?: boolean;
  isPreacher?: boolean;
  onChange?: (date) => void;
  isRange?: boolean;
  showClearDate?: boolean;
};
const DatePicker = (props: Props) => {
  const { form, field, label, errors, wrapperClassName, onChange, placeholder, showClearDate = false } = props;
  // const { form, field, label, errors, wrapperClassName, onChange, isRange = true, placeholder, showClearDate = false } = props;
  const [focused, setFocus] = React.useState(false);
  const onDateChange = (val) => {
    if (val) {
      const tempDate = val.clone();
      tempDate.set('hour', 0);
      tempDate.set('minute', 0);
      form.setFieldValue(field.name, tempDate.format());
      onChange && onChange(moment(tempDate.format()).format('YYYYMMDD'));
    } else {
      form.setFieldValue(field.name, val);
      onChange(val);
    }
  };
  const onFocusChange = ({ focused }) => setFocus(focused);
  return (
    <div className={`form__field form__field--datepicker form__field--${wrapperClassName}`}>
      <SingleDatePicker
        id="date_input"
        focused={focused}
        displayFormat={() => 'DD/MM/YYYY'}
        date={field.value ? moment(field.value) : null}
        // selected={}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        openDirection={label ? 'up' : 'down'}
        orientation="vertical"
        verticalHeight={label ? null : 410}
        // isOutsideRange={(day) => {
        //   if (isRange) {
        //     const today = moment().subtract(1, 'days');
        //     // if (today.diff(day) > 1) return true;
        //     if (moment(day._d) <= moment(today)) return true;
        //   } else {
        //     return false;
        //   }
        // }}
        // horizontalMargin={500}
        placeholder={placeholder ? placeholder : 'Wybierz datę'}
        showClearDate={showClearDate}
      />
      {label && (
        <label className="field-label" htmlFor={field.name}>
          {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
        </label>
      )}
    </div>
  );
};

export default DatePicker;
