import * as React from 'react';
import { Formik, Field } from 'formik';
import { RegularField, ZipCode, DatePicker } from 'Shared/components/forms';

type P = {
  manageCompany: (companydata: any) => void;
  company?: any;
  submitLabel: string;
  goBack: () => void;
  currentLoginsAmount: number;
};

const ManageUserForm = ({
  manageCompany,
  currentLoginsAmount,
  company: {
    company_name,
    nip,
    street,
    zip_code,
    country,
    city,
    phone_number,
    expiration_date,
    house_number,
    flat_number,
    logins_amount,
    first_name,
    last_name,
    email,
    job,
    has_add_permission,
  } = {},
  submitLabel,
  goBack,
}: P) => {
  const initialValues = {
    company_name,
    nip,
    street,
    zip_code,
    country,
    city,
    phone_number,
    expiration_date,
    flat_number,
    house_number,
    logins_amount,
    first_name,
    last_name,
    email,
    job,
    has_add_permission,
  };
  return (
    <div className="manageUser">
      <div className="manageUser__header">
        <button className="btn btn--blank" onClick={goBack}>{`< Wróć`}</button>
        {company_name && <div className="manageUser__companyName">{company_name} </div>}
      </div>
      <div className="manageUser__content">
        <Formik initialValues={initialValues} onSubmit={manageCompany} enableReinitialize>
          {({ handleSubmit, errors, values: { logins_amount } }) => (
            <form onSubmit={handleSubmit} className="manageUser__form">
              <Field name="company_name" type="text" label="Nazwa firmy" component={RegularField} errors={errors} />
              <Field name="nip" type="number" label="NIP" component={RegularField} errors={errors} />
              <div className="manageUser__form--address">
                <Field name="street" type="text" label="Ulica" component={RegularField} errors={errors} wrapperClassName={'street'} />
                <Field name="house_number" type="text" label="Nr domu" component={RegularField} errors={errors} wrapperClassName="house_number" />
                <Field name="flat_number" type="text" label="Nr lokalu" component={RegularField} errors={errors} wrapperClassName="flat_number" />
              </div>
              <div className="manageUser__form--city_zip">
                <Field name="city" type="text" label="Miasto" component={RegularField} errors={errors} />
                <Field name="zip_code" component={ZipCode} errors={errors} />
              </div>
              <Field name="country" type="text" label="Kraj" component={RegularField} errors={errors} />
              <Field name="logins_amount" type="number" label="Ilość loginów" component={RegularField} errors={errors} />
              <Field name="expiration_date" type="text" label="Termin wygaśnięcia dostępów" component={DatePicker} errors={errors} />
              <Field name="has_add_permission" type="checkbox" label="Dodawanie własnych wpisów" component={RegularField} errors={errors} />
              <div className="manageUser__form--contactPerson">Dane osoby kontaktowej</div>
              <Field name="first_name" type="text" label="Imię" component={RegularField} errors={errors} />
              <Field name="last_name" type="text" label="Nazwisko" component={RegularField} errors={errors} />
              <Field name="email" type="text" label="Email" component={RegularField} errors={errors} />
              <Field name="phone_number" type="text" label="Numer telefonu" component={RegularField} errors={errors} />
              <Field name="job" type="text" label="Stanowisko" component={RegularField} errors={errors} />

              <button
                className={`btn btn--navy-blue ${currentLoginsAmount > logins_amount ? 'disabled' : ''}`}
                type="submit"
                disabled={currentLoginsAmount > logins_amount}>
                {submitLabel}
              </button>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ManageUserForm;
