import * as React from 'react';
import { Cells, SVG, ReactTable, Modal, TaskBox } from 'Shared/components';
import { SVG_TYPE, USER_LEVELS, MODAL_TYPE } from 'Shared/enums';
import Select from 'react-select';
import moment from 'moment';
import { DatePicker } from 'Shared/components/forms';
import { Formik, Field } from 'formik';
import greenFace from 'Assets/Img/Icons/green-face-icon.svg';
import orangeFace from 'Assets/Img/Icons/orange-face-icon.svg';
import redFace from 'Assets/Img/Icons/red-face-icon.svg';
import ReactTooltip from 'react-tooltip';
import { orderBy, debounce } from 'lodash';
import { toast } from 'react-toastify';
import { setTimeout } from 'timers';

type P = {
  title: string;
  content: string;
  contentBolded?: boolean;
  badgeText?: string;
  badgeRedirect?: string;
  starred?: boolean;
  rating?: boolean;
  mobile?: boolean;
  isDate?: boolean;
  svgType?: SVG_TYPE;
  compendium?: any;
  addRatingCompatibility?: (data: any, showAlert?: boolean) => void;
  customerLevel?: string;
  compendiumsActiveCompany?: string;
  usersInCompanyOptions?: any;
  isCustomerLogged?: boolean;
  compendiumActiveRating?: any;
  setActiveRating?: (data: any) => void;
  loggedUserUuid?: string;
  pdfs?: (data: any) => void;
  addCompendiumEvidence?: (data: any) => void;
  removeFiles?: (uuid: any) => void;
  deleteRatingCompatibilities?: (uuid: any) => void;
  downloadFile?: (uuid: any) => void;
  authorizedUser?: any;
  removeTask?: (uuid: string) => void;
  addTask?: (data: any) => void;
  editTask?: (data: any, uuid: any) => void;
  addEvidence?: (data: any, uuid: any) => void;
  addEvidenceFile?: (data: any, file: any) => void;
  companyEmployee?: any;
  getCompanyEmployee?: (id: number) => void;
};

const InfoBox = ({
  title,
  content,
  contentBolded,
  badgeText,
  badgeRedirect,
  starred,
  rating,
  mobile,
  isDate,
  svgType,
  compendium,
  addRatingCompatibility,
  customerLevel,
  compendiumsActiveCompany,
  usersInCompanyOptions,
  isCustomerLogged,
  compendiumActiveRating,
  setActiveRating,
  loggedUserUuid,
  pdfs,
  addCompendiumEvidence,
  removeFiles,
  deleteRatingCompatibilities,
  downloadFile,
  authorizedUser,
  addTask,
  editTask,
  addEvidence,
  removeTask,
  addEvidenceFile,
  companyEmployee,
  getCompanyEmployee,
}: P) => (
  <div className={`infoBox ${mobile ? 'infoBox--mobile' : ''}`}>
    {!rating ? (
      <>
        <div className="infoBox__header">
          {starred && <div className="infoBox__star">☆</div>}
          <div className="infoBox__title">{title}</div>
          {badgeText && (
            <div className="infoBox__badge">
              <a href={badgeRedirect} target="_blank" rel="noopener noreferrer">
                {badgeText}
                <SVG type={svgType} />
              </a>
            </div>
          )}
        </div>
        <div className={`infoBox__content ${contentBolded ? 'infoBox__content--bolded' : ''}`}>
          {!isDate ? content : content ? moment(content).format('DD-MM-YYYY') : 'Brak'}
        </div>
      </>
    ) : (
      <RatingBox
        getCompanyEmployee={getCompanyEmployee}
        companyEmployee={companyEmployee}
        compendium={compendium}
        addRatingCompatibility={addRatingCompatibility}
        customerLevel={customerLevel}
        compendiumsActiveCompany={compendiumsActiveCompany}
        usersInCompanyOptions={usersInCompanyOptions}
        isCustomerLogged={isCustomerLogged}
        compendiumActiveRating={compendiumActiveRating}
        setActiveRating={setActiveRating}
        addCompendiumEvidence={addCompendiumEvidence}
        loggedUserUuid={loggedUserUuid}
        pdfs={pdfs}
        removeFiles={removeFiles}
        deleteRatingCompatibilities={deleteRatingCompatibilities}
        downloadFile={downloadFile}
        authorizedUser={authorizedUser}
        addTask={addTask}
        removeTask={removeTask}
        editTask={editTask}
        addEvidence={addEvidence}
        addEvidenceFile={addEvidenceFile}
      />
    )}
  </div>
);

export default InfoBox;

let timer;
const onChange = debounce((value, addRatingCompatibility, setAccess) => {
  clearTimeout(timer);
  timer = setTimeout(async () => {
    await addRatingCompatibility(value);
    setAccess(true);
  }, 700);
}, 1000);

const RatingBox = ({
  compendium,
  compendium: { rating, id },
  addRatingCompatibility,
  customerLevel,
  usersInCompanyOptions,
  isCustomerLogged,
  compendiumActiveRating: activeRating,
  compendiumActiveRating: {
    legal_requirement_owner,
    is_work_plan_owner_employee,
    work_plan_owner,
    rating_1,
    rating_2,
    rating_3,
    legal_requirement_proof,
    work_plan_description,
    work_plan_date,
    // fulfillment_proof,
    organizational_unit,
  },
  setActiveRating,
  loggedUserUuid,
  pdfs,
  addCompendiumEvidence,
  removeFiles,
  deleteRatingCompatibilities,
  downloadFile,
  authorizedUser,
  compendiumActiveRating,
  addTask,
  removeTask,
  editTask,
  addEvidence,
  addEvidenceFile,
  companyEmployee,
  getCompanyEmployee,
}: any) => {
  const ratingCopy = orderBy(rating, ['created_at'], ['desc']);
  const [files, setFiles] = React.useState({ first: [], second: [] });
  const [periodicity, setPeriodicity] = React.useState('');
  const [isModalOpened, setIsModalOpened] = React.useState({ isOpened: false, uuid: '' });
  React.useEffect(() => {
    // if(files?.first?.length === 0 && files?.second?.length === 0) {
    const rating = compendium?.rating?.find(({ id }) => id === activeRating.id);
    const first = rating?.rating_pdfs?.filter(({ type }) => type === 'legal_requirement_proof');
    const second = rating?.rating_pdfs?.filter(({ type }) => type === 'work_plan_description');
    setFiles({ first, second });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compendium.rating, activeRating.id]);

  // React.useEffect(()=> {
  //   const rating = compendium?.rating?.find(({id})=> id === activeRating.id);
  //   // console.log(rating);

  // },[activeRating.id, compendium])

  const [legalOwner, setLegalOwner] = React.useState(null);
  // const [workOwner, setWorkOwner] = React.useState(null);
  const [activeRatingFace, setActiveRatingFace] = React.useState(null);
  // const [legalRequirementOwner, setLegalRequirementOwner] = React.useState(null);
  // const [workPlanOwner, setWorkPlanOwner] = React.useState(null);
  const [legalRequirementProof, setLegalRequirementProof] = React.useState(null);
  const [workPlanDescription, setWorkPlanDescription] = React.useState(null);
  // const [fulfillmentProof, setFulfillmentProof] = React.useState(null);
  const [organizationalUnit, setOrganizationalUnit] = React.useState(null);
  const [access, setAccess] = React.useState(true);
  const [ratings, setRatings] = React.useState({ rating1: null, rating2: null, rating3: null });
  const workOwnerElement = React.useRef(null);

  const lastUpdate = moment(activeRating.updated_at).format('YYYYMMDD');
  const today = moment().format('YYYYMMDD');
  const thisSameDate = lastUpdate === today;

  const activeRatingCreatedAt = moment(activeRating.created_at);
  const comparisonDate = moment('2024-08-27', 'YYYY-MM-DD');
  const isEarlier = activeRatingCreatedAt.isBefore(comparisonDate);

  React.useEffect(() => {
    setLegalOwner(usersInCompanyOptions.find((item) => item.value === legal_requirement_owner?.uuid) || null);
    // setWorkOwner(
    //   is_work_plan_owner_employee === null
    //     ? ''
    //     : is_work_plan_owner_employee
    //     ? usersInCompanyOptions.find((item) => item.value === work_plan_owner)
    //     : // : { label: 'Inne', value: 'other' },
    //       null,
    // );
    // setLegalRequirementOwner(legal_requirement_owner !== 'other' ? legal_requirement_owner : '');
    // setWorkPlanOwner(work_plan_owner !== 'other' ? work_plan_owner : '');
    legalRequirementProof === null && setLegalRequirementProof(legal_requirement_proof ? legal_requirement_proof : '');
    setWorkPlanDescription(work_plan_description ? work_plan_description : '');
    // setFulfillmentProof(fulfillment_proof ? fulfillment_proof : '');
    setRatings({ rating1: rating_1, rating2: rating_2, rating3: rating_3 });
    organizationalUnit === null && setOrganizationalUnit(organizational_unit ? organizational_unit : '');
  }, [
    legal_requirement_owner,
    is_work_plan_owner_employee,
    work_plan_owner,
    rating_1,
    rating_2,
    rating_3,
    legal_requirement_proof,
    work_plan_description,
    work_plan_date,
    usersInCompanyOptions,
    activeRating.periodicity,
    organizational_unit,
    organizationalUnit,
    legalRequirementProof,
  ]);

  // React.useEffect(() => {
  //   if(!organizationalUnit) {
  //     setOrganizationalUnit(activeRating.organizational_unit ? activeRating.organizational_unit : '');
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const isManaging =
    (customerLevel === USER_LEVELS.DEMO || customerLevel === USER_LEVELS.MANAGING || customerLevel === USER_LEVELS.DETAILED) && isCustomerLogged;
  // (customerLevel === USER_LEVELS.DETAILED && loggedUserUuid === legal_requirement_owner?.uuid) ||
  // (customerLevel === USER_LEVELS.DETAILED && !legal_requirement_owner)) && isCustomerLogged;

  const addRating = (date, key) => {
    addRatingCompatibility({
      ...activeRating,
      organizational_unit: organizationalUnit,
      legal_requirement_proof: legalRequirementProof,
      rating_1: ratings.rating1,
      rating_2: ratings.rating2,
      rating_3: ratings.rating3,
      row_id: id,
      [key]: date ? date : null,
    });
  };

  const activateFace = async (key) => {
    if (thisSameDate) {
      if (key === 3) {
        const fileToDelete = activeRating.rating_pdfs.filter(({ type }) => type === 'work_plan_description');
        fileToDelete.map(async ({ uuid }) => {
          await removeFiles(uuid);
        });
        setFiles({ first: files.first, second: [] });
      }
      const response = await addRatingCompatibility({
        ...activeRating,
        organizational_unit: organizationalUnit,
        legal_requirement_proof: legalRequirementProof,
        row_id: id,
        rating_1: null,
        rating_2: null,
        rating_3: null,
        [`rating_${key}`]: moment(new Date()).format('YYYYMMDD'),
        periodicity: 'twelve_months',
        work_plan_date: key === 3 ? moment().add(12, 'month').format('YYYYMMDD') : null,
      });
      response?.rating_compatibility?.company_id && getCompanyEmployee(response?.rating_compatibility?.company_id);
    } else {
      setFiles({ first: [], second: [] });
      const response = await addRatingCompatibility({
        ...activeRating,
        row_id: id,
        rating_1: null,
        rating_2: null,
        rating_3: null,
        ating_pdfs: [],
        legal_requirement_owner: activeRating.legal_requirement_owner,
        [`rating_${key}`]: moment(new Date()).format('YYYYMMDD'),
        periodicity: 'twelve_months',
        work_plan_date: key === 3 ? moment().add(12, 'month').format('YYYYMMDD') : null,
      });
      response?.rating_compatibility?.company_id && getCompanyEmployee(response?.rating_compatibility?.company_id);
    }

    setPeriodicity(null);
    setRatings({ rating1: null, rating2: null, rating3: null });
    await setRatings((prevState) => ({ ...prevState, [`rating${key}`]: moment(new Date()).format('YYYYMMDD') }));
    // handleGetUsersInCompanyOptions();
  };

  const handleSetFile = async (e, type) => {
    const file = e.target.files[0];
    if (
      file.size < 10485760 &&
      (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'application/pdf')
    ) {
      const response = await addCompendiumEvidence({
        parent_uid: activeRating.uuid,
        file_name: e.target.files[0].name,
        type,
        file,
        activeRating: activeRating,
      });
      if (type === 'legal_requirement_proof') {
        setFiles({ first: [...files.first, response], second: files.second });
      } else {
        setFiles({ first: files.first, second: [...files.second, response] });
      }
    } else {
      toast.error('plik nie spełnia wymagań');
    }
  };

  const handleRemoveFile = async (pdfUuid, type) => {
    await removeFiles(pdfUuid);
    if (type === 'first') {
      const newArrayFiles = files.first.filter(({ uuid }) => uuid !== pdfUuid);
      setFiles({ first: newArrayFiles, second: files.second });
    } else {
      const newArrayFiles = files.second.filter(({ uuid }) => uuid !== pdfUuid);
      setFiles({ first: files.first, second: newArrayFiles });
    }
  };

  const RatingFaceClick = (activeFace) => {
    if (access) {
      if (authorizedUser.level === USER_LEVELS.DETAILED) {
        if (activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid) {
          legalRequirementProof && isManaging && activateFace(activeFace);
        } else {
          toast.error('Musisz być właścicielem wymagania prawnego');
        }
      } else {
        legalRequirementProof && isManaging && activateFace(activeFace);
      }
    } else {
      toast.error('Zapisywanie danych w toku. Prosimy o chwilę cierpliwości.');
    }
  };

  const getColumns = () => [
    // {
    //   id: 'date',
    //   accessor: 'created_at',
    //   Header: 'Data oceny',
    //   Cell: ({ original }) => moment(original.created_at).format('YYYY-MM-DD'),
    // },
    {
      id: 'status',
      Header: 'Status zgodności i data oceny',
      /* eslint-disable */
      Cell: ({ original }) => <Cells.Rating original={{ rating: original }} files={files} />,
      /* eslint-enable */
      style: { whiteSpace: 'unset' },
    },
    {
      id: 'work_plan_date',
      Header: 'Termin następnej oceny',
      Cell: ({ original: { work_plan_date } }) => (work_plan_date ? moment(work_plan_date).format('YYYY-MM-DD') : ''),
    },
    {
      id: 'user',
      Header: 'Właściciel wymagania',
      Cell: ({ original: { legal_requirement_owner } }) =>
        legal_requirement_owner ? `${legal_requirement_owner.first_name} ${legal_requirement_owner.last_name}` : '',
      style: { whiteSpace: 'unset', fontWeight: '600' },
    },
    {
      id: 'rating_author',
      Header: 'Dokonał oceny',
      Cell: ({ original: { rating_author } }) => (rating_author ? `${rating_author.first_name} ${rating_author.last_name}` : ''),
      style: { whiteSpace: 'unset', fontWeight: '600' },
    },
    {
      id: 'action',
      Header: 'Akcje',
      width: 120,
      /* eslint-disable */
      Cell: ({ original }) => (
        <div className="row-action">
          <button
            className="btn btn--xs btn--transparent btn--bordered"
            onClick={() => {
              setOrganizationalUnit(null);
              setLegalRequirementProof(null);
              setActiveRating(original);
            }}>
            Zobacz
          </button>
          {customerLevel === 'managing' && rating.length > 1 && (
            <div className="row-action--close" onClick={() => setIsModalOpened({ isOpened: true, uuid: original.uuid })}>
              <SVG type={SVG_TYPE.CLOSE} />
            </div>
          )}
        </div>
      ),
      /* eslint-enable */
      style: { whiteSpace: 'unset' },
    },
  ];

  const isSetRating = Object.values(ratings).some((rating) => rating);
  const accesOwnerSelect =
    !(USER_LEVELS.DETAILED === authorizedUser.level) || (USER_LEVELS.DETAILED === authorizedUser.level && compendium.rating.length === 0);
  const accessDownloadFile =
    (thisSameDate && activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid) || USER_LEVELS.MANAGING === authorizedUser.level;
  const lastRating =
    compendium && compendium.rating && compendium.rating.length > 0 && moment(compendium.rating.slice(-1)[0].created_at).format('DD/MM/YYYY');
  const isLastRatingCorrectly = compendium && compendium.rating.length > 0 && compendium.rating.slice(-1)[0].rating_3 !== null;
  const thisDay = moment().format('DD/MM/YYYY');
  const activeDayRating = moment(activeRating.created_at).format('DD/MM/YYYY');
  // const isAllTasksDoneExceptOne = () => {
  //   const doneTasks = activeRating.tasks.filter((task) => task.status === 'done');
  //   return !(doneTasks.length === activeRating.tasks.length);
  // };
  // const isBlockedChangeRating = isAllTasksDoneExceptOne();
  const isBlockedChangeRating =
    (thisDay === lastRating && isLastRatingCorrectly) ||
    lastRating !== thisDay ||
    (activeRating && activeRating.tasks && activeRating.tasks.length === 0);
  const isActiveDayRatingIsToday = thisDay === activeDayRating;

  return (
    <>
      <Modal
        type={MODAL_TYPE.DELETE}
        opened={isModalOpened.isOpened}
        onSubmit={() => deleteRatingCompatibilities(isModalOpened.uuid)}
        onClose={() => setIsModalOpened({ isOpened: false, uuid: null })}
        big>
        <>
          <div className="modal__title">Potwierdź usunięcie oceny</div>
        </>
      </Modal>
      <div className="ratingBox__wrapper">
        <div className="ratingBox__left">
          {/* <div className="ratingBox__header">Właściciel wymagania prawnego</div> */}
          <div className="ratingBox__container">
            <div className="ratingBox__content">
              <div className="ratingBox__header">Właściciel wymagania prawnego</div>

              <div className="ratingBox__content--left">
                {isManaging && accesOwnerSelect ? (
                  <>
                    <Select
                      value={legalOwner}
                      style={{ width: '100%' }}
                      placeholder="Wybierz pracownika"
                      // isClearable
                      onChange={(value) => {
                        setLegalOwner(value);
                        let rating = {};
                        if (activeRating.rating_1) {
                          rating = { rating_1: moment().format('YYYYMMDD'), work_plan_date: null };
                        } else if (activeRating.rating_2) {
                          rating = { rating_2: moment().format('YYYYMMDD'), work_plan_date: null };
                        } else if (activeRating.rating_3) {
                          rating = { rating_3: moment().format('YYYYMMDD') };
                        }

                        addRatingCompatibility({
                          ...activeRating,
                          legal_requirement_owner: {
                            uuid: value?.value,
                          },
                          ...rating,
                          // created_at: moment().format('YYYY-MM-DD HH:MM:SS'),
                          // updated_at: moment().format('YYYY-MM-DD HH:MM:SS'),
                          // work_plan_date: moment().format('YYYYMMDD'),
                          row_id: id,
                          legal_requirement_owner_uuid: value?.value,
                        });
                      }}
                      options={usersInCompanyOptions}
                      className={`rating-select ${!accesOwnerSelect ? 'rating-select--disabled' : ''}`}
                      classNamePrefix="rating-select"
                    />
                  </>
                ) : (
                  <div className="ratingBox__raw-select">
                    <SVG type={SVG_TYPE.USER_TIE} />
                    {legal_requirement_owner ? `${legal_requirement_owner.first_name} ${legal_requirement_owner.last_name}` : 'Brak'}
                  </div>
                )}
              </div>
            </div>
            <div className="ratingBox__organizationalUnit">
              <div className="ratingBox__header">Proces/Komórka organizacyjna</div>
              <div className="ratingBox__content">
                {isManaging ? (
                  <>
                    <input
                      value={organizationalUnit}
                      disabled={!legalOwner}
                      onChange={({ currentTarget: { value } }) => {
                        setOrganizationalUnit(value);
                        setAccess(false);
                        onChange(
                          {
                            ...activeRating,
                            row_id: id,
                            organizational_unit: value,
                          },
                          addRatingCompatibility,
                          setAccess,
                        );
                      }}
                    />
                  </>
                ) : (
                  <div className="fulfillment-proof__text">{organizationalUnit ? organizationalUnit : 'Brak'}</div>
                )}
              </div>
            </div>
          </div>
          <div className="ratingBox__content">
            <div className="ratingBox__content--right">
              <div className="fulfillment-proof">
                <div className="fulfillment-proof__header">
                  <div className="fulfillment-proof--left">
                    <SVG type={SVG_TYPE.FILE} />
                    Odniesienie do dowodu(ów)
                  </div>
                  <div className={`fulfillment-proof--right`}>
                    {/* <div className={`fulfillment-proof--right ${!thisSameDate || !(activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid)  ? 'fulfillment-proof--right--disabled' : ''}`}> */}
                    {files?.first?.length > 0 ? (
                      <>
                        {[...Array(3)].map((e, i) => (
                          <>
                            {files.first[i] ? (
                              <div className="fulfillment-proof--file">
                                <div
                                  className={`fulfillment-proof--icon-file ${
                                    (activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid && thisSameDate) ||
                                    (authorizedUser.level === 'managing' && activeRating.organizational_unit !== null && thisSameDate)
                                      ? ''
                                      : 'fulfillment-proof--right--disabled'
                                  }`}
                                  onClick={() => handleRemoveFile(files.first[i].uuid, 'first')}
                                  onMouseOver={() => setActiveRatingFace(1)}
                                  data-tip="Usuń plik">
                                  <SVG type={SVG_TYPE.FILE_REMOVE} />
                                </div>
                                <div className={`fulfillment-proof--file-name ${!accessDownloadFile ? 'fulfillment-proof--right--disabled' : ''}`}>
                                  <p>{files.first[i].name || files.first[i].file_name}</p>
                                  <div onClick={async () => await downloadFile(files.first[i])}>pobierz plik</div>
                                </div>
                              </div>
                            ) : (
                              <div className="fulfillment-proof--file">
                                <div
                                  className={`fulfillment-proof--icon-file ${
                                    (activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid && thisSameDate) ||
                                    (authorizedUser.level === 'managing' && activeRating.organizational_unit !== null && thisSameDate)
                                      ? ''
                                      : 'fulfillment-proof--right--disabled'
                                  }`}
                                  onMouseOver={() => setActiveRatingFace(3)}
                                  data-tip="maksymalny rozmiar pliku wynosi 10MB, formaty obsługiwanych plików: JPG, JPEG, PDF, PNG">
                                  <SVG type={SVG_TYPE.FILE_ADD} />
                                  <input
                                    className="fulfillment-proof--input"
                                    type="file"
                                    accept=".jpg, .jpeg, .png, .pdf"
                                    onChange={(e) => handleSetFile(e, 'legal_requirement_proof')}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {[...Array(3)].map(() => (
                          <>
                            <div className="fulfillment-proof--file">
                              <div
                                className={`fulfillment-proof--icon-file ${
                                  (activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid && thisSameDate) ||
                                  (authorizedUser.level === 'managing' && activeRating.organizational_unit !== null && thisSameDate)
                                    ? ''
                                    : 'fulfillment-proof--right--disabled'
                                }`}
                                onMouseOver={() => setActiveRatingFace(3)}
                                data-tip="maksymalny rozmiar pliku wynosi 10MB, formaty obsługiwanych plików: JPG, JPEG, PDF, PNG">
                                <SVG type={SVG_TYPE.FILE_ADD} />
                                <input
                                  className="fulfillment-proof--input"
                                  type="file"
                                  accept=".jpg, .jpeg, .png, .pdf"
                                  onChange={(e) => handleSetFile(e, 'legal_requirement_proof')}
                                />
                              </div>
                            </div>
                          </>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                {isManaging ? (
                  <textarea
                    value={legalRequirementProof}
                    disabled={!legalOwner}
                    placeholder="Nie dokonano jeszcze analizy"
                    onChange={async ({ currentTarget: { value } }) => {
                      setLegalRequirementProof(value);
                      setAccess(false);
                      onChange(
                        {
                          ...activeRating,
                          row_id: id,
                          legal_requirement_proof: value,
                          level: authorizedUser?.level,
                          authorizedUserId: authorizedUser.uuid,
                        },
                        addRatingCompatibility,
                        setAccess,
                      );
                    }}
                  />
                ) : (
                  <div className="fulfillment-proof__content">{legalRequirementProof ? legalRequirementProof : 'Brak'}</div>
                )}
              </div>
            </div>
          </div>
          {isSetRating && !ratings.rating3 && isEarlier && (
            <>
              <div className="ratingBox__header">Plan działań doskonalących</div>
              <div className="ratingBox__content">
                <div className="ratingBox__content--left">
                  {isManaging ? (
                    <>
                      {/* <Select
                        value={workOwner}
                        style={{ width: '100%' }}
                        placeholder="Wybierz pracownika"
                        isClearable
                        onChange={(value) => {
                          console.log(value);
                          setWorkOwner(value);
                          setWorkPlanOwner('');
                          addRatingCompatibility({
                            ...activeRating,
                            row_id: id,
                            work_plan_owner: value ? value.value : null,
                            is_work_plan_owner_employee: value && value.value !== 'other',
                          });
                          if (workOwnerElement.current) {
                            console.log('focus');

                            workOwnerElement.current.focus();
                          }
                        }}
                        options={usersInCompanyOptions}
                        className={'rating-select'}
                        classNamePrefix="rating-select"
                      /> */}
                      <Formik initialValues={{ work_plan_date: work_plan_date }} onSubmit={() => console.log('test')} enableReinitialize>
                        {({ handleSubmit, errors, setFieldValue }) => (
                          <form onSubmit={handleSubmit} className="ratingBox__date-picker">
                            <Field
                              name="work_plan_date"
                              type="text"
                              onChange={(date) => {
                                const today = new Date();
                                const modify = moment(today).format('YYYYMMDD');
                                date === null && setFieldValue('work_plan_date', modify);
                                addRatingCompatibility({
                                  ...activeRating,
                                  row_id: id,
                                  work_plan_date: date ? date : modify,
                                });
                              }}
                              component={DatePicker}
                              isRange={false}
                              errors={errors}
                              wrapperClassName={`date-picker-blank`}
                              placeholder={'Do kiedy'}
                            />
                            {/* <Field
                              name="work_plan_date"
                              type="text"
                              onChange={(date) => data(date)}
                              component={DatePicker}
                              errors={errors}
                              isRange={false}
                              wrapperClassName={`date-picker-blank ${periodicity !== 'custom_date' ? 'date-picker-empty' : '' }`}
                            /> */}
                          </form>
                        )}
                      </Formik>
                    </>
                  ) : (
                    <div className="ratingBox__raw-select">
                      <SVG type={SVG_TYPE.RATING_CALENDAR} />
                      {work_plan_date ? moment(work_plan_date).format('YYYY-MM-DD') : 'Brak'}
                    </div>
                  )}
                </div>
                <div className="ratingBox__content--right">
                  <div className="fulfillment-proof">
                    <div className="fulfillment-proof__header">
                      <div className="fulfillment-proof--left">
                        <SVG type={SVG_TYPE.FILE} />
                        Opis planu działań
                      </div>
                      <div className={`fulfillment-proof--right`}>
                        {files?.second?.length > 0 ? (
                          <>
                            {[...Array(3)].map((e, i) => (
                              <>
                                {files.second[i] ? (
                                  <div className="fulfillment-proof--file">
                                    <div
                                      className={`fulfillment-proof--icon-file ${
                                        !thisSameDate || !(activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid)
                                          ? 'fulfillment-proof--right--disabled'
                                          : ''
                                      }`}
                                      onClick={() => handleRemoveFile(files.second[i].uuid, 'second')}
                                      onMouseOver={() => setActiveRatingFace(3)}
                                      data-tip="Usuń plik">
                                      <SVG type={SVG_TYPE.FILE_REMOVE} />
                                    </div>
                                    <div className="fulfillment-proof--file-name">
                                      <p>{files.second[i].name || files.second[i].file_name}</p>
                                      <div onClick={async () => await downloadFile(files.second[i])}>pobierz plik</div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="fulfillment-proof--file">
                                    <div
                                      className={`fulfillment-proof--icon-file ${
                                        !thisSameDate || !(activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid)
                                          ? 'fulfillment-proof--right--disabled'
                                          : ''
                                      }`}
                                      onMouseOver={() => setActiveRatingFace(3)}
                                      data-tip="maksymalny rozmiar pliku wynosi 10MB, formaty obsługiwanych plików: JPG, JPEG, PDF, PNG">
                                      <SVG type={SVG_TYPE.FILE_ADD} />
                                      <input
                                        className="fulfillment-proof--input"
                                        type="file"
                                        accept=".jpg, .jpeg, .png, .pdf"
                                        onChange={(e) => handleSetFile(e, 'work_plan_description')}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <>
                            {[...Array(3)].map(() => (
                              <>
                                <div className="fulfillment-proof--file">
                                  <div
                                    className={`fulfillment-proof--icon-file ${
                                      !thisSameDate || !(activeRating?.legal_requirement_owner?.uuid === authorizedUser.uuid)
                                        ? 'fulfillment-proof--right--disabled'
                                        : ''
                                    }`}
                                    onMouseOver={() => setActiveRatingFace(3)}
                                    data-tip="maksymalny rozmiar pliku wynosi 10MB, formaty obsługiwanych plików: JPG, JPEG, PDF, PNG">
                                    <SVG type={SVG_TYPE.FILE_ADD} />
                                    <input
                                      className="fulfillment-proof--input"
                                      type="file"
                                      accept=".jpg, .jpeg, .png, .pdf"
                                      onChange={(e) => handleSetFile(e, 'work_plan_description')}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                    {isManaging ? (
                      <textarea
                        disabled={!legalOwner}
                        ref={workOwnerElement}
                        value={workPlanDescription}
                        onChange={({ currentTarget: { value } }) => {
                          setWorkPlanDescription(value);
                          onChange(
                            {
                              ...activeRating,
                              row_id: id,
                              work_plan_description: value,
                            },
                            addRatingCompatibility,
                          );
                        }}
                      />
                    ) : (
                      <div className="fulfillment-proof__content">{workPlanDescription ? workPlanDescription : 'Brak'}</div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className={`ratingBox__right ${isBlockedChangeRating ? '' : 'ratingBox__right--disabled'}`}>
          {/* className={`ratingBox__right ${isWrite ? '' : 'ratingBox__right--disabled'} ${isBlockedChangeRating ? 'ratingBox__right--disabled' : ''}`}> */}
          <div className="ratingBox__header">Status zgodności</div>
          <ReactTooltip id="ratingBox-tooltip" place="left" effect="solid" className={`tooltip tooltip--${activeRatingFace}`} delayShow={200} />
          <div className="ratingBox__content">
            <RatingFace
              access={access}
              rating={ratings.rating3}
              isManaging={isManaging}
              addRatingCompatibility={addRatingCompatibility}
              activeRating={activeRating}
              compendiumId={id}
              faceIcon={greenFace}
              faceLabel="Zgodność"
              dataTip="Przeprowadzona analiza zebranych dowodów wskazuje, że organizacja jest zgodna z wymaganiem prawnym."
              onChange={(date) => addRating(date, 'rating_3')}
              onClick={() => RatingFaceClick(3)}
              onMouseOver={() => setActiveRatingFace(3)}
              legalRequirementProof={legalRequirementProof}
              periodicity={periodicity}
              setPeriodicity={setPeriodicity}
              id={id}
              authorizedUser={authorizedUser}
              custom
            />
            <RatingFace
              rating={ratings.rating2}
              isManaging={isManaging}
              addRatingCompatibility={addRatingCompatibility}
              activeRating={activeRating}
              compendiumId={id}
              faceIcon={orangeFace}
              faceLabel="Częsciowa zgodność"
              dataTip="Przeprowadzona analiza zebranych dowodów wskazuje, że organizacja jest w przeważającym obszarze zgodna z wymaganiem prawnym, a uzyskanie zgodności wymaga drobnych działań doskonalących."
              onChange={(date) => addRating(date, 'rating_2')}
              onClick={() => RatingFaceClick(2)}
              onMouseOver={() => setActiveRatingFace(2)}
              legalRequirementProof={legalRequirementProof}
            />
            <RatingFace
              rating={ratings.rating1}
              isManaging={isManaging}
              addRatingCompatibility={addRatingCompatibility}
              activeRating={activeRating}
              compendiumId={id}
              faceIcon={redFace}
              faceLabel="Brak zgodności"
              dataTip="Przeprowadzona analiza wymagania prawnego wskazuje, że organizacja nie posiada wystarczających dowodów na wykazanie zgodności."
              onChange={(date) => addRating(date, 'rating_1')}
              onClick={() => RatingFaceClick(1)}
              onMouseOver={() => setActiveRatingFace(1)}
              legalRequirementProof={legalRequirementProof}
            />
          </div>
          {/* {isSetRating && !ratings.rating3 && (
            <>
              <div className="ratingBox__header">Dowód spełnienia</div>
              <div className="ratingBox__content">
                {isManaging ? (
                  <textarea
                    value={fulfillmentProof}
                    disabled={!workPlanDescription || !work_plan_date}
                    onChange={({ currentTarget: { value } }) => {
                      setFulfillmentProof(value);
                      onChange(
                        {
                          ...activeRating,
                          row_id: id,
                          fulfillment_proof: value,
                        },
                        addRatingCompatibility,
                      );
                    }}
                  />
                ) : (
                  <div className="fulfillment-proof__text">{fulfillmentProof ? fulfillmentProof : 'Brak'}</div>
                )}
              </div>
            </>
          )} */}
        </div>
      </div>
      {isSetRating && !isEarlier && (
        <TaskBox
          companyEmployee={companyEmployee}
          authorizedUser={authorizedUser}
          loggedUserUuid={loggedUserUuid}
          isActiveDayRatingIsToday={isActiveDayRatingIsToday}
          activeRating={activeRating}
          addTask={addTask}
          removeTask={removeTask}
          editTask={editTask}
          addEvidence={addEvidence}
          usersInCompanyOptions={usersInCompanyOptions}
          isCustomerLogged={isCustomerLogged}
          customerLevel={customerLevel}
          addEvidenceFile={addEvidenceFile}
        />
      )}
      {rating.length > 0 && <ReactTable columns={getColumns()} data={ratingCopy} className="bhpTable" areTrProps activeRatingId={activeRating.id} />}
    </>
  );
};

type faceP = {
  authorizedUser?: any;
  rating: any;
  isManaging: boolean;
  faceIcon: any;
  faceLabel: string;
  addRatingCompatibility: (data: any) => void;
  activeRating: any;
  compendiumId: any;
  onChange?: (date: any) => void;
  onClick: () => void;
  onMouseOver: () => void;
  dataTip: string;
  legalRequirementProof: boolean;
  periodicity?: string;
  setPeriodicity?: (data: any) => void;
  custom?: boolean;
  id?: number;
  access?: boolean;
};

const periodicityOptions = [
  {
    value: null,
    label: 'wyłączona cykliczność',
  },
  {
    value: 'one_month',
    label: 'co miesiąc',
  },
  {
    value: 'three_months',
    label: 'co 3 miesiące',
  },
  {
    value: 'six_months',
    label: 'co 6 miesięcy',
  },
  {
    value: 'twelve_months',
    label: 'co 12 miesięcy',
  },
  {
    value: 'custom_date',
    label: 'wybierz datę',
  },
];

const RatingFace = ({
  rating,
  isManaging,
  faceIcon,
  faceLabel,
  onChange,
  onClick,
  dataTip,
  onMouseOver,
  id,
  legalRequirementProof,
  periodicity,
  addRatingCompatibility,
  activeRating,
  setPeriodicity,
  custom,
  authorizedUser,
  access,
}: faceP) => {
  const defaultOptions = () => {
    const option = periodicityOptions.find(({ value }) => value === activeRating.periodicity);
    option?.value === 'custom_date' && setPeriodicity(option?.value);
    return option;
  };
  const data = async (value) => {
    setTimeout(
      () =>
        addRatingCompatibility({
          ...activeRating,
          row_id: id,
          work_plan_date: value,
        }),
      700,
    );
  };

  return (
    <>
      <div
        className={`ratingBox__face-container ${access && isManaging && legalRequirementProof ? 'ratingBox__face-container--hover' : ''}`}
        data-tip={dataTip}
        data-for="ratingBox-tooltip"
        onClick={onClick}
        onMouseOver={onMouseOver}>
        <div className={`ratingBox__face-label ${rating ? 'ratingBox__face-label--active' : ''} `}>
          <img src={faceIcon} alt="" /> {faceLabel}
        </div>
        <div className={`ratingBox__face-date ${rating ? 'ratingBox__face-date--active' : ''} `}>
          {isManaging && rating ? (
            <Formik initialValues={{ rating_date: rating }} onSubmit={() => console.log('test')} enableReinitialize>
              {({ handleSubmit, errors }) => (
                <form
                  onSubmit={handleSubmit}
                  className="ratingBox__date-picker ratingBox__date-picker--full ratingBox__date-picker--full ratingBox__date-picker--disabled">
                  <Field
                    name="rating_date"
                    type="text"
                    onChange={(date) => onChange(date)}
                    component={DatePicker}
                    errors={errors}
                    isRange={false}
                    wrapperClassName={'date-picker-blank date-picker-empty'}
                  />
                </form>
              )}
            </Formik>
          ) : (
            <>{rating && moment(rating).format('YYYY-MM-DD')}</>
          )}
        </div>
      </div>
      {custom && activeRating.rating_3 && (
        <div className="ratingBox__face-container">
          <div className="periodicity ratingBox__face-label ratingBox__face-label--active ratingBox__face-label--periodicity">
            <Select
              defaultValue={defaultOptions()}
              style={{ width: '100%', margin: '0 0 10px 0' }}
              placeholder="Cykliczność"
              onChange={({ value }) => {
                const changes = () => {
                  let howLong;
                  if (value === 'one_month') {
                    howLong = 1;
                  } else if (value === 'three_months') {
                    howLong = 3;
                  } else if (value === 'six_months') {
                    howLong = 6;
                  } else if (value === 'twelve_months') {
                    howLong = 12;
                  }
                  const endDay = moment().add(howLong, 'month');
                  const endDayFormat = endDay.format('YYYYMMDD');
                  value === null ? setPeriodicity(null) : setPeriodicity(value);
                  addRatingCompatibility({
                    ...activeRating,
                    periodicity: value,
                    work_plan_date: value === null ? null : endDayFormat,
                    row_id: id,
                  });
                };
                if (authorizedUser.level === USER_LEVELS.DETAILED) {
                  if (activeRating.legal_requirement_owner.uuid === authorizedUser.uuid) {
                    changes();
                  } else {
                    toast.error('Musisz być właścicielem wymagania prawnego');
                  }
                } else {
                  changes();
                }
              }}
              options={periodicityOptions}
              className={'rating-select '}
              wrapperClassName="ratingBox__face-label ratingBox__face-label--active"
              classNamePrefix="rating-select"
            />
          </div>
          <div className={`ratingBox__face-date ${rating ? 'ratingBox__face-date--active' : ''} `}>
            {isManaging && rating && (
              <Formik
                initialValues={{ work_plan_date: activeRating?.work_plan_date || moment().add(12, 'month') }}
                onSubmit={() => console.log('test')}
                enableReinitialize>
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit} className="ratingBox__date-picker ratingBox__date-picker--full">
                    <Field
                      name="work_plan_date"
                      type="text"
                      onChange={(date) => data(date)}
                      component={DatePicker}
                      errors={errors}
                      isRange={false}
                      wrapperClassName={`date-picker-blank ${periodicity !== 'custom_date' ? 'date-picker-empty' : ''}`}
                      // wrapperClassName={`date-picker-blank ${periodicity !== 'custom_date' ? 'date-picker-empty' : '' }`}
                    />
                  </form>
                  //   <Field
                  //   name="work_plan_date"
                  //   type="text"
                  //   onChange={(date) =>
                  //     addRatingCompatibility({
                  //       ...activeRating,
                  //       row_id: id,
                  //       work_plan_date: date ? date : null,
                  //     })
                  //   }
                  //   component={DatePicker}
                  //   isRange={false}
                  //   errors={errors}
                  //   wrapperClassName={'date-picker-blank'}
                  //   placeholder={'Do kiedy'}
                  // />
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </>
  );
};
