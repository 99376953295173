import * as React from 'react';
import { FieldProps } from 'formik';

type P = {
  label?: string;
  errors: any;
};
type S = {
  activeStarIdx: any;
  rating: any;
};

class Rating extends React.PureComponent<P & FieldProps, S> {
  constructor(props) {
    super(props);
    this.state = {
      activeStarIdx: undefined,
      rating: this.props.field.value,
    };
  }

  getRatingColor = (idx: number) => {
    if (idx === 1) {
      return 'red';
    }
    if (idx === 2) {
      return 'orange';
    }
    if (idx === 3) {
      return 'green';
    }
    if (idx === 4) {
      return 'green';
    }
    return 'blank';
  };

  setRating = (rating) => {
    const {
      field: { value, name },
      form: { setFieldValue },
    } = this.props;
    setFieldValue(name, value);
    this.setState({ rating });
  };

  render() {
    const {
      label,
      field: { value, name },
      form,
      errors,
    } = this.props;
    const { activeStarIdx } = this.state;
    const stars = [1, 2, 3, 4];
    return (
      <div className="form__field form__field--rating">
        {label && (
          <label className="field-label" htmlFor={name}>
            {label} {form.touched[name] && <span className="form__field--label-error">{errors[name] && `* ${errors[name]}`}</span>}
          </label>
        )}
        <div className="form__field--rating_value">
          <div className={`rating--stars rating--stars--${this.getRatingColor(value)} rating--stars--${this.getRatingColor(value)}`}>
            {stars.map((val) => (
              <span className={`${value >= val ? 'active-rating' : ''} ${activeStarIdx >= val ? 'active-hover' : ''} `} key={val} onMouseOver={() => this.setState({ activeStarIdx: val })} onMouseOut={() => this.setState({ activeStarIdx: undefined })} onClick={() => this.setRating(val)}>
                ☆
              </span>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Rating;
