import * as React from 'react';
import SubmenuHeader from './components/submenuHeader';
import { SUBMENU } from './consts';
import { getDeviceType } from 'Shared/utils';
import ClientsDesktop from './components/dashboardClientsDesktop';
import CompanyDesktop from './components/companyDesktop';
import { MainType } from 'Screens/users/Container';
import { ROUTES } from 'Shared/enums';

const DashboardUsers = ({ usersList, companies, actions, history, authorizedUser, editingEmployee }: MainType) => {
  const [activeSubmenu, setActiveSubmenu] = React.useState(SUBMENU.CLIENTS);
  const deviceType = getDeviceType();

  return (
    <div className="dashboardUsers">
      <SubmenuHeader
        active={activeSubmenu}
        onActiveChange={setActiveSubmenu}
        onSearch={(val) => console.log(val)}
        onAddClick={() => history.push(`${ROUTES.USERS}/dodaj`)}
      />
      {deviceType === 'DESKTOP' && (
        <>
          {activeSubmenu === SUBMENU.CLIENTS && (
            <CompanyDesktop
              history={history}
              companies={companies}
              getCompanies={actions.getCompanies}
              editCompany={actions.editCompany}
              deleteCompany={actions.deleteCompany}
            />
          )}
          {activeSubmenu === SUBMENU.WISO_EMPLOYEES && (
            <ClientsDesktop
              usersList={usersList}
              getUsers={actions.getUsers}
              addUser={actions.addUser}
              editEmployee={actions.editEmployee}
              deleteUser={actions.deleteUser}
              authorizedUser={authorizedUser}
              editingEmployee={editingEmployee}
            />
          )}
        </>
      )}
    </div>
  );
};
export default DashboardUsers;
