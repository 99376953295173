import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.COPY_COMPENDIUM_INIT,
});

const success = () => ({
  type: BHP.COPY_COMPENDIUM_SUCCESS,
});

const failure = () => ({
  type: BHP.COPY_COMPENDIUM_FAILURE,
});

const copyCompendium = (uid) => async (dispatch) => {
  dispatch(init());
  try {
    // dispatch(Actions.showLoader());
    const response = await Connector.copyCompendium(uid);
    dispatch(success());
    toast.success('Pomyślnie skopiowano kompendium');
    // dispatch(Actions.hideLoader());
    return response.data;
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    console.log(err);
    toast.error('Nie udało się skopiować kompendium');
    return err;
  }
};

export default copyCompendium;
