import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_INSTITUTIONS_INIT,
});

const success = (institutions: Array<any>) => ({
  type: BHP.GET_INSTITUTIONS_SUCCESS,
  payload: {
    institutions,
  },
});

const failure = () => ({
  type: BHP.GET_INSTITUTIONS_FAILURE,
});

const getInstitutions = (isMyCompanyEntries = false, areNewlyAdded = false) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.getInstitutions(getArea(), isMyCompanyEntries, areNewlyAdded);
    dispatch(success(data));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getInstitutions;
