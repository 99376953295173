import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { history } from 'Store/Reducers/RootReducer';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.ADD_INSTITUTION_INIT,
});

const success = () => ({
  type: BHP.ADD_INSTITUTION_SUCCESS,
});

const failure = () => ({
  type: BHP.ADD_INSTITUTION_FAILURE,
});

const addInstitution = (data, { route, path }) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    await Connector.addInstitution(data);
    dispatch(success());
    history.push({ pathname: path, state: { activeTab: route } });
    toast.success('Pomyślnie dodano wpis');
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    toast.error('Nie udało się dodać wpisu');
    return err;
  }
};

export default addInstitution;
