import * as React from 'react';
import ReactDOM from 'react-dom';
import { MODAL_TYPE } from 'Shared/enums';

type P = {
  onOpen?: () => void;
  onClose?: () => void;
  onSubmit?: () => void;
  opened?: boolean;
  children: JSX.Element;
  onOpenedChange?: (opened: boolean) => boolean;
  type: MODAL_TYPE;
  big?: boolean;
  className?: string;
};

type S = {
  isOpened: boolean;
};

class Modal extends React.Component<P, S> {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: this.props.opened || false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.opened !== this.props.opened) {
      this.setOpened(nextProps.opened);
    }
    return true;
  }

  componentDidMount() {
    this.props.onOpen && this.props.onOpen();
  }

  componentWillUnmount() {
    this.props.onClose && this.props.onClose();
  }

  setOpened = (isOpened) => this.setState({ isOpened }, () => this.props.onOpenedChange && this.props.onOpenedChange(isOpened));

  submit = () => {
    this.props.onSubmit && this.props.onSubmit();
    this.setOpened(false);
  };

  toggle = () => this.setOpened(!this.state.isOpened);

  close = () => {
    this.props.onClose && this.props.onClose();
    this.setOpened(false);
  };

  handleChildClick = (e: any) => e.stopPropagation();

  render() {
    const { isOpened } = this.state;
    const { children, type, big, className } = this.props;
    return (
      <>
        {isOpened && (
          <div className={`modal ${big ? 'modal--big' : ''}`} onClick={this.close}>
            <div className={`modal__inner modal__inner--${className ? className : ''}`} onClick={this.handleChildClick}>
              <div className="modal__content">{children}</div>
              {type !== MODAL_TYPE.FORM && (
                <div className="modal__actions">
                  <button className="btn btn--transparent btn--cancel" onClick={this.close}>
                    Anuluj
                  </button>
                  {type === MODAL_TYPE.CONFIRM ? (
                    <button className="btn btn--navy-blue" onClick={this.submit}>
                      Potwierdź
                    </button>
                  ) : (
                    <button className="btn btn--red" onClick={this.submit}>
                      Usuń
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default (props: P) => ReactDOM.createPortal(<Modal {...props} />, document.querySelector('body'));
