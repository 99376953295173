import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = () => ({
  type: BHP.DOWNLOAD_FILE_INIT,
});

const success = () => ({
  type: BHP.DOWNLOAD_FILE_SUCCESS,
});

const failure = () => ({
  type: BHP.DOWNLOAD_FILE_FAILURE,
});

const downloadFile = (object) => async (dispatch) => {
  dispatch(init());
  try {
    await Connector.downloadFile(object.uuid).then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.download = object.file_name;
      link.href = downloadUrl;
      document.body.appendChild(link);
      link.click();
    });
    
    dispatch(success());
  } catch (err) {
    toast.error('Nie udało się pobrać pliku');
    dispatch(failure());
  }
};

export default downloadFile;
