import * as Types from 'Shared/models/AuthModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { AuthAction } from '../Actions/Models/AuthActionModel';

export type State = Types.AuthStore;

export const initialState: State = {
  token: null,
  authorizedUser: null,
  devicesError: {
    connectedDevices: [],
    isMaxDevices: undefined,
    isNewDevice: undefined,
    error: undefined,
  },
};

export function authReducer(state: State = initialState, action: AuthAction) {
  switch (action.type) {
    case ActionTypes.Auth.LOG_IN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.LOG_OUT_SUCCESS: {
      localStorage.removeItem('isUserLogged');
      localStorage.removeItem('filters');
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.LOG_OUT_FAILURE: {
      localStorage.setItem('isUserLogged', JSON.stringify(false));
      return {
        ...state,
        token: null,
        tokenExpires: null,
        authorizedUser: null,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_FAILURE: {
      return {
        ...state,
        authorizedUser: null,
        devicesError: action.payload.devicesError,
      };
    }
    case ActionTypes.Auth.GET_AUTHORIZED_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: action.payload.authorizedUserData,
      };
    }
    case ActionTypes.Auth.SET_REFRESH_TOKEN: {
      return {
        ...state,
        token: action.payload.authData.token,
      };
    }
    case ActionTypes.Auth.GET_REFRESH_TOKEN_SUCCESS: {
      localStorage.setItem('isUserLogged', JSON.stringify(true));
      return {
        ...state,
        token: action.payload.token,
      };
    }
    case ActionTypes.Auth.ADD_NEW_DEVICE_SUCCESS: {
      return {
        ...state,
        devicesError: {
          connectedDevices: [],
          isMaxDevices: false,
          isNewDevice: false,
          error: '',
        },
      };
    }
    default: {
      return state;
    }
  }
}
