import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { MainType } from './Container';
import { ROUTES, TABLE_TYPES, SUBMENU, AREA_TYPE, ROLES, USER_LEVELS } from 'Shared/enums';
import AddCompendiumForm from './components/addCompendiumForm';
import AddDictionaryForm from './components/addDictionaryForm';
import AddInstitutionForm from './components/addInstitutionForm';
import AddNormForm from './components/addNormForm';
import { getArea } from 'Shared/utils';

type P = {
  match?: any;
  history?: any;
};

type S = {
  currentRoute: string;
  currentPath: string;
};

class AreaManagement extends React.PureComponent<MainType & P, S> {
  constructor(props) {
    super(props);
    this.state = {
      currentRoute: '',
      currentPath: '',
    };
  }
  componentDidMount() {
    const { BHP, ENVIRONMENT, ADDITIONAL } = AREA_TYPE;
    const area = getArea();
    const backToPathname =
      area === BHP ? ROUTES.BHP : area === ENVIRONMENT ? ROUTES.ENVIRONMENT_PROTECTION : area === ADDITIONAL ? ROUTES.ADDITIONAL : ROUTES.ENERGIA;
    this.setState({ currentRoute: this.getRoute(this.props.match.params.id), currentPath: backToPathname });
  }
  addDictionary = (data) =>
    this.props.actions.addDictionary({ ...data, area: getArea() }, { route: this.state.currentRoute, path: this.state.currentPath });
  addInstitution = (data) =>
    this.props.actions.addInstitution({ ...data, area: getArea() }, { route: this.state.currentRoute, path: this.state.currentPath });
  addCompendium = (data) => {
    this.props.actions.addCompendium(
      // { ...data, change_date: moment(data.change_date).format('YYYYMMDD'), area: getArea() },
      { ...data, area: getArea() },
      { route: this.state.currentRoute, path: this.state.currentPath },
    );
  };
  addNorm = (data) => this.props.actions.addNorm({ ...data, area: getArea() }, { route: this.state.currentRoute, path: this.state.currentPath });
  getRoute = (route) => {
    switch (route) {
      case TABLE_TYPES.SLOWNIK: {
        return SUBMENU.DICT;
      }
      case TABLE_TYPES.INSTYTUCJE: {
        return SUBMENU.INSTITUTIONS;
      }
      case TABLE_TYPES.KOMPENDIUM: {
        return SUBMENU.COMPENDIUMS;
      }
      case TABLE_TYPES.NORMY: {
        return SUBMENU.NORMS;
      }
    }
  };
  render() {
    const {
      history,
      authorizedUser: { companies, roles, level },
    } = this.props;
    const { currentRoute, currentPath } = this.state;
    const isCustomerAreaManager =
      roles.some(({ role }) => role === ROLES.CUSTOMER) &&
      (level === USER_LEVELS.MANAGING || level === USER_LEVELS.DETAILED || level === USER_LEVELS.DEMO) &&
      companies?.has_add_permission;
    const isPermissionDenied = roles.some(({ role }) => role === ROLES.CUSTOMER) && (level === USER_LEVELS.BASIC || !companies?.has_add_permission);

    if (isPermissionDenied) {
      return (
        <Redirect
          to={{
            pathname: currentPath,
          }}
        />
      );
    } else {
      return (
        <div className="areaManagement">
          <div className="areaManagement__header">
            <div className="areaManagement__header--backButton">
              <button className="btn btn--transparent" onClick={() => history.push({ pathname: currentPath, state: { activeTab: currentRoute } })}>
                {'<'} Wróć
              </button>
            </div>
            <div className="areaManagement__header--title">
              <h1>Dodaj nowy wpis</h1>
            </div>
          </div>
          <div className="areaManagement__inner">
            <div className="areaManagement__content">
              {currentRoute === SUBMENU.DICT && <AddDictionaryForm isCustomerAreaManager={isCustomerAreaManager} onSubmit={this.addDictionary} />}
              {currentRoute === SUBMENU.INSTITUTIONS && (
                <AddInstitutionForm isCustomerAreaManager={isCustomerAreaManager} onSubmit={this.addInstitution} />
              )}
              {currentRoute === SUBMENU.COMPENDIUMS && (
                <AddCompendiumForm isCustomerAreaManager={isCustomerAreaManager} userLevel={level} onSubmit={this.addCompendium} />
              )}
              {currentRoute === SUBMENU.NORMS && <AddNormForm isCustomerAreaManager={isCustomerAreaManager} onSubmit={this.addNorm} />}
            </div>
          </div>
        </div>
      );
    }
  }
}
export default AreaManagement;
