import * as React from 'react';
// import SearchForm from 'Shared/components/searchForm';

const AssociatedUsersHeader = ({ maxLoginsAmount, currentLoginsAmount }: any) => (
  <div className="associatedUsersHeader">
    <div className="associatedUsersHeader__inner">
      <div className="associatedUsersHeader__title">
        Lista podpiętych użytkowników{' '}
        <span className="associatedUsersHeader__title--logins-amount">
          {currentLoginsAmount ? currentLoginsAmount : 0}/{maxLoginsAmount ? maxLoginsAmount : 0}
        </span>
      </div>
      {/* <div className="associatedUsersHeader__searchBox">
        <SearchForm onSearch={(term) => console.log(term)} />
      </div> */}
    </div>
  </div>
);

export default AssociatedUsersHeader;
