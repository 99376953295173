import { Connector } from 'Modules/NotificationModule';
import { Notification } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Notification.READ_NOTIFICATION_INIT,
});

const success = (data: any) => ({
  type: Notification.READ_NOTIFICATION_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Notification.READ_NOTIFICATION_FAILURE,
});

const readNotification = (data) => {
  if(!data.uuid) {
    delete data.uuid
  }
  return async (dispatch) => {
    dispatch(init());
    try {
      await Connector.readNotification({...data});
      dispatch(success(data));
    } catch (err) {
      dispatch(failure());
    }
  };
};

export default readNotification;
