import * as React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const LearnMore = ({ onCtaClick }: P) => (
  <div className="learnMore">
    <div className="learnMore__icon">
      <SVG type={SVG_TYPE.ENERGY} />
    </div>
    <div className="learnMore__title">ENERGIA</div>
    <div className="learnMore__badge">Brak dostępu</div>
    <div className="learnMore__heading">Twój abonament obecnie nie obejmuje dostępu do tego obszaru. </div>
    <div className="learnMore__description">
      Znajdziesz w nim informacje potrzebne do skutecznej identyfikacji i oceny zgodności z wymaganiami prawnymi oraz audytowania Systemu Zarządzania
      Energią według normy ISO 50001:2018.
    </div>
    <div className="learnMore__ctaDescription">Skontaktuj się z nami w celu uzyskania dostępu do obszaru ENERGIA.</div>
    <div className="learnMore__badges">
      <div className=" learnMore__badge learnMore__badge--orange">
        <div>+ 512 193 743</div>
        <div>singula@wiso.pl</div>
      </div>
    </div>
  </div>
);
type P = {
  onCtaClick: () => void;
};

export default LearnMore;
