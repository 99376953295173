import React, { useState, useEffect } from 'react';
import { USER_LEVELS, MODAL_TYPE, SVG_TYPE, ROUTES, ROLES } from 'Shared/enums';
import { Button, Modal, SVG } from 'Shared/components';
import { RegularField, ColorPicker, IconsField } from 'Shared/components/forms';
import { Formik, Field } from 'formik';
import { Icons } from 'Shared/components/forms/iconsField/iconsField';
import { NavLink } from 'react-router-dom';

type P = {
  level: string;
  getNewTab: () => void;
  createNewTab: (data) => void;
  editNewTab: (data) => void;
  newTab: any;
  roles: any;
};

const NewTabHeader = ({ level, newTab, getNewTab, createNewTab, editNewTab, roles }: P) => {
  const isSuperAdmin = roles.some(({ role }) => role === ROLES.SUPER_ADMIN);

  useEffect(() => {
    getNewTab();
  }, [getNewTab]);
  const location = window.location;
  const isActivePage = location.pathname === ROUTES.ADDITIONAL;
  const [modalManage, setModalManage] = useState({ isOpen: false, type: 'add' });
  const isManaging = level === USER_LEVELS.MANAGING;
  const initialValues = {
    color: newTab?.color || '',
    name: newTab?.name || '',
    icon: newTab?.icon || '',
    new_tab_uuid: newTab?.uuid || '',
  };
  const handleSubmit = async (values) => {
    if (newTab) {
      await editNewTab(values);
      setModalManage({ isOpen: false, type: '' });
    } else {
      await createNewTab(values);
      setModalManage({ isOpen: false, type: '' });
      await getNewTab();
    }
  };

  return (
    <>
      {!isSuperAdmin && (
        <>
          <Modal onClose={() => setModalManage({ isOpen: false, type: '' })} type={MODAL_TYPE.FORM} opened={modalManage.isOpen} big>
            <div className="editModal editModal--topicality-categories new-tab__modal">
              <h1 className="font-lg bold-700">{modalManage.type === 'edit' ? 'Edycja' : 'Dodawanie'} zakładki</h1>
              <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)}>
                {({ handleSubmit, errors }) => (
                  <form onSubmit={handleSubmit} className="editModal--form">
                    <Field class="gray" name="name" label="Nazwa zakładki" type="input" component={RegularField} errors={errors} />
                    <Field label="Ikona zakładki" name="icon" component={IconsField} errors={errors} />
                    <Field name="color" type="text" label="Kolor zakładki" component={ColorPicker} errors={errors} />
                    <div className="editModal__actions">
                      <Button
                        label="Anuluj"
                        classNames="btn btn--transparent btn--cancel"
                        onClick={() => setModalManage({ isOpen: false, type: '' })}
                      />
                      <Button label="Zapisz zmiany" classNames="btn btn--navy-blue" type="submit" />
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Modal>
          <div className="new-tab">
            {newTab ? (
              <>
                <div className="new-tab__item">
                  <NavLink
                    style={{ borderBottomColor: isActivePage ? newTab.color : '' }}
                    to={ROUTES.ADDITIONAL}
                    className={`dashboard__header-item`}
                    activeClassName="dashboard__header-item--active">
                    <Icons type={newTab.icon} />
                    <p>{newTab.name}</p>
                  </NavLink>
                  <div className="dashboard__header-edit-icon" onClick={() => setModalManage({ isOpen: true, type: 'edit' })}>
                    <SVG type={SVG_TYPE.EDIT} />
                  </div>
                </div>
              </>
            ) : (
              <>
                {isManaging && (
                  <Button
                    label="Dodaj zakładkę +"
                    classNames="btn btn--navy-blue-stroke"
                    onClick={() => setModalManage({ isOpen: true, type: 'add' })}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default NewTabHeader;
