import * as React from 'react';

type P = {
  areHiddenRowsShown: boolean;
  onClick: () => void;
};

const VisibilityCell = ({ areHiddenRowsShown, onClick }: P) => (
  <div className="cell--visibility">
    <button className="btn btn--xs btn--transparent btn--bordered" onClick={onClick}>
      {areHiddenRowsShown ? 'Przywróć' : 'Ukryj'}
    </button>
  </div>
);

export default VisibilityCell;
