import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { getArea } from 'Shared/utils';

const init = () => ({
  type: BHP.GET_COMPENDIUMS_INIT,
});

const success = (compendiums: Array<any>, company_uuid) => ({
  type: BHP.GET_COMPENDIUMS_SUCCESS,
  payload: {
    compendiums,
    company_uuid,
  },
});

const failure = () => ({
  type: BHP.GET_COMPENDIUMS_FAILURE,
});

const getCompendiums = (
  getDetailed: boolean,
  company_uuid: string = '',
  isMyRequirements = false,
  isMyCompanyEntries = false,
  areNewlyAdded = false,
) => async (dispatch) => {

  dispatch(init());
  try {
    const { data } = await Connector.getCompendiums(getArea(), getDetailed, company_uuid, isMyRequirements, isMyCompanyEntries, areNewlyAdded);
    dispatch(success(data, company_uuid));
  } catch (err) {
    dispatch(failure());
    console.log(err);
  }
};

export default getCompendiums;
