import React from 'react';

type P = {
  isVisible: boolean;
};

// eslint-disable-next-line react/prop-types
const DemoRow = ({ isVisible }: P) => (isVisible ? <div className={`demo-row`}>DEMO</div> : null);

export default DemoRow;
