import { createSelector } from 'reselect';
import { RootState } from 'Store/Reducers/RootReducer';

const getViewMAnagementStore = (state: RootState) => state.viewManagementStore;
const loaderVisible = createSelector(getViewMAnagementStore, (state) => state.loaderVisible);
const isMenuExpanded = createSelector(getViewMAnagementStore, (state) => state.isMenuExpanded);
const isAddReminderView = createSelector(getViewMAnagementStore, (state) => state.isAddReminderView);
const prevPath = createSelector(getViewMAnagementStore, (state) => state.prevPath);
const isExtendedTextVisible = createSelector(getViewMAnagementStore, (state) => state.isExtendedTextVisible);

export { loaderVisible, isMenuExpanded, isAddReminderView, prevPath, isExtendedTextVisible };
