import * as AuthModule from '../../../Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';
import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Auth.LOG_OUT_INIT,
});

export const success = (): AuthActionModel.LogoutSuccess => ({
  type: Auth.LOG_OUT_SUCCESS,
});

const failure = (): AuthActionModel.LogoutFailure => ({
  type: Auth.LOG_OUT_FAILURE,
});

const logout = () => {
  return async (dispatch) => {
    dispatch(init());
    try {
      dispatch(ViewManagementModule.Actions.showLoader());
      await AuthModule.Connector.logout();
      dispatch(success());
      dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      dispatch(failure());
      dispatch(ViewManagementModule.Actions.hideLoader());
    }
  };
};

export default logout;
