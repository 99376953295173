import * as React from 'react';
import moment from 'moment';
import { Cells } from 'Shared/components';

type P = {
  norms: Array<any>;
  isCustomerLogged: boolean;
  manageRow: (type, id) => void;
  areHiddenRowsShown: boolean;
};
const NormsMobile = ({ norms, isCustomerLogged, areHiddenRowsShown, manageRow }: P) => {
  const [expandedRowId, setExpandedRowId] = React.useState(undefined);
  return (
    <div className="normsMobile">
      {norms.map((norm, idx) => (
        <NormsElement
          key={norm.id}
          norm={norm}
          isExpanded={idx === expandedRowId}
          onClick={() => setExpandedRowId(idx !== expandedRowId ? idx : undefined)}
          onEdit={() => console.log('norms', norm.id)}
          areHiddenRowsShown={areHiddenRowsShown}
          isCustomerLogged={isCustomerLogged}
          manageRow={manageRow}
        />
      ))}
    </div>
  );
};

export default NormsMobile;

const NormsElement = ({
  norm: { extra_activities, id, normative_act, requirement, updated_at },
  isExpanded,
  onClick,
  onEdit,
  isCustomerLogged,
  manageRow,
  areHiddenRowsShown,
}: any) => (
  <div className="normElement">
    <div className="normElement__name" onClick={onClick}>
      <span>{id}</span> <span>{normative_act}</span>
    </div>
    {isExpanded && (
      <div className="normElement__content">
        <div className="normElement__block">
          <span className="label">Akt normatywny II:</span>
          <span className="value">{requirement}</span>
        </div>
        <div className="normElement__block">
          <span className="label">Zadania:</span>
          <span className="value">
            <Cells.ExtendText value={extra_activities} />
          </span>
        </div>
        <div className="normElement__block">
          <span className="label">Data aktualizacji:</span>
          <span className="value">{moment(updated_at).format('YYYY-MM-DD HH:mm')}</span>
        </div>
        <div className="instutitionElement__block instutitionElement__block--edit">
          {!isCustomerLogged ? (
            <button className="btn btn--transparent btn--bordered btn--xs" onClick={onEdit}>
              Edytuj
            </button>
          ) : (
            <Cells.VisibilityCell areHiddenRowsShown={areHiddenRowsShown} onClick={() => manageRow('norms', id)} />
          )}
        </div>
      </div>
    )}
  </div>
);
