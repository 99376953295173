import * as Types from 'Shared/models/NewTabModel';
import { NewTab } from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.NewTabStore;

export const initialState: State = {
  newTab: undefined,
};

export function newTabReducer(state: State = initialState, action) {
  switch (action.type) {
    case NewTab.GET_NEW_TAB_INIT: {
      return {
        ...state,
        newTab: undefined,
      };
    }
    case NewTab.GET_NEW_TAB_SUCCESS: {
      return {
        ...state,
        ...action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}
