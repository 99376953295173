import React, { useEffect } from 'react';
import { HighlightText } from 'Shared/components';
import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { Selectors as ViewSelectors } from 'Modules/ViewManagementModule';

type P = {
  value: any;
  highlight?: boolean;
  columnId?: string;
};

const ExtendText = ({ value, highlight, isExtendedTextVisible, columnId }: P & ConnectedP) => {
  const [isExtended, setExtended] = React.useState(false);
  const textLength = 175;
  const toggle = () => setExtended(!isExtended);
  const txt =
    (isExtended && value?.length > textLength) || value?.length <= textLength ? value : value ? `${value?.substring(0, textLength)}...` : '';

  useEffect(() => {
    setExtended(isExtendedTextVisible);
  }, [isExtendedTextVisible]);

  return (
    <div className="extendText">
      <HighlightText text={txt} columnId={columnId} />
      {value?.length > textLength && (
        <div className="extendText--btn" onClick={toggle}>
          {isExtended ? 'Zwiń' : 'Rozwiń'}
        </div>
      )}
    </div>
  );
};

type ConnectedP = {
  isExtendedTextVisible: boolean;
};

const mapStateToProps = (state: RootState): ConnectedP => ({
  isExtendedTextVisible: ViewSelectors.isExtendedTextVisible(state),
});

export default connect(mapStateToProps, null)(ExtendText);
