import * as React from 'react';

const Regulations = () => {
  return (
    <div className="regulations">
      <div className="regulations__header">Regulamin usługi „Kompendium WISO”</div>
      <div className="regulations__paragraph">§ 1</div>
      <div className="regulations__title">[ Definicje ]</div>
      <div className="regulations__content">
        <p>Terminy użyte w niniejszym regulaminie posiadają następujące znaczenie:</p>
        <p>
          1. <span>WISO</span> – WISO GROUP spółka z ograniczoną odpowiedzialnością z siedzibą w Olkuszu, przy ulicy Floriańskiej 2 wpisana do
          rejestru przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla Krakowa-Śródmieścia w Krakowie, XII Wydział
          Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS 0000669346, NIP 6372199840
        </p>
        <p>
          2. <span>Użytkownik</span> – wskazana przez <span>Klienta</span> osoba posiadająca dostęp do <span>Kompendium</span> na warunkach ustalonych
          pomiędzy <span>WISO</span> a <span>Klientem</span>;
        </p>
        <p>
          3. <span>Klient</span> – Strona umowy z <span>WISO</span>, dotyczącej świadczenia usługi dostępu do <span>Kompendium WISO</span>;
        </p>
        <p>
          4. <span>Kompendium lub Kompendium WISO</span> – usługa dostępna pod adresem: kompendium.wiso.pl;
        </p>
        <p>
          5. <span>Newsletter</span> – dodatkowa usługa, polegająca na przesyłaniu informacji, w tym informacji o charakterze handlowym lub
          marketingowym, na adres e-mail <span>Użytkownika</span>;
        </p>
        <p>
          6. <span>Umowa lub Umowa o Kompendium</span> – umowa pomiędzy <span>WISO</span> a <span>Klientem</span> w zakresie dostępu do{' '}
          <span>Kompendium WISO</span>;
        </p>
        <p>
          7. <span>Regulamin</span> – niniejszy dokument.
        </p>
      </div>
      <div className="regulations__paragraph">§ 2</div>
      <div className="regulations__title">[ Zakres stosowania ]</div>
      <div className="regulations__content">
        <p>
          Niniejszy regulamin określa zasady korzystania z <span>Kompendium WISO</span> oraz <span>Newslettera</span>.
        </p>
      </div>
      <div className="regulations__title">[ Część I - Kompendium ]</div>
      <div className="regulations__paragraph">§ 3</div>
      <div className="regulations__title">[ Zakres świadczonych usług ]</div>
      <div className="regulations__content">
        <p>
          <span>Kompendium</span> zapewnia <span>Użytkownikom</span> dostęp do treści w nim zgromadzonych, ich dodawanie oraz zarządzanie dostępem do{' '}
          <span>Kompendium</span> oraz treści w nim zgromadzonych.
        </p>
      </div>
      <div className="regulations__paragraph">§ 4</div>
      <div className="regulations__title">[ Wymogi techniczne ]</div>
      <div className="regulations__content">
        <p>
          Korzystanie z <span>Kompendium WISO</span> wymaga:
        </p>
        <p>1) urządzenia z dostępem do Internetu</p>
        <p>2) przeglądarki internetowej w najnowszej dostępnej wersji spośród następujących: Opera, Safari, Chrome, Edge, Firefox;</p>
        <p>3) zaakceptowania funkcjonalnych plików cookie;</p>
        <p>4) posiadania działającego adresu e-mail.</p>
      </div>
      <div className="regulations__paragraph">§ 5</div>
      <div className="regulations__title">[ Dostęp do Kompendium ]</div>
      <div className="regulations__content">
        <p>
          1. Dostęp do <span>Kompendium</span> jest możliwy wyłącznie po zalogowaniu się przez <span>Użytkownika</span>. Zalogowanie polega na podaniu
          adresu e-mail i skorzystaniu z linka wysłanego przez <span>Kompendium</span> na podany adres e-mail, o ile adres ten został zarejestrowany w
          Kompendium przez <span>WISO</span> na polecenie <span>Klienta</span>.
        </p>
        <p>
          2. <span>Użytkownik</span> nie może korzystać <span>z Kompendium</span> z więcej niż jednego urządzenia jednocześnie.
        </p>
        <p>
          3. Możliwości <span>Użytkownika</span> w obrębie <span>Kompendium</span> wynikają z:
        </p>
        <div className="regulations__indent">
          <p>
            a. <span>Umowy o Kompendium</span>
          </p>
          <p>
            b. ustawieniami lub ograniczeniami wprowadzonymi przez <span>Klienta</span>.
          </p>
        </div>
        <p>
          4. <span>WISO</span> może czasowo lub trwale ograniczyć lub zablokować dostęp danemu <span>Użytkownikowi</span> w sytuacji, gdy:
        </p>
        <div className="regulations__indent">
          <p>
            a. nie przestrzega on obowiązków <span>Użytkownika</span>;
          </p>
          <p>
            b. wygasła <span>Umowa o Kompendium</span> na podstawie, której dostęp uzyskał <span>Użytkownik</span>;
          </p>
          <p>
            c. <span>Klient</span> zgłosił <span>WISO</span> chęć zablokowania lub usunięcia dostępu;
          </p>
          <p>
            d. <span>Użytkownik</span> próbuje korzystać z <span>Kompendium</span> z więcej niż jednego urządzenia jednocześnie.
          </p>
        </div>
        <p>
          5. <span>WISO</span> może czasowo ograniczyć dostęp do <span>Kompendium</span> celem przeprowadzenia prac technicznych, o pracach i ich
          czasie <span>Użytkownik</span> zostanie powiadomiony z wyprzedzeniem, jeśli będzie to możliwe.
        </p>
      </div>
      <div className="regulations__paragraph">§ 6</div>
      <div className="regulations__title">[ Obowiązki Użytkownika ]</div>
      <div className="regulations__content">
        <p>
          1. <span>Użytkownika</span> obowiązuje zakaz:
        </p>
        <div className="regulations__indent">
          <p>
            a. dostarczania treści bezprawnych w ramach korzystania z <span>Kompendium</span>.
          </p>
          <p>
            b. dzielenia się dostępem do <span>Kompendium</span> z innymi osobami.
          </p>
          <p>
            c. kopiowania, rozpowszechniania lub innego wykorzystania treści zawartych w <span>Kompendium</span> z wyjątkiem bezpośredniego dostępu do
            nich.
          </p>
        </div>
        <p>
          2. <span>Użytkownik</span> jest również zobowiązany chronić przed innymi osobami dostęp do adresu e-mail wykorzystywanego jako dostęp do{' '}
          <span>Kompendium</span>;
        </p>
      </div>
      <div className="regulations__title">[ Część II Newsletter ]</div>
      <div className="regulations__paragraph">§ 7</div>
      <div className="regulations__title">[ Wymogi techniczne ]</div>
      <div className="regulations__content">
        <p>By móc korzystać z usługi Newslettera koniecznym jest:</p>
        <p>1. Posiadanie dostępu do Internetu.</p>
        <p>2. Skorzystanie z przeglądarki internetowej.</p>
        <p>3. Utrzymywanie przez cały czas poprawnie skonfigurowanej skrzyni mailowej (poczty elektronicznej).</p>
      </div>
      <div className="regulations__paragraph">§ 8</div>
      <div className="regulations__title">[ Warunki Korzystania ]</div>
      <div className="regulations__content">
        <p>Użytkownik musi spełnić następujące warunki:</p>
        <p>
          1. Przejść przez procedurę zapisu polegającą na przekazaniu swojego adresu e-mail oraz potwierdzeniu zapisu poprzez kliknięcie w link
          (hiperłącze) zawarte w wiadomości wysłanej przez <span>WISO</span>.
        </p>
        <p>2. Posiadać przez cały czas poprawnie skonfigurowaną skrzynkę mailową (pocztę elektroniczną).</p>
        <p>
          3. Nie dostarczać do systemu <span>WISO</span> treści o charakterze bezprawnym.
        </p>
        <p>4. Wyrazić zgodę na otrzymywanie informacji marketingowych lub handlowych na podany adres e-mail.</p>
      </div>
      <div className="regulations__title">[ Część III pozostałe postanowienia ]</div>
      <div className="regulations__paragraph">§ 9</div>
      <div className="regulations__title">[ Postępowanie reklamacyjne ]</div>
      <div className="regulations__content">
        <p>
          <span>Użytkownik</span> może zgłosić reklamację drogą mailową na adres kompendium@wiso.pl, która zostanie rozpatrzona przez{' '}
          <span>WISO</span> w terminie 30 dni kalendarzowych
        </p>
      </div>
      <div className="regulations__paragraph">§ 10</div>
      <div className="regulations__title">[ Zawarcie i rozwiązanie Umowy ]</div>
      <div className="regulations__content">
        <p>
          1. Użytkownik akceptuje <span>Regulamin</span> podczas pierwszego zalogowania się do <span>Kompendium</span>;
        </p>
        <p>
          2. Użytkownik może wypowiedzieć <span>Regulamin</span> w całości lub w części poprzez przesłanie oświadczenia z adresu e-mail
          zarejestrowanego w<span>Kompendium</span> z zachowaniem 30 dniowego terminu wypowiedzenia. Oświadczenie to nie wpływa na{' '}
          <span>Umowę o Kompendium</span>.
        </p>
        <p>
          3. <span>Użytkownik</span> może w każdej chwili zrezygnować z <span>Newslettera</span> również poprzez rezygnację z subskrypcji za pomocą
          linku podanego w jego treści.
        </p>
      </div>
      <div className="regulations__paragraph">§ 11</div>
      <div className="regulations__title">[ Zmiana regulaminu ]</div>
      <div className="regulations__content">
        <p>
          1. <span>WISO</span> jest uprawniona do jednostronnej zmiany niniejszego regulaminu, poprzez przesłanie zmian na adres e-mail{' '}
          <span>Użytkownika</span> nie później niż na 14 dni kalendarzowych przed wejściem ich w życie.
        </p>
        <p>
          2. <span>Użytkownik</span> ma prawo wyrazić sprzeciw względem zmian, drogą mailową z adresu mailowego zarejestrowanego w{' '}
          <span>Kompendium WISO</span>, wysyłając wiadomość na adres kompendium@wiso.pl.
        </p>
        <p>
          3. Brak zgłoszenia sprzeciwu skutkuje wejściem w życie zmian w regulaminie w terminie podanym przez <span>WISO</span>.
        </p>
        <p>
          4. Zgłoszenie sprzeciwu skutkuje wygaszeniem dostępu <span>Użytkownika</span> do <span>Kompendium</span> i <span>Newslettera</span> w dniu
          poprzedzającym wejście w życie zmian regulaminu.
        </p>
      </div>
    </div>
  );
};

export default Regulations;
