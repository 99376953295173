import * as React from 'react';
import { FieldProps } from 'formik';

type P = {
  label?: string;
};
type S = {
  firstPart: string;
  secondPart: string;
};
class ZipCode extends React.PureComponent<P & FieldProps, S> {
  constructor(props) {
    super(props);
    this.state = {
      firstPart: '',
      secondPart: '',
    };
  }
  update = () => this.props.field.onChange(`${this.state.firstPart}-${this.state.secondPart}`);

  onFirstPartChange = (e) => {
    this.props.form.setFieldValue(this.props.field.name, `${e.target.value}-${this.state.secondPart}`);
    this.setState({ firstPart: e.target.value });
  };

  onSecondPartChange = (e) => {
    this.props.form.setFieldValue(this.props.field.name, `${this.state.firstPart}-${e.target.value}`);
    this.setState({ secondPart: e.target.value });
  };

  render() {
    const {
      label,
      field: { value },
    } = this.props;
    const [firstPartValue, secondPartValue] = value ? value.split('-') : '';
    return (
      <div className="zipCode">
        <div className="zipCode__inner form__field form__field--zipCode">
          <input className="first" type="text" maxLength={2} value={firstPartValue} onChange={this.onFirstPartChange} />
          <span className="zipCode--spacer" />
          <input className="second" type="text" maxLength={3} value={secondPartValue} onChange={this.onSecondPartChange} />
        </div>
        {label && <label>{label}</label>}
      </div>
    );
  }
}

export default ZipCode;
