import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import greenFace from 'Assets/Img/Icons/green-face-icon.svg';
import orangeFace from 'Assets/Img/Icons/orange-face-icon.svg';
import greyFace from 'Assets/Img/Icons/grey-face-icon.svg';
import redFace from 'Assets/Img/Icons/red-face-icon.svg';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type P = {
  original: any;
  addRatingCompatibility?: (data: any) => void;
  isManaging?: boolean;
  files?: any;
};

type ratingP = {
  rating?: any;
  iconFace?: any;
  dataTip?: string;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
};

const RatingFace = ({rating, iconFace, dataTip, onMouseOver, onMouseLeave }: ratingP) => {
  if (rating) {
    return (
      <div className="rating__container" data-tip={dataTip} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
        <img src={iconFace} alt="" /> {rating > 1 ? moment(rating).format('YYYY-MM-DD') : 'brak statusu zgodności'}
      </div>
    );
  } else return null;
};

const Rating = ({ original, files }: P) => {
  const ratingCopy = original.rating;
  const [activeRatingFace, setActiveRatingFace] = React.useState(null);
  const [activeTooltip, setActiveTooltip] = React.useState(false);
  const activeRating =
    ratingCopy && Array.isArray(ratingCopy)
      ? ratingCopy.reduce((a, b) => {
          return new Date(a.created_at) > new Date(b.created_at) ? a : b;
        })
      : ratingCopy
      ? ratingCopy
      : [];
  const { rating_1, rating_2, rating_3 } = activeRating;
  
  React.useEffect(() => {
    setActiveRatingFace(activeRatingFace);
  }, [activeRatingFace]);
  return (
    <>
    {activeTooltip ? (
      <>
        <ReactTooltip place="left" effect="solid" className={`tooltip tooltip--${activeRatingFace}`} delayShow={200} />
      </>
    ) : null}
    <div className="rating" onMouseOver={() => setActiveTooltip(true)} onMouseLeave={() => setActiveTooltip(false)}>
      {/* {console.log(!activeRating.id)} */}
      {/* {console.log()} */}
      {((!rating_1 && !rating_2 && !rating_3) || !activeRating.id) && (
        <>
          <RatingFace
            rating={1}
            iconFace={greyFace}
            dataTip="Ocena zgodności nie została dotychczas przeprowadzona. Należy rozważyć, czy wymaganie prawne ma zastosowanie w organizacji."
            onMouseOver={() => setActiveRatingFace(5)}
            onMouseLeave={() => setActiveRatingFace(0)}
          />
        </>
      )
      }
      <RatingFace
        iconFace={greenFace}
        rating={rating_3}
        //         dataTip="Z -
        //         zgodność
        //               zebrane dowody wskazują, że organizacja jest zgodna z wymaganiami prawnymi
        // "
        dataTip="Przeprowadzona analiza zebranych dowodów wskazuje, że organizacja jest zgodna z wymaganiem prawnym."
        onMouseOver={() => setActiveRatingFace(3)}
        onMouseLeave={() => setActiveRatingFace(0)}
      />                  
      <RatingFace
        iconFace={orangeFace}
        rating={rating_2}
        // dataTip="C -
        // częściowa zgodność
        //     zebrane dowody wskazują, że organizacja jest częściowo zgodna z wymaganiem prawnym "
        dataTip="Przeprowadzona analiza zebranych dowodów wskazuje, że organizacja jest w przeważającym obszarze zgodna z wymaganiem prawnym, a uzyskanie zgodności wymaga drobnych działań doskonalących."
        onMouseOver={() => setActiveRatingFace(2)}
        onMouseLeave={() => setActiveRatingFace(0)}
      />
      <RatingFace
        iconFace={redFace}
        rating={rating_1}
        //         dataTip="N -
        //         niezgodnoś
        //             zebrane dowody wskazują, że organizacja nie jest zgodna z wymaganiem prawnym (wymagana karta działań korygujących wg
        //             ps-26 oraz przywołanie jej w planie działań 'kolumna n')
        //  "
        dataTip="Przeprowadzona analiza wymagania prawnego wskazuje, że organizacja nie posiada wystarczających dowodów na wykazanie zgodności."
        onMouseOver={() => setActiveRatingFace(1)}
        onMouseLeave={() => setActiveRatingFace(0)}
      />
      {activeRating?.rating_pdfs?.length > 0  ? <div className='rating--files'><SVG type={SVG_TYPE.FILE} /></div> : ''}
    </div>
    </>
  );
};

export default Rating;
