import React from 'react';
import RTable from 'react-table';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type ReactTableP = {
  columns: Array<any>;
  data: Array<any>;
  expanded?: any;
  SubComponent?: any;
  className?: string;
  filterable?: boolean;
  areTrProps?: boolean;
  areRowsColored?: boolean;
  activeRatingId?: any;
  showArrows?: boolean;
  filtered?: any;
  showExtendedText?: () => void;
  hideExtendedText?: () => void;
  isExtendedTextVisible?: boolean;
  showExtendTextManage?: boolean;
  // filteredData?: any;
};
// const tableFilters = [{ id: 'term', value: '' }];
const ReactTable = ({
  className = '',
  showArrows,
  columns,
  data,
  expanded = {},
  SubComponent,
  filterable,
  areTrProps,
  areRowsColored,
  activeRatingId,
  filtered,
  showExtendedText,
  hideExtendedText,
  isExtendedTextVisible,
  showExtendTextManage = false,
}: ReactTableP) => {
  const [defaultTableWidth, setDefaultTableWidth] = React.useState(null);
  const [currentTableWidth, setCurrentTableWidth] = React.useState(null);
  const slider: any = document.querySelector('.rt-table');
  const tableHeader: any = document.querySelector('.rt-thead');

  let buttonPressTimer;
  let interval;
  const handleLeftButtonPress = () => {
    buttonPressTimer = setTimeout(
      () =>
        (interval = setInterval(() => {
          slider.scrollLeft -= 60;
        }, 80)),
      100,
    );
  };
  const handleRightButtonPress = () => {
    buttonPressTimer = setTimeout(
      () =>
        (interval = setInterval(() => {
          slider.scrollLeft += 60;
        }, 80)),
      100,
    );
  };

  const handleButtonRelease = () => {
    clearTimeout(buttonPressTimer);
    clearInterval(interval);
  };

  const handleManageText = () => {
    isExtendedTextVisible ? hideExtendedText() : showExtendedText();
  };

  React.useEffect(() => {
    if (tableHeader && !defaultTableWidth) {
      setDefaultTableWidth(tableHeader.clientWidth);
    }
  }, [tableHeader, defaultTableWidth]);

  const getTRPropsType = (state, rowInfo) =>
    // areTrProps && rowInfo.original.id === activeRatingId ? { style: { background: '#efefef' } } : { className: 'rt-tr--new-post' };
    {
      return areTrProps && rowInfo.original.id === activeRatingId ? { style: { background: '#efefef' } } : { className: '' };
    };

  // const getTDPropsType = (state, rowInfo) =>
  //   // areTrProps && rowInfo.original.id === activeRatingId ? { style: { background: '#efefef' } } : { className: 'rt-tr--new-post' };
  //   // (state, rowInfo) => (areRowsColored && rowInfo?.original.company_id ? { style: { color: rowInfo?.original.color } } : { className: 'test' });
  //   (state, rowInfo) => (true ? { style: { color: '#fff' } } : {});
  // // areRowsColored && rowInfo?.original.company_id ? { style: { color: rowInfo?.original.color } } : { style: { color: '#fff' } };

  return (
    <>
      {(showArrows && data.length) || currentTableWidth > defaultTableWidth ? (
        <>
          <div
            className="arrow arrow__left"
            onTouchStart={handleLeftButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleLeftButtonPress}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}
            onClick={() => {
              slider.scrollLeft -= 40;
            }}>
            <SVG type={SVG_TYPE.CHEVRON} />
          </div>
          <div
            className="arrow arrow__right"
            onClick={() => {
              slider.scrollLeft += 40;
            }}
            onTouchStart={handleRightButtonPress}
            onTouchEnd={handleButtonRelease}
            onMouseDown={handleRightButtonPress}
            onMouseUp={handleButtonRelease}
            onMouseLeave={handleButtonRelease}>
            <SVG type={SVG_TYPE.CHEVRON} />
          </div>
        </>
      ) : null}
      {showExtendTextManage ? (
        <button className={`btn btn--extendTextManage ${isExtendedTextVisible ? 'btn--extendActive' : ''}`} onClick={handleManageText}>
          {isExtendedTextVisible ? 'Zwiń' : 'Rozwiń'} wiersze
        </button>
      ) : null}
      {/* {
        data && data.length ? ( */}
      <RTable
        filterable={filterable}
        filtered={filtered}
        // defaultFilterMethod={(filter, row) => String(row[filter.id]) === filter.value}
        defaultFilterMethod={({ id, value }, row) => {
          if (value && row[id]) {
            return row[id].toLowerCase().indexOf(value?.toLowerCase()) > -1;
          } else {
            return false;
          }
        }}
        defaultPageSize={300}
        className={`${className} ${currentTableWidth > defaultTableWidth ? 'scrollable' : ''}`}
        showPagination={false}
        sortable={true}
        minRows={1}
        previousText="Poprzednia"
        nextText="Następna"
        pageText="Strona"
        noDataText="Brak danych"
        ofText="z"
        rowsText="rzędów"
        loadingText="Ładowanie..."
        columns={columns}
        data={data}
        expanded={expanded}
        SubComponent={SubComponent}
        onResizedChange={() => {
          setCurrentTableWidth(tableHeader?.clientWidth);
        }}
        // getTrProps={(state, rowInfo) => (areTrProps && rowInfo.original.id === activeRatingId ? { style: { background: '#efefef' } } : {})}
        getTrProps={getTRPropsType}
        getTdProps={(state, rowInfo) =>
          areRowsColored && rowInfo?.original.company_id ? { style: { color: rowInfo?.original.color } } : { className: 'extendedView--new-post' }
        }
        // getTdProps={getTDPropsType}
      />
      {/* ) : 
          (
           <div className="table__noData">Brak danych</div>
         )
      } */}
    </>
  );
};

// export default React.memo(ReactTable);
export default ReactTable;
