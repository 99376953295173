import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Field } from 'formik';
import { Redirect } from 'react-router-dom';
import Logo from 'Assets/Img/singula-logo.svg';
import { RegularField } from 'Shared/components/forms';
import { MainType } from './Container';
import { isTokenSaved } from 'Shared/utils';

const getLoginSchema = () =>
  Yup.object().shape({
    email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
  });

const CustomerLogin = ({ sendLink }: MainType) => {
  if (!isTokenSaved()) {
    return (
      <div className="login">
        <div className="login__container">
          <div className="login__header">
            <div className="login__logo-entire">
              <img src={Logo} alt="" />
              <h1>Logowanie</h1>
            </div>
          </div>
          <div className="login__form-container">
            <Formik initialValues={{ email: '' }} validationSchema={getLoginSchema()} onSubmit={(values) => sendLink(values.email)}>
              {({ handleSubmit, errors }) => (
                <form onSubmit={handleSubmit} className="login__form">
                  <Field name="email" type="email" placeholder="Wpisz adres email" label="Email" component={RegularField} errors={errors} />
                  {/* <p className="login__forget-password">Zapomniałeś hasła?</p> */}
                  <button className="btn btn--default btn--login btn--center" type="submit">
                    Zaloguj się
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  } else {
    return <Redirect to={'/dashboard'} />;
  }
};

export default CustomerLogin;
