import { getArea } from 'Shared/utils';
import { AREA_TYPE } from 'Shared/enums';
const checkAreaAccess = ({ area_bhp, area_environment, area_energy, area_additional }) => {
  switch (getArea()) {
    case AREA_TYPE.BHP: {
      return area_bhp ? AREA_TYPE.BHP : false;
    }
    case AREA_TYPE.ENVIRONMENT: {
      return area_environment ? AREA_TYPE.ENVIRONMENT : false;
    }
    case AREA_TYPE.ENERGY: {
      return area_energy ? AREA_TYPE.ENERGY : false;
    }
    case AREA_TYPE.ADDITIONAL: {
      return area_additional ? AREA_TYPE.ADDITIONAL : false;
    }
  }
};
export default checkAreaAccess;
