import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import DashboardDictionary from './Main';
import { bindActionCreators } from 'redux';
import { Actions, Selectors } from 'Modules/BhpModule';
import { Actions as UserActions, Selectors as UserSelectors } from 'Modules/UserModule';
import { Actions as VMActions, Selectors as VMSelectors } from 'Modules/ViewManagementModule';
import { Selectors as NewTabSelector } from 'Modules/NewTabModule';
import { REQUEST_STATUS } from 'Shared/enums';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';

type ConnectedP = {
  institutions: Array<any>;
  dictionaries: Array<any>;
  compendiums: Array<any>;
  companiesOptions: Array<any>;
  institutionsStatus: REQUEST_STATUS;
  dictionariesStatus: REQUEST_STATUS;
  compendiumsStatus: REQUEST_STATUS;
  compendiumsTermLetters: Array<any>;
  isCompendiumPreview: any;
  compendiumCounterRatings: any;
  authorizedUser: any;
  norms: Array<any>;
  normsStatus: REQUEST_STATUS;
  editingCompendium: {
    status: REQUEST_STATUS;
    uid: number;
  };
  editingDictionary: {
    status: REQUEST_STATUS;
  };
  editingInstitution: {
    status: REQUEST_STATUS;
  };
  editingNorm: {
    status: REQUEST_STATUS;
  };
  compendiumsActiveCompany: string;
  usersInCompanyOptions: any;
  isExtendedTextVisible: boolean;
  isCustomerAreaManager: boolean;
  prevPath: string;
  searchedTerm: string;
  searchedTermPathname: string;
  newTab: any;
};

type DispatchedP = {
  actions: {
    removeFiles: (uuid: any) => void;
    getInstitutions: () => void;
    getDictionaries: () => void;
    getCompendiums: (getDetailed: boolean) => void;
    getCompendiumRatingsCounter: (getDetailed: boolean) => void;
    searchCompendiums: (searchData: string, pathname: string) => void;
    openCompendiumPreview: () => void;
    closeCompendiumPreview: () => void;
    editCompendium: (compendiumData: any) => any;
    deleteCompendium: (deleteCompendium: any) => void;
    copyCompendium: (uuid: any) => void;
    editInstitution: (institution: any) => any;
    deleteInstitution: (deleteInstitution: any) => void;
    editDictionary: (dictionaryData: any) => void;
    deleteDictionary: (deleteDictionary: any) => void;
    editNorm: (normData: any) => void;
    deleteNorm: (deleteNorm: any) => void;
    getNorms: () => void;
    hideRow: (type: string, id: number) => void;
    destroyHiddenRow: (type: string, id: number) => void;
    getHiddenCompendiums: () => void;
    getHiddenDictionaries: () => void;
    getHiddenInstitutions: () => void;
    getHiddenNorms: () => void;
    getCompaniesOptions: () => void;
    getUsersInCompanyOptions: (company_uuid) => void;
    showExtendedText: () => void;
    hideExtendedText: () => void;
    setColumnFilterTerms: (data: any) => void;
    resetColumnFilterTerms: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      removeFiles: Actions.removeFiles,
      copyCompendium: Actions.copyCompendium,
      getInstitutions: Actions.getInstitutions,
      getDictionaries: Actions.getDictionaries,
      getCompendiums: Actions.getCompendiums,
      getCompendiumRatingsCounter: Actions.getCompendiumRatingsCounter,
      editCompendium: Actions.editCompendium,
      deleteCompendium: Actions.deleteCompendium,
      editDictionary: Actions.editDictionary,
      deleteDictionary: Actions.deleteDictionary,
      editInstitution: Actions.editInstitution,
      deleteInstitution: Actions.deleteIntitution,
      editNorm: Actions.editNorm,
      deleteNorm: Actions.deleteNorm,
      openCompendiumPreview: VMActions.openCompendiumPreview,
      closeCompendiumPreview: VMActions.closeCompendiumPreview,
      getNorms: Actions.getNorms,
      hideRow: Actions.hideRow,
      destroyHiddenRow: Actions.destroyHiddenRow,
      getHiddenCompendiums: Actions.getHiddenCompendiums,
      getHiddenDictionaries: Actions.getHiddenDictionaries,
      getHiddenInstitutions: Actions.getHiddenInstitutions,
      getHiddenNorms: Actions.getHiddenNorms,
      searchCompendiums: Actions.searchCompendiums,
      getCompaniesOptions: UserActions.getCompaniesOptions,
      getUsersInCompanyOptions: UserActions.getUsersInCompanyOptions,
      showExtendedText: VMActions.showExtendedText,
      hideExtendedText: VMActions.hideExtendedText,
      setColumnFilterTerms: Actions.setColumnFilterTerms,
      resetColumnFilterTerms: Actions.resetColumnFilterTerms
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  institutions: Selectors.getInstitutions(state),
  dictionaries: Selectors.getDictionaries(state),
  compendiums: Selectors.getCompendiums(state),
  compendiumCounterRatings: Selectors.getCompendiumRatingsCounter(state),
  compendiumsTermLetters: Selectors.getCompendiumTermLetters(state),
  institutionsStatus: Selectors.getInstitutionsStatus(state),
  dictionariesStatus: Selectors.getDictionariesStatus(state),
  compendiumsStatus: Selectors.getCompendiumsStatus(state),
  isCompendiumPreview: state.viewManagementStore.isCompendiumPreview,
  authorizedUser: AuthSelectors.authorizedUser(state),
  norms: Selectors.getNorms(state),
  normsStatus: Selectors.getNormsStatus(state),
  editingCompendium: Selectors.getEditingCompendiumStatus(state),
  editingDictionary: Selectors.getEditingDictionaryStatus(state),
  editingInstitution: Selectors.getEditingInstitutionStatus(state),
  editingNorm: Selectors.getEditingNormStatus(state),
  companiesOptions: UserSelectors.getCompaniesOptions(state),
  compendiumsActiveCompany: Selectors.getCompendiumsActiveCompany(state),
  usersInCompanyOptions: UserSelectors.getUsersInCompanyOptions(state),
  isExtendedTextVisible: VMSelectors.isExtendedTextVisible(state),
  prevPath: VMSelectors.prevPath(state),
  isCustomerAreaManager: AuthSelectors.getIsCustomerAreaManager(state),
  searchedTerm: Selectors.getSearchedTerm(state),
  searchedTermPathname: Selectors.getSearchedTermPathname(state),
  newTab: NewTabSelector.getNewTab(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDictionary);
