import * as React from 'react';
// import { ROUTES, USER_LEVELS } from 'Shared/enums';

type P = {
  value: any;
};

const DetailedHeader = ({ value }: P) => {
  return (
    <div className="detailedHeader">
      <span className="detailedHeader__star">☆ </span>
      {value}
    </div>
  );
};

export default DetailedHeader;
