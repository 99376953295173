import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import DashboardDictionary from './Main';
import { bindActionCreators } from 'redux';
import { Actions, Selectors } from 'Modules/BhpModule';
import { REQUEST_STATUS } from 'Shared/enums';
import { Selectors as AuthSelectors } from 'Modules/AuthModule';
import { Selectors as UserSelectors, Actions as UserActions } from 'Modules/UserModule';

type ConnectedP = {
  compendium: any;
  compendiumStatus: REQUEST_STATUS;
  usersInCompanyOptions: any;
  authorizedUser: any;
  compendiumsActiveCompany: string;
  compendiumActiveRating: any;
  companyEmployee: any;
};
type DispatchedP = {
  actions: {
    addCompendiumEvidence: (data: any) => void;
    removeFiles: (uuid: any) => void;
    deleteRatingCompatibilities: (uuid: any) => void;
    getCompendium: (id: number) => any;
    getUsersInCompanyOptions: () => void;
    addRatingCompatibility: (data: any, showAlert?: boolean) => void;
    setActiveRating: (data: any) => void;
    downloadFile: (uuid: string) => void;
    addEvidence: (data, uuid) => void;
    addTask: (data) => void;
    editTask: (data, uuid) => void;
    removeTask: (uuid: string) => void;
    addEvidenceFile: (data, file) => void;
    getCompanyEmployee: (id) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getCompanyEmployee: UserActions.getCompanyEmployee,
      addEvidenceFile: Actions.addEvidenceFile,
      removeTask: Actions.removeTask,
      addTask: Actions.addTask,
      editTask: Actions.editTask,
      addEvidence: Actions.addEvidence,
      downloadFile: Actions.downloadFile,
      removeFiles: Actions.removeFiles,
      addCompendiumEvidence: Actions.addCompendiumEvidence,
      getCompendium: Actions.getCompendium,
      getUsersInCompanyOptions: UserActions.getUsersInCompanyOptions,
      addRatingCompatibility: Actions.addRatingCompatibility,
      setActiveRating: Actions.setActiveRating,
      deleteRatingCompatibilities: Actions.deleteRatingCompatibilities,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  compendium: Selectors.getCompendium(state),
  compendiumStatus: Selectors.getCompendiumStatus(state),
  usersInCompanyOptions: UserSelectors.getUsersInCompanyOptions(state),
  authorizedUser: AuthSelectors.authorizedUser(state),
  compendiumsActiveCompany: Selectors.getCompendiumsActiveCompany(state),
  compendiumActiveRating: state.bhp.compendiumActiveRating.data,
  companyEmployee: UserSelectors.getCompanyEmployee(state),
  // compendiumActiveRating: Selectors.getCompendiumActiveRating(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDictionary);
