import React from 'react';
import { getArea } from 'Shared/utils';
import moment from 'moment';

const checkIfEarlierThanMonth = (created_at) => moment().diff(moment(created_at), 'days') < 90;

function NewRow({ created_at }: any) {
  if (checkIfEarlierThanMonth(created_at)) {
    return <div className={`new-row new-row--${getArea()}`}>NEW</div>;
  } else return null;
}

export default NewRow;
