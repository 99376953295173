import { Connector, Actions as UserActions } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import { Selectors } from 'Modules/UserModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.ADD_USER_INIT,
});

const success = () => ({
  type: User.ADD_USER_SUCCESS,
});

const failure = () => ({
  type: User.ADD_USER_FAILURE,
});

const addUser = (userData: any, company_uuid: string) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const state = getState();
    const uuid = Selectors.getUuid(state);
    await Connector.addUser({ ...userData, company_uuid });
    dispatch(success());
    dispatch(UserActions.getCompany(uuid));
    !company_uuid && dispatch(UserActions.getUsers());
    toast.success('Podpięto nowego użytkownika');
    dispatch(Actions.hideLoader());
  } catch ({ response }) {
    if (response && response.status === 400 && response.data.validation === 'unique' && response.data.field === 'email') {
      toast.error('Podany email już istnieje');
    }
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default addUser;
