import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { Actions } from 'Modules/ViewManagementModule';

const init = () => ({
  type: BHP.REMOVE_FILES_INIT,
});

const success = (uuid) => ({
  type: BHP.REMOVE_FILES_SUCCESS,
  payload: {
    uuid,
  }
});

const failure = () => ({
  type: BHP.REMOVE_FILES_FAILURE,
});

const removeFiles = (uuid) => async (dispatch) => {
  dispatch(init());
  await Connector.removeFiles(uuid);
  try {
    dispatch(Actions.showLoader());
    dispatch(success(uuid));
    toast.success('Pomyślnie usunięto plik');
    dispatch(Actions.hideLoader());
  } catch (err) {
    dispatch(Actions.hideLoader());
    dispatch(failure());
    console.log(err);
    toast.error('Nie udało się usunąć pliku');
    return err;
  }
};

export default removeFiles;
