import * as React from 'react';

type P = {
  onClick: () => void;
  areHiddenRowsShown: boolean;
  disabled?: boolean;
};

const HiddenRowsToggle = ({ onClick, areHiddenRowsShown, disabled = false }: P) => (
  <div className="hiddenRowsToggle">
    <span onClick={!disabled ? onClick : undefined}>Pokaż ukryte wiersze</span>
    <span
      onClick={!disabled ? onClick : undefined}
      className={`hiddenRowsToggle__switch ${areHiddenRowsShown ? 'hiddenRowsToggle__switch--active' : ''}`}>
      <div className="hiddenRowsToggle__switch_inner" />
    </span>
  </div>
);

export default HiddenRowsToggle;
