import { Connector } from 'Modules/BhpModule';
import { BHP } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';

const init = () => ({
  type: BHP.EDIT_NORM_INIT,
});

const success = (norm) => ({
  type: BHP.EDIT_NORM_SUCCESS,
  payload: {
    norm,
  },
});

const failure = () => ({
  type: BHP.EDIT_NORM_FAILURE,
});

const editNorm = (norm: any) => async (dispatch) => {
  dispatch(init());
  try {
    const { id } = norm;
    await Connector.editNorm({ ...norm }, id);
    dispatch(success(norm));
    toast.success('Pomyślnie edytowano normę');
  } catch (err) {
    dispatch(failure());
    toast.error('Nie udało się zapisać zmian');

    return err;
  }
};

export default editNorm;
