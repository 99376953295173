import API from './Config';
import { AxiosPromise } from 'axios';

export function getUsers(): AxiosPromise<any> {
  return API.get('users');
}

export function deleteUser(uuid: string): AxiosPromise<any> {
  return API.delete(`users/${uuid}`);
}

export function addUser(userData): AxiosPromise<any> {
  return API.post('users', userData);
}

export function editUser(userData: any, uuid: string): AxiosPromise<any> {
  return API.put(`users/${uuid}`, userData);
}

export function getCompanies(): AxiosPromise<any> {
  return API.get('companies');
}

export function getCompaniesOptions(): AxiosPromise<any> {
  return API.get('companies-options');
}

export function getUsersInCompanyOptions(company_uuid): AxiosPromise<any> {
  return API.get(`companies-users-options${company_uuid ? `?company_uuid=${company_uuid}` : ''}`);
}

export function addCompany(companyData: any): AxiosPromise<any> {
  return API.post('companies', companyData);
}

export function getCompany(uuid): AxiosPromise<any> {
  return API.get(`companies/${uuid}`);
}

export function editCompany(companyData: any, uuid: string): AxiosPromise<any> {
  return API.put(`companies/${uuid}`, companyData);
}

export function getChangelog(): AxiosPromise<any> {
  return API.get('changelogs');
}

export function findChangelogs(start_date, end_date): AxiosPromise<any> {
  return API.get(`changelogs/history/?date_start=${start_date}&date_end=${end_date}`);
}

export function deleteCompany(uuid: string): AxiosPromise<any> {
  return API.delete(`companies/${uuid}`);
}

export function getSettings(): AxiosPromise<any> {
  return API.get('settings');
}

export function editSettings(data): AxiosPromise<any> {
  return API.post('settings', data);
}

export function getCompanyEmployee(id): AxiosPromise<any> {
  return API.get(`users/label/?company_id=${id}`);
}
