import * as React from 'react';

const CompendiumElement = ({ term, department, onClick }: any) => (
  <div className="compendiumElement" onClick={onClick}>
    <div className="compendiumElement--term" data-letter={term.charAt(0)}>
      {term}
    </div>
    <div className="compendiumElement--department">{department}</div>
  </div>
);

export default CompendiumElement;
