import * as React from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const DictionaryPhone = ({ dictionaries }: P) => {
  let letter = '';
  return (
    <div className="dictionaryTable--phone">
      {dictionaries.map((el) => {
        const firstLetter = el.term && el.term.charAt(0);
        if (letter === firstLetter) {
          return <DictionaryElement data={el} displayLetter={false} key={el.term} />;
        }
        letter = firstLetter;
        return <DictionaryElement data={el} displayLetter key={el.term} />;
      })}
    </div>
  );
};
type P = {
  dictionaries: Array<any>;
};
export default DictionaryPhone;

const DictionaryElement = ({ data, displayLetter }: any) => {
  const [isDocumentOpen, setDocumentOpen] = React.useState(false);
  const [isContentsOpen, setContentOpen] = React.useState(false);
  const handleDocumentOpen = () => {
    if (isDocumentOpen) {
      return setDocumentOpen(false);
    }
    setContentOpen(false);
    setDocumentOpen(true);
  };
  const handleContentsOpen = () => {
    if (isContentsOpen) {
      return setContentOpen(false);
    }
    setDocumentOpen(false);
    setContentOpen(true);
  };
  const displayIconsBorder = isDocumentOpen || isContentsOpen;
  return (
    <>
      {displayLetter && <div className="dictionary__element--letter">{data.term.charAt(0)}</div>}
      <div className={`dictionary__element dictionary__element--phone  ${isContentsOpen || isDocumentOpen ? 'active' : ''}`}>
        <div className="dictionary__element--header">
          <div className="dictionary__element--header-l">
            <div className="dictionary__element--title">{data.term}</div>
          </div>
          <div className="dictionary__element--header-r">
            <div className={`dictionary__element--icon ${isDocumentOpen ? 'active-ico' : ''} ${displayIconsBorder ? 'bordered' : ''}`} onClick={handleDocumentOpen}>
              <div className="dictionary__element--icon__inner">
                <SVG type={SVG_TYPE.FILE} />
              </div>
            </div>
            <div className={`dictionary__element--icon ${isContentsOpen ? 'active-ico' : ''} ${displayIconsBorder ? 'bordered' : ''}`} onClick={handleContentsOpen}>
              <div className="dictionary__element--icon__inner">
                <SVG type={SVG_TYPE.PARAGRAPH} />
              </div>
            </div>
          </div>
        </div>
        <div className={`dictionary__element--content`}>
          {isDocumentOpen && (
            <>
              <p>{data.content}</p>
              <p className="dictionary__element--date">{data.updated_at}</p>
            </>
          )}
          {isContentsOpen && (
            <>
              <p>{data.normative_act}</p>
              <p className="dictionary__element--date">{data.updated_at}</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
