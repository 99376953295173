import * as React from 'react';
import RoleNameIcon from 'Assets/Img/Icons/role-name-icon.svg';
import { SystemRolesArray } from 'Shared/models/SystemRoles';
import { Types } from 'Modules/AuthModule';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { isEmpty } from 'lodash';

type P = {
  authorizedUser: Types.AuthorizedUserData;
  logout: () => void;
};

const DashboardSidebar = ({ authorizedUser, logout }: P) => {
  const hasUserCompany = !isEmpty(authorizedUser.companies);

  return (
    <div className="dashboard__menu">
      <p>
        {authorizedUser.first_name} {authorizedUser.last_name}
      </p>
      {authorizedUser.roles.map(({ role }) => (
        <div className={`dashboard__role-name`} key={role}>
          <img src={RoleNameIcon} alt={'Icon'} />
          {SystemRolesArray.find(({ value }) => value === role).label}
        </div>
      ))}
      {hasUserCompany && <p>{authorizedUser.companies.company_name}</p>}
      <div className="dashboard__socials">
        <a href="https://pl-pl.facebook.com/WISO.GROUP/" rel="noopener noreferrer" target="_blank" className="dashboard__socials-icon">
          <SVG type={SVG_TYPE.FACEBOOK} />
        </a>
        <a
          href="https://www.linkedin.com/company/wiso-group-sp-z-o-o/?originalSubdomain=pl"
          rel="noopener noreferrer"
          target="_blank"
          className="dashboard__socials-icon">
          <SVG type={SVG_TYPE.LINKEDIN} />
        </a>
      </div>
      <div className="dashboard__logout" onClick={logout}>
        Wyloguj się
      </div>
    </div>
  );
};

export default DashboardSidebar;
