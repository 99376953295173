import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
// import { Selectors } from 'Modules/UserModule';
import { Actions } from 'Modules/ViewManagementModule';
import { Actions as UserActions } from 'Modules/UserModule';
import { toast } from 'react-toastify';

const init = () => ({
  type: User.EDIT_EMPLYEE_INIT,
});

const success = (userData, uuid) => ({
  type: User.EDIT_EMPLYEE_SUCCESS,
  payload: {
    userData,
    uuid,
  },
});

const failure = () => ({
  type: User.EDIT_EMPLYEE_FAILURE,
});

const editEmployee = (userData: any, userUuid) => async (dispatch, getState) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());

    const state = getState();
    const { roles } = state.userStore.usersList.find((user) => user.uuid === userUuid);
    const role = [];
    if (userData.name !== 'super_admin') {
      roles.forEach(async (singleRole) => {
        return role.push(singleRole.role);
      });
    }
    if (userData.name) {
      await Connector.editUser(
        {
          role:
            role.length > 1 || role[0] === 'super_admin'
              ? Array.isArray(userData.name)
                ? [...userData.name]
                : [userData.name]
              : [...role, userData.name],
        },
        userUuid,
      );
    } else {
      await Connector.editUser({ ...userData, role }, userUuid);
    }
    dispatch(UserActions.getUsers());
    dispatch(success(userData, userUuid));
    toast.success('Pomyślnie edytowano pracownika');
    dispatch(Actions.hideLoader());
  } catch (err) {
    console.log(err);
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default editEmployee;
