import { Connector } from 'Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { Actions } from 'Modules/ViewManagementModule';
import { Actions as UserActions } from 'Modules/UserModule';
import { toast } from 'react-toastify';
import { ROUTES } from 'Shared/enums';
import { history } from 'Store/Reducers/RootReducer';

const init = () => ({
  type: User.ADD_COMPANY_INIT,
});

const success = () => ({
  type: User.ADD_COMPANY_SUCCESS,
});

const failure = () => ({
  type: User.ADD_COMPANY_FAILURE,
});
const placeholder = {
  house_number: '333',
  flat_number: '13',
};
const addCompany = (companyData: any) => async (dispatch) => {
  dispatch(init());
  try {
    dispatch(Actions.showLoader());
    const {
      data: { new_company_uuid },
    } = await Connector.addCompany({ ...companyData, ...placeholder });
    dispatch(success());
    dispatch(UserActions.getCompany(new_company_uuid));
    history.push(`${ROUTES.USERS}/${new_company_uuid}`);
    toast.success('Dodano nowego klienta');
    dispatch(Actions.hideLoader());
  } catch ({ response }) {
    if (response && response.status === 400 && response.data.validation === 'unique' && response.data.field === 'email') {
      toast.error('Podany email już istnieje');
    }
    dispatch(failure());
    dispatch(Actions.hideLoader());
  }
};

export default addCompany;
