import * as React from 'react';
import { ReactTable, Cells, NewRow, HighlightText } from 'Shared/components';
import moment from 'moment';

const prevFilter = {};

const filterMethod = (filter, row, setColumnFilterTerms) => {
  if (prevFilter[filter.id] !== filter.value) {
    prevFilter[filter.id] = filter.value;
    setColumnFilterTerms({ [filter.id]: filter.value.toLowerCase() });
  }
  return row[filter.id].toLowerCase().includes(filter.value.toLowerCase());
};

const getColumns = (
  onEditRowClick,
  isCustomerLogged,
  manageRow,
  areHiddenRowsShown,
  onDeleteRowClick,
  isEditorOrSuperAdmin,
  isCustomerAreaManager,
  userLevel,
  setColumnFilterTerms,
) => [
  {
    id: 'edit',
    accessor: 'edit',
    width: 30,
    headerClassName: 'sticky  sticky--hidden',
    className: 'sticky cell--noPadding cell--white',
    show: isEditorOrSuperAdmin || isCustomerAreaManager,
    filterable: false,
    /* eslint-disable */
    Cell: ({ value, original }) =>
      isEditorOrSuperAdmin || (isCustomerAreaManager && original.company_id) ? (
        <Cells.Edit value={value} original={original} onClick={onEditRowClick} onDeleteClick={onDeleteRowClick} />
      ) : null,
    /* eslint-enable */
  },
  {
    id: 'normative_act',
    accessor: 'normative_act',
    Header: 'Norma',
    style: { fontWeight: '700' },
    width: 200,
    minWidth: 400,
    /* eslint-disable */
    Cell: ({ value, original, column }) => (
      <>
        <NewRow created_at={original.created_at} /> <HighlightText text={value} columnId={column.id} />
      </>
    ),
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
  },
  {
    id: 'requirement',
    accessor: 'requirement',
    Header: 'Tytuł',
    style: { fontWeight: '700' },
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
  },
  {
    id: 'extra_activities',
    accessor: 'extra_activities',
    Header: 'Komentarz',
    style: { fontWeight: '700' },
    // Cell: Cells.ExtendText,
    Cell: ({ value, column }) => <Cells.ExtendText value={value} highlight columnId={column.id} />,
    filterMethod: (filter, row) => filterMethod(filter, row, setColumnFilterTerms),
    /* eslint-enable */
  },
  {
    id: 'updated_at',
    accessor: 'updated_at',
    Header: 'Data aktualizacji',
    filterable: false,
    Cell: ({ original }) => moment(original.updated_at).format('YYYY-MM-DD HH:mm'),
    width: 180,
  },
  {
    id: 'visibility',
    accessor: 'visibility',
    Header: 'WIDOCZNOŚĆ',
    filterable: false,
    width: 150,
    show: isCustomerLogged,
    /* eslint-disable */
    Cell: ({ original: { id } }) => <Cells.VisibilityCell areHiddenRowsShown={areHiddenRowsShown} onClick={() => manageRow('norms', id)} />,
    /* eslint-enable */
  },
];
type P = {
  norms: Array<any>;
  manageRow: (type, id) => void;
  areHiddenRowsShown: boolean;
  isCustomerLogged: boolean;
  isEditorOrSuperAdmin: boolean;
  onEditRowClick: (norms: any) => void;
  onDeleteRowClick: (norms: any) => void;
  isCustomerAreaManager: boolean;
  userLevel: string;
  setColumnFilterTerms: (data: any) => void;
};
const NormsDesktop = (props: P) => {
  const {
    isCustomerLogged,
    norms,
    manageRow,
    areHiddenRowsShown,
    isEditorOrSuperAdmin,
    onEditRowClick,
    onDeleteRowClick,
    isCustomerAreaManager,
    userLevel,
    setColumnFilterTerms,
  } = props;
  return (
    <div className="normsDesktop">
      <ReactTable
        filterable
        areRowsColored
        data={norms}
        columns={getColumns(
          onEditRowClick,
          isCustomerLogged,
          manageRow,
          areHiddenRowsShown,
          onDeleteRowClick,
          isEditorOrSuperAdmin,
          isCustomerAreaManager,
          userLevel,
          setColumnFilterTerms,
        )}
        className="bhpTable bhpTable--norms"
      />
    </div>
  );
};

export default React.memo(NormsDesktop);
